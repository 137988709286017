import React, { useState } from "react";

import {
  useLogout,
  useTitle,
  CanAccess,
  ITreeMenu,
  useNavigation,
} from "@pankod/refine-core";
import { AntdLayout, Menu, Grid, Icons, useMenu } from "@pankod/refine-antd";

import { antLayoutSider, antLayoutSiderMobile } from "./styles";

const { UnorderedListOutlined,
  SettingOutlined, FileDoneOutlined, ReadOutlined, CheckCircleOutlined,
  LogoutOutlined } = Icons;

export const Sider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { mutate: logout } = useLogout();
  const Title = useTitle();
  const { SubMenu } = Menu;

  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const { push } = useNavigation();
  const breakpoint = Grid.useBreakpoint();

  const isMobile = !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item;

      if (children.length > 0) {
        return (
          <SubMenu
            key={name}
            icon={icon ?? <UnorderedListOutlined />}
            title={label}
          >
            {renderTreeView(children, selectedKey)}
          </SubMenu>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);
      return (
        <CanAccess key={route} resource={name.toLowerCase()} action="list">
          <Menu.Item
            key={selectedKey}
            onClick={() => {
              push(route ?? "");
            }}
            style={{
              fontWeight: isSelected ? "bold" : "normal",
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            {label}
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  return (
    <AntdLayout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      breakpoint="sm"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}

    >
      {Title && <Title collapsed={collapsed} />}
      <Menu
        theme="light"
        selectedKeys={[selectedKey]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        onClick={({ key }) => {
          if (key === "logout") {
            logout();
            return;
          }

          if (!breakpoint.lg) {
            setCollapsed(true);
          }

          push(key as string);
        }}
      >
        {/*} {renderTreeView(menuItems, selectedKey)}*/}

        <Menu.SubMenu title="Question Bank" icon={<ReadOutlined />}>
          <Menu.Item
            key="/work_requests"
            onClick={() => {
              push("/work_requests");
            }}
            style={{
              fontWeight: (selectedKey === "/work_requests") ? "bold" : "normal",
            }}
          >
            Work Requests
          </Menu.Item>
          <Menu.Item
            key="/questions"
            onClick={() => {
              push("/questions");
            }}
            style={{
              fontWeight: (selectedKey === "/questions") ? "bold" : "normal",
            }}
          >
            Questions
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu title="Paper Setting" icon={<FileDoneOutlined />}>
          <Menu.Item
            key="/products"
            onClick={() => {
              push("/products");
            }}
            style={{
              fontWeight: (selectedKey === "/products") ? "bold" : "normal",
            }}
          >
            Products
          </Menu.Item>
          <Menu.Item
            key="/test_tracks"
            onClick={() => {
              push("/test_tracks");
            }}
            style={{
              fontWeight: (selectedKey === "/test_tracks") ? "bold" : "normal",
            }}
          >
            Test Tracks
          </Menu.Item>
          <Menu.Item
            key="/paper_templates"
            onClick={() => {
              push("/paper_templates");
            }}
            style={{
              fontWeight: (selectedKey === "/paper_templates") ? "bold" : "normal",
            }}
          >
            Paper Templates
          </Menu.Item>
          <Menu.Item
            key="/papers"
            onClick={() => {
              push("/papers");
            }}
            style={{
              fontWeight: (selectedKey === "/papers") ? "bold" : "normal",
            }}
          >
            Papers
          </Menu.Item>
        
        </Menu.SubMenu>
        <Menu.SubMenu title="Paper Evaluation" icon={<CheckCircleOutlined />}>
          <Menu.Item
            key="/test_events"
            onClick={() => {
              push("/test_events");
            }}
            style={{
              fontWeight: (selectedKey === "/test_events") ? "bold" : "normal",
            }}
          >
            Test Events
          </Menu.Item>
          <Menu.Item>Candidates</Menu.Item>
          <Menu.Item>Tests</Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu title="Setup" icon={<SettingOutlined />}>
          <Menu.Item
            key="/order"
            onClick={() => {
              push("/order");
            }}
            style={{
              fontWeight: (selectedKey === "/order") ? "bold" : "normal",
            }}
          >
            Orders
          </Menu.Item>
          <Menu.Item
            key="/subjects"
            onClick={() => {
              push("/subjects");
            }}
            style={{
              fontWeight: (selectedKey === "/subjects") ? "bold" : "normal",
            }}
          >
            Subjects
          </Menu.Item>
          <Menu.Item
            key="/themes"
            onClick={() => {
              push("/themes");
            }}
            style={{
              fontWeight: (selectedKey === "/themes") ? "bold" : "normal",
            }}
          >
            Themes
          </Menu.Item>
          <Menu.Item
            key="/sub_themes"
            onClick={() => {
              push("/sub_themes");
            }}
            style={{
              fontWeight: (selectedKey === "/subthemes") ? "bold" : "normal",
            }}
          >
            Sub-Themes
          </Menu.Item>
          <Menu.Item
            key="/topics"
            onClick={() => {
              push("/topics");
            }}
            style={{
              fontWeight: (selectedKey === "/topics") ? "bold" : "normal",
            }}
          >
            Topics
          </Menu.Item>
          <Menu.Item
            key="/report_themes"
            onClick={() => {
              push("/report_themes");
            }}
            style={{
              fontWeight: (selectedKey === "/report_themes") ? "bold" : "normal",
            }}
          >
            Report Themes
          </Menu.Item>
          <Menu.Item
            key="/sections"
            onClick={() => {
              push("/sections");
            }}
            style={{
              fontWeight: (selectedKey === "/sections") ? "bold" : "normal",
            }}
          >
            Sections
          </Menu.Item>
          <Menu.Item
            key="/competencies"
            onClick={() => {
              push("/competencies");
            }}
            style={{
              fontWeight: (selectedKey === "/competencies") ? "bold" : "normal",
            }}
          >
            Competencies
          </Menu.Item>
          <Menu.Item
            key="/competency_aspects"
            onClick={() => {
              push("/competency_aspects");
            }}
            style={{
              fontWeight: (selectedKey === "/competency_aspects") ? "bold" : "normal",
            }}
          >
            Competency Aspects
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="logout"
          icon={<LogoutOutlined />}
          onClick={({ key }) => {
            logout();
            return;
          }}>Logout</Menu.Item>
      </Menu>
    </AntdLayout.Sider>
  );
};
