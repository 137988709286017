import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import { useState } from "react";
const { TextArea } = Input;

type CreateTaxProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const CreateTax: React.FC<CreateTaxProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: vatClassSelectProps } = useSelect<IOrg>({
    resource: "vat_class",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });
  const { selectProps: vatGroupSelectProps } = useSelect<IOrg>({
    resource: "vat_group",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const onChange = (key: string) => {
  };

  return (
    <Modal {...modalProps} title="Create VAT Rule">
      <Form {...formProps}
        wrapperCol={{ span: 23 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col span={12}>

            <Form.Item
              label="VAT Name"
              name="name"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Input />
              </Form.Item>
            <Form.Item
              label="VAT class"
              name="vat_class"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select {...vatClassSelectProps} />
              {/* <Select defaultValue="Taxable" onChange={onChange}>
                <Select.Option value="taxable" name="vat_class" >Taxable</Select.Option>
                <Select.Option value="non_Taxable" name="vat_class" >Non Taxable</Select.Option>
              </Select> */}
            </Form.Item>

            <Form.Item
              label="VAT rate"
              name="vat_rate"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Input type="number" min="0" />
              {/* <Select defaultValue="Sales Tax Rate" onChange={onChange}>
                <Select.Option value="sales_tax_rate" name="vat_rate" >Sales Tax Rate</Select.Option>
                <Select.Option value="zero_sales_tax" name="vat_rate" >Zero Sales Tax</Select.Option>
              </Select> */}
            </Form.Item>

            <Form.Item
              label="VAT Customer Group"
              name="vat_customer_group"
              // rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select {...vatGroupSelectProps} />
              {/* <Select defaultValue="Sales Tax Applies" onChange={onChange}>
                <Select.Option value="sales_tax_applies" name="vat_customer_group" >Sales Tax Applies</Select.Option>
                <Select.Option value="no_sales_tax" name="No Sales Tax" >No Sales Tax</Select.Option>
              </Select> */}
            </Form.Item>
            {user ? <>
            
            {user?.defaultRole === "admin" ?
              <Form.Item
                label="Orderbit Organisation"
                name="ob_org_id"
                rules={[{ required: true, }]} >
                <Select {...OBOrgSelectProps} />
              </Form.Item> :
              <Form.Item
                name="ob_org_id"
                initialValue={user?.metadata?.org_id}
              >
                <Input hidden />
              </Form.Item>
            }

            <Form.Item name="created_by"
              initialValue={user?.id}
            >
              <Input type="hidden" />
            </Form.Item>
            </> : <></>}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
