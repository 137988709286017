import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    Checkbox,
    DatePicker,
    InputNumber,Select,useSelect
  } from "@pankod/refine-antd";
  import { useGetIdentity } from "@pankod/refine-core";
  import {IPrice,IOrg, ICategory} from "interfaces";
  import React, {useState} from 'react';
  import moment from 'moment';
  
  
  type CreateCouponsProductProps = {
    modalProps: ModalProps;
    formProps: FormProps;
  };
  
  export const CreateCouponsProduct: React.FC<CreateCouponsProductProps> = ({
    modalProps,
    formProps,
  }) => {
    const { data: user } = useGetIdentity();
  const[privacy, setPrivacy] = useState(false)
  const[options, setOptions] = useState("Percentage")

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });
    
    const onChangeCheckboxPrivacy = (e: any) => {
        let checkbox =  e.target.checked
        setPrivacy(checkbox)
      };

    const getOptions = (e:any) => {
        setOptions(e)
    }
  
    return (
      <Modal {...modalProps} title="Create Coupons">
        <Form {...formProps} size="large" layout="vertical">
          <Form.Item
            label="Code"
            name="code"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input 
            style={{width:"45%"}}
            />
          </Form.Item>
          {user?.defaultRole === "admin" ?
          <Form.Item
            label="Orderbit Organisation"
            name="ob_org_id"
            rules={[{ required: true, }]} >
            <Select {...OBOrgSelectProps} />
          </Form.Item> : (<>
          { user ? 
          <Form.Item
          name="ob_org_id"
          initialValue={user?.metadata?.org_id}
          style={{display: "none"}}
          >
          <Input hidden />
        </Form.Item>
         : <></> } </>)
          }
          <Form.Item
              label="Coupon type"
              name="coupon_type"
              rules={[{ required: true, }]} 
            style={{width:"45%"}}
            >
        <Select onChange={getOptions}>
            <Select.Option value="Percentage" name="coupon_type">Percentage</Select.Option>
                    <Select.Option value="Amount" name="coupon_type">Amount</Select.Option>
                     </Select>
            </Form.Item>
            {options === "Percentage" ? 
            <Form.Item
            label="Percentage"
            name="percentage"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber  
            style={{width:"45%"}} min="0"
            />
            </Form.Item> : <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber  
            style={{width:"45%"}}
            />
            </Form.Item>}
            <Form.Item
            label="Expires"
            name="expires"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker 
            style={{width:"45%"}}
            disabledDate={(current) => current && current < moment().startOf('day')}
            />
            </Form.Item>
            <Form.Item
            label="Minimum amount of order"
            name="minimum_amount_of_order"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber 
            style={{width:"45%"}} min="0"
            />
            </Form.Item>
          <Form.Item name="one_time_use" valuePropName="checked">
        <Checkbox>One time use</Checkbox>
      </Form.Item>
      <Form.Item name="active" valuePropName="checked">
        <Checkbox>Active</Checkbox>
      </Form.Item>
        {user ? 
          <Form.Item name="created_by" 
          initialValue={user?.id}
          >
            <Input type="hidden"/>
          </Form.Item>
          : <></> }
        </Form>
      </Modal>
    );
  };
  