import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    Checkbox,
    getValueFromEvent,
    Button,
    Space,
    InputNumber,Select,useSelect
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import {IPrice,IOrg, ICategory} from "interfaces";
import React, {useState} from 'react';
import InputColor from 'react-input-color';
import { MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

type EditProductOptionsProps = {
    modalProps: ModalProps;
    formProps: FormProps;
};


export const EditProductOptions: React.FC<EditProductOptionsProps> = ({
    modalProps,
    formProps,
}) => {
    const { data: user } = useGetIdentity();
  const[privacy, setPrivacy] = useState(false)
  const[selectOption, setSelectOption] = useState()

    const onChangeCheckboxPrivacy = (e: any) => {
        let checkbox =  e.target.checked
        setPrivacy(checkbox)
      };

    const { selectProps: OBProductSelectProps } = useSelect<IPrice>({
        resource: "ob_products",
        fetchSize: 1000,
        optionLabel: "name",
        optionValue: "id",
        metaData: {
          fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc", },]
      });
      const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
        resource: "ob_orgs",
        fetchSize: 1000,
        optionLabel: "name",
        optionValue: "id",
        metaData: {
          fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc", },]
      });  
      const { selectProps: categorySelectProps } = useSelect<ICategory>({
        resource: "categories",
        fetchSize: 1000,
        optionLabel: "name",
        optionValue: "id",
        metaData: {
          fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc", },]
      });
      
      const handleSelectOption = (e: any) => {
        setSelectOption(e)
       }
    return (
        <Modal {...modalProps} title="Create Payment Option">
        <Form {...formProps} size="large" layout="vertical">
        <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Code"
            name="code"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
              label="Type"
              name="type"
              rules={[{ required: true, }]} >
        <Select onChange={handleSelectOption}>
            <Select.Option value="List of values" name="currency">List of values</Select.Option>
                    <Select.Option value="Simple text" name="currency">Simple text</Select.Option>
                     <Select.Option value="Add Image" name="currency">Add Image</Select.Option>
                     </Select>
            </Form.Item>
            <Form.Item
            label="View Order"
            name="view_order"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        
          {/* <Form.Item
              label="Category"
              name="category_id"
              rules={[{ required: true, }]} >
              <Select {...categorySelectProps} />
            </Form.Item> */}
{selectOption === "List of values"? 
<>
          <h4>List of Values</h4> 
          <Form.List name="list_value">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          label="Name"
                          rules={[{ required: true, message: 'Missing to initial price' }]}
                        >
                          <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'code']}
                          label="Code"
                          rules={[{ required: true, message: 'Missing to final price' }]}
                        >
                          <Input placeholder="Code" />
                        </Form.Item>
                    <Form.Item
                          {...restField}
                        name={[name, "image"]}
                        valuePropName="fileList"
                       getValueFromEvent={getValueFromEvent}
                       label="Image"
                    >
                        <Input
                        type="file"
                            // name="file"
                            // // action={`${apiUrl}/media/upload`}
                            // listType="picture"
                            // maxCount={5}
                            // multiple
                        >
                        </Input>
                    </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'color_code']}
                          label="Color"
                          rules={[{ required: true, message: 'Missing to percentage' }]}
                        >
                                  <InputColor
        initialValue="#5e72e4"
        // onChange={setColor}
        placement="right"
      />
                        {/* <InputColor placeholder="Choose Colour"/> */}
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{ width: "340px" }}>
                        Add Discounts
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List> </>: ""}
          <Form.Item name="created_by" 
          initialValue={user?.id}
          >
            <Input type="hidden"/>
          </Form.Item>
        </Form>
      </Modal>
    );
};
