import { gql, GraphQLClient } from "graphql-request";
import { nhost } from "utility";

export const getAllUsers = async () => {
  let QUERY = `query {
    users(order_by: {createdAt: desc}) {
      id
      displayName
      email
      metadata
      defaultRole
      createdAt
      updatedAt
      lastSeen
      disabled
      avatarUrl
    }
  }`;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const getAllRoles = async () => {
  let QUERY = `query {
      authRoles {
      role
    }
  }`
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const getLastCreatedUser = async () => {
  let QUERY = `query {
     users(limit: 1, order_by: {createdAt: desc}) {
      displayName
      createdAt
      id
    }
  }`;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const createUser = async ({
  email,
  password,
  defaultRole,
  displayName,
}: {
  email: string;
  password: string;
  defaultRole: string;
  displayName: string;
}) => {
  const { error } = await nhost.auth.signUp({
    email: email,
    password: password,
    options: {
      defaultRole: "user",
      displayName: displayName,
    },
  });
  if (error) {
    return Promise.resolve(error?.message);
  } else {
    getLastCreatedUser().then((response: any) => {
      updateUserRole(response?.users[0]?.id, defaultRole).then((resp) => {
        updateUser(response?.users[0]?.id, defaultRole).then(res => {
          nhost.auth.sendVerificationEmail({ email: `${email}` })
        })
      });
    });
    return Promise.resolve("OK");
  }
};

export const updateUserRole = async (user_id: string, role: string) => {
  let QUERY = gql`mutation insertAuthUserRole {
    insertAuthUserRole(
      object: {
        userId:"${user_id}", role: "${role}"
      }
    ) {
      id
      role
    }
  }`;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const updateUser = async (user_id: string, role: string) => {
  let QUERY = gql`mutation update {
    updateUsers(where: {id:{_eq:"${user_id}"}},_set:{defaultRole:"${role}"}){
      returning{
        id
      }
    }
  }
  `;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const updateUserById = async ({
  email,
  password,
  defaultRole,
  displayName,
  user_id,
  disabled
}: {
  email: string;
  password: string;
  defaultRole: string;
  displayName: string;
  user_id: string;
  disabled: boolean
}) => {

  let QUERY = gql`mutation update {
    updateUsers(where: {id:{_eq:"${user_id}"}},_set:{defaultRole:"${defaultRole}",displayName:"${displayName}",email:"${email}",disabled:${disabled}}){
      returning{
        id
      }
    }
  }
  `;
  const { data, error } = await nhost.graphql.request(QUERY);
  if (error) {
    return Promise.resolve(error);
  }
  else {
    updateUserRole(user_id, defaultRole)
    return Promise.resolve("OK");
  }
};