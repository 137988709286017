import {
  IResourceComponentsProps,
  useExport,
  CrudFilters,
  HttpError,
} from "@pankod/refine-core";

import {
  List,
  RefreshButton,
  CreateButton,
  ExportButton,
  Table,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  Col,
  DatePicker,
  Card,
  Form,
  Select,
  Input,
  Button,
  FormProps,
  useSelect,
  Row,
  Modal,
  Popconfirm,
} from "@pankod/refine-antd";
import { CatalogueMenu } from "components/master/catalogue/menu";
import { useGetIdentity } from "@pankod/refine-core";
import { INews, IPostFilterVariablesNews } from "interfaces";
import { CreateNews } from "./create";
import { EditNews } from "./edit";
import { Delete_News } from "../../../query";
import { CheckCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

const {
  PlusOutlined,
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  CheckOutlined,
  CloseOutlined,
} = Icons;
const { RangePicker } = DatePicker;

export const NewsList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const [open, setOpen] = useState(false);
  const navigate =useNavigate()
  const userId = user?.metadata?.org_id;
  console.log(userId,'');
  
  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    INews,
    HttpError,
    IPostFilterVariablesNews
  >({
    resource: "news",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "title",
        "ob_org_id",
        { org: ["name"] },
        "summary",
        "important",
        "show",
        "content",
        "date",
        "created_at",
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { title, created_at } = params;

      filters.push(
        {
          field: "title",
          operator: "eq",
          value: title !== "" ? title : null,
        },
        {
          field: "created_at",
          operator: "gte",
          value: created_at ? created_at[0].toISOString() : undefined,
        },
        {
          field: "created_at",
          operator: "lte",
          value: created_at ? created_at[1].toISOString() : undefined,
        }
      );
      return filters;
    },
  });
  console.log(tableProps, "tableProps");
  console.log(sorter, "sorter");
  console.log(tableQueryResult, "tableQueryResult");
  console.log(searchFormProps, "searchFormProps");

  let datacount = tableQueryResult?.data?.total;

  const { formProps, modalProps, show } = useModalForm({
    resource: "news",
    action: "create",
    redirect: false,
    autoSubmitClose: true,
    autoResetForm: true,
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "news",
    action: "edit",
    redirect: false,
    autoSubmitClose: true,
    autoResetForm: true,
    metaData: {
      fields: [
        "id",
        "title",
        "ob_org_id",
        "summary",
        "important",
        "show",
        "content",
        "date",
        "created_at",
      ],
    },
  });

  const { triggerExport, isLoading } = useExport<INews>();
  const handleDelete = (data: any) => {
    Delete_News(data).then((repsone) => {
      if (repsone) {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 4000);
        tableQueryResult?.refetch();
      }
    });
  };

  const handleRefresh = () => {
    tableQueryResult?.refetch();
    navigate(0);
  }

  return (
    <List
      title="Orderbit News"
      pageHeaderProps={{
        extra: [
          <Filter formProps={searchFormProps} />,
          //  <ExportButton onClick={triggerExport} loading={isLoading} />,
          <RefreshButton
            style={{ marginTop: "15px" }}
            type="primary"
            children={<div style={{ display: "flex", alignItems: "center" }}>
            <ReloadOutlined style={{ marginRight: "5px" }} />
            Refresh
        </div>}
        icon={false}
        onClick={() => handleRefresh() }
          />,
          <CreateButton
            style={{ marginTop: "15px" }}
            type="primary"
            children={<div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Add News
                </div>}
                icon={false}
            resourceName="store/news"
            onClick={() => show()}
          />,
        ],
      }}
    >
      <span>Records: {datacount}</span>

      <Table
        style={{ marginTop: "5px" }}
        {...tableProps}
        rowKey="id"
        size="small"
      >
        <Table.Column dataIndex="title" title="Title" />
        {user?.defaultRole === "admin" ? (
          <Table.Column<INews>
            dataIndex="ob_org_id"
            title="Org"
            render={(_, record) => record?.org?.name}
            defaultSortOrder={getDefaultSortOrder("ob_org_id", sorter)}
            sorter
          />
        ) : (
          ""
        )}
        <Table.Column dataIndex="summary" title="Summary" />
        <Table.Column dataIndex="date" title="Date" />
        <Table.Column
          title="Important"
          dataIndex="important"
          render={(value) =>
            value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )
          }
        />
        <Table.Column
          title="Show"
          dataIndex="show"
          render={(value) =>
            value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )
          }
        />
        {/* <Table.Column
          dataIndex="content"
          title="Content"
          render={(value) => (
            <span style={{ overflowWrap: "anywhere" }}>{value}</span>
          )}
        /> */}
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column<INews>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                resourceName="store/news"
                onClick={() => editShow(record.id)}
              />
              {/* <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                resourceName="store/news"
                                recordItemId={record?.id}
                            /> */}
              <Popconfirm
                title="Delete?"
                onConfirm={() => handleDelete(record.id)}
              >
                <a style={{ color: "red" }}>Delete</a>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>

      <CreateNews modalProps={modalProps} formProps={formProps} />
      <EditNews modalProps={editModalProps} formProps={editFormProps} />
      <Modal
        visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        footer={null}
        width="27%"
        style={{
          position: "absolute",
          marginTop: "-5%",
          marginLeft: "70%",
          gridRow: "auto",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%" }}>
            Successfully deleted news
          </span>
        </div>
      </Modal>
    </List>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps} style={{ display: "flex" }}>
      <Form.Item label="Search" name="title">
        <Input
          allowClear
          placeholder="ID, Title, Content, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item
        label="Created At"
        name="created_at"
        style={{ marginLeft: "5px" }}
      >
        <RangePicker />
      </Form.Item>
      <Form.Item style={{ marginLeft: "5px", marginTop: "21px" }}>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
