import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Button,
  Upload,
  InputNumber, Select, useSelect, Checkbox
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message } from 'antd';
import { useEffect, useState } from "react";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { nhost } from "utility";
import { GET_ALL_CUSTOMERS_WITH_ORGID, GET_ALL_CUSTOMER_GRPS_WITH_ORGID } from "query";
import { jsonConverter } from "components/helpers/jsonConverter";
const { TextArea } = Input;
type CreateBrandsProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeCreateModal: () => void
  createModalVisible: boolean,
  handleFormSubmitted: () => void,
  tableQueryResult: any;
  viewOrderMax:any;
};

const { Dragger } = Upload;
const { Option } = Select;

const props: UploadProps = {
  beforeUpload: (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSizeInBytes) {
      message.error('File size exceeds the limit (10MB).');
      return false;
    }
    return true;
  },
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
  },
};


export const CreateBrand: React.FC<CreateBrandsProps> = ({
  modalProps,
  formProps,
  closeCreateModal,
  createModalVisible,
  handleFormSubmitted,
  tableQueryResult,
  viewOrderMax,
}) => {
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [active, setActive] = useState(false);
  const [Private, setPrivate] = useState(false);
  const [urlList, setUrlList] = useState([] as any[]);
  const [privacy, setPrivacy] = useState(false);
  const [customers, setCustomers] = useState<any>([]);
  const [customerGrps, setCustomerGrps] = useState<any>([]);
  const [open, setOpen] = useState(false)
  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  useEffect(() => {
    formProps?.form?.resetFields()
    formProps?.form?.setFieldsValue({[ `sort`]:++viewOrderMax})
  },[viewOrderMax])

  const getCustomers = () => {
    GET_ALL_CUSTOMERS_WITH_ORGID(userId).then((data:any) => {
      if(data){        
        setCustomers(data?.customers)
      }
    })
  }

  const getAllCustomerGrps = () => {
    GET_ALL_CUSTOMER_GRPS_WITH_ORGID(userId).then((res:any) => {      
      setCustomerGrps(() => []);
      setCustomerGrps(res?.customer_groups);
    })
  }

  useEffect(() => {
    getCustomers();
    getAllCustomerGrps();
  },[userId, formProps?.initialValues])
  const handleOk = () => {
    setOpen(false)
  }
  // ,active:${e.active},private:${e.private}

  const apidata = (e: any) => {
    e.access_customer_ids = e.access_customer_ids ?? null
    e.access_customergroup_ids = e.access_customergroup_ids ?? null
    const MUTATIONAPI = `mutation MyMutation {
        insert_brands(objects: { name: "${e.name}", description: "${e.description}",sort:"${e.sort}",active:${e.active},private:${e.private},image: ${e.image ? `"${e.image}"` : null},
        access_customer_ids: ${jsonConverter(e.access_customer_ids)}, access_customergroup_ids: ${jsonConverter(e.access_customergroup_ids)},featured_brand: ${e.featured_brand},
        ob_org_id: "${user?.metadata?.org_id ? user?.metadata?.org_id : "e92f767c-febd-4fe3-9e0e-59fd0d0291ac"}",created_by:"${user?.id}"
      }){
          affected_rows
        }
      }
      `
    const imageSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      if (data) {
        // handleFormSubmitted()
        setOpen(true)
        setTimeout(() => {
          setOpen(false)
        }, 4000);
        tableQueryResult?.refetch()
        closeCreateModal()
        formProps?.form?.resetFields();
        return data
      }
    }
    imageSubmit()
  }

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setUrlList(urlList => [...urlList, data.secure_url]);
          })
          .catch(err => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const handleCustomerSearch = (search:any) => {
    if(search?.length > 0){
    const searchValue = customers?.filter((data:any,i:number) => {            
     return data?.name?.toLowerCase().includes(search?.toLowerCase())
    })
    if(searchValue?.length !== 0){
      setCustomers([...searchValue])
    }
    else{
      getCustomers();
    }
  }
  else{
    getCustomers();
  }
  }

  const handleCustomerGrpSearch = (customerGrpsearch: string) => {

    if (customerGrpsearch?.length > 0) {
      const filterProduct = customerGrps?.filter((cusGrp: any) => {
        return cusGrp?.group_name?.toLowerCase().includes(customerGrpsearch?.toLocaleLowerCase())
      })
      if (filterProduct?.length != 0) {
        setCustomerGrps([...filterProduct])
      }
      else {
        getAllCustomerGrps();
      }
    } else {
      getAllCustomerGrps();
    }
  };
 
  const onChangeCheckboxPrivacy = (e: any) => {
    let checkbox = e.target.checked
    setPrivacy(checkbox);
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setActive(e.target.checked);
  };
  const onChangeViewCheckbox = (e: CheckboxChangeEvent) => {
    setPrivate(e.target.checked);
  };

  return (
    <>
      <Modal {...modalProps} title="Create Brand"
        onCancel={closeCreateModal}
        visible={createModalVisible}
      >
        <Form {...formProps} onFinish={(e) => {
          e.image = urlList.join(",")
          apidata(e)
        }} size="large" layout="vertical">
          <Form.Item
            label="Brand Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            // rules={[{ required: true, message: 'Please input your description!' }]}
          >
            <TextArea rows={4} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Sort"
            name="sort"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Image"
          >
            <div style={{ marginTop: '-15px' }}>
              <Dragger {...props} name="image" customRequest={uploadImages}
                style={{ width: '100%', marginTop: '30px' }}>
                <p className="ant-upload-drag-icon">
                  <PictureOutlined />
                </p>
                <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
              </Dragger>
            </div>
            <Form.Item
              style={{ width: '100%' }}
            >
              <h1 style={{ marginTop: '10px', fontSize: '15px' }}>Uploaded image will be displayed here</h1>
              {urlList.map((url) => {
                return <img style={{ width: '10%', padding: '5px' }} src={url} />
              })}
            </Form.Item>
          </Form.Item>
          <Form.Item name="active" valuePropName="checked" initialValue={true}>
            <Checkbox name='active' checked={active} onChange={onChangeCheckbox}>Active</Checkbox>
          </Form.Item>
                <Form.Item
                  label=""
                  name="featured_brand"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox name="featured_brand">
                    Show in Featured Brand (homepage)
                  </Checkbox>
                </Form.Item>
          {/* <Form.Item name="private" valuePropName="checked">
            <Checkbox name='private' checked={Private} onChange={onChangeViewCheckbox}>Private</Checkbox>
          </Form.Item> */}
          <p>By making a brand private, it will be visible only to selected customers.</p>
          <div>
                <Form.Item
                  label=""
                  name="private"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox
                    name="private"
                    onChange={onChangeCheckboxPrivacy}
                  >
                    Private
                  </Checkbox>
                </Form.Item>
                {privacy === true ? (
                  <>
                    {user?.defaultRole === "admin" ? (
                      <Form.Item
                        name="access_customer_id"
                        label="Customer"
                        style={{ width: "210px" }}
                      >
                        {/* <Select {...OBCustomerSelectProps} /> */}
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Select Customers"
                        name="access_customer_ids"
                      >
                        <Select
                          mode="multiple"
                          showSearch
                          onSearch={handleCustomerSearch}
                          // onChange={handleSelectedChange}
                          filterOption={false}
                        >
                          {customers?.map((data: any) => {
                            return <Option key={data?.id}>{data?.name}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item
                      name="access_customergroup_ids"
                      label="Customer Groups"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder=""
                        onSearch={handleCustomerGrpSearch}
                        filterOption={false}
                        defaultValue={
                          formProps?.initialValues?.access_customergroup_ids
                        }
                      >
                        {customerGrps?.map((e: any, i: any) => {
                          return <Option key={e?.id}>{e?.group_name}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </>
                ) : (
                  ""
                )}
              </div>
          <Form.Item name="created_by"
            initialValue={user?.id}
          >
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleOk}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully created brand
          </span>
        </div>
      </Modal>
    </>
  );
};
