import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber,Select,useSelect
  } from "@pankod/refine-antd";
  import { useGetIdentity } from "@pankod/refine-core";
import {IOrg} from "interfaces";
import { useState } from "react";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";
  
  type CreateEmailTemplateProps = {
    modalProps: ModalProps;
    formProps: FormProps;
  };
  
  export const CreateEmailTemplate: React.FC<CreateEmailTemplateProps> = ({
    modalProps,
    formProps,
  }) => {
    const { data: user } = useGetIdentity();
    const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
      resource: "ob_orgs",
      optionLabel: "name",
      optionValue: "id",
      metaData: {
        fields: ["id", "name"],
      },
      sort: [{ field: "name", order: "asc", },]
    });

    const [selectedTab, setSelectedTab] = useState<"write" | "preview">(
      "write",
  );

    return (
      <Modal {...modalProps} title="Create Email Template">
        <Form {...formProps} size="large" layout="vertical">
          <Form.Item
                    label="Content"
                    name="content"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <ReactMde
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown) =>
                            Promise.resolve(
                                <ReactMarkdown>{markdown}</ReactMarkdown>,
                            )
                        }
                    />
                </Form.Item>
                {user?.defaultRole === "admin" ?
                <Form.Item
                  label="Orderbit Organisation"
                  name="ob_org_id"
                  rules={[{ required: true, }]} >
                  <Select {...OBOrgSelectProps} />
                </Form.Item> : 
                <Form.Item
                name="ob_org_id"
                initialValue={user?.metadata?.org_id}
                >
                <Input hidden />
              </Form.Item>
                }
          <Form.Item name="created_by" 
          initialValue={user?.id}
          >
            <Input type="hidden"/>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  