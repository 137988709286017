import { useNavigation, useShow, IResourceComponentsProps, useExport, CrudFilters, useMany, HttpError } from "@pankod/refine-core";
import {
  List,
  RefreshButton,
  Table,
  Space,
  DeleteButton,
  Icons,
  Typography,
  DateField,
  useModalForm,
  useModal,
  CreateButton,
  EditButton, FormProps, Form, Input, Button, DatePicker, useTable
} from "@pankod/refine-antd";
import { TableColumnsType, Tag } from "antd";
import { getAllUsers } from "users";
import { useState, useEffect } from "react";
import { IUser, IPostUserFilterVariables } from "interfaces";
import { UserCreate } from "pages/master/users/create";
import { UserEdit } from "pages/master/users/edit";
const { PlusOutlined, AppstoreOutlined, CopyOutlined } = Icons;
const { Text, Link } = Typography;
const { RangePicker } = DatePicker;




export const UserManagementTable = () => {
  const { show } = useNavigation();
  const [refresh, setRefresh] = useState(false);
  const [userList, setUserList] = useState([] as any[]);
  const [userRecord, setUserRecord] = useState([]);
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;


  // const { searchFormProps } = useTable<IUser, HttpError, IPostUserFilterVariables>({
  //   resource: "users",
   
  //   metaData: {
  //     fields: [
  //       "id",
  //       "disabled",
  //       "displayName",
  //       "defaultRole",
  //       "email",
  //       // "password",
  //     ],
  //   },
  //   onSearch: (params: any) => {
  //     const filters: CrudFilters = [];
  //     const { displayName, created_at } = params;

  //     filters.push(
  //       {
  //         field: "displayName",
  //         operator: "eq",
  //         value: displayName !== '' ? displayName : null,
  //       },
  //       {
  //         field: "created_at",
  //         operator: "gte",
  //         value: created_at ? created_at[0].toISOString() : undefined,
  //       },
  //       {
  //         field: "created_at",
  //         operator: "lte",
  //         value: created_at ? created_at[1].toISOString() : undefined,
  //       },
  //     );

  //     return filters;
  //   },
  // });
  // const metaData = {
  //   fields: [
  //     "id",
  //     "disabled",
  //     "displayName",
  //     "defaultRole",
  //     "email",
  //     "password",
  //   ],
  // };
  const getUsers = async () => {
    getAllUsers().then((response: any) => {
      setUserList(response.users);
    });
  };
  useEffect(() => {
    getUsers();
  }, [refresh]);
  const getUserDetails = (record: any) => {
    setUserRecord(record);
    editShow();
  };
  const {
    modalProps,
    formProps,
    close: close,
    show: createShow,
  } = useModalForm({
    resource: "users",
    action: "create",
    autoSubmitClose: true,
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
    close: editClose,
  } = useModalForm({
    // resource: "users",
    action: "edit",
  });
  const columns: TableColumnsType<IUser> = [
    // {
    //   dataIndex: "id",
    //   title: "Id",

    // },
    {
      dataIndex: "displayName",
      title: "User Name",
    },
    {
      dataIndex: "email",
      title: "Email id",
    },
    {
      dataIndex: "defaultRole",
      title: "Role",
    },
    {
      dataIndex: "disabled",
      title: "Active",
      render: (value) => (
        <Tag color={value ? "red" : "green"} key={value}>
          {value ? "INACTIVE" : "ACTIVE"}
        </Tag>
      ),

      //  return (
      //       <Tag color={color} key={tag}>
      //         {tag.toUpperCase()}
      //       </Tag>
      //     );
    },
    {
      dataIndex: "createdAt",
      title: "Created_at",
      render: (value) => <DateField format="DD-MMM-YY" value={value} />,
    },
    {
      title: "Updated_at",
      dataIndex: "updatedAt",
      render: (value) => <DateField format="DD-MMM-YY" value={value} />,
    },
    {
      title: "Last seen",
      dataIndex: "lastSeen",
      render: (value) =>
        value !== null ? <DateField format="DD-MMM-YY" value={value} /> : "",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <Space>

          <EditButton
            size="small"
            type="link"
            hideText
            title="Edit"
            resourceName="master/users"
            recordItemId={record.id}
            onClick={() => {
              getUserDetails(record);
              // editShow(record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const onChange = (page: number, pageSize?: number): void => {
    // setCurrent(page)
    ;
  };
  const refreshData = () => {
    setRefresh(!refresh);
  };
  return (
    <List
      pageHeaderProps={{
        extra: [
          // <Filter formProps={searchFormProps} />,
          <RefreshButton style={{ marginTop: '15px' }} onClick={() => getUsers()} />,
          <CreateButton
            style={{ marginTop: '15px' }}
            type="primary"
            resourceName="master/users"
            icon={<PlusOutlined />}
            onClick={() => createShow()}
          />,
        ],
      }}
    >
      <Table
        size="small"
        columns={columns}
        dataSource={userList}
        pagination={{
          total: userList?.length,
          onChange,
        }}
      />

      <UserCreate
        modalProps={modalProps}
        formProps={formProps}
        close={close}
        refreshData={refreshData}
      />
      <UserEdit
        modalProps={editModalProps}
        formProps={editFormProps}
        close={editClose}
        record={userRecord}
        refreshData={refreshData}
      />


    </List>
  );
};



const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps} style={{ display: 'flex' }}>
      <Form.Item label="Search" name="displayName">
        <Input
          allowClear
          placeholder="ID, Title, Content, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Created At" name="created_at" style={{ marginLeft: '5px' }}>
        <RangePicker />
      </Form.Item>
      <Form.Item style={{ marginLeft: '5px', marginTop: '20px' }}>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};

function useGetIdentity(): { data: any; } {
  throw new Error("Function not implemented.");
}
