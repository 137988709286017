
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
import { Editor } from '@tinymce/tinymce-react';
import { IPrice, IOrg, ICategory } from "interfaces";
import { Col, Row } from 'antd';
import {
  List,
} from "@pankod/refine-antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  useSelect
} from "@pankod/refine-antd";
// import ReactMarkdown from 'react-markdown'
// import ReactMde from 'react-mde'
// import {ReactMdeToolbarCommands} from ''
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import { Tabs, Form, Input, Button, InputNumber, Dropdown, Space, Tooltip, Menu, Select, Checkbox, Popconfirm, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState, } from 'react';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const onChange = (key: string) => {
};

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}



interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >

        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  // if (editabledropdown) {
  //   childNode = editing ? (
  //     <Form.Item
  //       style={{ margin: 0 }}
  //       name={dataIndex}
  //       rules={[
  //         {
  //           required: true,
  //           message: `${title} is required.`,
  //         },
  //       ]}
  //     >
  //       <Select>
  //                   <Select.Option value="string">String</Select.Option>
  //                   <Select.Option value="dropdown">Dropdown</Select.Option>
  //                   <Select.Option value="number">Number</Select.Option>
  //                 </Select>
  //     </Form.Item>
  //   ) : (
  //     <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
  //       {children}
  //     </div>
  //   );
  // }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  // name: string;
  // age: string;
  // address: string;
  pricelist: string,
  initialprice: string,
  fromquantity: string,
  percentage: string,
  dateform: string,
  dateto: string,
  finalprice: string,
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const props: UploadProps = {
  beforeUpload: (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSizeInBytes) {
      message.error('File size exceeds the limit (10MB).');
      return false;
    }
    return true;
  },
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
  },
};

const handleMenuClick: MenuProps['onClick'] = e => {
  message.info('Click on menu item.');
};

const handleMenuProductClick = () => {
  message.info('Please add product options before creating product variants. Product variants will be created based on the combination of the options you add.');
}

const onChangeCheckbox = (e: CheckboxChangeEvent) => {
};

const menu = (
  <Menu
    onClick={handleMenuClick}
    items={[
      {
        label: '1st menu item',
        key: '1',
        icon: <UserOutlined />,
      },
      {
        label: '2nd menu item',
        key: '2',
        icon: <UserOutlined />,
      },
      {
        label: '3rd menu item',
        key: '3',
        icon: <UserOutlined />,
      },
    ]}
  />
);

export const AddProductsList: React.FC = () => {
  // const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')
  const [privacy, setPrivacy] = useState(false)
  const editorRef = useRef(null);

  const { selectProps: OBProductSelectProps } = useSelect<IPrice>({
    resource: "pricelists",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const log = () => {
    if (editorRef.current) {
    }
  };

  const onFinish = (values: any) => {
  };

  const onFinishFailed = (errorInfo: any) => {
  };

  const onChangeNumber = (value: number) => {
  };

  const [dataSource, setDataSource] = useState<DataType[]>([
    {
      key: '1',
      pricelist: 'Enter PriceList',
      initialprice: 'Enter Initial Price',
      fromquantity: 'Enter From Quantity',
      percentage: 'Enter Percentage',
      dateform: 'Enter From Date',
      dateto: 'Enter To Date',
      finalprice: 'Enter Final Price',
    }
  ]);

  const [count, setCount] = useState(2);

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter(item => item.key !== key);
    setDataSource(newData);
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Price List',
      dataIndex: 'pricelist',
      // width: '30%',
      editable: true,
    },
    {
      title: 'Initial Price',
      dataIndex: 'initialprice',
      editable: true,
    },
    {
      title: 'From quantity',
      dataIndex: 'fromquantity',
      editable: true,
    },
    {
      title: 'Percentage (%)',
      dataIndex: 'percentage',
      editable: true,
    },
    {
      title: 'Date from',
      dataIndex: 'dateform',
      editable: true,
    },
    {
      title: 'Date to',
      dataIndex: 'dateto',
      editable: true,
    },
    {
      title: 'Final price',
      dataIndex: 'finalprice',
      editable: true,
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      // render: (_:any, record: { key: React.Key }) =>
      //   dataSource.length >= 1 ? (
      //     <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
      //       <a>Delete</a>
      //     </Popconfirm>
      //   ) : null,
    },
  ];

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      pricelist: 'Enter PriceList',
      initialprice: 'Enter Initial Price',
      fromquantity: 'Enter From Quantity',
      percentage: 'Enter Percentage',
      dateform: 'Enter From Date',
      dateto: 'Enter To Date',
      finalprice: 'Enter Final Price',
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const onChangeCheckboxPrivacy = (e: any) => {
    let checkbox = e.target.checked
    setPrivacy(checkbox)
  };

  return (
    <List>
      <Form
        name="basic"
        wrapperCol={{ span: 23 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Tabs defaultActiveKey="1" onChange={onChange}>
          <TabPane tab="Product" key="1">
            <Row>
              <Col span={12}>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="Name"
                      rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="code"
                      label="Code"
                      rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Editor
                    apiKey='your-api-key'
                    // onInit={(evt, editor) => editorRef.current = editor}
                    initialValue="<p>This is the initial content of the editor.</p>"
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="Upload"
                  label="Main Image"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <PictureOutlined />
                    </p>
                    <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                  </Dragger>
                </Form.Item>
                <h3>Dimentions</h3>
                <Form.Item
                  name="Upload"
                  label="Length"
                  rules={[{ required: true, message: 'Please input your username!' }]}>

                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="Upload"
                  label="Width"
                  rules={[{ required: true, message: 'Please input your username!' }]}>

                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="Upload"
                  label="Height"
                  rules={[{ required: true, message: 'Please input your username!' }]}>

                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                {/* <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={markdown => Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)}
                toolbarCommands={ReactMdeToolbarCommands}
              /> */}
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>

              </Col>
              <Col span={12}>
                <Form.Item
                  name="Upload"
                  label="Categories"
                  rules={[{ required: true, message: 'Please input your username!' }]}>
                  <Dropdown overlay={menu}>
                    <Button style={{ width: "100%" }}>
                      Select Categories
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Form.Item>

                <Form.List name="fields">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => add()}
                            style={{ width: "60%" }}
                          >
                            <PlusOutlined /> Add to more Category
                          </Button>
                        </Form.Item>
                        {fields.map((field, index) => (
                          <div key={field.key} style={{ display: "flex", justifyContent: "space-between" }}>
                            <Form.Item
                              name={[index, "type"]}
                              rules={[{ required: true }]}
                              style={{ width: "100%" }}
                            >
                              <Select>
                                <Select.Option value="string">String</Select.Option>
                                <Select.Option value="dropdown">Dropdown</Select.Option>
                                <Select.Option value="number">Number</Select.Option>
                              </Select>
                            </Form.Item>
                            {fields.length > 0 ? (
                              <Button
                                className="primary dynamic-delete-button"
                                onClick={() => remove(field.name)}
                                icon={<MinusCircleOutlined />}
                              >
                              </Button>
                            ) : null}
                          </div>
                        ))}

                      </div>
                    );
                  }}
                </Form.List>
                <Form.Item
                  name="Upload"
                  label="Best Price List"
                  rules={[{ required: true, message: 'Please input your username!' }]}>

                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="Upload"
                  label="Wholesale Price List"
                  rules={[{ required: true, message: 'Please input your username!' }]}>

                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="Upload"
                  label="Discounted Price List"
                  rules={[{ required: true, message: 'Please input your username!' }]}>
                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="Upload"
                  label="VIP Price List"
                  rules={[{ required: true, message: 'Please input your username!' }]}>
                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="Upload"
                  label="MSRP"
                  rules={[{ required: true, message: 'Please input your username!' }]}>
                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="Upload"
                  label="Minimum quantity"
                  rules={[{ required: true, message: 'Please input your username!' }]}>
                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="Upload"
                  label="Maximum quantity"
                  rules={[{ required: true, message: 'Please input your username!' }]}>
                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="Upload"
                  label="quantity"
                  rules={[{ required: true, message: 'Please input your username!' }]}>
                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Checkbox onChange={onChangeCheckbox}>Track inventory</Checkbox>
                <Checkbox onChange={onChangeCheckbox}>Allow backorder</Checkbox>
                <Form.Item
                  name="Upload"
                  label="Box Quantity / Multiples"
                  rules={[{ required: true, message: 'Please input your username!' }]}>
                  <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  label="Status"
                  name="type"
                  rules={[{ required: true }]}
                  style={{ width: "100%" }}
                >
                  <Select>
                    <Select.Option value="string">String</Select.Option>
                    <Select.Option value="dropdown">Dropdown</Select.Option>
                    <Select.Option value="number">Number</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="VAT class"
                  name="type"
                  rules={[{ required: true }]}
                  style={{ width: "100%" }}
                >
                  <Select>
                    <Select.Option value="string">String</Select.Option>
                    <Select.Option value="dropdown">Dropdown</Select.Option>
                    <Select.Option value="number">Number</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Brand"
                  name="type"
                  rules={[{ required: true }]}
                  style={{ width: "100%" }}
                >
                  <Select>
                    <Select.Option value="string">String</Select.Option>
                    <Select.Option value="dropdown">Dropdown</Select.Option>
                    <Select.Option value="number">Number</Select.Option>
                  </Select>
                </Form.Item>
                <Checkbox onChange={onChangeCheckbox}>Is active?</Checkbox>
              </Col>
            </Row>

          </TabPane>
          <TabPane tab="Details" key="2">
            <div>
              <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
              </Button>
              <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns as ColumnTypes}
              />
            </div>
          </TabPane>
          <TabPane tab="Customer Prices" key="3">
            <div>
              <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
              </Button>
              <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns as ColumnTypes}
              />
            </div>
          </TabPane>
          <TabPane tab="Related Products" key="4">
            <div>
              <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
              </Button>
              <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns as ColumnTypes}
              />
            </div>
          </TabPane>
          <TabPane tab="Product Options" key="5">
            <div>
              <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add a row
              </Button>
              <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns as ColumnTypes}
              />
            </div>
          </TabPane>
          <TabPane tab="Code and Price Variants" key="6">
            <Button type="primary" onClick={handleMenuProductClick}> Create Code Price Variants</Button>
          </TabPane>
          <TabPane tab="Promotions" key="7">
            <Checkbox onChange={onChangeCheckbox}>Track inventory</Checkbox>
            <Checkbox onChange={onChangeCheckbox}>Allow backorder</Checkbox>
            <Form.Item
              label="Brand"
              name="type"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select>
                <Select.Option value="string">String</Select.Option>
                <Select.Option value="dropdown">Dropdown</Select.Option>
                <Select.Option value="number">Number</Select.Option>
              </Select>
            </Form.Item>
          </TabPane>
          <TabPane tab="Images" key="8">
            <Form.Item
              name="Upload"
              label="Main Image"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <PictureOutlined />
                </p>
                <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
              </Dragger>
            </Form.Item>
          </TabPane>
          <TabPane tab="Files" key="9">
            <div>
              <Form.List name="productfile">
                {(productfile, { add, remove }) => {
                  return (
                    <div>
                      {productfile.map((field, index) => (
                        <div key={field.key} style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                          <div>
                            <Form.Item
                              name="title"
                              label="Title"
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="type"
                              label="Image Files"
                              rules={[{ required: true }]}
                              style={{ width: "184px" }}
                            >
                              <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                              </Upload>
                            </Form.Item>
                          </div>

                          <div>
                            {productfile.length > 0 ? (
                              <Button
                                className="primary dynamic-delete-button"
                                onClick={() => remove(field.name)}
                                icon={<MinusCircleOutlined />}
                              >
                                Remove
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => add()}
                        >
                          <PlusOutlined /> Add Files
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </div>
          </TabPane>
          <TabPane tab="Advanced" key="10">
            <div>
              <Form.Item
                name="upccode"
                label="UPC code"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input style={{ width: "50%" }} />
              </Form.Item>
              <Form.Item
                name="barcode"
                label="Barcode"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input style={{ width: "50%" }} />
              </Form.Item>
              <Form.Item
                name="referencecode"
                label="Reference code"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input style={{ width: "50%" }} />
              </Form.Item>
              <Form.Item
                name="name"
                label="Package quantity"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <InputNumber defaultValue="0.0" onChange={onChange} style={{ width: "50%" }} />
              </Form.Item>
              <Form.Item
                name="name"
                label="Meta description"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <TextArea rows={4} style={{ width: "50%" }} />
              </Form.Item>
              <Form.Item
                name="name"
                label="PDF description (suggested max length: 12 lines)"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Editor
                  apiKey='your-api-key'
                  // onInit={(evt, editor) => editorRef.current = editor}
                  initialValue="<p>This is the initial content of the editor.</p>"
                  init={{
                    height: 200,
                    width: 600,
                    menubar: false,
                    plugins: [
                      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                      'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                      'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item
              name="searchkeywords"
              label="Search keywords"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input style={{ width: "50%" }} />
            </Form.Item>
          </TabPane>
          <TabPane tab="Product Status Rules" key="11">
            <div>
              <Form.List name="productstatus">
                {(productstatus, { add, remove }) => {
                  return (
                    <div>
                      {productstatus.map((field, index) => (
                        <div key={field.key}>
                          <Form.Item
                            name="type"
                            label="From Quantity"
                            rules={[{ required: true }]}
                            style={{ width: "50%" }}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="type"
                            label="To Quantity"
                            rules={[{ required: true }]}
                            style={{ width: "50%" }}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="type"
                            label="Status"
                            rules={[{ required: true }]}
                            style={{ width: "50%" }}
                          >
                            <Select>
                              <Select.Option value="string">String</Select.Option>
                              <Select.Option value="dropdown">Dropdown</Select.Option>
                              <Select.Option value="number">Number</Select.Option>
                            </Select>
                          </Form.Item>
                          {productstatus.length > 0 ? (
                            <Button
                              className="primary dynamic-delete-button"
                              onClick={() => remove(field.name)}
                              icon={<MinusCircleOutlined />}
                            >
                              Remove
                            </Button>
                          ) : null}

                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => add()}
                        >
                          <PlusOutlined /> Add Rules
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </div>
          </TabPane>
          <TabPane tab="Access" key="12">
            <div>
              <Checkbox onChange={onChangeCheckboxPrivacy}>Private</Checkbox>
              {
                privacy === true ?
                  <>
                    <Form.Item
                      name="type"
                      label="Customers"
                      rules={[{ required: true }]}
                      style={{ width: "50%" }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="type"
                      label="Privacy groups"
                      rules={[{ required: true }]}
                      style={{ width: "50%" }}
                    >
                      <Input />
                    </Form.Item>
                  </> : ""
              }
            </div>
          </TabPane>
        </Tabs>
      </Form>
    </List>
  );
};
