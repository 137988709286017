
import { IResourceComponentsProps, useExport, useGetIdentity } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons, Popconfirm, Modal, Form, Select, useSelect
} from "@pankod/refine-antd";
import { CatalogueMenu } from "components/setup/catalogue/menu";
import { IPrice } from "interfaces";
import { CreateProduct } from "./create";
import { EditProduct } from "./edit";
import { Delete_pricelists } from "../../../query";
import React, { useEffect, useState } from 'react';
import { CheckCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;


export const PriceList: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
    const navigate = useNavigate();
    const userId = user?.metadata?.org_id;
    const [open, setOpen] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [brandId, setBrandId] = useState(null);
    const { tableProps, sorter, tableQueryResult } = useTable<IPrice>({
        resource: "pricelists",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
            {
                field: "id",
                operator: "eq",
                value: brandId,
            },
        ],
        metaData: {
            fields: ["id", "name", "created_at", "short_name", "currency", "basedon", "discount_percentage", "default"],
        },
    });
    const { formProps, modalProps, show } = useModalForm({
        resource: "pricelists",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,

    } = useModalForm({
        resource: "pricelists",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "name", "created_at", "short_name", "currency", "basedon", "discount_percentage", "default"],
        },
    });

    const { selectProps } = useSelect<any>({
        resource: "pricelists",
        metaData: {
          fields: ["id", "name"],
        },
        optionLabel: "name",
        optionValue: "id",
        filters:[
          {
            field:"ob_org_id",
            operator:"eq",
            value:userId
          }
         ],
        onSearch: (value:any) => [
          {
            field: "name",
            operator: "contains",
            value: "%" + value + "%",
          },
        ],
      });

    const handleDelete = (data: any) => {
        Delete_pricelists(data).then((repsone) => {
            if (repsone) {
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 4000);
                tableQueryResult?.refetch()
            }
        })
    }

    const handleCloseCreateModal = () => setCreateModalVisible(false);
    const handleCloseEditModal = () => setEditModalVisible(false);

    const { triggerExport, isLoading } = useExport<IPrice>();
    let datacount = tableQueryResult?.data?.total;

    useEffect(() => {
        setTimeout(() => {
            tableQueryResult?.refetch()
        }, 1000);
    },[createModalVisible == true, editModalVisible == true])

    const handleSearch = (value: any) => {
      setBrandId(value);
    };

    const handleOnClearSearch = () => {
      setBrandId(null);
    };
    const handleRefresh = () => {
      tableQueryResult?.refetch();
      navigate(0);
    };
    return (
      <List
        title="Price lists"
        pageHeaderProps={{
          extra: [
            <>
              <Form
                layout="vertical"
                style={{ display: "flex", marginBottom: 17 }}
              >
                <Form.Item label="Pricelist">
                  <Select
                    placeholder="Select a Pricelist Name"
                    style={{ width: 300, marginRight: 20 }}
                    onSelect={handleSearch}
                    clearIcon="*"
                    allowClear
                    onClear={handleOnClearSearch}
                    {...selectProps}
                  />
                </Form.Item>
              </Form>
              {/* <ExportButton onClick={triggerExport} loading={isLoading} />, */}
              <RefreshButton
                onClick={handleRefresh}
                type="primary"
                children={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ReloadOutlined style={{ marginRight: "5px" }} />
                    Refresh
                  </div>
                }
                icon={false}
              />
              ,
              <CreateButton
                type="primary"
                resourceName="settings/customergroups"
                children={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Add Price list
                  </div>
                }
                icon={false}
                onClick={() => setCreateModalVisible(true)}
                // onClick={() => show()}
              />
              ,
            </>,
          ],
        }}
      >
        <span>Records: {datacount}</span>
        <Table {...tableProps} rowKey="id" size="small">
          <Table.Column dataIndex="name" title="Name" sorter />
          <Table.Column dataIndex="short_name" title="Short Name" sorter />
          <Table.Column dataIndex="currency" title="Currency" sorter />
          {/* <Table.Column dataIndex="basedon" title="Based On" sorter />
                <Table.Column dataIndex="discount_percentage" title="Discount percentage" sorter /> */}
          {/* <Table.Column<IProduct> dataIndex="category_id" title="Category" 
                 render={(_, record) => record?.category?.name}
                 defaultSortOrder={getDefaultSortOrder("category_id", sorter)}
                 sorter/> */}

          <Table.Column
            dataIndex="created_at"
            title="Created At"
            render={(value) => <DateField value={value} format="LLL" />}
            defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
            sorter
          />

          <Table.Column<IPrice>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton
                  size="small"
                  type="link"
                  hideText={true}
                  resourceName="settings/customergroups"
                  recordItemId={record.id}
                  onClick={() => {
                    editShow(record.id);
                    setEditModalVisible(true);
                  }}
                />
                {/* <DeleteButton
                                size="small"
                                type="link"
                                resourceName="catalogue/pricelist"
                                hideText={true}
                                recordItemId={record.id}
                            /> */}
                <Popconfirm
                  title="Delete?"
                  onConfirm={() => handleDelete(record.id)}
                >
                  <a style={{ color: "red" }}>Delete</a>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
        <CreateProduct
          modalProps={modalProps}
          formProps={formProps}
          closeCreateModal={handleCloseCreateModal}
          openCreateModal={createModalVisible}
        />
        <EditProduct
          modalProps={editModalProps}
          formProps={editFormProps}
          closEditModal={handleCloseEditModal}
          openEditModal={editModalVisible}
        />
        <Modal
          visible={open}
          maskStyle={{ width: "0px" }}
          bodyStyle={{ height: "80px" }}
          footer={null}
          width="27%"
          style={{
            position: "absolute",
            marginTop: "-5%",
            marginLeft: "70%",
            gridRow: "auto",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
            <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
            <span style={{ paddingLeft: "3%" }}>
              Pricelist has been deleted
            </span>
          </div>
        </Modal>
      </List>
    );
};
