import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect, Space, Checkbox,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
import { Editor } from '@tinymce/tinymce-react';
import { Col, Row, DatePicker } from 'antd';
import {
  List,
} from "@pankod/refine-antd";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct, IPrice, IProductObject } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { nhost } from "utility";
import { jsonConverter } from "components/helpers/jsonConverter";
import { GET_ALL_CATEGORIES, GET_ALL_CUSTOMERS_WITH_ORGID, GET_ALL_CUSTOMER_GRPS_WITH_ORGID, GET_ALL_PRODUCTS_WITH_ORGID, GET_ALL_TAXES, GET_CATEGORIES_BY_ORGID, GET_PRICELIST } from "query";
import { ModalFooter } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import { AuthPermission } from "utility/authPermission";

type EditProductProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeEditCreateModal: () => void
  createEditModalVisible: boolean,
  handleEditFormSubmitted: () => void,
  tableQueryResult: any,
  currentModalId?: null | string
};

const props: UploadProps = {
  beforeUpload: (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSizeInBytes) {
      message.error('File size exceeds the limit (10MB).');
      return false;
    }
    return true;
  },
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
  },
};


const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const { Option } = Select;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const onChange = (key: string) => {
};


export const EditProduct: React.FC<EditProductProps> = ({
  modalProps,
  formProps,
  closeEditCreateModal,
  createEditModalVisible,
  handleEditFormSubmitted,
  tableQueryResult,
  currentModalId
}) => {
  const { data: user } = useGetIdentity();
  const [privacy, setPrivacy] = useState(false)
  const [priceData, setPriceData] = useState<any>([]);
  const [listimage, setListimage] = useState<any>("");
  const [thumbimage, setThumbimage] = useState<any>("");
  const [urlList, setUrlList] = useState([] as any[]);
  const [thumbnailurlList, setThumbnailUrlList] = useState([] as any[]);
  const [form] = Form.useForm();
  const [trackInventory, settrackInventory] = useState(false);
  const [backOrder, setbackOrder] = useState(false);
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('');
  const [allCategories, setAllCategories] = useState<any>([]);
  const [catecoryPopup, setCatecoryPopup] = useState<any>(false);
  const [allPricelist, setAllPricelist] = useState<any>([]);
  const [customerPrice, setCustomerPrice] = useState<any>();
  const [products, setProducts] = useState<any>([]);
  const [customers, setCustomers] = useState<any>([]);
  const [customerGrps, setCustomerGrps] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write",);
  const [vatClassId, setVatClassId] = useState<any>(null);
  const [allTaxes, setAllTaxes] = useState<any>(null);
  const [menuPermission, setPermissions] = useState<any>(true)

  useEffect(()=>{
    if(user){
      AuthPermission(user?.metadata?.org_id).then((res: any) =>{
        setPermissions(res)
      })
    }
  },[user])

  const userId = user?.metadata?.org_id;
  const productId = formProps?.initialValues?.id;
useEffect(() => {
  setCustomerPrice(formProps?.initialValues?.customer_price)  
},[formProps?.initialValues?.customer_price])
  const { selectProps: OBProductSelectProps } = useSelect<IProduct>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });
  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "name",
        operator: "ne",
        value: "",
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: priceListSelectProps } = useSelect<IPrice>({
    resource: "pricelists",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductAdminSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBBrandAdminSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductAdminOptionSelectProps } = useSelect<IProductObject>({
    resource: "product_option",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerAdminSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgAdminSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categoryAdminSelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: priceListAdminSelectProps } = useSelect<IPrice>({
    resource: "pricelists",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: vatcalssSelectProps } = useSelect<IPrice>({
    resource: "vat_class",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value:any) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  let vatFilters:any = [
    {
      field: "ob_org_id",
      operator: "eq",
      value: userId,
    },
  ];
  
  if (vatClassId !== null) {
    vatFilters.push({
      field: "vat_class",
      operator: "eq",
      value: vatClassId,
    });
  }
  const { selectProps: taxSelectProps } = useSelect<IPrice>({
    resource: "taxes",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: vatFilters,
    onSearch: (value:any) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { options: originalOptions, ...restSelectProps } = taxSelectProps;

  const customizedOptions: any = originalOptions?.map(option => {
    let sageCode: any = allTaxes?.filter((data: any) => option.value === data?.id )
    return {
      ...option,
      label: `${option?.label} - ${sageCode[0]?.vat_rate}`,  // Combine name and sage_tax_code
    };
  });


  useEffect(()=>{
    getAllTaxes()
  },[userId])

  const getAllTaxes = () => {
    GET_ALL_TAXES(userId).then((res: any) => {
      setAllTaxes(res?.taxes)
    })
  }

  const getCategories = async () => {
    GET_CATEGORIES_BY_ORGID(userId).then((response: any) => {
        setAllCategories(response?.categories);        
    });
};

const getPricelist = async () => {
  GET_PRICELIST(userId).then((res:any) => {
    setAllPricelist(res?.pricelists)
  })
}
const getCustomers = () => {
  GET_ALL_CUSTOMERS_WITH_ORGID(userId).then((data:any) => {
    if(data){        
      setCustomers(data?.customers)
    }
  })
}

const getAllProducts = () => {
  GET_ALL_PRODUCTS_WITH_ORGID(userId).then((res:any) => {
    setProducts(() => []);
    setProducts(res?.products);
  })
}

const getAllCustomerGrps = () => {
  GET_ALL_CUSTOMER_GRPS_WITH_ORGID(userId).then((res:any) => {      
    setCustomerGrps(() => []);
    setCustomerGrps(res?.customer_groups);
  })
}

useEffect(() => {
    getCategories();  
    getPricelist();  
    getAllProducts();
    getCustomers();
    getAllCustomerGrps();
}, [userId, formProps?.initialValues]);
useEffect(() => {
    getCategories(); 
    getPricelist();   
}, []);

  useEffect(() => {
    setUrlList(urlList => [formProps.initialValues?.image]) 
    setThumbnailUrlList(thumbnailurlList => [formProps.initialValues?.temp_img]);
    setVatClassId(formProps?.initialValues?.vat_class)
  },[formProps.initialValues])

  const handleProductSearch = (productsearch: string) => {

    if (productsearch?.length > 0) {
      const filterProduct = products?.filter((prod: any) => {
        return prod?.name?.toLowerCase().includes(productsearch?.toLocaleLowerCase())
      })
      if (filterProduct?.length != 0) {
        setProducts([...filterProduct])
      }
      else {
        getAllProducts();
      }
    } else {
      getAllProducts();
    }
  };

  const handleCustomerSearch = (search:any) => {
    if(search?.length > 0){
    const searchValue = customers?.filter((data:any,i:number) => {            
     return data?.name?.toLowerCase().includes(search?.toLowerCase())
    })
    if(searchValue?.length !== 0){
      setCustomers([...searchValue])
    }
    else{
      getCustomers();
    }
  }
  else{
    getCustomers();
  }
  }

  const handleCustomerGrpSearch = (customerGrpsearch: string) => {

    if (customerGrpsearch?.length > 0) {
      const filterProduct = customerGrps?.filter((cusGrp: any) => {
        return cusGrp?.group_name?.toLowerCase().includes(customerGrpsearch?.toLocaleLowerCase())
      })
      if (filterProduct?.length != 0) {
        setCustomerGrps([...filterProduct])
      }
      else {
        getAllCustomerGrps();
      }
    } else {
      getAllCustomerGrps();
    }
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    settrackInventory(e.target.checked)
  };
  const onChangeCheckboxOrder = (e: CheckboxChangeEvent) => {
    setbackOrder(e.target.checked)
  };

  const onChangeCheckboxPrivacy = (e: any) => {
    let checkbox = e.target.checked
    setPrivacy(checkbox)
  };

  const onChangePriceValue = (priceData: any, value: any, pricelist:any) => {
    let productPrice:any = []
    productPrice = customerPrice ?? [];

    if(priceData == undefined){
      // let prodRefCode = formProps?.initialValues?.referencecode
      // console.log(pricelist,"pricelist");
      // let newCustomerPrice: any = {
      //   "CALC_METHOD": 2,
      //   "CALC_VALUE": 22,
      //   "PRICE_ID": "P" + pricelist?.short_name + prodRefCode,
      //   "PRICING_REF": pricelist?.short_name,
      //   "STORED_PRICE": value,
      //   "TYPE": "P",
      // }
      // productPrice?.push(newCustomerPrice)
      // if(productPrice?.length > 0){
      //   setCustomerPrice([...productPrice])
      // }
    }
    else{
    const findIndex = customerPrice?.findIndex(
      (cus: any) => cus?.PRICING_REF === priceData?.PRICING_REF
    );
    productPrice[findIndex].STORED_PRICE = value
    setCustomerPrice([...productPrice])    
    }

    // let datas: any = [...priceData]
    // let isExist = datas?.findIndex((d: any) => d?.value === data?.value);
    // if (isExist !== -1) {
    //   datas[isExist].price = e.target.value;
    // } else {
    //   let mockPrice = {
    //     label: data.label,
    //     value: data.value,
    //     price: e.target.value
    //   }
    //   datas.push(mockPrice);
    // }
    // setPriceData(datas);
  };

  const [updateRefetch, setUpdateRefetch] = useState(false)


  const apidata = (e: any) => {
    let length = e.length ?? 0;
    let width = e.width ?? 0;
    let height = e.width ?? 0;

e.priceData = priceData
e.product_option = e.product_option ?? null;
e.price_details = e.price_details ?? null;
e.customer_price = e.customer_price ?? null;
e.related_products = e.related_products ?? null;
    e.access_private = e.access_private ?? false;
    e.access_customer_ids = e.access_customer_ids ?? null;
    e.access_customergroup_ids = e.access_customergroup_ids ?? null;
    e.specifications = e.specifications == undefined ? formProps?.initialValues?.specifications : e.specifications; //for tabinactive issue resolved
    e.tags = e.tags == undefined ? formProps?.initialValues?.tags : e.tags;
    e.product_status_rules = e.product_status_rules ?? null;
    e.metadescription = e.metadescription ?? null;
    e.searchkeywords = e.searchkeywords ?? null;
    e.deals = e.deals ?? null;
    e.metadescription = e.metadescription ?? null;
    e.searchkeywords = e.searchkeywords ?? null;
    e.deals = e.deals ?? null;
    e.packagequantity = e.packagequantity ?? 0;
    e.is_active = e.is_active ?? false;
    e.upccode = e.upccode ?? 0;
    e.barcode = e.barcode ?? 0;
    e.minimum_quantity = e.minimum_quantity ?? 1;
    e.maximum_quantity = e.maximum_quantity ?? 100;
    e.box_quantity = e.box_quantity ?? 0;
    e.msrp = e.msrp ?? 0.00;
    e.quantity = e.quantity ?? 0.00;
    e.promote_category = e.promote_category ?? false;
    e.featured_product = formProps?.initialValues?.featured_product == true ? formProps?.initialValues?.featured_product : e.featured_product ?? false;
    e.description = e.description ?? "Empty";
    e.status = e.status ?? "Available";
    e.vat_class = e.vat_class ?? null;
    e.tax_id = e.tax_id ?? null;
    e.updated_by = user?.id;

    let category = categorySelectProps?.options?.find((data:any) => {
      if(data?.value == e.category_id){
        return data?.label
      }
    })     
    const MUTATIONAPI = `mutation MyMutation {
      update_products(_set: { name: "${e.name}", updated_by: "${e.updated_by}", code: "${e.code}",price_data: ${jsonConverter(priceData)}, image: ${e.image ? `"${e.image}"` : null},temp_img: ${e.temp_img ? `"${e.temp_img}"` : null}, description: "${e.description}",category_id: "${e.category_id}",categories: "${category?.label}", length : ${length},width : ${width},height : ${height}, msrp: ${e.msrp}, minimum_quantity: ${e.minimum_quantity}, maximum_quantity: ${e.maximum_quantity}, quantity: ${e.quantity}, box_quantity: ${e.box_quantity}, 
      status: "${e.status}",vat_class: ${e.vat_class ? `"${e.vat_class}"` : null}, is_active: ${e.is_active}, price_details : ${jsonConverter(e.price_details)}, customer_price: ${jsonConverter(customerPrice)}, related_products: ${jsonConverter(e.related_products)}, upccode: "${e.upccode}", barcode: "${e.barcode}", referencecode: "${e.code}", packagequantity: ${e.packagequantity},
      metadescription: "${e.metadescription}", searchkeywords: "${e.searchkeywords}", promote_category: ${e.promote_category}, featured_product: ${e.featured_product}, deals: "${e.deals}", product_status_rules: ${jsonConverter(e.product_status_rules)},access_private: ${e.access_private}, access_customer_ids: ${jsonConverter(e.access_customer_ids)},
      brand_id: ${e.brand_id ? `"${e.brand_id}"` : null},tax_id: ${e.tax_id ? `"${e.tax_id}"` : null},
       access_customergroup_ids: ${jsonConverter(e.access_customergroup_ids)},specifications : ${jsonConverter(e.specifications)},tags: ${jsonConverter(e.tags)}
    },where: { id: { _eq: "${productId}"} } ){
        affected_rows
      }
    }
    `

    const imageSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI);
      if (data) {
        setOpen(true)
        setUpdateRefetch(true)
        setTimeout(() => {
          setOpen(false)
        }, 3000);
        tableQueryResult?.refetch()
        closeEditCreateModal()
        return data
      }
    }
    imageSubmit();
  }

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setUrlList(urlList => [ data.secure_url]);
          })
          .catch(err => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  }
  const uploadThumbnailImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setThumbnailUrlList(thumbnailurlList => [data.secure_url]);
          })
          .catch(err => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  }
  const removeImage = (id:any) => {
    // if(id == currentModalId){
    //   setListimage("hide")
    // }
    setUrlList([])
  }
  const removeThumImage = (id:any) => {
    // if(id == currentModalId){
    //   setThumbimage("hide")
    // }
    setThumbnailUrlList([])
  }

  const imageStyles = { width: '10%', paddingTop: '10px', paddingBottom: '15px', paddingRight: '10px' }
  useEffect(() => {    
    // form.setFieldsValue(formProps?.initialValues)
  }, [formProps])

  const handleOk = () => {
    setOpen(false);
  }
  const handleCategoryClose = () => {
    setCatecoryPopup(false);
    // setSearchValue("");
  }
  const handleCategory = () => {
    const MUTATIONCATEGORYAPI = `mutation MyMutation {
      insert_categories(objects: {
        name: "${searchValue?.trim()}",
        ob_org_id: "${userId}",
        is_active: ${true},
    }){
        affected_rows
        returning {
          id
        }
      }
    }
    `
    const callApi = async () => {
      const { data }:any = await nhost.graphql.request(MUTATIONCATEGORYAPI);
      if(data){
        let getId = data?.insert_categories?.returning[0]?.id
        getCategories();
        form.setFieldsValue({...formProps?.initialValues, category_id: getId,});
        // setSearchValue("");
        setCatecoryPopup(false);
      }
    }
    callApi();
  }

  const handleSearch = (value:string) => {
    if(value != "") {
    setSearchValue(value)
    let findCat = [...allCategories]?.filter((cat:any) => {
      return cat?.name?.toLowerCase().includes(value?.toLowerCase())
    })
    if(findCat?.length > 0){
      setAllCategories([...findCat])
    }
    if(findCat?.length == 0){
      setAllCategories([])
    }
  }
    else{
      getCategories();
    }
  };

  const addnewCategory = (e:any) => {
    setCatecoryPopup(true)
  }
  
  const renderNotFoundContent = () => {
    if(searchValue.trim().length > 0){
   return <div style={{display:"flex", justifyContent: "center"}}><Button style={{margin: "10px", backgroundColor: "#4EA144", color: "white", borderRadius: "10px"}} onClick={addnewCategory}>Add New Category</Button></div>
    }
  };
  useEffect(() => {
    setValue();
    // formProps.initialValues = {...formProps.initialValues, "category_id" : "2c97a13a-0e2a-4115-8ba1-ca614580349c"} 
  },[formProps?.initialValues])
  useEffect(() => {
    setValue();

  },[formProps?.initialValues != undefined])
  const setValue = () => {
    form.setFieldsValue({...formProps?.initialValues});
  }

  const validateMinQuantity = async (_:any, minimum_quantity:any) => {    
    const max_quantity = form?.getFieldValue("maximum_quantity");
if(minimum_quantity == 0) {
      throw new Error("Minimum Quantity cannot be Zero")
    }
  };

  const validateMaxQuantity = async (_:any, maximum_quantity:any) => {    
    const min_quantity = form?.getFieldValue("minimum_quantity");
    if(maximum_quantity == 0) {
      throw new Error("Maximum Quantity cannot be Zero")
    }
  };

  const vatclassChange = (e:any) => {
    setVatClassId(e)
  }
  
  return (
    <>
      <Modal
        {...modalProps}
        title={!menuPermission ? "Edit Product": "Show Product"}
        onCancel={closeEditCreateModal}
        visible={createEditModalVisible}
        footer={false}
      >
        <Form
          form={form}
          // {...formProps}
          onFinish={(e) => {
            e.image = urlList.join(" ");
            e.temp_img = thumbnailurlList?.join(" ");
            apidata(e);
          }}
          wrapperCol={{ span: 23 }}
          autoComplete="off"
          layout="vertical"
        >
          <Tabs defaultActiveKey="1" onChange={onChange}>
            <TabPane tab="Product" key="1">
              <Row>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: menuPermission ? false :true, message: 'Please insert Product Name!' }]}
                      >
                        <Input readOnly = {menuPermission ? true :false}/>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="code"
                        label="Code"
                        rules={[
                          {
                            required: menuPermission ? false: true,
                            message: "Please input your codes!",
                          },
                        ]}
                      >
                        <Input readOnly = {menuPermission ? true :false}/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="description"
                    label="Description"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <TextArea rows={4} style={{ width: "100%" }} readOnly = {menuPermission ? true :false} />
                  </Form.Item>
                  {!menuPermission &&
                  <Form.Item
                    name="images"
                    label="Main Image"
                    // rules={[{ required: false, message: 'Please input your image!' }]}
                  >
                    <Dragger
                      showUploadList={false}
                      {...props}
                      customRequest={uploadImages}
                      disabled = {menuPermission ? true :false}
                    >
                      <p className="ant-upload-drag-icon">
                        <PictureOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Drag image file here to upload(or Click)
                      </p>
                    </Dragger>
                  </Form.Item>
                  }

                  {!menuPermission &&
                  <Form.Item name="images" style={{ width: "100%" }}>
                    <h1 style={{ marginTop: "10px", fontSize: "14px" }}>
                      Uploaded image will be displayed here
                    </h1>
                    {(() => {
                      if (urlList.length) {
                        return urlList.map((url) => {
                          return (
                            <div>
                              <img style={imageStyles} src={url} />
                              {url ? (
                                <span>
                                  <DeleteOutlined onClick={removeImage}  disabled = {menuPermission ? true :false}/>
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        });
                      }

                      // if (currentModalId === formProps.initialValues?.id) {
                      //   if(listimage == "hide"){
                      //     return
                      //   }
                      //   else{
                      //     return <img onClick={() => removeImage(formProps.initialValues?.id)} style={imageStyles} src={formProps.initialValues?.image} alt="" />
                      //   }
                      // }

                      return <></>;
                    })()}
                  </Form.Item>
                  }
                  {!menuPermission &&
                  <Form.Item name="thumbnail_image" label="Thumbnail Image">
                    {/* <div style={{ marginTop: ''}}> */}
                    <Dragger
                      showUploadList={false}
                      {...props}
                      name="image"
                      customRequest={uploadThumbnailImages}
                      disabled = {menuPermission ? true :false}
                    >
                      <p className="ant-upload-drag-icon">
                        <PictureOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Drag image file here to upload(or Click)
                      </p>
                    </Dragger>
                    {/* </div> */}
                  </Form.Item>
                  }
                  {!menuPermission &&
                  <Form.Item name="image" style={{ width: "100%" }}>
                    <h1 style={{ marginTop: "10px", fontSize: "14px" }}>
                      Uploaded image will be displayed here
                    </h1>
                    {(() => {
                      if (thumbnailurlList.length) {
                        return thumbnailurlList.map((url) => {
                          return (
                            <div>
                              <img style={imageStyles} src={url} />
                              {url ? (
                                <DeleteOutlined onClick={removeThumImage} />
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        });
                      }

                      // if (currentModalId === formProps.initialValues?.id) {
                      //   if(thumbimage == "hide"){
                      //     return
                      //   }
                      //   else{
                      //     return <img onClick={() => removeThumImage(formProps.initialValues?.id)} style={imageStyles} src={formProps.initialValues?.temp_img} alt="" />
                      //   }
                      // }

                      return <></>;
                    })()}
                  </Form.Item>
                  } 
                  {user?.defaultRole === "admin" ? (
                    <Form.Item
                      label="Category"
                      name="category_id"
                      rules={[{ required: menuPermission ? false :true }]}
                    >
                      <Select {...categoryAdminSelectProps} disabled = {menuPermission ? true :false}/>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="Category"
                      name="category_id"
                      rules={[{ required: menuPermission ? false :true }]}
                    >
                      {/* <Select onSearch={() => handleSearch} notFoundContent={renderNotFoundContent()} {...categorySelectProps} /> */}
                      <Select
                        showSearch
                        onSearch={handleSearch}
                        filterOption={false}
                        notFoundContent={renderNotFoundContent()}
                        autoClearSearchValue={false}
                        disabled = {menuPermission ? true :false}
                      >
                        {allCategories?.length > 0 ? (
                          <>
                            {[...allCategories]?.map((opt: any) => {
                              return (
                                <Option value={opt?.id} key={opt?.id}>
                                  {opt?.name}
                                </Option>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </Select>
                    </Form.Item>
                  )}
                  {user?.defaultRole === "admin" ? (
                    <>
                      {priceListAdminSelectProps?.options?.map((item) => {
                        return (
                          <Form.Item
                            label={item.label}
                            name={["customer_price", "STORED_PRICE"]}
                          >
                            <Input
                              type="number"
                              // required
                              onChange={(e) =>
                                onChangePriceValue(item, e, item)
                              }
                              readOnly = {menuPermission ? true :false}
                            />
                          </Form.Item>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {/* {priceListSelectProps?.options?.map((item:any, i:number) => {
                        return (
                          <Form.Item
                            label={item.label}
                            name={['customer_price',i, 'STORED_PRICE']}
                          >
                            <Input type="number"
                              // required 
                              onChange={(e) => onChangePriceValue(item, e)} />
                          </Form.Item>
                        );
                      })} */}
                      {/* {allPricelist?.length > 0 ? (
                        <>
                          {allPricelist?.map((price: any, i: number) => {
                            const matchedPrice = customerPrice?.find(
                              (cus: any) =>
                                cus?.PRICING_REF === price?.short_name
                            );

                            return (
                              <Form.Item
                                label={price?.name}
                                // name={matchedPrice ? ['customer_price', i, 'STORED_PRICE'] : undefined}
                                // name={customerPrice?.find((cus:any) => cus?.PRICING_REF == price?.short_name) ? ['customer_price', i, 'STORED_PRICE'] : undefined}
                              >
                                <Input
                                  type="number"
                                  value={matchedPrice?.STORED_PRICE}
                                  disabled={!matchedPrice}
                                  onChange={(e) =>
                                    onChangePriceValue(
                                      matchedPrice,
                                      e.target.value,
                                      price
                                    )
                                  }
                                />
                              </Form.Item>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )} */}
                    </>
                  )}
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="msrp"
                    label="MSRP"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <InputNumber
                      defaultValue="0.0"
                      onChange={onChange}
                      style={{ width: "100%" }}
                      readOnly = {menuPermission ? true :false}
                    />
                  </Form.Item>
                  {!menuPermission &&
                  <Form.Item
                    name="minimum_quantity"
                    label="Minimum Quantity"
                    rules={[{validator: validateMinQuantity}]}
                  >
                    <InputNumber
                    type="number"
                      defaultValue="1"
                      onChange={onChange}
                      style={{ width: "100%" }}
                      readOnly = {menuPermission ? true :false}
                    />
                  </Form.Item>
                  }
                  {!menuPermission &&
                  <Form.Item
                    name="maximum_quantity"
                    label="Maximum Quantity"
                    rules={[
                      { validator: validateMaxQuantity },
                    ]}
                  >
                    <InputNumber
                    type="number"
                      defaultValue="100"
                      onChange={onChange}
                      style={{ width: "100%" }}
                      readOnly = {menuPermission ? true :false}
                    />
                  </Form.Item>
                  }
                  <Form.Item
                    name="quantity"
                    label="Quantity"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <InputNumber
                      defaultValue="0.0"
                      onChange={onChange}
                      style={{ width: "100%" }}
                      readOnly = {menuPermission ? true :false}
                    />
                  </Form.Item>
                  {!menuPermission &&
                  <>
                  <Form.Item name="track_inventory" valuePropName="checked">
                    <Checkbox
                      name="track_inventory"
                      checked={trackInventory}
                      onChange={onChangeCheckbox}
                      disabled = {menuPermission ? true :false}
                    >
                      Track inventory
                    </Checkbox>
                  </Form.Item>

                  <Form.Item name="allow_backorder" valuePropName="checked">
                    <Checkbox
                      name="allow_backorder"
                      checked={backOrder}
                      onChange={onChangeCheckboxOrder}
                      disabled = {menuPermission ? true :false}
                    >
                      Allow backorder
                    </Checkbox>
                  </Form.Item>
                  </>
                  }
                  {!menuPermission &&
                  <Form.Item
                    name="box_quantity"
                    label="Box Quantity / Multiples"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <InputNumber
                      defaultValue="0.0"
                      onChange={onChange}
                      style={{ width: "100%" }}
                      readOnly = {menuPermission ? true :false}
                    />
                  </Form.Item>
                  }
                  <Form.Item
                    label="Status"
                    name="status"
                    // rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Select disabled = {menuPermission ? true :false}>
                      <Select.Option value="Available" name="status">
                        Available
                      </Select.Option>
                      <Select.Option value="Limited Stock" name="status">
                        Limited Stock
                      </Select.Option>
                      <Select.Option value="Pre- order" name="status">
                        Pre- order
                      </Select.Option>
                      <Select.Option value="Not Available" name="status">
                        Not Available
                      </Select.Option>
                      <Select.Option value="Discountinued" name="status">
                        Discountinued
                      </Select.Option>
                      <Select.Option value="Sold Out" name="status">
                        Sold Out
                      </Select.Option>
                      <Select.Option value="Test Product Status" name="status">
                        Test Product Status
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {!menuPermission &&
                  <Form.Item
                    label="VAT class"
                    name="vat_class"
                    // rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Select
                    disabled = {menuPermission ? true :false}
                    onSelect={vatclassChange}
                     {...vatcalssSelectProps}/>
                  </Form.Item>
                  }
                  <Form.Item
                    label="Tax"
                    name="tax_id"
                    style={{ width: "100%" }}
                  >
                  <Select options={customizedOptions}
                    {...restSelectProps}
                  />
                  </Form.Item>
                  {!menuPermission &&
                  <>
                  {user?.defaultRole === "admin" ? (
                    <Form.Item label="Brand" name="brand_id">
                      <Select
                      disabled =  {menuPermission ? true :false}
                        {...OBBrandAdminSelectProps}
                        style={{ width: "100%", height: "40px" }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item label="Brand" name="brand_id">
                      <Select
                      disabled = {menuPermission ? true :false}
                        {...OBBrandSelectProps}
                        style={{ width: "100%", height: "40px" }}
                      />
                    </Form.Item>
                  )}
                  </>
                }
                  <Form.Item label="" name="is_active" valuePropName="checked">
                    <Checkbox name="is_active" onChange={onChangeCheckbox} disabled = {menuPermission ? true :false}>
                      Is active?
                    </Checkbox>
                  </Form.Item>
                  {/* <Form.Item name="tags" label="Tags">
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      placeholder="Add tags"
                      allowClear
                      tokenSeparators={[","]}
                    >
                    </Select>
                  </Form.Item>
                      <Form.Item
                        name={["specifications", "weight"]}
                        label="Weight"
                      >
                        <Input type="number" />
                      </Form.Item>
                      <Form.Item
                        name={["specifications", "ingredients"]}
                        label="Ingredients"
                      >
                        <TextArea rows={3} />
                      </Form.Item>
                      <Form.Item
                        name={["specifications", "storage"]}
                        label="Storage"
                      >
                        <TextArea rows={2} />
                      </Form.Item>
                      <Form.Item
                        name={["specifications", "cooking"]}
                        label="Cooking"
                      >
                        <TextArea rows={2} />
                      </Form.Item> */}
                  <Form.Item name="created_by" initialValue={user?.id}>
                    <Input type="hidden" readOnly = {menuPermission ? true :false}/>
                  </Form.Item>
                  <Form.Item name="updated_by" initialValue={userId}>
                    <Input type="hidden" readOnly = {menuPermission ? true :false}/>
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
            {!menuPermission && 
            <TabPane tab="Details" key="21">
              <Form.Item name="tags" label="Tags">
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Add tags"
                  allowClear
                  tokenSeparators={[","]}
                  disabled = {menuPermission ? true :false}
                ></Select>
              </Form.Item>
              <Form.Item name={["specifications", "weight"]} label="Weight">
                <Input type="number" readOnly = {menuPermission ? true :false}/>
              </Form.Item>
              <h6>Details</h6>
              <Form.List name={["specifications", "details"]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          label="Name"
                          style={{width: "250px"}}
                        >
                          <Select disabled = {menuPermission ? true :false}>
                            <Select.Option value="Detail Description">
                              Detail Description
                            </Select.Option>
                            <Select.Option value="Ingredients">
                              Ingredients
                            </Select.Option>
                            <Select.Option value="Storage">
                              Storage
                            </Select.Option>
                            <Select.Option value="Cooking">
                              Cooking
                            </Select.Option>
                            <Select.Option value="Allergens">
                              Allergens
                            </Select.Option>
                            <Select.Option value="May contain">
                              May contain
                            </Select.Option>
                          </Select>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "value"]}
                          label="Value"
                          style={{width: "630px"}}
                        >
                          <ReactMde
                          readOnly = {menuPermission ? true :false}
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            generateMarkdownPreview={(markdown: any) =>
                              Promise.resolve(
                                <ReactMarkdown>{markdown}</ReactMarkdown>
                              )
                            }
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        block
                        style={{ width: "340px" }}
                        disabled = {menuPermission ? true :false}
                      >
                        <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                          <PlusOutlined style={{marginRight: "5px"}}/> Add Details
                        </div>
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* <Form.Item
                        name={["specifications", "ingredients"]}
                        label="Ingredients"
                      >
                        <TextArea rows={3} />
                      </Form.Item>
                      <Form.Item
                        name={["specifications", "storage"]}
                        label="Storage"
                      >
                        <TextArea rows={2} />
                      </Form.Item>
                      <Form.Item
                        name={["specifications", "cooking"]}
                        label="Cooking"
                      >
                        <TextArea rows={2} />
                      </Form.Item>
                      <Form.Item
                        name={["specifications", "allergens"]}
                        label="Allergens"
                      >
                        <TextArea rows={2} />
                      </Form.Item>
                      <Form.Item
                        name={["specifications", "may_contain"]}
                        label="May contain"
                      >
                        <TextArea rows={2} />
                      </Form.Item> */}
              <h6>Nutritional Informations</h6>
              <Form.List name={["specifications", "nutritional_information"]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          label="Name"
                        >
                          <Input placeholder="Like Fat, protein, etc.." readOnly = {menuPermission ? true :false}/>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, "value"]}
                          label="Value"
                        >
                          <Input placeholder="Like 2g, 5g, etc.." readOnly = {menuPermission ? true :false}/>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        block
                        style={{ width: "340px" }}
                        disabled = {menuPermission ? true :false}
                      >
                        <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                          <PlusOutlined style={{marginRight: "5px"}}/> Add Nutritional
                        </div>
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </TabPane>
            }
            <TabPane tab="Prices" key="13">
              <>
              {allPricelist?.length > 0 ? (
                <Row gutter={32}>
                  {allPricelist?.map((price: any, i: number) => {
                    const matchedPrice = customerPrice?.find(
                      (cus: any) =>
                        cus?.PRICING_REF === price?.short_name
                    );

                    return (
                      <Col span={12} key={i}>
                        <Form.Item
                          label={price?.name}
                          // name={matchedPrice ? ['customer_price', i, 'STORED_PRICE'] : undefined}
                          // name={customerPrice?.find((cus:any) => cus?.PRICING_REF == price?.short_name) ? ['customer_price', i, 'STORED_PRICE'] : undefined}
                        >
                          <Input
                            type="number"
                            value={matchedPrice?.STORED_PRICE}
                            disabled={!matchedPrice}
                            onChange={(e) =>
                              onChangePriceValue(
                                matchedPrice,
                                e.target.value,
                                price
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                <></>
              )}
              </>
            </TabPane>
            {!menuPermission &&
            <>
              <TabPane tab="Discounts" key="2">
                <div>
                  <Form.List name="price_details">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            {user?.defaultRole === "admin" ? (
                              <Form.Item
                                {...restField}
                                name={[name, "price_id"]}
                                label="Price list"
                                // rules={[{ required: true, }]}
                              >
                                <Select
                                disabled = {menuPermission ? true :false}
                                  {...priceListAdminSelectProps}
                                  style={{ width: "150px" }}
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                {...restField}
                                name={[name, "price_id"]}
                                label="Price list"
                                // rules={[{ required: true, }]}
                              >
                                <Select
                                disabled = {menuPermission ? true :false}
                                  {...priceListSelectProps}
                                  style={{ width: "150px" }}
                                />
                              </Form.Item>
                            )}
                            <Form.Item
                              {...restField}
                              name={[name, "initial_price"]}
                              label="Initial Price"
                              // rules={[{ required: true, message: 'Missing to initial price' }]}
                            >
                              <Input placeholder="Initial Price" readOnly = {menuPermission ? true :false}/>
                            </Form.Item>

                            <Form.Item
                              {...restField}
                              name={[name, "from_quality"]}
                              label="From Quantity"
                              // rules={[{ required: true, message: 'Missing to from quality' }]}
                            >
                              <Input placeholder="From Quantity" readOnly = {menuPermission ? true :false}/>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "percentage"]}
                              label="Percentage (%)"
                              // rules={[{ required: true, message: 'Missing to percentage' }]}
                            >
                              <Input placeholder="Percentage (%)" readOnly = {menuPermission ? true :false}/>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "final_price"]}
                              label="Final Price"
                              // rules={[{ required: true, message: 'Missing to final price' }]}
                            >
                              <Input placeholder="Final Price" readOnly = {menuPermission ? true :false}/>
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => add()}
                            block
                            icon={false}
                            style={{ width: "340px" }}
                            disabled = {menuPermission ? true :false}
                          >
                            <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                            <PlusOutlined style={{marginRight: "5px"}}/> Add Discounts
                          </div>
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </TabPane>
              <TabPane tab="Customer Prices" key="3">
                <div>
                  <Form.List name="customer_price">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                              alignItems: "end",
                            }}
                            align="baseline"
                          >
                            {user?.defaultRole === "admin" ? (
                              <Form.Item
                                {...restField}
                                name={[name, "customer_id"]}
                                label="Customer"
                                style={{ width: "210px" }}
                              >
                                <Select {...OBCustomerAdminSelectProps} disabled = {menuPermission ? true :false}/>
                              </Form.Item>
                            ) : (
                              <Form.Item
                                {...restField}
                                name={[name, "customer_id"]}
                                label="Customer"
                                style={{ width: "210px" }}
                              >
                                <Select {...OBCustomerSelectProps}  disabled = {menuPermission ? true :false}/>
                              </Form.Item>
                            )}
                            <Form.Item
                              {...restField}
                              name={[name, "percentage"]}
                              label="Percentage (%)"
                            >
                              <Input placeholder="Percentage (%)" readOnly = {menuPermission ? true :false}/>
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} disabled  = {menuPermission ? true :false}/>
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => add()}
                            block
                            icon={false}
                            style={{ width: "340px" }}
                            disabled  = {menuPermission ? true :false}
                          >
                            <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                            <PlusOutlined style={{marginRight: "5px"}}/> Add Customer Prices
                          </div>
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </TabPane>
              <TabPane tab="Related Products" key="4">
                <div>
                  <Form.Item label="Related Products" name="related_products">
                    <Select
                      mode="multiple"
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Products"
                      onSearch={handleProductSearch}
                      filterOption={false}
                      disabled = {menuPermission ? true :false}
                    >
                      {products?.map((e: any, i: any) => {
                        return <Option key={e?.id}>{e?.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                  {/* <Form.List name="related_products">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space key={key} style={{ display: 'flex', marginBottom: 8, alignItems: "end" }} align="baseline">
                            {user?.defaultRole === "admin" ?
                              <Form.Item
                                {...restField}
                                name={[name, 'product_id']}
                                label="Product"
                                style={{ width: "210px" }}
                              >
                                <Select {...OBProductAdminSelectBuyProps} />
                              </Form.Item>
                              :
                              <Form.Item
                                {...restField}
                                name={[name, 'product_id']}
                                label="Product"
                                style={{ width: "210px" }}
                              >
                                <Select {...OBProductSelectBuyProps} />
                              </Form.Item>
                            }
                            <MinusCircleOutlined onClick={() => remove(name)} style={{ margin: "0px 0px 10px -105px" }} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{ width: "340px" }}>
                            Add Related Products
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List> */}
                </div>
              </TabPane>
              <TabPane tab="Advanced" key="10">
                <div>
                  <Form.Item
                    name="upccode"
                    label="UPC code"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input style={{ width: "50%" }} readOnly = {menuPermission ? true :false}/>
                  </Form.Item>
                  <Form.Item
                    name="barcode"
                    label="Barcode"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input style={{ width: "50%" }} readOnly = {menuPermission ? true :false}/>
                  </Form.Item>
                  <Form.Item
                    name="referencecode"
                    label="Reference code"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input style={{ width: "50%" }} readOnly = {menuPermission ? true :false}/>
                  </Form.Item>
                  <Form.Item
                    name="packagequantity"
                    label="Package quantity"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <InputNumber
                      defaultValue="0.0"
                      onChange={onChange}
                      style={{ width: "50%" }}
                      readOnly = {menuPermission ? true :false}
                    />
                  </Form.Item>
                  <Form.Item
                    name="metadescription"
                    label="Meta description"
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <TextArea rows={4} style={{ width: "50%" }} readOnly = {menuPermission ? true :false}/>
                  </Form.Item>
                </div>
                <Form.Item
                  name="searchkeywords"
                  label="Search keywords"
                  // rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input style={{ width: "50%" }} readOnly = {menuPermission ? true :false}/>
                </Form.Item>
              </TabPane>
              <TabPane tab="Promotions" key="7">
                <Form.Item
                  label=""
                  name="promote_category"
                  valuePropName="checked"
                >
                  <Checkbox name="promote_category" disabled = {menuPermission ? true :false}> 
                    Promote in category page
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  label=""
                  name="featured_product"
                  valuePropName="checked"
                >
                  <Checkbox name="featured_product" disabled = {menuPermission ? true :false}>
                    Show in Featured Products (homepage)
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  label="show in deals page"
                  name="deals"
                  // rules={[{ required: true }]}
                  style={{ width: "100%" }}
                >
                  <Select disabled = {menuPermission ? true :false}>
                    <Select.Option
                      value="Only if discounts are Available"
                      name="deals"
                    >
                      Only if discounts are Available
                    </Select.Option>
                    <Select.Option value="Always" name="deals">
                      Always
                    </Select.Option>
                    <Select.Option value="Never" name="deals">
                      Never
                    </Select.Option>
                  </Select>
                </Form.Item>
              </TabPane>
              <TabPane tab="Product Status Rules" key="11">
                <Form.List name="product_status_rules">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "from_quantity"]}
                            label="From Quantity"
                            // rules={[{ required: true, message: 'Missing from quantity' }]}
                          >
                            <Input placeholder="from quantity" readOnly = {menuPermission ? true :false}/>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "to_quantity"]}
                            label="To Quantity"
                            // rules={[{ required: true, message: 'Missing to quantity' }]}
                          >
                            <Input placeholder="to quantity" readOnly = {menuPermission ? true :false}/>
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} disabled = {menuPermission ? true :false}/>
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => add()}
                          block
                          icon={false}
                          style={{ width: "340px" }}
                          disabled = {menuPermission ? true :false}
                        >
                          <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                            <PlusOutlined style={{marginRight: "5px"}}/> Add Product Status Rules
                          </div>
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </TabPane>
              <TabPane tab="Access" key="12">
                <div>
                  <Form.Item
                    label=""
                    name="access_private"
                    valuePropName="checked"
                  >
                    <Checkbox
                      name="access_private"
                      onChange={onChangeCheckboxPrivacy}
                      disabled = {menuPermission ? true :false}
                    >
                      Private
                    </Checkbox>
                  </Form.Item>
                  {privacy === true ||
                  formProps?.initialValues?.access_private === true ? (
                    <>
                      {user?.defaultRole === "admin" ? (
                        <Form.Item
                          name="access_customer_id"
                          label="Customer"
                          style={{ width: "210px" }}
                          // rules={[{ required: true, message: 'Missing Customer name' }]}
                        >
                          {/* <Select {...OBCustomerSelectProps} /> */}
                        </Form.Item>
                      ) : (
                        // <Form.Item
                        //   name='access_customer_id'
                        //   label="Customer"
                        //   style={{ width: "210px" }}
                        // // rules={[{ required: true, message: 'Missing Customer name' }]}
                        // >
                        //   <Select {...OBCustomerSelectProps} />
                        // </Form.Item>
                        <Form.Item
                          label="Select Customers"
                          name="access_customer_ids"
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            onSearch={handleCustomerSearch}
                            defaultValue={
                              formProps?.initialValues?.access_customer_ids
                            }
                            // onChange={handleSelectedChange}
                            filterOption={false}
                            disabled = {menuPermission ? true :false}
                          >
                            {customers?.map((data: any) => {
                              return <Option key={data?.id}>{data?.name}</Option>;
                            })}
                          </Select>
                        </Form.Item>
                      )}
                      {/* <Form.Item
                          name="access_private_group"
                          label="Privacy groups"
                          // rules={[{ required: true }]}
                          style={{ width: "50%" }}
                        >
                          <Input />
                        </Form.Item> */}
                      <Form.Item
                        name="access_customergroup_ids"
                        label="Customer Groups"
                        // style={{display: "none"}}
                        rules={[]}
                      >
                        {/* <Select {...privacyGroupListSelectProps} /> */}
                        <Select
                          mode="multiple"
                          showSearch
                          style={{ width: "100%" }}
                          placeholder=""
                          onSearch={handleCustomerGrpSearch}
                          filterOption={false}
                          defaultValue={
                            formProps?.initialValues?.access_customergroup_ids
                          }
                          disabled = {menuPermission ? true :false}
                        >
                          {customerGrps?.map((e: any, i: any) => {
                            return <Option key={e?.id}>{e?.group_name}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </TabPane>
            </>
            }
          </Tabs>
          {!menuPermission &&
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ marginInlineEnd: "10px" }}
              onClick={closeEditCreateModal}
              type="default"
            >
              Cancel
            </Button>
            <Button
              style={{ marginInlineStart: "10px" }}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
          }
        </Form>
      </Modal>
      <Modal
        visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleOk}
        footer={null}
        width="27%"
        style={{
          position: "absolute",
          marginTop: "-5%",
          marginLeft: "70%",
          gridRow: "auto",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%" }}>
            Product details have been updated
          </span>
        </div>
      </Modal>
      <Modal
        visible={catecoryPopup}
        onCancel={handleCategoryClose}
        footer={null}
      >
        <div>
          <span>
            Do you want to create this <strong>{searchValue}</strong> category
          </span>
        </div>
        <ModalFooter></ModalFooter>
        
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              margin: "10px",
              backgroundColor: "#4EA144",
              color: "white",
              borderRadius: "10px",
            }}
            onClick={handleCategory}
          >
            Yes
          </Button>
          <Button
            style={{
              margin: "10px",
              backgroundColor: "red",
              color: "white",
              borderRadius: "10px",
            }}
            onClick={handleCategoryClose}
          >
            No
          </Button>
        </div>
      </Modal>
    </>
  );
};
function apidata(e: any) {
  throw new Error("Function not implemented.");
}

