
import { IResourceComponentsProps, useExport, CrudFilters, HttpError, useList } from "@pankod/refine-core";
import { Tag, Tabs } from 'antd';

import {
  List,
  RefreshButton,
  CreateButton,
  ExportButton,
  Table,
  useTable,
  ShowButton,
  Space,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm, Icons, Col, DatePicker, Card, Form,
  Select, Input, Button, FormProps, useSelect, Row
} from "@pankod/refine-antd";
import { CatalogueMenu } from "components/setup/catalogue/menu";
import { IProduct, IOrg } from "interfaces";
import { CreateProfile } from "./create";
import { EditProfile } from "./edit";
import { BasicInfo } from "./pages/BasicInfo";
import { Appearance } from "./pages/Appearance";
import { Address } from "./pages/Address";
import { SocialMedia } from "./pages/SocialMedia";
import { EmailNotification } from "./pages/EmailNotification";
import { ApplicationConfiguration } from "./pages/ApplicationConfiguration";
import { CustomerRegisterField } from "./pages/CustomerRegisterField";
import { DefaultValues } from "./pages/DefaultValues";
import { Advanced } from "./pages/Advanced";
import { ApiConfiguration } from "./pages/ApiConfiguration";
import { useGetIdentity } from "@pankod/refine-core";
const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { TextArea } = Input;

export const ProfileList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IProduct, HttpError>({
    resource: "products",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    metaData: {
      fields: ["id", "name", "category_id", { category: ["name"] }, "ob_org_id", { org: ["name"] }, "description", "image", "length", "width", "height", "msrp", "minimum_quantity", "maximum_quantity", "quantity", "allow_backorder", "track_inventory", "box_quantity", "status", "vat_class", "is_active", "promote_category", "featured_product", "deals", "product_id", "buy_with", "created_at"],
    }
  });

  const userId = user?.metadata?.org_id;

  const orgBasicInfoQueryResult = useList<IOrg>({
    resource: "ob_orgs",
    metaData: {
      fields: ["name", "email", "language", "company_description", "minimum_order","logo", "favicon",
        "country", "timezone", "currency", "email_signature", "public_product", "public_pages", "out_of_product_status","show_price", "allow_out_of_stock", "minimum_further"],
    },
    config: {
      filters: [
        {
          field: "id",
          operator: "eq",
          value: userId,
        },
      ],
    },
  });

  const orgAddressQueryResult = useList<IOrg>({
    resource: "ob_orgs",
    metaData: {
      fields: ["address", "city", "postal_code", "province", "phone", "fax", "google_location_map"]
    },
    config: {
      filters: [
        {
          field: "id",
          operator: "eq",
          value: userId,
        },
      ],
    },
  });

  const orgPhoneQueryResult = useList<IOrg>({
    resource: "ob_orgs",
    metaData: {
      fields: ["facebook", "twitter", "linkedin", "instagram", "pinterest"]
    },
    config: {
      filters: [
        {
          field: "id",
          operator: "eq",
          value: userId,
        },
      ],
    },
  });

  const orgAppeareanceQueryResult = useList<IOrg>({
    resource: "ob_orgs",
    metaData: {
      fields: ["product_view", "primary_color", "primary_font", "favicon", "footer_icon", "show_orders",
        "show_latest_products", "show_latest_news", "display_copyright_in_footer", "catalog_logo", "catalog_cover",
        "pdf_category_color", "pdf_image_border_color", "catalog_footer_text", "customers_PDF_catalog", "description","show_FAQ_page","show_aboutus_page"]
    },
    config: {
      filters: [
        {
          field: "id",
          operator: "eq",
          value: userId,
        },
      ],
    },
  });
  const orgEmailNotificationQueryResult = useList<IOrg>({
    resource: "ob_orgs",
    metaData: {
      fields: ["new_customer_email", "new_order_email", "bcc_outgoing_email", "new_order_email_from_customer", "new_orderXLS", "order_PDF"]
    },
    config: {
      filters: [
        {
          field: "id",
          operator: "eq",
          value: userId,
        },
      ],
    },
  });

  const orgDefaultValuesQueryResult = useList<IOrg>({
    resource: "ob_orgs",
    metaData: {
      fields: ["track_inventory", "backOrder", "default_product_image"]
    },
    config: {
      filters: [
        {
          field: "id",
          operator: "eq",
          value: userId,
        },
      ],
    },
  });

  const orgCustomerRegisterFieldQueryResult = useList<IOrg>({
    resource: "ob_orgs",
    metaData: {
      fields: ["customer_phone", "company_activity", "customer_address", "customer_address_2", "customer_country", "customer_city", "customer_province", "customer_postal_code", "customer_comapny_number", "customer_vat_number", "customer_website"]
    },
    config: {
      filters: [
        {
          field: "id",
          operator: "eq",
          value: userId,
        },
      ],
    },
  })
  return (
    <List
      title="Profile"
    >
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Basic Info" key="1">
          <BasicInfo basicinfo={orgBasicInfoQueryResult} />
        </TabPane>
        <TabPane tab="Address" key="2">
          <Address addressData={orgAddressQueryResult} />
        </TabPane>
        {
          user?.defaultRole == "admin" || "owner" ?
            <>
              <TabPane tab="Appearance" key="3">
                <Appearance AppearanceData={orgAppeareanceQueryResult} />
              </TabPane>
              <TabPane tab="Social Media" key="4">
                <SocialMedia SocialMediaData={orgPhoneQueryResult} />
              </TabPane>
              <TabPane tab="Email Notifications" key="5">
                <EmailNotification EmailNotificationData={orgEmailNotificationQueryResult} />
              </TabPane>
              {/* <TabPane tab="Application Configuration" key="6">
                <ApplicationConfiguration />
              </TabPane> */}
              <TabPane tab="Customer Registration Fields" key="7">
                <CustomerRegisterField CustomerRegistrationFieldData={orgCustomerRegisterFieldQueryResult} />
              </TabPane>
              <TabPane tab="Default Values" key="8">
                <DefaultValues DefaultValueData={orgDefaultValuesQueryResult} />
              </TabPane>
              {/* <TabPane tab="Advanced" key="9">
                <Advanced />
              </TabPane>
              <TabPane tab="API Configuration" key="10">
                <ApiConfiguration />
              </TabPane> */}
            </> : ""
        }
      </Tabs>
    </List >
  );
};