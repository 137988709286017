
import { IResourceComponentsProps,useExport, CrudFilters, HttpError, useGetIdentity } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    DatePicker,
    Button,
    Card,
    Input,
    Form,
    FormProps,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm,Icons
} from "@pankod/refine-antd";
import { IEmailTemplates } from "interfaces";
import { CreateEmailTemplate } from "./create";
import { EditEmailTemplate } from "./edit";
import { ReloadOutlined } from "@ant-design/icons";
const { PlusOutlined,MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;


const { RangePicker } = DatePicker;

export const EmailTemplateList: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IEmailTemplates>({
        resource: "email_templates",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
        ],
        metaData: {
            fields: ["id", "content", "created_at"],
        }
    });
    
    const { formProps, modalProps, show } = useModalForm({
        resource: "email_templates",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,
        
    } = useModalForm({
        resource: "email_templates",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "content", "created_at"],
        },
    });

    const { triggerExport, isLoading } = useExport<IEmailTemplates>();
    let datacount = tableQueryResult?.data?.total;

    return (
        <List 
        title="Email Templates"
            pageHeaderProps={{

                extra: [
                  //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                    <RefreshButton
                    type="primary"
                    children={<div style={{ display: "flex", alignItems: "center" }}>
                    <ReloadOutlined style={{ marginRight: "5px" }} />
                    Refresh
                  </div>}
                  icon={false}
                    onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" resourceName='settings/emailtemplates'
                     children={<div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Add Email Template
                  </div>}
                  icon={false}
                     onClick={() => show()} />,
                ],

            }}
        >
            <span>Records: {datacount}</span>
            <Table {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="content" title="Content" />
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<IEmailTemplates>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                resourceName='settings/emailtemplates'
                                onClick={() => editShow(record.id)}
                            />
                            <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                resourceName='settings/emailtemplates'
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            <CreateEmailTemplate modalProps={modalProps} formProps={formProps} />
            <EditEmailTemplate modalProps={editModalProps} formProps={editFormProps} />

        </List>
    );
};
