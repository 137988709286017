import { DeleteOutlined, PictureOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Tabs,
  InputNumber, Checkbox, useSelect, Select,
} from "@pankod/refine-antd";
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import { useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import { nhost } from "utility";
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { jsonConverter } from "components/helpers/jsonConverter";

type EditBannersProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeEditModal: () => void
  editModalVisible: boolean,
  handleFormSubmitted: () => void,
  tableQueryResult: any
};
const { TextArea } = Input;
const {TabPane} = Tabs

const onChange = (key: string) => {
};

export const EditBanner: React.FC<EditBannersProps> = ({
  modalProps,
  formProps,
  closeEditModal,
  editModalVisible,
  handleFormSubmitted,
  tableQueryResult
}) => {
  const { data: user } = useGetIdentity();
  const { Dragger } = Upload;
  const [form] = Form.useForm();
  const { open, close } = useNotification();

  const [urlList, setUrlList] = useState<any>([])
  const userId = formProps?.initialValues?.id
  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });
  const [headingShow, setHeadingShow] = useState({signup:true, login: true})
  const [urlButtonShow, setURLButtonShow] = useState({signup:true, login: true})
  const [textBoxModal, setTextBoxModal] = useState(false);
  const [mobileUrlList, setMobileUrlList] = useState([] as any[]);
  const [selectedBannerType, setSelectedBannerType] = useState<any>(null);
  const [selectedBannerPage, setSelectedBannerPage] = useState(null);
  const [selectedLinkType, setSelectedLinkType] = useState<any>(null);
  const [selectedUrl, setSelectedUrl] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [selectedsidebarLocation, setSelectedsidebarLocation] = useState<any>(null);
  const handleActiveCheck = () => {
    setTextBoxModal(!textBoxModal);
  };

  const props: UploadProps = {
    beforeUpload: (file) => {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSizeInBytes) {
        message.error('File size exceeds the limit (10MB).');
        return false;
      }
      return true;
    },
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
    },
  };

  useEffect(() => {
    setUrlList([formProps?.initialValues?.image]);
    setMobileUrlList([formProps?.initialValues?.mobile_image]);
    setSelectedBannerType(formProps?.initialValues?.banner_props?.banner_type);
    setSelectedBannerPage(formProps?.initialValues?.banner_props?.banner_page);
    if(formProps?.initialValues?.banner_props?.signup?.heading?.title != null){
      // setHeadingShow(false)
      handelHead()
    }
    if(formProps?.initialValues?.banner_props?.signup?.link?.link_type != null){
      handelUrlButton() 
    }
    if(formProps?.initialValues?.banner_props?.login?.heading?.title != null){
      // setHeadingShow(false)
      handelHeadLogin()
    }
    if(formProps?.initialValues?.banner_props?.login?.link?.link_type != null){
      handelUrlButtonLogin()
    }
  },[formProps]);

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setUrlList((urlList: any) => [data.secure_url]);
          })
          .catch(err => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const removeImage = () => {
    setUrlList([])
  }

  const resetHeadingProps = (heading: any) => {
    Object.assign(heading, {
      heading_color: null,
      title: null,
    });
  };
  
  const resetLinkProps = (link: any) => {
    Object.assign(link, {
      link_color: null,
      link_text: null,
      link_text_color: null,
      link_type: null,
    });
  };

  const apidata = (e: any) => {

    if (headingShow.login) {
      if(e.banner_props.login){
        resetHeadingProps(e.banner_props.login.heading);
      }
    }
    
    if (urlButtonShow.login) {
      if(e.banner_props.login){
        resetLinkProps(e.banner_props.login.link);
      }
    }
    
    if (headingShow.signup) {
      if(e.banner_props.signup){
        resetHeadingProps(e.banner_props.signup.heading);
      }
    }
    
    if (urlButtonShow.signup) {
      if(e.banner_props.signup){
        resetLinkProps(e.banner_props.signup.link);
        Object.assign(e.banner_props.login,{
          redirect_url: null
        })
      }
    }

    const MUTATIONAPI = `mutation MyMutation {
        update_banners(_set: {  view_order:${e.view_order}, active: ${e.active},image: ${e.image ? `"${e.image}"` : null},mobile_image: "${e.mobile_image}",
         ob_org_id: "${user?.metadata?.org_id}", banner_props: ${jsonConverter(e.banner_props)},
      }where: { id: { _eq: "${userId}" } }){
          affected_rows
        }
      }
      `
    const imageSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      if (data) {
        open?.({
          key:"success",
          type: "success",
          message: "",
          description: `Banner Sucessfully Updated`,
      });
        setTimeout(() => {
          close("success");
        }, 4000);
        closeEditModal();
        setTimeout(() => {
          tableQueryResult?.refetch();  
        }, 300);
        return data
      }
    }
    imageSubmit();
  }
  
  const handleBannerPageChange = (value:any) => {
    setSelectedBannerPage(value);
  };

  const handleBannerTypeChange = (value:any) => {
    setSelectedBannerType(value);
  };

  const selectBannerPageOptions = [
    { label: 'Home', value: 'Home' },
    { label: 'Contact', value: 'Contact' },
    { label: 'About', value: 'About' },
    { label: 'FAQ', value: 'FAQ' },
  ];

  const selectHomeOptions = [
    { label: 'Main', value: 'Main' },
    { label: 'Special Banner', value: 'Special Banner' },
    { label: 'Card', value: 'Card' },
    { label: 'Side bar', value: 'Side bar' },
  ];

  const selectOtherPageOptions = [
    { label: 'Main', value: 'Main' },
    { label: 'Side bar', value: 'Side bar' },
  ];
  
  const selectOptions = selectedBannerPage == "Home" ? selectHomeOptions : selectOtherPageOptions;

  // useEffect(() => {
  //   if (selectedBannerPage === "Contact" || selectedBannerPage === "About") {
      
  //     setSelectedBannerType("Main");
  //     formProps?.form?.setFieldsValue({[`banner_props.banner_type`]: "Main"});
  //   }    
  // }, [selectedBannerPage]);
  
  const handleLinkTypeChange = (value:any) => {
    setSelectedLinkType(value);
  };
  const linkSelectOptions = [
    { label: 'Button', value: 'Button' },
    { label: 'Link', value: 'Link' },
  ];

  const handleUrlChange = (value:any) => {
    setSelectedUrl(value);
  };
  const urlSelectOptions = [
    { label: 'Category', value: 'category' },
    { label: 'Signin', value: 'signin' },
    { label: 'Favourites', value: 'my-account/favourites' },
    { label: 'Orders', value: 'my-account/orders' },
  ];

  const handleLocationChange = (value:any) => {
    setSelectedLocation(value);
  };

  const handlesidebarLocationChange = (value:any) => {
    setSelectedsidebarLocation(value);
  };
  const locationSelectOptions = [
    { label: 'Below Main banner', value: 'Below Main banner' },
    { label: 'Below Cards', value: 'Below Cards' },
    { label: 'Below Feature Product', value: 'Below Feature Product' },
    { label: 'Below Category', value: 'Below Category' },
    { label: 'Above Footer', value: 'Above Footer' },
  ];

  const sidebarLocationOptions = [
    { label: 'Left', value: 'Left' },
    { label: 'Right', value: 'Right' },
  ]

    
  const handelHead = () => {
    setHeadingShow((prevState) => ({
      ...prevState,
      signup: !prevState.signup,
    }));
  };
  const handelHeadLogin = () => {
    setHeadingShow((prevState) => ({
      ...prevState,
      login: !prevState.login,
    }));
  };
  const handelUrlButton = () =>{
    setURLButtonShow((prevState) =>({
      ...prevState,
      signup: !prevState.signup,
    }))
  }
  const handelUrlButtonLogin = () =>{
    setURLButtonShow((prevState) =>({
      ...prevState,
      login: !prevState.login,
    }))
  }

  const removeMobileImage = () => {
    setMobileUrlList([]);
  }

  const uploadMobileImages = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "bt5y9hp4");
      data.append("cloud_name", "dov89ugan");
      data.append("folder", "orderbit");

      const response = await fetch("https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
        method: "post",
        body: data,
      });

      const result = await response.json();

      if (response.ok) {
        const transformedUrl = `https://res.cloudinary.com/dov89ugan/image/upload/w_450,h_222,c_fill/${result.public_id}.${result.format}`;

        
        setMobileUrlList(mobileUrlList => [transformedUrl]);
        onSuccess("Ok");
      } else {
        throw new Error(result.error.message);
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  return (
    <Modal {...modalProps} title="Edit Banner"
      onCancel={closeEditModal}
      visible={editModalVisible}
      className="edit_banner"
    >
      <Form {...formProps} onFinish={(e) => {
          e.image = urlList.join(",")
          e.mobile_image = mobileUrlList.join(",")
          apidata(e)
        }} size="large" layout="vertical">

          <Form.Item name={['banner_props', 'banner_page']} label="Banner Page"
            rules={[{ required: true, message: 'Please Select Banner Page!' }]}  
          >
            <Select
              onChange={handleBannerPageChange}
              value={selectedBannerPage}
            >
              {selectBannerPageOptions.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="banner_props">
            <Form.Item name={['banner_props', 'banner_type']} label="Banner Type"
                rules={[{ required: true, message: 'Please Select Banner Type!' }]}  
              >
                <Select
                  onChange={handleBannerTypeChange}
                  value={selectedBannerType}
                >
                  {selectOptions?.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                  ))}
                </Select>
            </Form.Item>
        
            {selectedBannerType  != "Special Banner" ? 
            <>
            { selectedBannerType  == "Side bar" ? 
            <>
            <Form.Item
            name={['banner_props', 'side_bar_location']}
            label="Location"
            rules={[{ required: true, message: 'Please Select Side bar Location!' }]}  
          >
            <Select
              onChange={handleLocationChange}
            >
              {sidebarLocationOptions.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          </>
          : <></>
          }
            </>
            : <>
            <Form.Item
              name={['banner_props', 'special_banner_location']}
              label="Location"
              rules={[{ required: true, message: 'Please Select Banner Location!' }]}  
            >
              <Select
                onChange={handleLocationChange}
                value={selectedLocation}
              >
                {locationSelectOptions.map((option: any) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            </> }
          </div>
          <Tabs>
            <TabPane tab="Signup" key={1}>
              <div className="heading">
                <div style={{display: 'flex', alignItems:'center', gap: '20px'}} aria-disabled ={headingShow.signup} >
                  <Form.Item
                    name={['banner_props','signup','heading','title']}
                    label={<span style={{color:headingShow.signup?"gray":""}}>Heading</span>}
                    rules={[{ required:headingShow.signup ? false: true, message: 'Please Enter Heading!' }]}
                    style={{width:'85%'}}
                  >
                    <TextArea rows={1} disabled ={headingShow.signup}/>
                  </Form.Item>
                  <Form.Item  style={{display:'flex', alignItems: 'center', alignSelf: 'flex-end'  }}>
                    <Checkbox checked = {headingShow.signup ? false : true} onClick={()=>handelHead()}>Show</Checkbox>
                  </Form.Item>
                </div>
                
                <Form.Item
                  name={['banner_props','signup','heading','heading_color']}
                  label={<span style={{color:headingShow.signup?"gray":""}}>Color</span>}                       
                  initialValue={headingShow.signup ? "#080808" : "#808080"}
                >
                  <Input type="color" disabled = {headingShow.signup}/>
                </Form.Item>
              </div>
              {selectedBannerType  != "Card" ? 
              <div className="description">
              <Form.Item
                name={['banner_props','signup','description','description']}
                label="Description"
              >
                <TextArea rows={2} />
              </Form.Item>
              <Form.Item
                name={['banner_props','signup','description','description_color']}
                label="Color"
                initialValue={"#080808"}
              >
                <Input type="color"/>
              </Form.Item>
              </div>
              : <></> }
              {selectedBannerPage == "Home" ? 
              <div className="button">
              <Form.Item
                name={['banner_props','signup','link','link_type']}
                label={<span style={{color:urlButtonShow.signup?"gray":""}}>Link Type</span>}
                rules={[{ required: urlButtonShow.signup? false :true, message: 'Please Select Link Type!' }]}
              >
                <Select
                  onChange={handleLinkTypeChange}
                  value={selectedLinkType}
                  disabled = {urlButtonShow.signup}
                >
                  {linkSelectOptions.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={['banner_props','signup','link','link_text']}
                label={<span style={{color:urlButtonShow.signup?"gray":""}}>Link Name</span>}
                rules={[{ required: urlButtonShow.signup? false :true, message: 'Please Enter Link Name!' }]}
              >
                <TextArea rows={1} disabled = {urlButtonShow.signup}/>
              </Form.Item>
              <Form.Item
                name={['banner_props','signup','link','link_color']}
                label={<span style={{color:urlButtonShow.signup?"gray":""}}>Color</span>}
                initialValue={"#080808"}
              >
                <Input type="color" disabled = {urlButtonShow.signup}/>
              </Form.Item>
              <Form.Item
                name={['banner_props','signup','link','link_text_color']}
                label={<span style={{color:urlButtonShow.signup?"gray":""}}>Text Color</span>}
                initialValue={"#f5f5f5"}
              >
                <Input type="color" disabled = {urlButtonShow.signup}/>
              </Form.Item>
              </div>
              : <></> }
              <div className="others">
                <div>
                {selectedBannerPage == "Home" ? 
                <div style={{display:'flex', justifyItems: 'center', alignItems: 'center', gap: '20px'}}>
                  <Form.Item
                    name={['banner_props','signup','redirect_url']}
                    label={<span style={{color:urlButtonShow.signup?"gray":""}}>Url</span>}
                    style={{color:'gray', width:'100%'}}
                    rules={[{ required: urlButtonShow.signup ? false : true, message: 'Please Select Link Url!' }]}
                  >
                    <Select
                      onChange={handleUrlChange}
                      value={selectedUrl}
                      disabled = {urlButtonShow.signup}
                    >
                      {urlSelectOptions.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{display:'flex', alignItems: 'center', alignSelf: 'flex-end'  }}>
                    <Checkbox checked = {urlButtonShow.signup ? false : true} onClick={()=>handelUrlButton()}> Show </Checkbox>
                  </Form.Item>
                </div>
                : <></> }
                  
                </div>
              <Form.Item label="" name="active" style={{ display: "flex", alignSelf: "end" }} initialValue={true} valuePropName="checked">
                <Checkbox> Active </Checkbox>
              </Form.Item>
              <Form.Item
                name="view_order"
                label="View Order"
                rules={[{ required: true, message: 'Please input View Order!' }]}
              >
                <InputNumber defaultValue="0" style={{ width: '100%' }} name="view_order" />
              </Form.Item>
              </div>
                {user?.defaultRole === "admin" ?
                  <Form.Item
                    label="Orderbit Organisation"
                    name="ob_org_id"
                    rules={[{}]} >
                    <Select {...OBOrgSelectProps} />
                  </Form.Item> : ""
                }
            </TabPane>
            <TabPane tab="Login" key={2}>
              <div className="heading">
                <div style={{display: 'flex', alignItems:'center', gap: '20px'}} aria-disabled ={headingShow.login} >
                  <Form.Item
                    name={['banner_props', 'login','heading','title']}
                    label={<span style={{color:headingShow.login?"gray":""}}>Heading</span>}
                    rules={[{ required:headingShow.login ? false: true, message: 'Please Enter Heading!' }]}
                    style={{width:'85%'}}
                  >
                    <TextArea rows={1} disabled ={headingShow.login}/>
                  </Form.Item>
                  <Form.Item  style={{display:'flex', alignItems: 'center', alignSelf: 'flex-end'  }}>
                    <Checkbox checked = {headingShow.login ? false : true} onClick={()=>handelHeadLogin()}>Show</Checkbox>
                  </Form.Item>
                </div>
                
                <Form.Item
                  name={['banner_props','login', 'heading','heading_color']}
                  label={<span style={{color:headingShow.login?"gray":""}}>Color</span>}                      
                  initialValue={headingShow.login ? "#080808" : "#808080"}
                >
                  <Input type="color" disabled = {headingShow.login}/>
                </Form.Item>
              </div>
              {selectedBannerType  != "Card" ? 
              <div className="description">
              <Form.Item
                name={['banner_props','login', 'description','description']}
                label="Description"
              >
                <TextArea rows={2} />
              </Form.Item>
              <Form.Item
                name={['banner_props','login', 'description','description_color']}
                label="Color"
                initialValue={"#080808"}
              >
                <Input type="color"/>
              </Form.Item>
              </div>
              : <></> }
              {selectedBannerPage == "Home" ? 
              <div className="button">
              <Form.Item
                name={['banner_props','login', 'link','link_type']}
                label={<span style={{color:urlButtonShow.login?"gray":""}}>Link Type</span>}
                rules={[{ required: urlButtonShow.login? false :true, message: 'Please Select Link Type!' }]}
              >
                <Select
                  onChange={handleLinkTypeChange}
                  value={selectedLinkType}
                  disabled = {urlButtonShow.login}
                >
                  {linkSelectOptions.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={['banner_props','login', 'link','link_text']}
                label={<span style={{color:urlButtonShow.login?"gray":""}}>Link Name</span>}
                rules={[{ required: urlButtonShow.login? false :true, message: 'Please Enter Link Name!' }]}
              >
                <TextArea rows={1} disabled = {urlButtonShow.login}/>
              </Form.Item>
              <Form.Item
                name={['banner_props','login', 'link','link_color']}
                label={<span style={{color:urlButtonShow.login?"gray":""}}>Color</span>}
                initialValue={"#080808"}
              >
                <Input type="color" disabled = {urlButtonShow.login}/>
              </Form.Item>
              <Form.Item
                name={['banner_props','login', 'link','link_text_color']}
                label={<span style={{color:urlButtonShow.login?"gray":""}}>Text Color</span>}
                initialValue={"#f5f5f5"}
              >
                <Input type="color" disabled = {urlButtonShow.login}/>
              </Form.Item>
              </div>
              : <></> }
              <div className="others">
                <div>
                {selectedBannerPage == "Home" ? 
                <div style={{display:'flex', justifyItems: 'center', alignItems: 'center', gap: '20px'}}>
                  <Form.Item
                    name={['banner_props','login','redirect_url']}
                    label={<span style={{color:urlButtonShow.login?"gray":""}}>Url</span>}
                    style={{color:'gray', width:'100%'}}
                    rules={[{ required: urlButtonShow.login ? false : true, message: 'Please Select Link Url!' }]}
                  >
                    <Select
                      onChange={handleUrlChange}
                      value={selectedUrl}
                      disabled = {urlButtonShow.login}
                    >
                      {urlSelectOptions.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item style={{display:'flex', alignItems: 'center', alignSelf: 'flex-end'  }}>
                    <Checkbox checked = {urlButtonShow.login ? false : true} onClick={()=>handelUrlButtonLogin()}> Show </Checkbox>
                  </Form.Item>
                </div>
                : <></> }
                  
                </div>
              {/* <Form.Item label="" name="active" style={{ display: "flex", alignSelf: "end" }} initialValue={true} valuePropName="checked">
                <Checkbox> Active </Checkbox>
              </Form.Item>
              <Form.Item
                name="view_order"
                label="View Order"
                rules={[{ required: true, message: 'Please input View Order!' }]}
              >
                <InputNumber defaultValue="0" style={{ width: '100%' }} name="view_order" />
              </Form.Item> */}
              </div>
                {user?.defaultRole === "admin" ?
                  <Form.Item
                    label="Orderbit Organisation"
                    name="ob_org_id"
                    rules={[{}]} >
                    <Select {...OBOrgSelectProps} />
                  </Form.Item> : ""}
              
              <Form.Item name="created_by"
                initialValue={user?.id}
              >
                <Input type="hidden" />
              </Form.Item>
            </TabPane>
          </Tabs>
          <div>
            <Form.Item label={selectedBannerType  == "Side bar" ? <div>Upload Deshbord Banner Image <pre>(When uploading an image, please ensure it has a <strong> 2:3 </strong> aspect ratio for the best viewing experience)</pre></div> : "Upload Deshbord Banner Image"}
            name="image"
            rules={[{ required: true, message: 'Please Select Image!' }]}
            >
              <div className="Image_div">
                <div>
                  <Dragger showUploadList={false}
                  {...props}
                  name="image" 
                  customRequest={uploadImages}
                  >
                    <p className="ant-upload-drag-icon">
                      <PictureOutlined />
                    </p>
                    <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                  </Dragger>
                </div>
                <div className="category_img_div">
                  <h1 style={{ fontSize: "14px" }}>Uploaded image will be displayed here</h1>
                  {urlList?.map((url:any) => {
                    return <div>
                    <img className="category_img" src={url} />
                      {url ? <span ><DeleteOutlined onClick={removeImage}/></span> : <></> }
                    </div>
                  })}
                </div>
              </div>
            </Form.Item>
            {selectedBannerType == "Main" &&
              <Form.Item label={<div>Upload Mobile Banner Image <pre>(When uploading an image, please ensure it has a <strong> 450 x 222 </strong> pixel for the best viewing experience)</pre></div>}
                name="mobile_image"
                // rules={[{ required: true, message: 'Please Select Image!' }]}
              >
                <div className="Image_div">
                  <div>
                    <Dragger showUploadList={false}
                    {...props}
                    name="image" 
                    customRequest={uploadMobileImages}
                    >
                      <p className="ant-upload-drag-icon">
                        <PictureOutlined />
                      </p>
                      <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                    </Dragger>
                  </div>
                  <div className="category_img_div">
                    <h1 style={{ fontSize: "14px" }}>Uploaded image will be displayed here</h1>
                    {mobileUrlList?.map((url:any) => {
                      return <div>
                      <img className="category_img" src={url} />
                        {url ? <span ><DeleteOutlined onClick={removeMobileImage}/></span> : <></> }
                      </div>
                    })}
                  </div>
                </div>
              </Form.Item>
            }
          </div>
      </Form>
    </Modal>
  );
};
