import {
    CrudFilter,
    CrudFilters,
    HttpError, 
     GetListResponse,
  
    IResourceComponentsProps,
    useExport,
    useNotification,
  } from "@pankod/refine-core";
  import {
    List,
    Form,
    RefreshButton,
    CreateButton,
    Modal,
    ExportButton,
    Table,
    useTable,
    useSelect,
    Space,
    Button,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm,
    Icons,
    Input,
    Grid,
    ModalProps,
    ShowButton,
    FilterDropdown,
    Select,
    AutoComplete,
    SaveButton,
  } from "@pankod/refine-antd";
  import { Tag, Radio } from "antd";
  
  import { IOrder, IOrderStatus, ICustomer } from "interfaces";
  import { useState, useEffect, useRef } from "react";
  import { TableColumnsType } from "antd";
  import { useGetIdentity } from "@pankod/refine-core";
  import { IProduct } from "interfaces";
  import { useNavigation } from "@pankod/refine-core";
  import moment from "moment";
  import "../../style.css";
  import { GET_CATEGORIES, Update_Order, Update_Status, Update_Trip } from "query";
  
  const { PlusOutlined, BlockOutlined, GatewayOutlined, CalendarOutlined } = Icons;
const { Option } = Select;

  interface ISearch {
    name: string;
  }

export const OrderViewList: React.FC<IResourceComponentsProps> = () => {
    const [userList, setUserList] = useState([] as any[]);
    const { data: user } = useGetIdentity();
  const { open, close } = useNotification();
    const route = useNavigation()
    const userId = user?.metadata?.org_id;
    const { push } = useNavigation();
    const [ordersFilters, setOrdersFilters] = useState<any>([
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ]);
    const [customerId, setCustomerId] = useState<any>();
    const [myStatus, setMyStatus] = useState<any>([]);
    const [statusChange, setStatusChange] = useState<any>(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [showModalVisible, setShowModalVisible] = useState(false);
    const [showTable, setShowTable] = useState(true);
  
    useEffect(() => {
      setOrdersFilters([
        {
          field: "customer_id",
          operator: "eq",
          value: customerId,
        },
        // {
        //   field: "ob_org_id",
        //   operator: "eq",
        //   value: userId,
        // },
        {
          field: "status",
          operator: "eq",
          value: status,
        },
      ]);
    }, [customerId]);
  
    const handleSearch = (e: any) => {
      setCustomerId(e);
    };
  
    const handleOnClear = () => {
      setStatus(null);
    };
    const handleOnClearSearch = () => {
      setCustomerId(null);
    };
    const [status, setStatus] = useState(null);
    const [trip, setTrip] = useState(null);
    const [search, setSearch] = useState(null);
    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IOrder>({
      resource: "orders",
      initialPageSize: 10,
      initialSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      permanentSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      permanentFilter: [
        {
          field: "ob_org_id",
          operator: "eq",
          value: userId, 
        },
        {
          field: "customer_id",
          operator: "eq",
          value: customerId,
        },
        {
          field: "status",
          operator: "contains",
          value: status,
        },
        {
          field: "trip_id",
          operator: "eq",
          value: trip,
        },
        // ...ordersFilters,
      ],
      // permanentFilter: ordersFilters,
      metaData: {
        fields: [
          "id",
          "name",
          "date",
          "email",
          "items",
          "phone_no",
          "status",
          "order_Unique_id",
          "created_at",
          "total_price",
          "address",
          "trip_id",
        ],
      },
    });
  
    const { tableProps:newTableProps, sorter: newSorter, tableQueryResult: newTableQueryResult, searchFormProps: newSearchFormProps } = useTable<IOrder>({
      resource: "orders",
      initialPageSize: 10,
      initialCurrent: 1,
      initialSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      permanentSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      permanentFilter: [
        {
          field: "ob_org_id",
          operator: "eq",
          value: userId, 
        },
        {
          field: "customer_id",
          operator: "eq",
          value: customerId,
        },
        {
          field: "status",
          operator: "contains",
          value: status,
        },
        {
          field: "trip_id",
          operator: "eq",
          value: trip,
        },
      ],
      metaData: {
        fields: [
          "id",
          "name",
          "date",
          "email",
          "items",
          "phone_no",
          "status",
          "order_Unique_id",
          "created_at",
          "total_price",
          "address",
          "trip_id",
        ],
      },
    });
  
    const tableData = useTable<IOrderStatus>({
      resource: "trip",
  
      queryOptions: {
        staleTime: 0,
        cacheTime: 0, 
      },
  
      initialSorter: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
      permanentFilter: [
        {
          field: "ob_org_id",
          operator: "eq",
          value: userId,
        },
      ],
      metaData: {
        fields: [
          "id",
          "route_id",
          "date",
          "driver_name",
          "ob_org_id",
          "vehicle_number",
          "notes",
          "start_time",
          "end_time",
          "name",
          "created_at",
          "updated_at",
          "due_time",
          "seq_number",
        ],
      },
    });
  
    useEffect(() => {
      const refet = async () => {
        const result = await tableQueryResult.refetch();
      };
  
      refet();
    }, []);
  
    useEffect(() => {    
        !myStatus.length &&
          setMyStatus(() => {
            return Array.from(
              new Set(tableProps.dataSource?.map((item) => item.status))
            );
          }); 
    }, [tableProps.dataSource, statusChange == true]);
    const { selectProps } = useSelect<any>({
      resource: "customers",
      metaData: {
        fields: ["id", "name"],
      },
      optionLabel: "name",
      optionValue: "id",
      filters:[
        {
          field:"ob_org_id",
          operator:"eq",
          value:userId
        }
       ],
      onSearch: (value) => [
        {
          field: "name",
          operator: "contains",
          value: "%" + value + "%",
        },
      ],
    });
  
    useEffect(() => {
      const copyFilters = [...ordersFilters];
      copyFilters.pop();
      copyFilters.push({
        field: "status",
        operator: "eq",
        value: status,
      });
  
      setOrdersFilters([...copyFilters]);
    }, [status]);
  
    useEffect(() => {
      setTimeout(() => {
        setMyStatus(() => {
          return Array.from(
            new Set(tableProps.dataSource?.map((item) => item.status))
          );
        });  
      }, 500);
    }, [tableProps.dataSource, statusChange == true, !showModalVisible]);
  
    useEffect(() => {
      const copyFilters = [...ordersFilters];
      copyFilters.pop();
      copyFilters.push({
        field: "status",
        operator: "eq",
        value: status,
      });
  
      setOrdersFilters([...copyFilters]);
    }, [status]);
  
    let datacount = tableQueryResult?.data?.total;
    const orderTripList = tableData.tableProps?.dataSource?.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  
    const getBadgeColor = (type: any, dataSource: any) => {
      const item = dataSource?.find(
        (item: any) => item.name.toLowerCase() === type.toLowerCase()
      ) ?? { bg_color: "#FFFFFF" };
      return item?.bg_color ?? "#FFFFFF";
    };
  
    const BadgeItem = (status: string, bg_color?: string) => {
      return <Tag color={getBadgeColor(status, bg_color)}>{status}</Tag>;
    };
    const handleChange = (id: string, value: string) => {    
      setStatusChange(false);
      Update_Trip(id, value).then((res:any) => {
        open?.({
          key: "success",
          type: "success",
          message: "",
          description: `Trip added Successfully`,
        });
        setTimeout(() => {
          close("success");
        }, 2000);
      });
      setTimeout(() => {
        tableQueryResult?.refetch();
        setStatusChange(true);
      }, 300);
    };
  
    const DropDown = (tripId: string, record: any) => {        
      return (
        // <Select
        // className="status-select"
        //  bordered={false}
        //   defaultValue={tripId}
        //   dropdownStyle={{border:"none",paddingRight:"1px "}}
        //   style={{
        //     width: 200,
        //     border: "none",
        //     // borderColor: `1px solid ${getBadgeColor(
        //     //   tripId,
        //     //   tableData.tableProps?.dataSource
        //     // )}`,
        //     // background: getBadgeColor(tripId, tableData.tableProps?.dataSource),
        //   }}
        //   onSelect={(status: string) => handleChange(record.id, status)}
        //   onChange={(status) => {
        //     handleChange(record.id, status);
        //   }}
        //   dropdownRender={(status) => <>{status}</>}
        //   options={orderTripList?.map((e) => ({
        //     // label: drowpItem(e.label),
        //     label: e.label,
        //     value: e.value,
        //   }))}
        // />
        <Select
          filterOption={false}
          defaultValue={tripId}
          placeholder="Select Trip"
          style={{ width: "300px" }}
          onChange={(status) => {
            handleChange(record.id, status);
          }}
        >
          {tableData?.tableProps?.dataSource?.map((data: any) => {
            const givenDateTime = new Date(data?.due_time);
            const currentDateTime = new Date();
            // if (givenDateTime > currentDateTime) {
              const findRoute = routeSelectProps?.options?.find(
                (res: any) => res?.value == data?.route_id
              );
              return (
                <Option disabled={givenDateTime < currentDateTime ? true : false} key={data?.id}>
                  <div style={{display: "flex",alignItems: "center",}}>
                    <div><BlockOutlined /> {data?.name}-</div>
                    <div>{findRoute?.label}-</div>
                    <div><DateField value={data?.date} format="MMM DD" />-</div>
                    <div>{data?.seq_number ? data?.seq_number?.toString().padStart(2, '0') : "00"}</div>
                  </div>
                </Option>
              );
            // }
          })}
        </Select>
      );
    };
  
    const drowpItem = (status: string) => {
      return (
        <Tag
          color={getBadgeColor(status, tableData.tableProps?.dataSource)}
          style={{ width: "100%" }}
        >
          {status}
        </Tag>
      );
    };
    const { formProps, modalProps, show } = useModalForm({
      resource: "orders",
      action: "create",
    });
  
    const {
      formProps: showFormProps,
      modalProps: showModalProps,
      show: ShowPopup,
  
  } = useModalForm({
      resource: "orders",
      action: 'clone',
      redirect: false,
      metaData: {
          fields: ["id", "name", "email", "phone_no", "items", "status", "created_at", "order_Unique_id", "date", "phone_no", "total_price", "address", "trip_id",],
      },
  });
    const getUsers = async () => {
      GET_CATEGORIES().then((response: any) => {
        setUserList(response.orders);
      });
    };
    useEffect(() => {
      getUsers();
    }, []);
  
    const {
      formProps: ViewFormProps,
      modalProps: ViewModalProps,
      show: ViewShow,
    } = useModalForm({
      resource: "orders",
      action: "edit",
      metaData: {
        fields: [
          "id",
          "name",
          "email",
          "phone_no",
          "items",
          "status",
          "created_at",
        ],
      },
    });
  
    const listOrder = ViewFormProps.initialValues;
  
    const {
      formProps: editFormProps,
      modalProps: editModalProps,
      show: editShow,
    } = useModalForm({
      resource: "orders",
      action: "edit",
      metaData: {
        fields: [
          "id",
          "name",
          "email",
          "phone_no",
          "items",
          "status",
          "created_at",
        ],
      },
    });
    const { selectProps: routeSelectProps } = useSelect<IProduct>({
      resource: "route",
      optionLabel: "name",
      optionValue: "id",
      metaData: {
        fields: ["id", "name"],
      },
      sort: [{ field: "name", order: "asc" }],
    });
  
    const orderDate = listOrder?.created_at;
  
    const formateDate = new Date(orderDate);
  
    const defaultDate = formateDate.toString();
    let total = 0;
    listOrder?.items?.forEach((data: any) => (total += data?.price));
    let initialValue = 0;
    let QuatityinitialValue = 0;
  
  
    const handleSelect = (e: any) => {
  
      setStatus(e);
    };

    const handleTripSelect = (e: any) => {
        setTrip(e)
    };
  
    const handleTripOnClear = () => {
        setTrip(null);
      };

    useEffect(() => {
      setTimeout(() => {
        tableQueryResult?.refetch();
      }, 500);
    }, [createModalVisible, showModalVisible]);
    
    useEffect(() => {
      setShowTable(false);
      if(tableProps?.dataSource){
        setTimeout(() => {
          setShowTable(true);
        }, 300);
     }
    }, [tableProps?.dataSource]);
  
    return (
      <List
      title="Orders Trip Map"
        pageHeaderProps={{
          extra: [
            <>
              <Form
                layout="vertical"
                style={{ display: "flex", marginBottom: 17 }}
              >
                <Form.Item label="Customer">
                  <Select
                    placeholder="Select a customer"
                    style={{ width: 300, marginRight: 20 }}
                    onSelect={handleSearch}
                    clearIcon="*"
                    allowClear
                    onClear={handleOnClearSearch}
                    {...selectProps}
                  />
                </Form.Item>
                <Form.Item label="Trip" name="trip">
                  <Select
                    allowClear
                    style={{ width: 200, marginRight: 20 }}
                    options={tableData?.tableProps?.dataSource?.map(
                      (item: any) => ({
                        value: item?.id,
                        label: item?.name,
                      })
                    )}
                    placeholder="Check Trip"
                    onSelect={handleTripSelect}
                    onClear={handleTripOnClear}
                  />
                </Form.Item>
                {/* <Form.Item label="Status" name="Status">
                  <Select
                    allowClear
                    options={tableData?.tableProps?.dataSource?.map(
                      (item: any) => ({
                        value: item?.name,
                        label: item?.name,
                      })
                    )}
                    placeholder="Check Status"
                    onSelect={handleSelect}
                    onClear={handleOnClear}
                  />
                </Form.Item> */}
              </Form>{" "}
              {/* <CreateButton
                type="primary"
                children={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Create
                  </div>
                }
                icon={false}
                resourceName="orders"
                onClick={() =>
                  // show()
                  setCreateModalVisible(true)
                }
              /> */}
              ,
            </>,
          ],
        }}
      >
        <>
          {showTable ? (
            <>
              {customerId || status ? (
                <Table
                  className="listTable"
                  style={{
                    marginTop: "5px",
                    justifyContent: "center",
                    gridRow: "auto",
                    cursor: "pointer",
                  }}
                  {...newTableProps}
                  rowKey="id"
                  size="small"
                  // pagination={false}
                >
                  <Table.Column
                    dataIndex="order_Unique_id"
                    title="Order Id"
                    align="center"
                    render={(value, record: any) => (
                      <span
                        onClick={() => {
                          ShowPopup(record.id);
                          setShowModalVisible(true);
                        }}
                      >
                        {value}
                      </span>
                    )}
                  />
  
                  <Table.Column
                    dataIndex="date"
                    title="Date"
                    align="center"
                    render={(value) => <DateField value={value} format="LL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                  />
                  <Table.Column
                    dataIndex="name"
                    title="Customer Name"
                    align="center"
                  />
                  <Table.Column
                    dataIndex="items"
                    title="Quantity"
                    align="center"
                    render={(value) => (
                      <span style={{ overflowWrap: "anywhere" }}>
                        {value.reduce(function (accumulator: any, curValue: any) {
                          return accumulator + Number(curValue.quantity);
                        }, QuatityinitialValue)}
                      </span>
                    )}
                  />
                  <Table.Column
                    dataIndex="items"
                    title="Price"
                    align="center"
                    render={(value) => (
                      <span style={{ overflowWrap: "anywhere" }}>
                        £{" "}
                        {value
                          .reduce(function (accumulator: any, curValue: any) {
                            return (
                              accumulator + curValue.price * curValue.quantity
                            );
                          }, initialValue)
                          .toFixed(2)}
                      </span>
                    )}
                  />
                  <Table.Column
                    dataIndex="trip_id"
                    title="Trip"
                    align="center"
                    render={(value, record) => {                        
                      return DropDown(value, record);
                    }}
                  />
                  {/* <Table.Column<IOrder>
                    title="Actions"
                    dataIndex="actions"
                    align="center"
                    render={(_, record) => (
                      <Space>
                        <ShowButton
                          hideText={true}
                          style={{ border: "none" }}
                          resourceName={"orders"}
                          onClick={() => {
                            ShowPopup(record.id);
                            setShowModalVisible(true);
                          }}
                        ></ShowButton>
                      </Space>
                    )}
                  /> */}
                </Table>
              ) : (
                <Table
                  className="listTable"
                  style={{
                    marginTop: "5px",
                    justifyContent: "center",
                    // border: "2px solid lightgray",
                    gridRow: "auto",
                    cursor: "pointer",
                  }}
                  {...tableProps}
                  rowKey="id"
                  size="small"
                >
                  <Table.Column
                    dataIndex="order_Unique_id"
                    title="Order Id"
                    align="center"
                    render={(value, record: any) => (
                      <span
                        // onClick={() => {
                        //   ShowPopup(record.id);
                        //   setShowModalVisible(true);
                        // }}
                      >
                        {value}
                      </span>
                    )}
                  />
  
                  <Table.Column
                    dataIndex="date"
                    title="Date"
                    align="center"
                    render={(value) => <DateField value={value} format="LL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                  />
                  <Table.Column
                    dataIndex="name"
                    title="Customer Name"
                    align="center"
                  />
                  <Table.Column
                    dataIndex="items"
                    title="Quantity"
                    align="center"
                    render={(value) => (
                      <span style={{ overflowWrap: "anywhere" }}>
                        {value.reduce(function (accumulator: any, curValue: any) {
                          return accumulator + Number(curValue.quantity);
                        }, QuatityinitialValue)}
                      </span>
                    )}
                  />
                  <Table.Column
                    dataIndex="items"
                    title="Price"
                    align="center"
                    render={(value) => (
                      <span style={{ overflowWrap: "anywhere" }}>
                        £{" "}
                        {value
                          .reduce(function (accumulator: any, curValue: any) {
                            return (
                              accumulator + curValue.price * curValue.quantity
                            );
                          }, initialValue)
                          .toFixed(2)}
                      </span>
                    )}
                  />
                  <Table.Column
                    dataIndex="trip_id"
                    title="Trip"
                    align="center"
                    render={(value, record) => {
                      return DropDown(value, record);
                    }}
                  />
                  {/* <Table.Column<IOrder>
                    title="Actions"
                    dataIndex="actions"
                    align="center"
                    render={(_, record) => (
                      <Space>
                        <ShowButton
                          hideText={true}
                          style={{ border: "none" }}
                          resourceName={"orders"}
                          // icon
                          // recordItemId={record?.id}
                          // onClick={() => route?.push(`./show/${record.id}`)}
                          onClick={() => {
                            ShowPopup(record.id);
                            setShowModalVisible(true);
                          }}
                        ></ShowButton>
                      </Space>
                    )}
                  /> */}
                </Table>
              )}
            </>
          ) : (
            <></>
          )}
        </>
  
      </List>
    );
  };