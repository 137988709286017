import { ArrowUpOutlined } from "@ant-design/icons"
import { Table } from "@pankod/refine-antd"
import { useDashboardContext } from "contaxt/dashboardContext"
import { number } from "currency-codes"
import { useEffect, useState } from "react"
import CountUp from "react-countup"


export const ProductInsightChart = () => {
    const {topSalesTabale, filterDate} = useDashboardContext()
    let filterStates = filterDate == null ? true : false
    const [tps, setTps] = useState<number>(0);
    const [tpr, setTprs] = useState<number>(0);
    const [asp, setAsp] = useState<number>(0);
  
    useEffect(()=>{
      let totalAmount = topSalesTabale?.reduce((total: any, item: any) => total + item.revenue, 0)
      setTps( topSalesTabale?.reduce((total: any, item: any) => total + item.quantity, 0));
      setTprs(totalAmount);
      let avgAsp: number = (Number(totalAmount) / (topSalesTabale.length - 1));
      setAsp(avgAsp);
    },[topSalesTabale])

    const coloums: any =[
      {
        dataIndex: "name",
        title: "Product",
      },
      {
        dataIndex:"revenue",
        title: "Revenue",
        render:(_: any)=>{
          return(
            <p>£ {_.toFixed(2)}</p>
          )
        }
      },
      {
        dataIndex: "quantity",
        title: "Unit"
      }
    ]

    return(
      <div className="continer">
        <div className="analysis_card">
          <div className="analysis_card-header">
            <div className="card_title">
              <h4>Product Insights</h4>
            </div>
          </div>
          <div className="analysis_card-body">
            <div className="analysis_report_prductInsig_box">
              <div className="report_card report_card_prductInsig">
                <div className="report_title">
                  <p>Total Products Sold</p>
                </div>
                <div className="report_body">
                  <div className="report_box">
                    <div className="report_value_section">
                      <CountUp
                        start={0}
                        end={tps}
                        duration={2.6}
                        className="countup_count"
                      />
                      <ArrowUpOutlined className="arrow" />
                      {/* <p className="report_rate">0.0%</p> */}
                    </div>
                    {filterStates && <p className="report_rate">this week</p>}
                  </div>
                </div>
              </div>
              <div className="report_card report_card_prductInsig">
                <div className="report_title">
                  <p>Total Revenue</p>
                </div>
                <div className="report_body">
                  <div className="report_box">
                    <div className="report_value_section">
                      <CountUp
                        start={0}
                        end={tpr}
                        prefix="£ "
                        duration={2.75}
                        className="countup_count"
                      />
                      <ArrowUpOutlined className="arrow" />
                      {/* <p className="report_rate">0.0%</p> */}
                    </div>
                    {filterStates && <p className="report_rate">this week</p>}
                  </div>
                </div>
              </div>
              <div className="report_card report_card_prductInsig">
                <div className="report_title">
                  <p>Average Selling Price (ASP)</p>
                </div>
                <div className="report_body">
                  <div className="report_box">
                    <div className="report_value_section">
                      <CountUp
                        start={0}
                        end={asp}
                        prefix="£"
                        duration={2.75}
                        decimals={2}
                        className="countup_count"
                      />
                      <ArrowUpOutlined className="arrow" />
                      {/* <p className="report_rate">0.0%</p> */}
                    </div>
                    {filterStates && <p className="report_rate">this week</p>}
                  </div>
                </div>
              </div>
            </div>
            <div className="analysis_table">
              <div className="analysis_tsp_table">
                  <h5>Top Selling Product</h5>
                  <Table columns={coloums} dataSource={topSalesTabale.sort((a: any, b: any) => b.quantity - a.quantity)} pagination={{ pageSize: 5 }}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}