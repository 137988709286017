import "./style.css"
import { DashBoardProvider } from 'contaxt/dashboardContext';
import { OrderTrendChart } from 'components/dashboard/orderTrend';
import { OrderInsightChart } from "components/dashboard/orderInsight";
import { ProductInsightChart } from "components/dashboard/productInsight";
import { CustomerOrderAnalysis } from "components/dashboard/customerOrderAnalysis";

export const Dashboard = () => {
  
  return (
    <div className="container">
      <div className="dashboard-content">
        <DashBoardProvider>
          <OrderTrendChart/>
          <div style={{marginTop: "10px"}}>
            <OrderInsightChart/>
          </div>
          <div style={{marginTop: "10px"}}>
            <ProductInsightChart/>
          </div>
          <div style={{marginTop: "10px"}}>
            <CustomerOrderAnalysis/>
          </div>
        </DashBoardProvider>
      </div>
    </div>
  )
}
