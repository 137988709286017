import { EditButton, Form, List, Select, Table, TableProps, useModalForm, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity } from "@pankod/refine-core";
import { ICustomer, ICustomerFilterVariables } from "interfaces";
import { useState } from "react";
import "./style.css";
import { EyeOutlined } from "@ant-design/icons";
import { EditCustomeAlert } from "./show";

export const CustomerAlert: React.FC<IResourceComponentsProps> = () => {

    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    const [customerId, setCustomerId] = useState<any>();

    const {tableProps, sorter, tableQueryResult} = useTable<ICustomer, HttpError,ICustomerFilterVariables>({
        resource: "customers",
        initialPageSize: 10,
        initialSorter: [
          {
            field: "created_at",
            order: "desc",
          },
        ],
        permanentFilter: [
          {
            field: "ob_org_id",
            operator: "eq",
            value: userId,
          },
          {
            field: "id",
            operator: "eq",
            value: customerId,
          },
        ],
        metaData: {
          fields: ["id", "name", "fullname", "address", "status", "created_at", "customer_org_id", "custom_alert"],
        },
        onSearch: (params) => {
          const filters: CrudFilters = [];
          const { name } = params;
          filters.push({
            field: "name",
            operator: "contains",
            value: name !== "" ? name : null,
          },
          {
            field:"ob_org_id",
            operator:"eq",
            value:userId
          });
          return filters;
        },
    });

    const {
        formProps,
        modalProps,
        show: editShow,
        close:editClose
    } = useModalForm({
        resource: "customers",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "custom_alert"],
        },
    });

    const { selectProps } = useSelect<any>({
        resource: "customers",
        metaData: {
          fields: ["id", "name"],
        },
        optionLabel: "name",
        optionValue: "id",
        filters:[
          {
            field:"ob_org_id",
            operator:"eq",
            value:userId
          }
         ],
        onSearch: (value) => [
          {
            field: "name",
            operator: "contains",
            value: "%" + value + "%",
          },
        ],
    });

    const handleSearch = (e: any) => {
        setCustomerId(e);
    };
    
    const handleOnClearSearch = () => {
        setCustomerId(null);
    };

    const Columns:TableProps<ICustomer>['columns'] =[
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Message",
            dataIndex: "custom_alert",
            render: (text:any,record: any)=>{
              const truncatedText = text?.length > 100 ? `${text?.slice(0, 50)}...` : text;
                return(
                    text && text != "undefined" ? <p>{truncatedText}</p> : <p className="emptey_msg">No Message</p>
                )
            }
        },
        {
            title: "Action",
            render: (record: any)=>{
                return(
                    <EditButton
                        size="middle"
                        type="link"
                        hideText = {true}
                        icon = {<EyeOutlined/>}
                        resourceName="order/customeAlert"
                        onClick= {()=>{
                            editShow(record?.id)
                        }}
                    />
                )
            }
        },
    ]

    return(
        <List
            title = {"Custom Alert"}
            pageHeaderProps={{
                extra: [
                  <>
                    <Form layout="vertical">
                      <Form.Item label="Name">
                        <Select
                          placeholder="Select a customer"
                          style={{ width: 300, marginRight: 20 }}
                          onSelect={handleSearch}
                          clearIcon="*"
                          allowClear
                          onClear={handleOnClearSearch}
                          {...selectProps}
                        />
                      </Form.Item>
                    </Form>
                  </>
                ]
              }}
        >
            <Table {...tableProps} columns={Columns}/>
            <EditCustomeAlert formProps={formProps} modalProps={modalProps} tableQueryResult={tableQueryResult}  closeModal={editClose}/>
        </List>
    )
}