import React from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import { TitleProps } from "@pankod/refine-core";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
      <img
        src={"/images/icon.png"}
        alt="OrderBit"
        style={{
          width:"60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft:"10px",
          padding: "8px",
        }}
      />
    ) : (
      <img
        src={"/images/logo-dark.png"}
        alt="Refine"
        style={{
          width: "200px",
          padding: "12px 24px",
        }}
      />
    )}
  </Link>
);
