import React from 'react';

import { useLogin, useTranslate, useNavigation, } from "@pankod/refine-core";

import {
    AntdLayout, Menu, MenuProps, Icons, useMenu
} from "@pankod/refine-antd";
import {
    Title,
    Header,
    Footer,
    OrderBitLayout,
    OffLayoutArea,
} from "components/layout";
import { Routes, Route, Link } from 'react-router-dom';
import { BannersList } from './banners';
import { NewsList } from './news';
import { PagesList } from './pages';
import { FaqLiat } from './faq/list';
import { AboutusList } from './aboutus';
const { BorderOutlined, ReadOutlined, FileTextOutlined, FilterOutlined, CommentOutlined } = Icons;
const { Sider, Content } = AntdLayout;
const { SubMenu } = Menu;


const items2: MenuProps['items'] = [Icons.UserOutlined, Icons.LaptopOutlined, Icons.NotificationOutlined].map(
    (icon, index) => {
        const key = String(index + 1);

        return {
            key: `sub${key}`,
            icon: React.createElement(icon),
            label: `subnav ${key}`,

            children: new Array(4).fill(null).map((_, j) => {
                const subKey = index * 4 + j + 1;
                return {
                    key: subKey,
                    label: `option${subKey}`,
                };
            }),
        };
    },
);

export const StorePage: React.FC = () => {

    const { push } = useNavigation();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();


    return (
        <AntdLayout style={{ minHeight: "90vh", flexDirection: "row" }}>
            <Sider width={200} className="site-layout-background">
                <h2 style={{ margin: "20px" }}>Store</h2>
                <Menu
                    theme="light"
                    selectedKeys={[selectedKey]}
                    defaultOpenKeys={defaultOpenKeys}
                    mode="inline"
                    onClick={({ key }) => {


                        push(key as string);
                    }}
                >
                    <Menu.Item
                        icon={<BorderOutlined />}
                        key="/store/banners"
                        onClick={() => {
                            push("/store/banners");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/store/banners") ? "bold" : "normal",
                        }}
                    >
                        Banners
                    </Menu.Item>
                    <Menu.Item
                        icon={<ReadOutlined />}
                        key="/store/news"
                        onClick={() => {
                            push("/store/news");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/store/news") ? "bold" : "normal",
                        }}
                    >
                        News
                    </Menu.Item>
                    <Menu.Item
                        icon={<FilterOutlined />}
                        key="/store/faqs"
                        onClick={() => {
                            push("/store/faqs");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/store/faqs") ? "bold" : "normal",
                        }}
                    >
                        FAQ's
                    </Menu.Item>
                    <Menu.Item
                        icon={<CommentOutlined />}
                        key="/store/aboutus"
                        onClick={() => {
                            push("/store/aboutus");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/store/aboutus") ? "bold" : "normal",
                        }}
                    >
                        About Us
                    </Menu.Item>
                    <Menu.Item
                        icon={< FileTextOutlined />}
                        key="/store/pages"
                        onClick={() => {
                            push("/store/pages");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/store/pages") ? "bold" : "normal",
                        }}
                    >
                        Pages
                    </Menu.Item>




                </Menu>
            </Sider>
            <Content
                className="site-layout-background"
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                }}
            >
                <Routes>
                    <Route index element={<BannersList />} />
                    <Route path="banners" element={<BannersList />} />
                    <Route path="news" element={<NewsList />} />
                    <Route path="faqs" element={<FaqLiat />} />
                    <Route path="aboutus" element={<AboutusList />} />
                    <Route path="pages" element={<PagesList />} />


                    <Route path="*" element={<div>Anything</div>} />
                </Routes>
            </Content>
        </AntdLayout>
    );
};
