import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Button,
  Space,
  DatePicker,
  Upload,
  getValueFromEvent,
  Checkbox,
  InputNumber, Select, useSelect
} from "@pankod/refine-antd";
import { useGetIdentity, useApiUrl } from "@pankod/refine-core";
import InputColor from 'react-input-color';
//   import InputColor from "./InputColor";
import { IPrice, IOrg, ICategory } from "interfaces";
import React, { useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { nhost } from "utility/nhost";

type CreateProductOptionsProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const CreateProductOptions: React.FC<CreateProductOptionsProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const apiUrl = useApiUrl();
  const [privacy, setPrivacy] = useState(false)
  const [urlList, setUrlList] = useState([] as any[]);
  const [selectOption, setSelectOption] = useState("List of values")
  // console.table(selectOption)
  const { selectProps: OBProductSelectProps } = useSelect<IPrice>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });
  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  // const { selectProps: categorySelectProps } = useSelect<ICategory>({
  //   resource: "categories",
  //   fetchSize: 1000,
  //   optionLabel: "name",
  //   optionValue: "id",
  //   metaData: {
  //     fields: ["id", "name"],
  //   },
  //   sort: [{ field: "name", order: "asc", },]
  // });
  const onChangeCheckboxPrivacy = (e: any) => {
    let checkbox = e.target.checked
    setPrivacy(checkbox)
  };

  const apidata = (e: any) => {
    const MUTATIONAPI = `mutation MyMutation {
        insert_pages(objects: { name: "${e.name}", imagefile: "${e.imagefile}", header_menu: ${e.header_menu}, header_submenu: ${e.header_submenu},footer_menu: ${e.footer_menu}, ob_org_id: "${user?.metadata?.org_id}",
        content: "${e.content}",meta_description: "${e.meta_description}",active: ${e.active},login: ${e.login},private: ${e.private},customer: "${e.customer}",privacy_group: "${e.privacy_group}",
      }){
          affected_rows
        }
      }
      `
    const imageSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      return data
    }
    imageSubmit()
  }

  const handleSelectOption = (e: any) => {
    setSelectOption(e)
  }
  return (
    <Modal {...modalProps} title="Create Product Options">
      <Form {...formProps}
        //   onFinish={(e) => {
        //   e.imagefile = urlList.join(",")

        //   apidata(e)
        // }} 
        size="large" layout="vertical">
        <Form.Item
          label="Name (Name of option group, eg Color, Size etc)"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {user?.defaultRole === "admin" ?
          <Form.Item
            label="Orderbit Organisation"
            name="ob_org_id"
            rules={[{ required: true, }]} >
            <Select {...OBOrgSelectProps} />
          </Form.Item> :
          <Form.Item
            name="ob_org_id"
            initialValue={user?.metadata?.org_id}
          >
            <Input hidden />
          </Form.Item>
        }
        <Form.Item
          label="Code"
          name="code"
          style={{ marginTop: "-35px" }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[{ required: true, }]} >
          <Select onChange={handleSelectOption}>
            <Select.Option value="List of values" name="currency">List of values</Select.Option>
            <Select.Option value="Simple text" name="currency">Simple text</Select.Option>
            <Select.Option value="Add Image" name="currency">Add Image</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="View Order"
          name="view_order"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {selectOption === "List of values" ?
          <>
            <h4 style={{ fontSize: '14px', marginTop: '20px' }}>List of Values</h4>
            <Form.List name="list_value">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        label="Name"
                        rules={[{ required: true, message: 'Missing to initial price' }]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'code']}
                        label="Code"
                        rules={[{ required: true, message: 'Missing to final price' }]}
                      >
                        <Input placeholder="Code" />
                      </Form.Item>
                      {/* <Form.Item
                          {...restField}
                        name={[name, "image"]}
                        valuePropName="fileList"
                       getValueFromEvent={getValueFromEvent}
                       label="Image"
                    >
                        <Input
                        type="file"
                        >
                        </Input>
                    </Form.Item> */}
                      <Form.Item
                        {...restField}
                        name={[name, 'color_code']}
                        label="Color"
                        rules={[{ required: true, message: 'Missing to percentage' }]}
                      >
                        <InputColor
                          initialValue="#5e72e4"
                          // onChange={setColor}
                          placement="right"
                        />
                        {/* <InputColor placeholder="Choose Colour"/> */}
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{ width: "340px", marginTop: '10px' }}>
                      Add Discounts
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List> </> : ""}


        <Form.Item name="created_by"
          initialValue={user?.id}
        >
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
