import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Select,
  InputNumber,
  useSelect,
  message,
} from "@pankod/refine-antd";
import {
  useGetIdentity,
  useCreate,
  useMutationMode,
  useNavigation,
  useNotification,
} from "@pankod/refine-core";
import { IUser } from "interfaces";
import { useEffect, useState } from "react";
import { nhost } from "utility";
import { getAllRoles, createUser } from "users";
import { } from "@pankod/refine-react-router-v6";
import { GET_USER } from "query";
import { jsonConverter } from "components/helpers/jsonConverter";

type CreateUserProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  close: any;
  refreshData: any;
};

type CreateAdminProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeModal: any;
  refreshData: any;
};
export interface ISignup {
  email: string;
  password: string;
  displayName: string;
  defaultRole: string;
}
export const UserCreate: React.FC<CreateUserProps> = ({
  modalProps,
  formProps,
  close,
  refreshData,
}) => {
  const { data: user } = useGetIdentity();
  const [form] = Form.useForm<ISignup>();
  const [reload, setReload] = useState(false);
  const { push, goBack } = useNavigation();
  const [roleList, setRoleList] = useState([] as any[]);

  useEffect(() => {
    // getAllRoles().then((response: any) => {
    //   setRoleList(response?.authRoles);
    // });
    form.resetFields();
  }, []);

  const success = (msg: string, type: string) => {
    form.resetFields();
    refreshData();
    if (type === "success") {
      message.success(msg);
      close();
    } else message.error(msg);

  };

  //   const { mutate: createUser } = useCreate<IUser>();
  return (
    <Modal {...modalProps} title="Create User">
      <Form
        form={form}
        {...formProps}
        size="large"
        layout="vertical"
        onFinish={(values) => {
          createUser(values).then((response) => {
            form.resetFields();
            if (response === "OK") {
              success("User Created", "success");
            } else {
              success(response, "danger");
            }
          });
        }}
      >
        <Form.Item
          label="Display Name"
          name="displayName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item label="Role" name="defaultRole" rules={[{ required: true }]}>
          <Select>
            {roleList.length > 0 &&
              roleList?.map((item) => (
                <option key={item?.role} value={item?.role}>
                  {item?.role}
                </option>
              ))}
          </Select>

        </Form.Item> */}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password placeholder="input password" />
        </Form.Item>

        <Form.Item name="created_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const AdminCreate: React.FC<CreateAdminProps> = ({
  modalProps,
  formProps,
  closeModal,
  refreshData,
}) => {
  const { data: user } = useGetIdentity();
  const [form] = Form.useForm<ISignup>();
  const { open, close } = useNotification();
  

  const success = (msg: string, type: string) => {
    form.resetFields();
    refreshData();
    if (type === "success") {
      message.success(msg);
      closeModal();
    } else message.error(msg);

  };

  const CreateAdmin = async(values: any) =>{
    console.log(values, "values");
      const result = await nhost.auth.signUp({
        email: values.email,
        password: values.password,
        options: {
            defaultRole: "user",
            displayName: `${values.first_name}`.trim(),
            locale: "en",
            metadata: {
                org_id: user?.metadata?.org_id,
            },
        },
      }
    ).then((res: any)=>{
      console.log(res)
      
    //  if(res.error){
    //   open?.({
    //     key: "error",
    //     type: "error",
    //     message: "",
        
    //     description: res.error?.message,
    //   });
    //   setTimeout(() => {
    //     close("error");
    //   }, 5000);
    //  }else{
    //   var org_id = { org_id: user?.metadata?.org_id }
      
    //   const MUTATIONAPI = `mutation MyMutation {
    //     updateUsers(where: {id: {_eq: "${res?.session?.user?.id}"}}, _set: {metadata:${jsonConverter(org_id)},defaultRole:"owner", emailVerified:${true}}) {
    //       affected_rows
    //     }
    //   }
    //   `
    //   nhost.graphql.request(MUTATIONAPI).then((user_res: any)=>{
    //     if(user_res?.data == null || user_res?.error?.length > 0){
    //       open?.({
    //           key:"userUpdate_error",
    //           type: "error",
    //           message: "",
    //           description: `Something Went Wrong ${user_res?.error[0]?.message}`,
    //       });
    //         setTimeout(() => {
    //           close("userUpdate_error");
    //         }, 4000);
    //       }
    //       else{
    //         open?.({
    //             key:"success",
    //             type: "success",
    //             message: "",
    //             description: `User Created Successfully`,
    //         });
    //         setTimeout(() => {
    //           close("success");
    //         }, 4000);
    //       }
    //     })
    //   }
    })  
  }

  return (
    <Modal {...modalProps} title="Create Admin">
      <Form
        form={form}
        {...formProps}
        size="large"
        layout="vertical"
        onFinish={(values) => {
          CreateAdmin(values)
        }}
      >
        <Form.Item
          label="Name"
          name="displayName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.Password placeholder="input password" />
        </Form.Item>

        <Form.Item name="created_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};