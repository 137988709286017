import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
} from "@pankod/refine-core";
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import {
  Button,
  CreateButton,
  DateField,
  DatePicker,
  EditButton,
  Form,
  FormProps,
  Icons,
  Input,
  List,
  Popconfirm,
  RefreshButton,
  Select,
  Space,
  Table,
  getDefaultSortOrder,
  useModalForm,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import { IFaq, IPostFilterVariables } from "interfaces";
import { CreateRoute } from "./create";
import { EditRoute } from "./edit";
import { DELETE_ROUTE } from "query";
import { ReloadOutlined } from "@ant-design/icons";
const { PlusOutlined, CheckOutlined, CloseOutlined } = Icons;
const { RangePicker } = DatePicker;

export const RouteList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();
  const userId = user?.metadata?.org_id;

  const [routeId, setRouteId] = useState<any>(null);

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    IFaq,
    HttpError,
    IPostFilterVariables
  >({
    resource: "route",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "id",
        operator: "eq",
        value: routeId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "description",
        "ob_org_id",
        "is_active",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name, created_at } = params;

      filters.push({
        field: "name",
        operator: "contains",
        value: name !== "" ? name : null,
      });

      return filters;
    },
  });
  const { tableProps:newTableProps, sorter: newTableSorter, tableQueryResult: newTableQuery, searchFormProps : newTableSearch } = useTable<
    IFaq,
    HttpError,
    IPostFilterVariables
  >({
    resource: "route",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "id",
        operator: "eq",
        value: routeId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "description",
        "ob_org_id",
        "is_active",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name, created_at } = params;

      filters.push({
        field: "name",
        operator: "contains",
        value: name !== "" ? name : null,
      });

      return filters;
    },
  });

  let datacount = tableQueryResult?.data?.total;

  const { selectProps } = useSelect<any>({
    resource: "route",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  const { formProps, modalProps, show } = useModalForm({
    resource: "route",
    action: "create",
    redirect: false,
    autoSubmitClose: true,
    autoResetForm: true,
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "route",
    action: "edit",
    redirect: false,
    autoSubmitClose: true,
    autoResetForm: true,
    metaData: {
      fields: [
        "id",
        "name",
        "description",
        "ob_org_id",
        "is_active",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
      ],
    },
  });

  const handleDelete = (data: any) => {
    DELETE_ROUTE(data).then((repsone:any) => {
      if (repsone?.delete_route) {
        open({
          key: "delete_success",
          type: "success",
          message: "",
          description: "Successfully Deleted",
        });
        setTimeout(() => {
          close("delete_success");
        }, 2000);
        tableQueryResult?.refetch();
      }
      else {
        open({
          key: "delete_error",
          type: "error",
          message: "",
          description: "You have to remove this Route from Trip",
        });
        setTimeout(() => {
          close("delete_error");
        }, 3000);
      }
    });
  };

  const handleSearch = (e: any) => {
    setRouteId(e);
  };

  const handleOnClearSearch = () => {
    setRouteId(null);
  };

  return (
    <List
      title="Orderbit Route"
      pageHeaderProps={{
        extra: [
          <>
            <Form layout="vertical">
              <Form.Item label="Name">
                <Select
                  placeholder="Select a Route"
                  style={{ width: 300, marginRight: 20 }}
                  onSelect={handleSearch}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearSearch}
                  {...selectProps}
                />
              </Form.Item>
            </Form>
            {/* <RefreshButton
                  style={{ marginTop: "15px" }}
                  type="primary"
                  children={<div style={{ display: "flex", alignItems: "center" }}>
                    <ReloadOutlined style={{ marginRight: "5px" }} />
                    Refresh
                  </div>}
                  icon={false}
                  onClick={() => tableQueryResult?.refetch()}
                />, */}
            <CreateButton
              style={{ marginTop: "15px" }}
              type="primary"
              children={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <PlusOutlined style={{ marginRight: "5px" }} />
                  Add Route
                </div>
              }
              icon={false}
              resourceName="store/news"
              onClick={() => show()}
            />
            ,
          </>,
        ],
      }}
    >
      <span>Records: {datacount}</span>
      { routeId ? 
      <Table
        style={{ marginTop: "5px" }}
        {...newTableProps}
        rowKey="id"
        size="small"
      >
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column
          title="Show"
          dataIndex="is_active"
          render={(value) =>
            value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )
          }
        />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column<IFaq>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                resourceName="store/news"
                onClick={() => editShow(record.id)}
              />
              <Popconfirm
                title="Delete?"
                onConfirm={() => handleDelete(record.id)}
              >
                <a style={{ color: "red" }}>Delete</a>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
       : <Table
        style={{ marginTop: "5px" }}
        {...tableProps}
        rowKey="id"
        size="small"
      >
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column
          title="Show"
          dataIndex="is_active"
          render={(value) =>
            value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )
          }
        />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column<IFaq>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                resourceName="store/news"
                onClick={() => editShow(record.id)}
              />
              <Popconfirm
                title="Delete?"
                onConfirm={() => handleDelete(record.id)}
              >
                <a style={{ color: "red" }}>Delete</a>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      }

      <CreateRoute modalProps={modalProps} formProps={formProps} />
      <EditRoute modalProps={editModalProps} formProps={editFormProps} />
    </List>
  );
};
