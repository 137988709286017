import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber, Checkbox, useSelect, Select, DatePicker
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import dayjs from 'dayjs'
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import { useState } from "react";
import moment, { Moment } from 'moment';

type EditNewsProps = {
    modalProps: ModalProps;
    formProps: FormProps;
};
const { TextArea } = Input;
const { RangePicker } = DatePicker;

export const EditNews: React.FC<EditNewsProps> = ({
    modalProps,
    formProps,
}) => {
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write",);

    const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
        resource: "ob_orgs",
        fetchSize: 1000,
        optionLabel: "name",
        optionValue: "id",
        metaData: {
            fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc", },]
    });
    return (
        <Modal {...modalProps} title="Edit News">
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true }]}
                    style={{ paddingBottom: '5px' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Summary"
                    name="summary"
                    rules={[{ required: true }]}
                    style={{ paddingBottom: '5px' }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true }]}
              getValueProps={value => ({ 
                value: value ? dayjs(value) : '', 
              })}
            >
              <DatePicker
              disabledDate={(current) => {
                return current && current < moment().startOf('day');
                }}
              />
            </Form.Item>
                <div style={{ display: 'flex', float: 'left', paddingBottom: '5px' }}>
                    <Form.Item label="" name="important" valuePropName="checked">
                        <Checkbox> Important </Checkbox>
                    </Form.Item>
                    <Form.Item label="" name="show" valuePropName="checked" style={{ marginLeft: '25px' }}>
                        <Checkbox> Show </Checkbox>
                    </Form.Item>
                </div>
                {user?.defaultRole === "admin" ?
                    <Form.Item
                        label="Orderbit Organisation"
                        name="ob_org_id"
                        rules={[{}]}
                        style={{ width: "100%", paddingBottom: '5px' }} >
                        <Select {...OBOrgSelectProps} />
                    </Form.Item> :
                    <Form.Item
                        name="ob_org_id"
                        initialValue={userId}
                    >
                        <Input type="hidden" />
                    </Form.Item>}
                <Form.Item
                    label="Content"
                    name="content"
                    rules={[{}]}
                    style={{ width: '100%', height: '100%' }}
                >
                    {/* <Editor
                // apiKey='your-api-key'
                // onInit={(evt, editor) => editorRef.current = editor}
                initialValue="<p>This is the initial content of the editor.</p>"
                init={{
                  height: 250,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              /> */}
                    {/* <TextArea /> */}
                    <ReactMde
                        selectedTab={selectedTab}
                        onTabChange={setSelectedTab}
                        generateMarkdownPreview={(markdown: any) =>
                            Promise.resolve(
                                <ReactMarkdown>{markdown}</ReactMarkdown>,
                            )
                        }
                    />
                </Form.Item>
                <Form.Item name="updated_by"
                    initialValue={user?.id}>
                    <Input type="hidden" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
