import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useMany,
} from "@pankod/refine-core";
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import {
  Button,
  CreateButton,
  DateField,
  DatePicker,
  EditButton,
  Form,
  FormProps,
  Icons,
  Input,
  List,
  Popconfirm,
  RefreshButton,
  Select,
  ShowButton,
  Space,
  Table,
  getDefaultSortOrder,
  useModalForm,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import { IFaq, IPostFilterVariables } from "interfaces";
import { DELETE_TRIP } from "query";
import { ReloadOutlined } from "@ant-design/icons";
import { TripSheetShow } from "./show";
import { nhost } from "utility";
const { PlusOutlined } = Icons;
const { RangePicker } = DatePicker;

export const TripSheetList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();
  const userId = user?.metadata?.org_id;
  const [tripId, setTripId] = useState<any>(null)
  const [allTripIds, setAllTripIds] = useState<any>(null)
  const [maxViewOrder, setMaxViewOrder] = useState<any>(0);

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    IFaq,
    HttpError,
    IPostFilterVariables
  >({
    resource: "trip",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "id",
        operator: "eq",
        value: tripId,
      },
      {
        field: "id",
        operator: "in",
        value: allTripIds,
      },
    ],
    metaData: {
      fields: [
        "id",
        "route_id",
        "date",
        "start_time",
        "end_time",
        "driver_name",
        "vehicle_number",
        "notes",
        "ob_org_id",
        "name",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
        "due_time",
        "seq_number",
      ],
    },
    onSearch: (params) => {
        const filters: CrudFilters = [];
        const { name, created_at } = params;

        filters.push(
            {
                field: "name",
                operator: "contains",
                value: name !== '' ? name : null,
            },
        );

        return filters;
    },
  });
  let datacount = tableQueryResult?.data?.total;

useEffect(() => {
    let values = tableProps?.dataSource
        let QUERY = `
      query ($id: [uuid!]) {
        orders(where: { trip_id: { _in: [${values?.map((code: any) => `"${code?.id}"`).join(",")}] } }) {
          id
          name
          items
          trip_id
        }
      }
      `;
        const callQuery = async () => {
            const { data: order }: any = await nhost.graphql.request(QUERY);
            if(order){
                const setIds = order?.orders?.map((ord:any) => ord?.trip_id)
                setAllTripIds(setIds)    
            }
        }
        callQuery();  
    },[tableProps?.dataSource])

  const {
    formProps: showFormProps,
    modalProps: showModalProps,
    show: ShowPopup,

} = useModalForm({
    resource: "trip",
    action: 'clone',
    redirect: false,
    metaData: {
        fields: [
          "id",
          "route_id",
          "date",
          "start_time",
          "end_time",
          "driver_name",
          "vehicle_number",
          "notes",
          "ob_org_id",
          "name",
          "created_at",
          "created_by",
          "updated_at",
          "updated_by",
          "due_time",
          "seq_number",
        ],
      },
});
  const { formProps, modalProps, show } = useModalForm({
    resource: "trip",
    action: "create",
    redirect: false,
    autoSubmitClose: true,
    autoResetForm: true,
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "trip",
    action: "edit",
    redirect: false,
    autoSubmitClose: true,
    autoResetForm: true,
    metaData: {
      fields: [
        "id",
        "route_id",
        "date",
        "start_time",
        "end_time",
        "driver_name",
        "vehicle_number",
        "notes",
        "ob_org_id",
        "name",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
        "due_time",
        "seq_number",
      ],
    },
  });

  const { selectProps } = useSelect<any>({
    resource: "trip",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "id",
        operator: "in",
        value: allTripIds,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  const { selectProps: routeSelectProps } = useSelect<any>({
    resource: "route",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  const handleDelete = (data: any) => {
    DELETE_TRIP(data).then((repsone:any) => {      
      if (repsone?.delete_trip) {
        open({
          key: "delete_success",
          type: "success",
          message: "",
          description: "Successfully Deleted",
        });
        setTimeout(() => {
          close("delete_success");
        }, 2000);
        tableQueryResult?.refetch();
      }
      else {
        open({
          key: "delete_error",
          type: "error",
          message: "",
          description: "You have to remove this Trip from Order",
        });
        setTimeout(() => {
          close("delete_error");
        }, 3000);
      }
    });
  };

  const handleSearch = (e: any) => {
    setTripId(e);
  };

  const handleOnClearSearch = () => {
    setTripId(null);
    setAllTripIds(null);
  };

  
  useEffect(() => {
    if(tableProps?.dataSource){
    const maxViewOrder = tableProps?.dataSource?.reduce((max:any, item:any) => {
      const viewOrder = item.seq_number;
      return viewOrder > max ? viewOrder : max;
    }, 0);
    if(maxViewOrder){
      setMaxViewOrder(maxViewOrder)
    }
  }
},[tableProps]);  
  
  return (
    <List
      title="Orderbit Trip Sheet"
      pageHeaderProps={{
        extra: [
          <>
            <Form layout="vertical">
              <Form.Item label="Name">
                <Select
                  placeholder="Select a Trip"
                  style={{ width: 300, marginRight: 20 }}
                  onSelect={handleSearch}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearSearch}
                  {...selectProps}
                />
              </Form.Item>
            </Form>
            {/* <CreateButton
            style={{ marginTop: "15px" }}
            type="primary"
            children={
              <div style={{ display: "flex", alignItems: "center" }}>
                <PlusOutlined style={{ marginRight: "5px" }} />
                Add Trip
              </div>
            }
            icon={false}
            resourceName="store/news"
            onClick={() => show()}
          />, */}
          </>,
        ],
      }}
    >
      <span>Records: {datacount}</span>

      <Table
        style={{ marginTop: "5px" }}
        {...tableProps}
        rowKey="id"
        size="small"
      >
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="route_id"
          title="Route"
          render={(value) => {
            const findRoute = routeSelectProps?.options?.find(
              (res: any) => value == res?.value
            );
            return findRoute?.label;
          }}
        />
        <Table.Column
          dataIndex="date"
          title="Date"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="start_time"
          title="Start Time"
          render={(value) => <DateField value={value} format="HH:mm:ss" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="end_time"
          title="End Time"
          render={(value) => <DateField value={value} format="HH:mm:ss" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="due_time"
          title="Due Time"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        {/* <Table.Column dataIndex="driver_name" title="Driver Name" />
        <Table.Column dataIndex="vehicle_number" title="Vehicle Number" /> */}
        <Table.Column<IFaq>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <ShowButton
                resourceName="products"
                hideText
                style={{ border: "none" }}
                recordItemId={record.id}
                onClick={() => {
                  ShowPopup(record?.id);
                }}
              />
              {/* <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                resourceName="store/news"
                onClick={() => editShow(record.id)}
              />
              <Popconfirm
                title="Delete?"
                onConfirm={() => handleDelete(record.id)}
              >
                <a style={{ color: "red" }}>Delete</a>
              </Popconfirm> */}
            </Space>
          )}
        />
      </Table>
      <TripSheetShow formProps={showFormProps} modalProps={showModalProps} />
    </List>
  );
};
