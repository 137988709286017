import {
  IResourceComponentsProps,
  useExport,
  CrudFilters,
  HttpError,
  useNotification,
} from "@pankod/refine-core";
import {
  List,
  RefreshButton,
  CreateButton,
  // Table,
  Select,
  useTable,
  useSelect,
  Space,
  EditButton,
  DateField,
  Modal,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  DatePicker,
  Form,
  Input,
  FormProps,
  Button,
  Popconfirm,
} from "@pankod/refine-antd";
import { Table } from "antd";

import { CheckCircleOutlined, ReloadOutlined } from "@ant-design/icons";

import { useGetIdentity } from "@pankod/refine-core";
import { ICategory, ICategoryFilterVariables } from "interfaces";
import { CreateCategory } from "./create";
import { EditCategory } from "./edit";
import { Delete_Category } from "../../../query";
import React, { useEffect, useState } from "react";
import { GET_ALL_CATEGORIES } from "../../../query";
import { useNavigate } from "react-router-dom";

const { PlusOutlined, CheckOutlined, CloseOutlined } = Icons;
const { RangePicker } = DatePicker;

export const CategoriesList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();
  const navigate = useNavigate();

  const [openPopup, setOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formEditSubmitted, setFormEditSubmitted] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [allCategories, setAllCategories]: any = useState({});
  const [myCategory, setMyCategory] = useState<any>(null);
  const userId = user?.metadata?.org_id;

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    ICategory,
    HttpError,
    ICategoryFilterVariables
  >({
    resource: "categories",
    initialPageSize: 1000,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "is_active",
        operator: "eq",
        value: true,
      },
      {
        field: "id",
        operator: "eq",
        value: myCategory,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "ob_org_id",
        { org: ["name"] },
        "created_at",
        "is_active",
        "featured_category",
        "parent_id",
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name, created_at } = params;

      filters.push(
        {
          // field: "name",
          // operator: "eq", // or use "===" for strict equality
          // value: name !== '' ? (str:any) => str.startsWith(name) : null,
          field: "name",
          operator: "contains",
          value: name !== "" ? "%" + name + "%" : null,
        },
        {
          field: "created_at",
          operator: "gte",
          value: created_at ? created_at[0].toISOString() : undefined,
        },
        {
          field: "created_at",
          operator: "lte",
          value: created_at ? created_at[1].toISOString() : undefined,
        }
      );

      return filters;
    },
  });

  const { selectProps: categorySelectProps } = useSelect<any>({
    resource: "categories",
    filters: [
      {
        field: "is_active",
        operator: "eq",
        value: true,
      },
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",

    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  let datacount = tableQueryResult?.data?.total;

  const { formProps, modalProps, show } = useModalForm({
    resource: "categories",
    action: "create",
    redirect: false,
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "categories",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "ob_org_id",
        "is_active",
        "created_at",
        "created_by",
        "image_url",
        { org: ["name"] },
        "featured_category",
        "parent_id",
      ],
    },
  });

  const { triggerExport, isLoading } = useExport<ICategory>();

  const handleDelete = (data: any) => {
    Delete_Category(data).then((repsone) => {
      if (repsone) {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 4000);
        tableQueryResult?.refetch();
      } else {
        open?.({
          key: "error",
          type: "error",
          message: "",
          description: `This category linked to some products can't delete it`,
        });
        setTimeout(() => {
          close("error");
        }, 4000);
      }
    });
  };

  const handleFormSubmitted = () => setFormSubmitted(true);
  const handleEditFormSubmitted = () => setFormEditSubmitted(true);
  const handleCloseCreateModal = () => setCreateModalVisible(false);
  const handleCloseEditModal = () => setEditModalVisible(false);

  const getCategories = async () => {
    GET_ALL_CATEGORIES().then((response: any) => {
      setAllCategories(response);
    });
  };
  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    setPopup(true);
    getCategories();
    tableQueryResult?.refetch();
    setTimeout(() => {
      setPopup(false);
    }, 500);
  }, [createModalVisible == false, editModalVisible == false, openPopup]);

  const [dataSource, setDataSource] = useState<
    readonly ICategory[] | undefined
  >(undefined);
  const [newTableProps, setNewTableProps]: any = useState(tableProps);
  const [searchTableProps, setSearchTableProps]: any = useState([]);
  const [filterDateTableProps, setFilterDateTableProps]: any = useState([]);

  let searchedValue = searchFormProps.form?.getFieldValue("name");
  // let searchedDate = searchFormProps.form?.getFieldValue("created_at")

  // useEffect(() => {
  //     if(searchedDate!= undefined){
  //         let fromDate = new Date(searchedDate[0]?.$d)?.toISOString()
  //         let toDate = new Date(searchedDate[1]?.$d)?.toISOString()
  //         let filterDate = allCategories?.categories?.filter((data:any) => fromDate <= data?.created_at && toDate >= data?.created_at)
  //         setNewTableProps((prev: any) => ({ ...prev, dataSource: filterDate }))
  //         setFilterDateTableProps(filterDate)
  //     }
  //     if(searchedDate == undefined){
  //         setFilterDateTableProps([])
  //         setNewTableProps(tableProps)
  //     }

  // },[searchedDate])

  useEffect(() => {
    if (!dataSource?.length) {
      return;
    }
    if (searchedValue) {
      const newDataSource = tableProps?.dataSource?.filter((item: any) =>
        item?.name.toLowerCase().includes(searchedValue.toLowerCase())
      );
      setNewTableProps((prev: any) => ({ ...prev, dataSource: newDataSource }));
      setSearchTableProps(newDataSource);
      return;
    }

    if (searchedValue === "") {
      setSearchTableProps([]);
      setNewTableProps(tableProps);
    }
  }, [searchFormProps?.form?.getFieldValue("name"), popup]);

  useEffect(() => {
    if (!newTableProps.dataSource) {
      setNewTableProps(tableProps);
    }
    if (tableProps.dataSource?.length) {
      setDataSource(tableProps.dataSource);
    }
  }, [newTableProps, tableProps.dataSource?.length, popup]);

  useEffect(() => {
    if (tableProps?.dataSource) {
      const buildCategoryHierarchy = (data: any, parent_id: any) => {
        let categoryHierarchy: any = [];
        // allCategories?.categories
        data.forEach((category: any) => {
          if (category.parent_id === parent_id) {
            const children = buildCategoryHierarchy(data, category.id);

            if (children.length > 0) {
              category.children = children;
            }

            categoryHierarchy.push({ ...category, key: category?.id });
          }
        });

        return categoryHierarchy;
      };

      const categoryHierarchy = buildCategoryHierarchy(
        tableProps?.dataSource,
        null
      );
      if (categoryHierarchy) {
        setFilterDateTableProps([...categoryHierarchy]);
      }
    }
  }, [tableProps?.dataSource]);

  const handleSearch = (e: any) => {
    setMyCategory(e);
  };
  const handleOnClearSearch = () => {
    setMyCategory(null);
  };

  const handleRefresh = () => {
    tableQueryResult?.refetch();
    navigate(0);
  };

  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      width: "10%",
      render: (value: any) => {
        return (
          <>
            {" "}
            {value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )}
          </>
        );
      },
    },
    {
      title: "Featured Category",
      dataIndex: "featured_category",
      key: "featured_category",
      width: "15%",
      render: (value: any) => {
        return (
          <>
            {" "}
            {value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )}
          </>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      width: "20%",
      key: "created_at",
      render: (value: any) => <DateField value={value} format="LLL" />,
    },
    {
      title: "Actions",
      dataIndex: "",
      width: "20%",
      render: (value: any, record: any) => {
        return (
          <>
            <Space>
              <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                resourceName="catalogue/categories"
                onClick={() => {
                  editShow(record.id);
                  setEditModalVisible(true);
                }}
              />
              <Popconfirm
                title="Delete?"
                onConfirm={() => handleDelete(record.id)}
              >
                <a style={{ color: "red" }}>Delete</a>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <List
        title="Categories"
        pageHeaderProps={{
          extra: [
            <>
              {/* <Form layout="vertical" style={{ display: "flex" }}>
                        <Form.Item label="Category">
                          <Select
                            placeholder="Select a Category"
                            style={{ width: 300, marginRight: 20 }}
                            onSelect={handleSearch}
                            clearIcon="*"
                            allowClear
                            onClear={handleOnClearSearch}
                            {...categorySelectProps}
                          />
                        </Form.Item>
                      </Form> */}
              <Filter formProps={searchFormProps} />,
              {/* <ExportButton onClick={triggerExport} loading={isLoading} />, */}
              <RefreshButton
                style={{ marginTop: "15px" }}
                type="primary"
                children={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ReloadOutlined style={{ marginRight: "5px" }} />
                    Refresh
                  </div>
                }
                icon={false}
                onClick={handleRefresh}
              />
              ,
              <CreateButton
                style={{ marginTop: "15px" }}
                resourceName="catalogue/categories"
                type="primary"
                children={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Add Category
                  </div>
                }
                icon={false}
                onClick={() => setCreateModalVisible(true)}
              />
              ,
            </>,
          ],
        }}
      >
        <span>
          Records: {searchedValue ? searchTableProps?.length : datacount}
        </span>

        {!searchedValue ? (
          <div>
            <Table
              columns={columns}
              dataSource={filterDateTableProps}
              // pagination={{
              //     pageSize: 20,
              //   }}
            />
          </div>
        ) : (
          <Table
            style={{ marginTop: "5px" }}
            {...newTableProps}
            rowKey="id"
            size="small"
          >
            <Table.Column dataIndex="name" title="Name" width={350} />
            {/* <Table.Column<ICategory> dataIndex="ob_org_id" title="Org"
                    render={(_, record) => record?.org?.name}
                    defaultSortOrder={getDefaultSortOrder("ob_org_id", sorter)}
                    sorter /> */}
            <Table.Column
              title="Is active"
              dataIndex="is_active"
              key="is_active"
              render={(value) =>
                value == true ? (
                  <CheckOutlined style={{ color: "#6ead65" }} />
                ) : (
                  <CloseOutlined style={{ color: "#ff7875" }} />
                )
              }
            />
            <Table.Column
              title="Featured Category"
              dataIndex="featured_category"
              key="featured_category"
              render={(value) =>
                value == true ? (
                  <CheckOutlined style={{ color: "#6ead65" }} />
                ) : (
                  <CloseOutlined style={{ color: "#ff7875" }} />
                )
              }
            />
            <Table.Column
              dataIndex="created_at"
              title="Created At"
              render={(value) => <DateField value={value} format="LLL" />}
              defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
              sorter
            />
            <Table.Column<ICategory>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    size="small"
                    type="link"
                    icon={null}
                    recordItemId={record.id}
                    resourceName="catalogue/categories"
                    onClick={() => {
                      editShow(record.id);
                      setEditModalVisible(true);
                    }}
                  />
                  <Popconfirm
                    title="Delete?"
                    onConfirm={() => handleDelete(record.id)}
                  >
                    <a style={{ color: "red" }}>Delete</a>
                  </Popconfirm>
                </Space>
              )}
            />
          </Table>
        )}
        {/* <Table style={{ marginTop: '5px' }} {...tableProps} rowKey="id" size="small">

                     <Table.Column dataIndex="name" title="Name" />
                     <Table.Column<ICategory> dataIndex="ob_org_id" title="Org"
                         render={(_, record) => record?.org?.name}
                         defaultSortOrder={getDefaultSortOrder("ob_org_id", sorter)}
                         sorter />
                        <Table.Column<ICategory> dataIndex="parent_id" title="Parent Name"
                            render={(id, record, index,) => {
                                if (id != null) {
                                    const findParent = tableProps?.dataSource?.find((data: any) => {
                                        return data?.id == id
                                    })
                                    return findParent?.name
                                }
                            }}
                        />
                     <Table.Column title="Is active" dataIndex="is_active" key="is_active"
                         render={(value) =>
                         (value == true ?
                             <CheckOutlined style={{ color: '#6ead65' }} /> :
                             <CloseOutlined style={{ color: '#ff7875' }} />)}
                     />
                     <Table.Column
                         dataIndex="created_at"
                         title="Created At"
                         render={(value) => <DateField value={value} format="LLL" />}
                         defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                         sorter
                     />
                     <Table.Column<ICategory>
                         title="Actions"
                         dataIndex="actions"
                         render={(_, record) => (
                             <Space>
                                 <EditButton
                                     size="small"
                                     type="link"
                                     icon={null}
                                     recordItemId={record.id}
                                     resourceName="catalogue/categories"
                                     onClick={() => {
                                        editShow(record.id);
                                        setEditModalVisible(true);
                                     }
                                    }
                                 />
                                 <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} >
                                     <a style={{ color: "red" }}>Delete</a>
                                 </Popconfirm>
                             </Space>
                         )}
                     />
                 </Table>
                 } */}
        <CreateCategory
          modalProps={modalProps}
          formProps={formProps}
          handleFormSubmitted={handleFormSubmitted}
          closeCreateModal={handleCloseCreateModal}
          createModalVisible={createModalVisible}
        />
        <EditCategory
          modalProps={editModalProps}
          formProps={editFormProps}
          closeEditModal={handleCloseEditModal}
          openEditModal={editModalVisible}
        />
        <Modal
          visible={openPopup}
          maskStyle={{ width: "0px" }}
          bodyStyle={{ height: "80px" }}
          footer={null}
          width="27%"
          style={{
            position: "absolute",
            marginTop: "-5%",
            marginLeft: "70%",
            gridRow: "auto",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
            <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
            <span style={{ paddingLeft: "3%" }}>Category has been deleted</span>
          </div>
        </Modal>
      </List>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps} style={{ display: "flex" }}>
      <Form.Item label="Search" name="name">
        <Input
          allowClear
          placeholder="ID, Title, Content, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      {/* <Form.Item label="Created At" name="created_at" style={{ marginLeft: '5px' }}>
                <RangePicker />
            </Form.Item> */}
      <Form.Item style={{ marginLeft: "5px", marginTop: "20px" }}>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
