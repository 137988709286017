import {
  Form,
  Input,
  ModalProps,
  DatePicker,
  Button,
  Select,
  FormProps,
  Modal,
  Space,
  useSelect,
  InputNumber,
  Upload,
  RefreshButton,
  useTable
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { IProduct } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IOrder } from "interfaces";

const { Option } = Select;

type EditOrderProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};


export const EditOrder: React.FC<EditOrderProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const [orderList, setOrderList] = useState();
  const [open, setOpen] = useState(false);
  const config = {
    rules: [{ type: 'object' as const, required: true, message: 'Please select time!' }],
  };

  const { tableProps, sorter, tableQueryResult } = useTable<IOrder>({
    resource: "orders",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    metaData: {
      fields: ["id", "name", "email", "phone_no", "items", "status", "created_at"],
    },
  });

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  // setOrderList()
  const listOrder = formProps.initialValues;

  // (listOrder?.name, "orderList")

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const handleOpenModel = () => {
    return modalProps
  }

  const [modal2Open, setModal2Open] = useState(false);

  function apidata() {
    tableQueryResult?.refetch();
  }
  
  return (
    <Modal
      {...modalProps}
      // onCancel={() => apidata()}
      title="Edit Order">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Order Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
            label="Date"
            name="date"
            {...config}
          >
             <DatePicker style={{ width: '100%' }}/>
          </Form.Item> */}
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone_no"
          label="Phone Number"
          rules={[{ required: true, message: 'Please input your phone number!' }]}
        >
          <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
        </Form.Item>
        {/* <Form.Item
            label="Total Quantity"
            name="total_quantity"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }}/>
          </Form.Item> */}
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    label="Add product"
                    rules={[{ required: true, }]} >
                    <Select {...OBProductSelectBuyProps} style={{ width: "150px" }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    label="Price"
                    rules={[{ required: true, message: 'Missing to price' }]}
                  >
                    <Input placeholder="Price" disabled />
                  </Form.Item>
                  {/* <Form.Item
                          {...restField}
                          name={[name, 'stock']}
                          label="Stock"
                          rules={[{ required: true, message: 'Missing to stock' }]}
                        >
                          <Input placeholder="Stock" />
                        </Form.Item> */}
                  <Form.Item
                    {...restField}
                    name={[name, 'quantity']}
                    label="Quantity"
                    rules={[{ required: true, message: 'Missing to Quantity' }]}
                  >
                    <Input placeholder="Quantity" />
                  </Form.Item>
                  <div>
                    {
                      listOrder?.items?.map((data: any) => {
                        return <img src={data.image} alt="product image" style={{ width: "100px" }}></img>
                      })
                    }
                  </div>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{ width: "16%" }}>
                  Add Product
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value="Available" name="status" >Available</Select.Option>
            <Select.Option value="Limited Stock" name="status" >Limited Stock</Select.Option>
            <Select.Option value="Pre- order" name="status" >Pre- order</Select.Option>
            <Select.Option value="Not Available" name="status" >Not Available</Select.Option>
            <Select.Option value="Discountinued" name="status" >Discountinued</Select.Option>
            <Select.Option value="Sold Out" name="status" >Sold Out</Select.Option>
            <Select.Option value="Test Product Status" name="status" >Test Product Status</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="created_by"
          initialValue={user?.id}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="updated_by"
          initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
      {/* <div>
      {formProps.initialValues}
      </div> */}
      {/* <Form {...formProps} layout="vertical">
        <Form.Item
          label="Order Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone_no"
          label="Phone Number"
          rules={[{ required: true, message: 'Please input your phone number!' }]}
        >
          <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
        </Form.Item>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    label="Add product"
                    rules={[{ required: true, }]} >
                    <Select {...OBProductSelectBuyProps} style={{ width: "150px" }} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    label="Price"
                    rules={[{ required: true, message: 'Missing to price' }]}
                  >
                    <Input placeholder="Price" disabled />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'quantity']}
                    label="Quantity"
                    rules={[{ required: true, message: 'Missing to Quantity' }]}
                  >
                    <Input placeholder="Quantity" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />} style={{ width: "16%" }}>
                  Add Product
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value="Available" name="status" >Available</Select.Option>
            <Select.Option value="Limited Stock" name="status" >Limited Stock</Select.Option>
            <Select.Option value="Pre- order" name="status" >Pre- order</Select.Option>
            <Select.Option value="Not Available" name="status" >Not Available</Select.Option>
            <Select.Option value="Discountinued" name="status" >Discountinued</Select.Option>
            <Select.Option value="Sold Out" name="status" >Sold Out</Select.Option>
            <Select.Option value="Test Product Status" name="status" >Test Product Status</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="created_by"
          initialValue={user.id}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="updated_by"
          initialValue={user.id}>
          <Input type="hidden" />
        </Form.Item>

      </Form> */}
    </Modal>
  );
};
