import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
// import { Editor } from '@tinymce/tinymce-react';
import { Col, Row } from 'antd';
import {
  List,
} from "@pankod/refine-antd";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { nhost } from "utility";
import "./pages.css"



type EmailNotificationProps = {
  EmailNotificationData?: {} | any;
};

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const onChange = (key: string) => {
};

const onChangeCheckbox = (e: CheckboxChangeEvent) => {
};

export const EmailNotification: React.FC<EmailNotificationProps> = ({ EmailNotificationData }) => {
  const [form] = Form.useForm();
  const { data: user } = useGetIdentity();
  const [open, setOpen] = useState(false);
  const [newOrder, setnewOrder] = useState(false);
  const [newAttach, setnewAttach] = useState(false);
  const userId = user?.metadata?.org_id;
  const { selectProps: OBProductSelectProps } = useSelect<IProduct>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const onChangeCheckboxnew = (e: CheckboxChangeEvent) => {
    setnewOrder(e.target.checked)
  };
  const onChangeCheckboxAttach = (e: CheckboxChangeEvent) => {
    setnewAttach(e.target.checked)
  };

  const apidata = (e: any) => {
    const MUTATIONAPI = `mutation MyMutation {
        update_ob_orgs(_set: {new_customer_email:"${e.new_customer_email}",new_order_email:"${e.new_order_email}",bcc_outgoing_email:"${e.bcc_outgoing_email}",new_order_email_from_customer:"${e.new_order_email_from_customer}",new_orderXLS:${e.new_orderXLS},order_PDF:${e.order_PDF}}, where: { id: { _eq: "${userId}" } }) {
          affected_rows
        }
      }
      `
    const onSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000);
      return data
    }
    onSubmit();
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const validateCompanyName = (_:any, value:any) => {
    if (!/^[\w+.-]+@\w+(\.\w+)*(\s*,\s*[\w+.-]+@\w+(\.\w+)*)*$/.test(value)) {
      return Promise.reject('Please enter valid email addresses separated by commas');
    }
    return Promise.resolve();
  };
  
  return (
    <>
      <Form
        // {...formProps}
        // name="basic"
        wrapperCol={{ span: 23 }}
        initialValues={EmailNotificationData?.data?.data[0]}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        onFinish={(e) => {
          apidata(e)
        }}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="new_customer_email"
              label="Email for new customer notification"
              rules={[{ required: true },{ validator: validateCompanyName },]}
              // rules={[{ required: true, message: 'Please enter valid email addresses separated by commas!' }]}
              >

              <Input onChange={(e) => onChange} style={{ width: "100%" }} type="email" placeholder="Enter multiple emails separated by commas" 
                    //  pattern="^[\w+.-]+@\w+(\.\w+)*(\s*,\s*[\w+.-]+@\w+(\.\w+)*)*$" 
                    //  title="Please enter valid email addresses separated by commas" 
                     multiple={true}/>
            </Form.Item>
            <Form.Item
              name="new_order_email"
              label="Email for new orders notification"
              rules={[{ required: true },{ validator: validateCompanyName },]}
              >

              <Input onChange={(e) => onChange} style={{ width: "100%" }} type="email"
               multiple={true}/>
            </Form.Item>
            <p>You can add more than one email separated by commas. For example email1@example.com,email2@example.com,email3@example.com</p>

            <Form.Item name="new_orderXLS" valuePropName="checked" >
              <Checkbox
                name="new_orderXLS" checked={newOrder} onChange={onChangeCheckboxnew}
              >Attach new order as XLS to email</Checkbox>
            </Form.Item>
            <Form.Item name="order_PDF" valuePropName="checked">
              <Checkbox
                name="order_PDF" checked={newAttach} onChange={onChangeCheckboxAttach}
              >Attach order as PDF to email</Checkbox>
            </Form.Item>

            <Form.Item
              name="bcc_outgoing_email"
              label="Bcc outgoing emails to customers"
              rules={[{ required: true },{ validator: validateCompanyName },]}
              >

              <Input onChange={(e) => onChange} style={{ width: "100%" }} type="email" multiple={true} />
            </Form.Item>
            <Form.Item
              name="new_order_email_from_customer"
              label="Email for new order messages from customer"
              rules={[{ required: true },{ validator: validateCompanyName },]}
              >

              <Input onChange={(e) => onChange} style={{ width: "100%" }} type="email" multiple={true} />
            </Form.Item>
            <Form.Item>
              <input type="submit" className="input-save" value="Save" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleCancel}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully Updated Email Notification
          </span>
        </div>
      </Modal>
    </>

  );
};
