import {
  Form,
  Input,
  ModalProps,
  DatePicker,
  FormProps,
  Button,
  Select,
  Checkbox,
  Space,
  Modal,
  InputNumber,
  useSelect,
  DateField,
  Typography,
  Radio
} from "@pankod/refine-antd";
import { Table, Tooltip } from 'antd';
import { HttpError, useGetIdentity, useNotification, useOne,useNavigation } from "@pankod/refine-core";
import { PlusOutlined, CheckCircleOutlined, MinusOutlined, DeleteOutlined, CalendarOutlined, GatewayOutlined, BlockOutlined, EditOutlined, CheckOutlined, CopyOutlined, PrinterOutlined } from '@ant-design/icons';
import { ICustomer, IOrder, IOrg, IProduct } from "interfaces";
import { nhost } from "utility";
import { jsonConverter } from "components/helpers/jsonConverter";
import { useState, useEffect } from "react";
import { GET_ALL_CUSTOMERS_WITH_ORGID, GET_ALL_PRODUCTS_WITH_ORGID, GET_ALL_TAXES, GET_CUSTOMER_FAVLIST, GET_FAVITEM_CATEGORIES, GET_FAVOURITELIST_ITEMS, GET_ORDERS, GET_PRICELIST, GET_TBC_ITEMS_ID, GET_TRIPS, INSERT_FAVORITE_LIST_ITEMS, INSERT_INVOICES, INSERT_ORDERS, ORG_NAME, UPDATE_PRODUCTS } from "../../query";
import { Col, ModalFooter, Row } from "react-bootstrap";
import { AuthPermission } from "utility/authPermission";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import InvoicePreview from "utility/invoicePreview";

const { Option, OptGroup } = Select;
const { Paragraph } = Typography;
const { TextArea } = Input

type CreateOrderProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeCreateModal: () => void
  createModalVisible: boolean,
  tableQueryResult: any
};

export const CreateOrder: React.FC<CreateOrderProps> = ({
  modalProps,
  formProps,
  closeCreateModal,
  createModalVisible,
  tableQueryResult
}) => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();
  const [favModal, setFavModal] = useState(false)
  const [favListModal, setFavListModal] = useState(false)
  const [newFavItemModal, setNewFavItemModal] = useState(false)
  const [selectFavId, setSelectFavId] = useState<any>([])
  const [addFavItem, setAddFavItem] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [openOrderSave, setOpenOrderSave] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [tbcModal, setTbcModal] = useState(false)
  const [ tbcItem ,setTbcItem] = useState<any>([])
  const [ tbcData ,setTbcData] = useState<any>([])
  const [prodCount, setProdCount] = useState();
  const [OrgName, setOrgName] = useState([]);
  const [pageContent, setPageContent] = useState("Product Select");
  const [oldOrderId, setOldOrderId] = useState<any>(null);
  const [newOrderId, setNewOrderId] = useState<any>(null);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [allProductSearch, setAllProductSearch] = useState<any>([]);
  const [permanentAllProducts, setPermanentAllProducts] = useState<any>([]);
  const [permanentFavProducts, setPermanentFavProducts] = useState<any>([]);
  const [customers, setCustomers] = useState<any>(null);
  const [customerSearch, setCustomerSearch] = useState<any>(null);
  const [customerId, setCustomerId] = useState<any>(null);
  const [getOnePrice, setGetOnePrice] = useState<any>(null);
  const [favlistId, setFavlistId] = useState<any>(null);
  const [favlist, setFavlist] = useState<any>(null);
  const [favListId, setFavListId] = useState<any>(null);
  const [favOne, setFavOne] = useState<any>([]);
  const [favListItems, setFavListItems] = useState<any>([]);
  const [allPricelist, setAllPricelist] = useState<any>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<any>([]);
  const [rerenderTable, setRerenderTable] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [fields, setFields] = useState<any>([]);
  const [selectedProductIds, setSelectedProductIds] = useState<any>([]);
  const [allTrips, setAllTrips] = useState<any>(null);
  const [tripId, setTripId] = useState<any>(null);
  const [statusId, setStatusId] = useState<any>(null);
  const [allTaxes, setAllTaxes] = useState<any>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [form] = Form.useForm()
  const [menuPermission, setPermissions] = useState<any>(true)
  const [alertMessage, setAlertMessage] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const [editBtnShow,setEditBtnShow] = useState(false)
  const [expandedComments, setExpandedComments] = useState<any>({});
  const [preview, setPreview] = useState<any>(false);

  const currentUrl: string = window.location.origin;
  const { push } = useNavigation();

  useEffect(()=>{
    if(user){
      AuthPermission(user?.metadata?.org_id).then((res: any) =>{
        setPermissions(res)
      })
    }
  },[user])
 


  
  const userId = user?.metadata?.org_id;
  // PFS 
  console.log(userId,
    "urban eats"
  );
  const custome_modify_demo = userId === "43e3d773-b849-4106-8949-1771225a536e" ? true : false
  const custome_modify = userId === "1e84e052-4427-4808-b100-870391d93c2d" ? true : false
  let initialValue = 0;

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps } = useSelect<any>({
    resource: "route",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  const { data: alertData, refetch } = useOne<ICustomer, HttpError>({
    resource: "customers",
    id: customerId?.id || "",
    queryOptions: {
      enabled: !!customerId?.id,
    },
    metaData: {
      fields: ["id", "custom_alert"],
  },
  });

  useEffect(()=>{
    if(customerId){
      if(alertData?.data){
        setAlertMessage(alertData?.data?.custom_alert)
      }
    }else{
      setAlertMessage("")
    }
  }, [alertData, customerId])

  function CountFunc(prodCount: any) {
    let mock = prodCount?.split('-');
    let count = Number(mock[1]) + 1;
    let incrementvalue: any = (+count);
    setNewOrderId(incrementvalue = ("0000" + incrementvalue).slice(-4))
    return incrementvalue = ("0000" + incrementvalue).slice(-4);
  };

  const handleOk = () => {
    setOpenModal(false)
  }

  const getOrders = () => {
    GET_ORDERS(userId).then((res: any) => {
      if (res?.orders?.length > 0) {
        setOldOrderId(res?.orders[0]?.order_Unique_id)
        CountFunc(res?.orders[0]?.order_Unique_id)
      }
      else {
        CountFunc(`${OrgName} - 0000`)
      }
    })
  }

  const getAllProducts = () => {
    GET_ALL_PRODUCTS_WITH_ORGID(userId).then((res: any) => {
      setAllProducts(() => [])
      if(selectedProductIds?.length > 0){
        const removeDuplicate = res?.products?.filter((product:any) => !selectedProductIds.includes(product?.id));
        setAllProducts([...removeDuplicate])
      }
      else{
        setAllProducts(res?.products)
        setPermanentAllProducts(res?.products)
      }
    })
  }

  const getCustomers = () => {
    GET_ALL_CUSTOMERS_WITH_ORGID(userId).then((res: any) => {
      setCustomers(res?.customers)
      setCustomerSearch(res?.customers)
    })
  }

  const getFavList = (cusId: any) => {
    GET_CUSTOMER_FAVLIST(cusId).then((res: any) => {
      setFavlist(res?.favorite_lists)

      // Platinum foods
      // initial loading Favourte Item

      res?.favorite_lists?.map((resData: any)=>{
        
        GET_FAVOURITELIST_ITEMS(resData?.id).then((res: any) =>{
          setFavOne((prevFavItem: any)=> {
            
            return [...prevFavItem, ...res?.favorite_lists_items]});
        })
      })
    })
  }  

  const getTrips = () => {
    GET_TRIPS(userId).then((res:any) => {
      setAllTrips(res?.trip)
    })
  }

  const getFavlistItems = (favId: any) => {
    GET_FAVOURITELIST_ITEMS(favId).then((res: any) => {
      setFavListItems(() => [])
      if(res?.favorite_lists_items){
      if(selectedProductIds?.length > 0){
        const removeDuplicate = res?.favorite_lists_items?.filter((fav:any) => !selectedProductIds.includes(fav?.product?.id));
        setFavListItems([...removeDuplicate])
      }
      else{        
        setFavListItems(res?.favorite_lists_items);
      }
      setPermanentFavProducts(res?.favorite_lists_items)
      // setFavListItems(res?.favorite_lists_items)
    }
    })
  }

  const getPricelist = async () => {
    GET_PRICELIST(userId).then((res: any) => {
      setAllPricelist(res?.pricelists)
    })
  }
  const getCategories = async () => {
    GET_FAVITEM_CATEGORIES(userId).then((res: any) => {
      setCategoriesOptions(res)
    })
  }

  const getAllTaxes = () => {
    GET_ALL_TAXES(userId).then((res: any) => {
      setAllTaxes(res?.taxes)
    })
  }
  useEffect(() => {
    if (userId) {
      ORG_NAME(userId).then((response: any) => {
        setOrgName(response?.ob_orgs[0]?.orgname_prefix);
      });
      getCustomers();
      getAllProducts();
      getOrders();
      getPricelist();
      getCategories();
      getTrips();
      getAllTaxes();
    }
  }, [userId, createModalVisible]);

  var pad = (function (num) {
    return function () {
      var str = String(num++);
      while (str.length < 4) str = "0" + str;
      return str;

    }

  })(1);

  // Customer Search
  const handleCustomerSearch = (search: any) => {
    const searchValue = customers?.filter((data: any, i: number) => {
      return data?.name?.toLowerCase().includes(search?.toLowerCase()) || data?.customer_reference_code?.toLowerCase().includes(search?.toLowerCase())
    })

    if (searchValue?.length !== 0) {
      setCustomerSearch([...searchValue])
    }
    else {
      setCustomerSearch([])
    }

    if(search?.length === 0){
      getCustomers()
    }
  }

  const handleTripSearch = (search: any) => {
    const searchValue = allTrips?.filter((data: any, i: number) => {
      return data?.name?.toLowerCase().includes(search?.toLowerCase()) || data?.date?.toLowerCase().includes(search?.toLowerCase())
    })
    if (searchValue?.length !== 0) {
      setAllTrips([...searchValue])
    }
    else {
      getTrips()
    }
  }

  const handleFavouriteProductSearch = (productsearch: string) => {

    if (productsearch?.length > 0) {
      const filterProduct = favListItems?.filter((fav: any) => {
        return fav?.product?.name?.toLowerCase().includes(productsearch?.toLocaleLowerCase())
      })
      if (filterProduct?.length != 0) {
        setFavListItems([...filterProduct])
      }
      else {
        getFavlistItems(favlistId);
      }
    } else {
      getFavlistItems(favlistId);
    }
  };
  const handleProductSearch = (productsearch: string) => {
    if (productsearch?.length > 0) {
      const filterProduct = allProducts?.filter((product: any) => {
        return product?.name?.toLowerCase().includes(productsearch?.toLocaleLowerCase()) || product?.referencecode?.toLowerCase().includes(productsearch?.toLocaleLowerCase())
      })
      if (filterProduct?.length != 0) {
        setAllProductSearch([...filterProduct])
      }
      else {
        setAllProductSearch([]);
      }
    } else {
      setAllProductSearch(allProducts);
    }
  };

  const handleCustomerChange = (e: any) => {
    setTbcData([])
    setTbcItem([])
    setSelectedProducts([])
    setFields([])
    getFavList(e);
    const findCustomer = customers?.find((cus: any) => cus?.id == e)
    if (findCustomer) {
      getTbcData(findCustomer)
      setCustomerId(findCustomer);
      const findPriceList = allPricelist?.find((pricelist: any) => findCustomer?.price_list == pricelist?.id)
      if (findPriceList) {
        setGetOnePrice(findPriceList)
      }
    }
  }
  
  const getTbcData = async(id: string) =>{
    if(id){
      await GET_TBC_ITEMS_ID(id).then((res: any)=>{
        if(res?.to_be_charged?.[0]?.items?.length > 0){
          setTbcData(res?.to_be_charged?.[0])
          setTbcItem(res?.to_be_charged?.[0]?.items)
          setOpenAlert(true)
        }
      })
    }
  }
  const handleTripChange = (e: any) => {
    setTripId(e);    
  }

  const handleStatusChange = (e: any) => {
    setStatusId(e);    
  }
  
  const handleFavlistChange = (e: any) => {  
    setFavlistId(e);
    getFavlistItems(e); 
    
  }

  const handleSelectChange = (id:any, quantity: number = 1) => {
    let getProducts = permanentAllProducts?.filter((product:any) => {
      return product?.id == id;
    });
    
  if(favOne){
    let newFavProducts = favOne?.find((item: any) =>{
      return (item?.product?.id === getProducts[0]?.id)})
      
    if(!newFavProducts){
      const obj: any = {
        // favorite_list_id: favlist[0]?.id,
        product_id: id,
        product_name: getProducts[0]?.name,
        quantity: 1,
        ob_org_id: user?.metadata?.org_id,
        is_active: true,
        created_by: user?.id,
      };
      setAddFavItem((prevFavItem:any) => {
        return [...prevFavItem, obj]
      })
    }
  }
    
  
    if (getProducts?.length > 0) {
      let selectedProduct = getProducts?.map((data:any) => {
        
        let pricelistValue;
        let isCustomerRefCode = data?.customer_price?.some((price:any) => {
          return price?.PRICING_REF == customerId?.customer_reference_code;
        });
        
        if (!isCustomerRefCode) {
          pricelistValue = data?.customer_price?.find((price:any) => {
            return price?.PRICING_REF == getOnePrice?.short_name;
          });
          
        } else {
          pricelistValue = data?.customer_price?.find((price:any) => {
            return price?.PRICING_REF == customerId?.customer_reference_code;
          });
          
        }
        
        let mockData = {
          "id": data?.id,
          "productId": data?.id,
          "name": data?.name,
          "Product_code": data?.code,
          "image": data?.image,
          "quantity": data?.quantity,
          "price": pricelistValue ? Number(pricelistValue?.STORED_PRICE) : data?.msrp,
          "stock": data?.quantity,
          "neededquantity": quantity,
          "tax_id": data?.tax_id, 
          "qty_allocated": data?.qty_allocated,
          "product_type":"Select Store Product",
          "comment":"",
          "description":""
        };

  
        return mockData;
      })[0];

      setSelectedProducts((prevSelectedProducts:any) => {
        const existingProductIndex = prevSelectedProducts.findIndex((product:any) => product.id === selectedProduct.id);
  
        if (existingProductIndex !== -1) {
          const updatedSelectedProducts = [...prevSelectedProducts];
          updatedSelectedProducts.splice(existingProductIndex, 1);
          updatedSelectedProducts.push(selectedProduct);
          return updatedSelectedProducts;
        } else {
          return [...prevSelectedProducts, selectedProduct];
        }
      });
      setSelectedProductIds((prevSelectedProductIds:any) => {
        if (!prevSelectedProductIds.includes(selectedProduct.id)) {
          return [...prevSelectedProductIds, selectedProduct.id];
        }
        return prevSelectedProductIds;
      });
    }
    const newField = {
      product: null,
      quantity: null,
      type: "Select Store Product",
      index: (fields?.length-1) +1
    };

  setFields((prevFields: any) => [...prevFields, newField]);
    form.setFieldsValue({"selectedproducts": ''})
    
  }  

  const handleSelectProductChange = (id:string, index: number, prv: string) => {
  
    let getProducts = permanentAllProducts?.filter((product:any) => {
      return product?.id == id
    });
    let removeProducts = permanentAllProducts?.filter((product:any) => {
      return product?.id == selectedProducts[index]?.id
    });

    if(removeProducts){
      allProducts?.push(removeProducts[0])
    }
     
    if (getProducts?.length > 0) {
      let selectedProduct = getProducts?.map((data:any) => {
        
        let pricelistValue;
        let isCustomerRefCode = data?.customer_price?.some((price:any) => {
          return price?.PRICING_REF == customerId?.customer_reference_code;
        });

        if (!isCustomerRefCode) {
          pricelistValue = data?.customer_price?.find((price:any) => {
            return price?.PRICING_REF == getOnePrice?.short_name;
          })
        } else {
          pricelistValue = data?.customer_price?.find((price:any) => {
            return price?.PRICING_REF == customerId?.customer_reference_code;
          })
        }
        
        let mockData = {
          "id": data?.id,
          "productId": data?.id,
          "name": data?.name,
          "Product_code": data?.code,
          "image": data?.image,
          "quantity": data?.quantity,
          "price": pricelistValue ? Number(pricelistValue?.STORED_PRICE) : data?.msrp,
          "stock": data?.quantity,
          "neededquantity": 1,
          "tax_id": data?.tax_id,
          "qty_allocated": data?.qty_allocated,
          "product_type":"Select store product",
          "comment":"",
          "description":""
        };

  
        return mockData;
      })[0];
      setSelectedProducts((prevSelectedProducts: any) => {
        const updatedProducts = [...prevSelectedProducts];
        const productIndex = updatedProducts.findIndex(item => item?.id === prv);
        updatedProducts[productIndex] = selectedProduct;
        return updatedProducts;
      });
  
      setSelectedProductIds((prevSelectedProductIds: any) => {
        const updatedProductIds = [...prevSelectedProductIds];
        const productIndex = updatedProductIds.findIndex(item => item === prv);
        updatedProductIds[productIndex] = id;
        return updatedProductIds;
      });
    }
  }  

  const handleFavSelectChange = (id: any) => {  
    let getProducts = permanentAllProducts?.filter((product:any) => {
      return product?.id == id;
    });
  
    if (getProducts?.length > 0) {
      let selectedProduct = getProducts?.map((data:any) => {
        let pricelistValue;
        let isCustomerRefCode = data?.customer_price?.some((price:any) => {
          return price?.PRICING_REF == customerId?.customer_reference_code;
        });
  
        if (!isCustomerRefCode) {
          pricelistValue = data?.customer_price?.find((price:any) => {
            return price?.PRICING_REF == getOnePrice?.short_name;
          });
        } else {
          pricelistValue = data?.customer_price?.find((price:any) => {
            return price?.PRICING_REF == customerId?.customer_reference_code;
          });
        }
  
        let mockData = {
          "id": data?.id,
          "productId": data?.id,
          "name": data?.name,
          "Product_code": data?.code,
          "image": data?.image,
          "quantity": data?.quantity,
          "price": pricelistValue ? Number(pricelistValue?.STORED_PRICE) : data?.msrp,
          "stock": data?.quantity,
          "neededquantity": 1,
          "tax_id": data?.tax_id,
          "product_type":"Select Favourite Product",
          "comment":"",
          "description":""
        };
  
        return mockData;
      })[0];
  
      setSelectedProducts((prevSelectedProducts:any) => {
        const existingProductIndex = prevSelectedProducts.findIndex((product:any) => product.id === selectedProduct.id);
  
        if (existingProductIndex !== -1) {
          const updatedSelectedProducts = [...prevSelectedProducts];
          updatedSelectedProducts.splice(existingProductIndex, 1);
          updatedSelectedProducts.push(selectedProduct);
          return updatedSelectedProducts;
        } else {
          return [...prevSelectedProducts, selectedProduct];
        }
      });
      setSelectedProductIds((prevSelectedProductIds:any) => {
        if (!prevSelectedProductIds.includes(selectedProduct.id)) {
          return [...prevSelectedProductIds, selectedProduct.id];
        }
        return prevSelectedProductIds;
      });
    }
    form.setFieldsValue({"favouriteProduct": ''})
  }

  const handleSelectFavProductChange = (id: string, index: number, prv: string) => {
    
    let getProducts = permanentAllProducts?.filter((product:any) => {
      return product?.id == id;
    });    
    
    let removeProducts = permanentAllProducts?.filter((product:any) => {
      return product?.id == selectedProducts[index]?.id
    });
  
    if(removeProducts){
      //  add the item in favItem
      favListItems?.push({product:removeProducts[0]})
    }

    if (getProducts?.length > 0) {
      let selectedProduct = getProducts?.map((data:any) => {
        let pricelistValue;
        let isCustomerRefCode = data?.customer_price?.some((price:any) => {
          return price?.PRICING_REF == customerId?.customer_reference_code;
        });
  
        if (!isCustomerRefCode) {
          pricelistValue = data?.customer_price?.find((price:any) => {
            return price?.PRICING_REF == getOnePrice?.short_name;
          });
        } else {
          pricelistValue = data?.customer_price?.find((price:any) => {
            return price?.PRICING_REF == customerId?.customer_reference_code;
          });
        }
  
        let mockData = {
          "id": data?.id,
          "productId": data?.id,
          "name": data?.name,
          "Product_code": data?.code,
          "image": data?.image,
          "quantity": data?.quantity,
          "price": pricelistValue ? Number(pricelistValue?.STORED_PRICE) : data?.msrp,
          "stock": data?.quantity,
          "neededquantity": 1,
          "tax_id": data?.tax_id,
          "qty_allocated": data?.qty_allocated,
          "product_type": "Select Favourite Product ",
          "comment":"",
          "description":""
        };
  
        return mockData;
      })[0];
  
      setSelectedProducts((prevSelectedProducts: any) => {
        const updatedProducts = [...prevSelectedProducts];
        const productIndex = updatedProducts.findIndex(item => item?.id === prv);
        updatedProducts[productIndex] = selectedProduct;
        return updatedProducts;
      });
  
      setSelectedProductIds((prevSelectedProductIds: any) => {
        const updatedProductIds = [...prevSelectedProductIds];
        const productIndex = updatedProductIds.findIndex(item => item === prv);
        updatedProductIds[productIndex] = id;
        return updatedProductIds;
      });
    }
  }

  const handelChangeFav = (e: any) =>{
    setSelectFavId(e)  
  }

  const handelChangeFavList = (e: any) =>{
    setFavListId(e.target.value)  
  }
  
  const handeSaveFavProduct = ()=>{
    setFavListModal(true)
    setFavModal(false)
  }

  const addNewFav = async()=>{
    const FavProductSelect = addFavItem
    ?.filter((fav: any) => selectFavId.includes(fav.product_id))
    .map((fav: any) => {
      const { product_name, ...rest } = fav;
      
      // Adding the extra field (e.g., `extraField`)
      return {
        ...rest,
        favorite_list_id: favListId, // Replace "Some value" with the desired value or calculation
      };
    });
    
    await INSERT_FAVORITE_LIST_ITEMS ({Object:FavProductSelect}).then((res: any)=>{
      setFavListModal(false)
      setFavModal(false)
      setNewFavItemModal(false)
      setAddFavItem([])
      setOpenOrderSave(true)
      open({
        key: "favAdd",
        type: "success",
        message: "",
        description: "Product has been added to the Favourite list",
      });
      setTimeout(() => {
        close("favAdd");
      }, 2000);
    })
     
  }

  const handelOpenFav = () =>{
    setNewFavItemModal(true)
    setFavListModal(false)
  }
  
  const handleQuantityChangeInput = (value: any, id: any, stock:any) => {

    if(value > stock){
      open({
        key: "quantityerror",
        type: "error",
        message: "",
        description: "Only " + Number(stock)?.toFixed() + " items are available",
      });
      setTimeout(() => {
        close("quantityerror");
      }, 2000);
      setSelectedProducts((pre: any) => {
        const updatedSelectedProducts = [...pre];
        const productIndex = updatedSelectedProducts.findIndex(item => item?.id === id);
        if (productIndex) {
          updatedSelectedProducts[productIndex].neededquantity = value.slice(0, -1);
        }
        return updatedSelectedProducts;
      });
    }
    else if(value == 0 || value?.length == 0){

      setSelectedProducts((pre: any) => {
        const updatedSelectedProducts = [...pre];
        const productIndex = updatedSelectedProducts.findIndex(item => item?.id === id);
        if (productIndex >= 0) {
          updatedSelectedProducts[productIndex].neededquantity = value;
        }
        return updatedSelectedProducts;
      });
    }
    else{

      setSelectedProducts((pre: any) => {
        const updatedSelectedProducts = [...pre];
        const productIndex = updatedSelectedProducts.findIndex(item => item?.id === id);
        
        if (productIndex >= 0) {
         updatedSelectedProducts[productIndex].neededquantity = value;
        }
        return updatedSelectedProducts;
      });
    }
  }

  const deleteSelectedProduct = (data: any, index: any) => {
    const deleteProduct = selectedProducts?.filter((selctProd: any) => selctProd?.id != data?.id)
    if (deleteProduct) {
      setSelectedProducts([...deleteProduct]);
      fields.splice(index, 1);
      setSelectedProductIds((preId:any) => preId?.filter((id:any) => id !== data?.id));
      // setFields([...fields]);
    }
  }
  const DataApi = (e: any) => {
    const order_Unique_id = (prodCount !== undefined) ? OrgName + "-" + Math.random() + CountFunc(prodCount) : OrgName + "-" + Math.random() + pad();
    const uniqueValue = OrgName + "-" + order_Unique_id.slice(-5)
    const MUTATIONAPI = `mutation MyMutation {
      insert_orders(objects: { name: "${e.name}", order_Unique_id:"${uniqueValue}", ob_org_id: "${e.ob_org_id}", date: "${e.date}",email:"${e.email}",phone_no,:"${e.phone_no}",
      status,:"${e.status}",order_Type,:"${"Sales Order"}", items: ${jsonConverter(e.items)},created_by:"${user?.id}"
      }){
          affected_rows
        }
      }
      `;
    const createOrder = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      if (data) {
        setOpenModal(true)
        setTimeout(() => {
          setOpenModal(false)
        }, 3000);
        tableQueryResult?.refetch()
        closeCreateModal()
        return data
      }
    }
    createOrder()
  }

  const handleExpandClick = (index: number) => {
    setExpandedComments((prevState: any) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const  coloums: any = [
    {
      title: "Product",
      dataIndex: "name",
      width: 400,
      render: (value: any) => <div>{value}</div>
    },
    {
      title: <div style={{display: "flex", justifyContent: "flex-start", marginLeft: "30px"}}>Quantity</div>,
      dataIndex: '',
      width: 200,
      render: (text: any, record: any, index: any) => {
        const handleQuantityChange = (e: any, value: any) => {
          if (Number(e) <= Number(value?.stock)) {
            let allproductclone = selectedProducts
            let findindex = allproductclone?.findIndex((ind: any) => {
              return ind?.id == value?.id
            })
            if (findindex > -1) {
              allproductclone[findindex].neededquantity = Number(e)
              setSelectedProducts([...allproductclone])
            }
          }
          else {
            open({
              key: "stockerror",
              type: "error",
              message: "",
              description: "Only " + Number(value?.stock)?.toFixed() + " items are available",
            });
            setTimeout(() => {
              close("stockerror");
            }, 2000);
          }
        };

        const handleIncrement = () => {
          if (selectedProducts?.length > 0) {
            const allProductClone = [...selectedProducts];
            const findIndex = allProductClone.findIndex((ind:any) => ind.id === text.id);
        
            if (findIndex > -1 && allProductClone[findIndex].neededquantity < allProductClone[findIndex].quantity) {
              allProductClone[findIndex].neededquantity++;
              setSelectedProducts(allProductClone);
            }
          }
        };

        const handleDecrement = () => {
          if (text?.neededquantity > 0) {
            const allProductClone = [...selectedProducts];
            const findIndex = allProductClone.findIndex((ind:any) => ind.id === text.id);
        
            if (findIndex > -1) {
              let temporaryNeededValue = allProductClone[findIndex].neededquantity - 1;
              allProductClone[findIndex].neededquantity = temporaryNeededValue;
              setSelectedProducts(allProductClone);
              const tempProduct = allProductClone.filter((data) => data.neededquantity > 0);
            }
          }
        };        
        return (
          <div style={{display: "flex", justifyContent: "flex-start"}} className="quantity_container">
            <Button
              className="quantity_decreament_button"
              icon={<MinusOutlined />}
              onClick={handleDecrement}
              disabled={text?.neededquantity == 1}
            />
            <InputNumber
              className="quantity_input"
              value={text?.neededquantity}
              min={1}
              controls={false}
              onChange={(e: any) => handleQuantityChange(e, text)}
            />
            <Button
              className="quantity_increament_button"
              icon={<PlusOutlined />}
              onClick={handleIncrement}
              disabled={text?.neededquantity == text?.stock}
            />
          </div>
        );
      },
    },
    ...(custome_modify
      ? [
          {
            title: (
              <div style={{ display: "flex", justifyContent: "left", fontWeight: "500", fontSize: "15px", lineHeight: "15px" }}>
                Comment
              </div>
            ),
            dataIndex: "comment",
            width: 200,
            key: "comment",
            render: (value: string, index: number) => {
              const isExpanded = expandedComments[index];
              const truncatedText = value?.length > 20 ? `${value.slice(0, 100)}` : value;
      
              return (
                <div style={{width: "200px"}}>
                 <p> {isExpanded ? value : truncatedText} {value?.length > 100 && (<span  onClick={() => handleExpandClick(index)} style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}>{isExpanded? 'show less':'show more...'}</span>)}</p>
                </div>
              );
            },
          },
        ]
      : []),
    {
      title: "Price £ ",
      dataIndex: "",
      width: 200,
      render: (text: any, record: any, index: any) => {
        return <div >{ Number(text?.neededquantity * text?.price).toFixed(2)}</div>
      }
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "left", }}>
          VAT £
        </div>
      ),
      width: 90,
      key: "tax_id",
      dataIndex: "",
      render: (value: any) => {
        const tax = allTaxes?.find((tax: any) => tax.id === value.tax_id);
        if (tax) {
          const vatAmount = (value.neededquantity * value.price * (tax.vat_rate / 100)).toFixed(2);
          return (
            <div style={{ display: "flex", justifyContent: "left" }}>
              {vatAmount}
            </div>
          );
        }
        return "0.00";
      }
    },
    {
      title: <div style={{display: "flex", justifyContent: "center"}}>Actions</div>,
      dataIndex: "",
      width: 120,
      render: (text: any, record: any, index: any) => {
        return <div style={{display: "flex", justifyContent: "center"}}><Button
          onClick={() => deleteSelectedProduct(text, index)}
          style={{ color: "red", outline: "none", border: "none", backgroundColor: "inherit" }} icon={<DeleteOutlined />}></Button>
        </div>
      }
    },
  ]

  const  tbcColoums = [
    {
      title: "S.No",
      dataIndex: "id",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
    }
  ]

  const handleFinalSaveClose = () => {
    setOpenOrderSave(false)
  }

  const handleSaveOpen = () => {
    let hasProductQut = selectedProducts?.every((data: any)=>{
      return data?.neededquantity > 0
    })
    if(hasProductQut){
      if(addFavItem?.length > 0 && favlist?.length > 0){
        // setFavModal(true)
        setOpenOrderSave(true) 
      }else{
        setOpenOrderSave(true) 
      }
    }else{
      open?.({
        key: "quantity",
        type: "error",
        message: "",
        description: `Product quantity can't be zero`,
      });
      setTimeout(() => {
        close("invoice_error");
      }, 2000);
    }
  }

  // changes

  const insertInvoice = (data:any, totalPrice:any ) => {
    INSERT_INVOICES({
      Object: {
        orderids: data?.order_Unique_id,
        invoicetype: "Sales Invoice",
        paymentreference: "Initiate",
        price: totalPrice,
        total: Number(totalPrice).toFixed(2),
        created_by: user?.id,
        outstanding:  Number(totalPrice).toFixed(2),
        ob_org_id: `${userId}`,
        paymentmode: "",
        comments: "Admin Order Invoice",
        payeedetails: null,
        discounts: null,
        paymentgateway: "",
        paymentgatewayresponce: null,
        status: "Payment Pending",
        is_paid: false,
      },
    }).then((inv_res:any) => {
      let filterData = tbcItem?.filter((data: any) => !selectedRowKeys.includes(data?.id))

      let mutation = `mutation MyMutation ($id: uuid, $items: jsonb) {
        update_to_be_charged(where: {id: {_eq: $id}}, _set: {items: $items}) {
          affected_rows
        }
      }
      `
      let variable = {
        id: tbcData?.id,
        items: filterData
      }

      nhost.graphql.request(mutation, variable).then((res: any) =>{
        
      }).catch((error: any) =>{
        // console.log(error);
      })
    }).catch((inv_err:any) => {
      open?.({
        key: "invoice_error",
        type: "error",
        message: "",
        description: `An error occurred during the invoice insertion.`,
      });
      setTimeout(() => {
        close("invoice_error");
      }, 2000);
    })
  }

  const totalPrice = selectedProducts?.reduce(
    (accumulator: any, curValue: any) =>
      accumulator + curValue.price * curValue.neededquantity,
    0
  );

  const totalTax = selectedProducts?.reduce(
    (accumulator: any, curValue: any) => {
      if (curValue.tax_id !== null) {
        const tax = allTaxes?.find(
          (tax: any) => tax.id === curValue.tax_id
        );
        if (tax) {
          return (
            accumulator +
            curValue.neededquantity *
              curValue.price *
              (tax.vat_rate / 100)
          );
        }
      }
      return accumulator;
    },
    0
  );

  const grandTotal = totalPrice + totalTax;
  
  const createOrder = () => {
    getOrders();
    let totalValue = selectedProducts?.reduce(function (accumulator: any, curValue: any) {
      return accumulator + curValue.neededquantity * curValue.price.toFixed(2)
    }, initialValue)  


    let orderItems = selectedProducts?.map((data: any, index: number) => {
      let mock = {
        "id": data?.id,
        "productId": data?.id,
        "name": data?.name,
        "Product_code": data?.Product_code,
        "image": data?.image,
        "quantity": data?.neededquantity,
        "price": data?.price,
        "stock": data?.stock,
        "itemTotal": Number(data?.price * data?.neededquantity),
        "item_code": index + 1,
        "tax_id": data?.tax_id,
        "comment":data?.comment,
        "description":data?.description
      }
      return mock
    })

    let listOrderItem = selectedProducts?.map((data: any, index: number)=>{
      let calculatorQuntity = 0;
      let totalQuantity = data?.stock;
      let req_stock = data?.qty_allocated;
      let neededquantity = data?.neededquantity

      if(!req_stock){
        calculatorQuntity = totalQuantity - neededquantity
      }else{
        calculatorQuntity = req_stock - neededquantity
      }
      return({
        id:data.id,
        req_quantity: calculatorQuntity
      })
    })

    INSERT_ORDERS({
      Object: {
        order_Unique_id: `${OrgName}-${newOrderId}`,
        name: customerId?.name,
        ob_org_id: userId,
        email: customerId?.email,
        created_by: user?.id,
        updated_by: user?.id,
        items: orderItems,
        address: { "customers": [customerId] },
        // phone_no: customerId?.phone != null || undefined || "" ? customerId?.phone : null,
        // delivery_Date: selectedDeliveryDate,
        // delivery_Time: selectedDeliveryTime,
        // delivery_Tip: selectedDeliveryTip,
        // delivery_Instruction: selectedDeliveryText ?? deliveryAddress,
        customer_id: customerId?.id,
        customer_reference_code: customerId?.customer_reference_code,
        total_price: grandTotal?.toFixed(2),
        status: "Preparing",
        order_Type: "Sales Order",
        trip_id: tripId,
        despatch_status: 'Preparing',
      },
    }).then((res: any) => {
      
      handleFinalSaveClose();
      closeCreateModal();
      if (res?.data?.insert_orders?.returning?.length > 0) {
        insertInvoice(res?.data?.insert_orders?.returning[0], grandTotal);
        listOrderItem?.forEach(async(data: any)=>{
          await UPDATE_PRODUCTS(data)
        })
        open?.({
          key: "success",
          type: "success",
          message: "",
          description: `Order has been created for ${res?.data?.insert_orders?.returning[0]?.name} customer`,
        });
        
        EmailNotification(res?.data?.insert_orders?.returning[0]);
        setTimeout(() => {
          close("success");
          setPageContent("Product Select");
          form.resetFields();
          setCustomerId(null);
          setFavlistId(null);
          setSelectedProductIds([])
          setFields([]);
          setGetOnePrice(null);
          setSelectedProducts([]);
        }, 3000);
      }
      else {
        open?.({
          key: "error",
          type: "error",
          message: "",
          description: `Something Went Wrong`,
        });
        setTimeout(() => {
          close("error");
          setPageContent("Product Select")
        }, 5000);
      }
    })
  }

  async function EmailNotification(data: any) {

  try {
    const res = await fetch(`${currentUrl}/api`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "payload": {
          "orderno": data?.order_Unique_id,
          "customername": data?.name,
        }
      }),
    });

    if (!res.ok) {
      console.error("Error: ", res.status, res.statusText);
    } else {
      const result = await res.json();
    }
  } catch (error) {
    console.error("Fetch error: ", error);
  }
}

  let uniqueData:any = []
  if(favListItems?.length > 0){
    uniqueData = favListItems?.filter((value: any, index: any, self: any) => {
      return index === self.findIndex((item: any) => (
        item?.product?.categories === value?.product?.categories && item?.product?.categories != null
      ));
    });
    let categoryOrder = categoriesOptions?.categories?.map((cat: any) => {
      return cat?.name
    })
    const getCategoryIndex = (categoryName: any) => {
      const index = categoryOrder?.indexOf(categoryName);
      return index === -1 ? Infinity : index;
    };
  }

  useEffect(() =>{
      setAllProducts((pre:any) => permanentAllProducts?.filter((product:any) => !selectedProductIds.includes(product?.id)));
      setFavListItems((preProd:any) => permanentFavProducts?.filter((fav:any) => !selectedProductIds.includes(fav?.product?.id)));
  }, [selectedProductIds]);

  useEffect(()=>{
    setAllProductSearch(allProducts)
  },[allProducts])

  const handeClear = ()=>{
    
  form.setFieldsValue({"favouritelist": ''})
    setCustomerId(null)
    setFields([])
    setSelectedProducts([])
    setAlertMessage("")
  }
  
  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(selectedRows);
  };
  
  const rowSelection: any = {
    hideSelectAll: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };

const addTbcProduct = () =>{
  selectedRow?.map((data: any)=>{
    handleSelectChange(data?.id, data?.cartQuantity)
  })
  open?.({
    key: "tbc_success",
    type: "success",
    message: "",
    description: `TBC Product Add Successfully.`,
  });
  setTimeout(() => {
    close("tbc_success");
  }, 2000);
  setTbcModal(false)
}


const updateAlertApi = () =>{
  const Mutation = `mutation MyMutation {
    update_customers(_set: {custom_alert: "${alertMessage}"},where: { id: { _eq: "${customerId?.id}"} } ){
      affected_rows
    }
  }
  `
  nhost.graphql.request(Mutation).then((response: any)=>{
    refetch()
    setEditBtnShow(!editBtnShow)
    open?.({
      key: "success",
      type: "success",
      message: "",
      
      description: `Custom Alert has been updated`,
    });
    setTimeout(() => {
      close("success");
    }, 5000);
  }).catch((error)=>{

  })
}

const handleChangeMessage = (e: any) =>{
  setAlertMessage(e)
}

const handleEditButtonClick = () => {
  setIsEditing(!isEditing);
  setEditBtnShow(!editBtnShow)
};

const handleAlertUpdate = () =>{
  setIsEditing(!isEditing);
  updateAlertApi()
}

const handelClose = () =>{
  setCustomerId(null)
  setCustomers(null)
  setCustomerSearch(null)
  setSelectedProducts([])
  setSelectedProductIds([])
  setFields([])
  closeCreateModal()
  setPageContent("Product Select")
  form.resetFields()
}

const handelCopie = (e: any) =>{
  navigator.clipboard.writeText(e).then(() => {
    open({
        type: 'success',
        message: 'Copied to clipboard!',
    });
    close("success")
}).catch((err) => {
    open({
        type: 'error',
        message: 'Failed to copy!',
    });
    close("error")
});
}

const handleTbc = () =>{
  setTbcModal(false)
  setSelectedRowKeys([]) 
  setSelectedRow([])
}

const handlePreview = () =>{
  setPreview(true);
}

const handleClosePreview = () =>{
  setPreview(false);
}

const handelMessaage = (e: any,filed: string, index: number) =>{
  let value = e.target.value
  if(filed === "comment"){
    setSelectedProducts((pre: any) => {
      const updatedSelectedProducts = [...pre];
      const productIndex = updatedSelectedProducts.findIndex(item => item?.id === index);
      if (productIndex >= 0) {
        updatedSelectedProducts[productIndex].comment = value;
      }
      return updatedSelectedProducts;
    })
  }else{
    setSelectedProducts((pre: any) => {
      const updatedSelectedProducts = [...pre];
      const productIndex = updatedSelectedProducts.findIndex(item => item?.id === index);
      if (productIndex >= 0) {
        updatedSelectedProducts[productIndex].description = value;
      }
      return updatedSelectedProducts;
    })
  }  
}

// Drag and drop
const handleOnDragEnd = (result: any) => {

  if (!result.destination) return;

  const items = Array.from(selectedProducts)
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);
  setSelectedProducts(items);
};

const handleSelectDrager = (result: any) =>{
  if (!result.destination) return;
  const items = Array.from(selectedProducts)
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);
  setSelectedProducts(items);
}


  return (
    <>
      <Modal {...modalProps} title="Create Order"
        onCancel={handelClose}
        visible={createModalVisible}
        footer={false}
        maskClosable = {false}
      >
        <Form {...formProps} layout="vertical" form={form}>
          <div>
            {pageContent == "Product Select" ?
              <div className="">
                <div className= {`d-flex justify-content-around ${custome_modify && "oreder_frame"}`}>
                  <div>
                    <div className="mb-3">
                      <span className="fontStyle6 float-md-left">Name :</span>
                      <span className="float-md-left p-6">
                        {" "}
                        <span>{customerId?.name}</span>
                      </span>
                    </div>
                    {!custome_modify && 
                      <div className="mb-3">
                        <span className="fontStyle6 float-md-left">Email :</span>
                        <span className="float-md-left p-6">
                          {" "}
                          <span>{customerId?.email}</span>
                        </span>
                      </div>
                    }
                    {custome_modify &&<div className="mb-3">
                      <span className="fontStyle6 float-md-left">Phone Number :</span>
                      <span className="float-md-left p-6">
                        {" "}
                        <span>{customerId?.phone}</span>
                      </span>
                    </div>}
                  </div>
                  <div>
                    {!custome_modify &&<div className="mb-3">
                      <span className="fontStyle6 float-md-left">City :</span>
                      <span className="float-md-left p-6">
                        {" "}
                        <span>{customerId?.city}</span>
                      </span>
                    </div>}
                    {custome_modify &&<div className="mb-3">
                      <span className="fontStyle6 float-md-left">Address :</span>
                      <span className="float-md-left p-6">
                        {" "}
                        <span>{customerId?.address}{customerId?.address_line_2} {customerId?.address} {customerId?.city}{customerId?.country}</span>
                      </span>
                    </div>}
                    <div className="mb-3">
                      <span className="fontStyle6 float-md-left">Reference code :</span>
                      <span className="float-md-left p-6">
                        {" "}
                        <span>{customerId?.customer_reference_code}</span>
                      </span>
                    </div>
                  </div>
                  {custome_modify &&
                    <>
                      <div className="d-flex justify-content-end ">
                        <span className="fontStyle6 float-md-left mr-2">No. of products:   </span>
                        <span className="float-md-left" style={{marginLeft: '10px'}}>   {selectedProducts?.length}</span>
                      </div>
                      
                    </>
                  }
                </div>
                <div className={ custome_modify ? `customer_box cusBox_color`: `customer_box`}>
                  <Form.Item
                    label="Select Customer"
                    name="customer"
                    rules={[{ required: true, message: "Please Select Customer" }]}
                  >
                    <Select
                      allowClear 
                      onClear= {handeClear}
                      clearIcon="*"
                      showSearch
                      placeholder="Customer"
                      filterOption={false}
                      onSearch={handleCustomerSearch}
                      onChange={handleCustomerChange}
                    >
                      {customerSearch?.map((cus: any) => {
                        return (
                          <Option key={cus?.id}>
                            {`Code: ${cus?.customer_reference_code}:  ${cus?.name}`}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label= "Select Favourite List"
                    name="favouritelist"
                  >
                    <Select
                      placeholder="Favourite List"
                      onChange={handleFavlistChange}
                      allowClear
                      clearIcon="*"
                      dropdownClassName="select-dropdown-custom"
                    >
                      {favlist?.map((fav: any) => {
                        return <Option key={fav?.id}>{fav?.name}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </div>
                  {favlistId &&
                    <Form.Item
                      label="Select Favourite Products"
                      name= "favouriteProduct"
                    >
                      <Select
                        placeholder={"Select Favourite Products"}
                        filterOption={false}
                        showSearch
                        onSearch={handleFavouriteProductSearch}
                        onChange={(id: any) => handleFavSelectChange(id)}
                      >
                        {uniqueData.map((category: any) => (
                          <OptGroup key={category?.product?.categories} label={category?.product?.categories}>
                            {favListItems.map((fav: any) =>
                              fav?.product?.categories === category?.product?.categories ? (
                                <Option key={fav?.product?.id}>
                                  <div className="drop_list">
                                    <p>{`Code: ${fav?.product?.code}:${fav?.product?.name}`}</p>
                                    {custome_modify && <p>{`Stock:${ fav?.product?.qty_allocated ? fav?.product?.qty_allocated : fav?.product?.quantity }`}</p>}
                                  </div>
                                </Option>
                              ) : null
                            )}
                          </OptGroup>
                        ))}
                        </Select>
                    </Form.Item>
                  }
                  <Form.Item
                    label="Select Store Products"
                    name={"selectedproducts"}
                    rules={[{ required: false, message: "Please Select Products" }]}
                  >
                    <Select
                      placeholder = "Select Product"
                      filterOption={false}
                      showSearch
                      allowClear
                      clearIcon="*"
                      onSearch={handleProductSearch}
                      onChange={(id: any) => handleSelectChange(id)}                     
                    >
                      {[...allProductSearch]?.map((product: any) => {
                        return <Option key={product?.id}>
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p>{`Code:${product?.referencecode}:${product?.name}`}</p>
                            {custome_modify &&
                            <p>{`Stock:${product?.qty_allocated ? product?.qty_allocated : product?.quantity}`}</p>
                            }
                          </div>
                        </Option>
                        
                      })}
                    </Select>
                </Form.Item>
                <div style={{minHeight: custome_modify ? `${fields?.length * 160}px`: "auto"}}>
                  <DragDropContext onDragEnd={handleSelectDrager}>
                    <Droppable droppableId = "ordercart" isDropDisabled = {custome_modify? false : true}>
                      {(provided) =>(
                        <section 
                          {...provided.droppableProps}
                          ref = {provided.innerRef}
                        >
                          {selectedProducts.slice().reverse().map((field: any, index: any) => (
                            <Draggable
                              key={field?.id}
                              draggableId={String(field?.id?.toString())}
                              index={index}
                              isDragDisabled = {custome_modify ? false : true}
                            >
                              {(provided) =>(
                                <div 
                                  className={`row content-align ${custome_modify?"content-row": ""}`}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <div key={index} className="row align-items-center">
                                    <div className="col-6">
                                      <Form.Item
                                        label={
                                          <>
                                          {`${field?.product_type}`} <CopyOutlined style={{color: "#4EA144"}} className="m-1" onClick={()=>handelCopie(field?.name)}/>
                                          </>
                                        }
                                        rules={[{ required: true, message: `Please ${field?.product_type}` }]}
                                      >
                                        {
                                          field?.product_type == "Select Store Product" ?
                                          <Select
                                            placeholder={`${field?.product_type}`}
                                            filterOption={false}
                                            showSearch
                                            onSearch={handleProductSearch}
                                            onChange={(id: any) => handleSelectProductChange(id, index, field?.id)}
                                            value={`Code:${field?.Product_code}:${field?.name}`}
                                          >
                                            { [...allProductSearch]?.map((product: any) => {
                                              return <Option key={product?.id}>
                                                  <div className="drop_list">
                                                    <p>{`Code:${product?.referencecode}:${product?.name}`}</p>
                                                    {custome_modify &&
                                                    <p>{`Stock:${field?.stock}`}</p>}
                                                  </div>
                                                </Option>
                                            })}
                                          </Select>
                                          :
                                          <Select
                                            placeholder={`${field?.product_type}`}
                                            filterOption={false}
                                            showSearch
                                            onSearch={handleFavouriteProductSearch}
                                            onChange={(id: any) => handleSelectFavProductChange(id, index, field?.id)}
                                            value={`Code:${field?.Product_code}:${field?.name}`}
                                          >
                                          {uniqueData.map((category: any) => (
                                            <OptGroup key={category?.product?.categories} label={category?.product?.categories}>
                                              {favListItems.map((fav: any) =>
                                                fav?.product?.categories === category?.product?.categories ? (
                                                  <Option key={fav?.product?.id}>
                                                    <div className="drop_list">
                                                      <p>{`Code:${field?.Product_code}:${fav?.product?.name}`}</p>
                                                      <p>{`Stock:${field?.stock}`}</p>
                                                    </div>
                                                  </Option>
                                                ) : null
                                              )}
                                            </OptGroup>
                                          ))}
                                          </Select>
                                        }
                                      
                                      </Form.Item>
                                    </div>
                                    <div className="col-5">
                                      <Form.Item label="Quantity"
                                      // name={[index, "quantity"]}
                                      required = {false}
                                      rules={[
                                        { required: true, message: "Please enter a quantity" },
                                        {
                                          validator(_, value) {
                                            if(!value){
                                              return Promise.resolve()
                                            }
                                            if (value <= 0) {
                                              return Promise.reject(new Error("Quantity can't be zero"));
                                            }
                                            return Promise.resolve();
                                          },
                                        },
                                      ]}
                                      >
                                        <Input
                                          type="number"
                                          min={1}
                                          max={field?.quantity}
                                          value={field?.neededquantity}
                                          disabled={field == undefined}
                                          onChange={(e: any) => handleQuantityChangeInput(e.target.value, field?.id, field?.quantity)}
                                        />
                                      </Form.Item>
                                    </div>
                                    {custome_modify &&
                                    <div className="col-1">
                                      <DeleteOutlined className="btn-del" onClick={()=>deleteSelectedProduct(field, index)}/>
                                    </div>
                                    }
                                  </div>
                                  {custome_modify &&
                                  <div className="row">
                                    <div className="col-6">
                                      <Form.Item
                                        label = {"Comment"}
                                      >
                                        <TextArea autoSize={{ minRows: 3, maxRows: 4 }} style={{ width: "100%", resize: "none" }} onChange={(e)=>handelMessaage(e, "comment", field?.id)} value={field?.comment}/>
                                      </Form.Item>
                                    </div>
                                    <div className="col-6">
                                      <Form.Item
                                        label ={"Internal Description"}
                                      >
                                        <TextArea autoSize={{ minRows: 3, maxRows: 4 }} style={{ width: "100%", resize: "none" }}  onChange={(e)=>handelMessaage(e, "description", field?.id)} value={field?.description}/>
                                      </Form.Item>
                                    </div>
                                  </div>
                                  }
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </section>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button style={{ marginInlineEnd: "10px" }} onClick={handelClose} type="default">
                    Cancel
                  </Button>
                  <Button style={{ marginInlineStart: "10px" }} type="primary"
                    onClick={() => setPageContent("Checkout Page")}
                    disabled={customerId == null || selectedProducts?.length == 0 || customerId?.length === 0}
                  >
                    Checkout
                  </Button>
                </div>
              </div>
              :
              <div>
                <div className="d-flex justify-content-end" style={{marginRight:"16px"}}>
                  {/* <span className="fontStyle6 float-md-left mr-2">Invoice Preview</span> */}
                  <Tooltip title = {"Invoice Preview"}>
                    <span className="float-md-left" style={{marginLeft: '10px', fontSize: "20px", marginBottom: "10px"}}>   <PrinterOutlined onClick={handlePreview} /></span>
                  </Tooltip>
                </div>
                <Row>
                  <Col>
                    <div className="addressArea col-md-6 text-center">
                      <span className="fontStyle6 float-md-left">Order Id :</span>
                      <span className="float-md-left p-6">
                        {" "}
                        <span>{`${OrgName} - ${newOrderId}`}</span>
                      </span>
                    </div>
                    <div className="addressArea col-md-6 text-center">
                      <span className="fontStyle6 float-md-left">Customer :</span>
                      <span className="float-md-left p-6">
                        {" "}
                        <span>{customerId?.name}</span>
                      </span>
                    </div>
                    <div className="addressArea col-md-6 text-center">
                      <span className="fontStyle6 float-md-left">Address :</span>
                      <span className="float-md-left p-6">
                        {" "}
                        <span>
                        {( customerId?.address === "empty" ? '' : customerId?.address)} {" "}
                        {( customerId?.address_line_2 === "empty" ? '' : customerId?.address_line_2)} {" "}
                        {( customerId?.city === "empty" ? '' : customerId?.city)}{" "}
                        {( customerId?.country === "empty" ? '' : customerId?.country)} {" "}
                        {(customerId?.postal_code === "empty" ? '' : customerId?.postal_code)}
                        </span>
                      </span>
                    </div>
                    <div className="addressArea col-md-6 text-center">
                      <span className="fontStyle6 float-md-left">Total :</span>
                      <span className="float-md-left p-6">
                        {" "}
                        <span>£ {grandTotal?.toFixed(2)}</span>
                      </span>
                    </div>
                  </Col>
                  {!custome_modify_demo && (
                  <Col>
                  <div style={{display: "flex", flexDirection:"column", justifyContent: "flex-end", marginRight: "16px"}}>
                    {!menuPermission ?
                      <Form.Item
                      label="Select Trip"
                      name="trip"
                    >
                      <Select
                        allowClear
                        clearIcon="*"
                        showSearch
                        placeholder="Search Trip Name"
                        filterOption={false}
                        onSearch={handleTripSearch}
                        onChange={handleTripChange}
                      >
                        {allTrips?.map((data: any) => {
                          const givenDateTime = new Date(data?.due_time);
                          const currentDateTime = new Date();
                          if(givenDateTime > currentDateTime){
                        const findRoute = selectProps?.options?.find((res:any) => res?.value == data?.route_id)
                          return (
                            <Option key={data?.id}>
                              <div
                                style={{ display: "flex",
                                alignItems: "center" }}
                              >
                                <div style={{ display: "flex", justifyContent: "space-between" ,alignItems: "center" }}>
                                  <BlockOutlined /> {" "} {data?.name}-
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" ,alignItems: "center" }}>
                                
                                  {findRoute?.label}-
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" ,alignItems: "center" }}>
                                
                                  <DateField value={data?.date} format="MMM DD" />-
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" ,alignItems: "center" }}>
                                  {data?.seq_number ? data?.seq_number?.toString().padStart(2,'0') : "00"}
                                </div>
                              </div>
                            </Option>
                          );
                          }
                        })}
                      </Select>
                      </Form.Item>
                      :
                      <>
                        <div className="d-flex justify-content-end">
                          <span className="fontStyle6 float-md-left" style={{marginRight: "10px"}}>No. of products:   </span>
                          <span className="float-md-left">   {selectedProducts?.length}</span>
                        </div>
                      </>
                    }
                  </div>
                  </Col>
                )}
                  {/* <Col>
                  <div className="d-flex justify-content-end ">
                        <span className="fontStyle6 float-md-left mr-2">Invoice Preview</span>
                        <span className="float-md-left" style={{marginLeft: '10px'}}>   <PrinterOutlined onClick={handlePreview} /></span>
                      </div>
                  </Col> */}
                </Row>
                <div>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="ordertable" isDropDisabled = {custome_modify ? false : true} >
                      {(provided) => (
                        <Table 
                          {...provided.droppableProps}
                          columns={coloums} 
                          dataSource={selectedProducts}  
                          ref={provided.innerRef}
                          rowKey="id"
                          components = {{
                            body: {
                              row: (props: any) => {
                                
                                return(
                                  <Draggable
                                    draggableId={String(props["data-row-key"]?.toString())}
                                    index={props["children"]?.[0]?.props?.index}
                                    isDragDisabled = {custome_modify ? false : true}
                                  >
                                    {(provided) => {
                                      return(
                                      <tr
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        className={`${custome_modify ? selectedProducts?.length > 0 &&'content-row' : ''}`}
                                      >
                                        {props.children}
                                      </tr>
                                    )}}
                                  </Draggable>
                                )
                              }
                            }
                          }}
                          pagination = {false}
                          className="ordercheckout_table"
                        />
                      )}
                      </Droppable>
                    </DragDropContext>
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button style={{ marginInlineEnd: "10px" }} onClick={() => setPageContent("Product Select")} type="default">
                    Back
                  </Button>
                  <Button style={{ marginInlineStart: "10px" }} type="primary"
                    onClick={() => handleSaveOpen()}
                    // htmlType="submit"
                    disabled={selectedProducts?.length == 0}
                  >
                    Save
                  </Button>
                </div>
              </div>
            }
          </div>

        </Form>
        {(customerId && custome_modify) &&
          (<div className="order_alert_box">
            <div className="order_alert_content">
              <div className="order_content_head">
                <h5>Custom Alert</h5>
                <div className="alert_btn">
                  <EditOutlined className="edit_btn" onClick={()=>handleEditButtonClick()} />
                  { editBtnShow &&<CheckOutlined className="tick_btn"  onClick={()=>handleAlertUpdate()}/>}
                </div>
              </div>
              <Paragraph
                editable ={{
                  enterIcon: null,
                  editing: isEditing,
                  onChange: handleChangeMessage,
                  triggerType: []
                }}
                className="alert_mgs"
              >
                {alertMessage}
              </Paragraph>
            </div>
          </div>)
        }

      </Modal>
      <Modal visible={openModal}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleOk}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully created Order
          </span>
        </div>
      </Modal>
      <Modal visible={openOrderSave}
        onCancel={handleFinalSaveClose}
        footer={null}
      >
        <div>
          <span>Do you want to create order for <strong>{customerId?.name} </strong></span>
        </div>
        <ModalFooter>
        </ModalFooter>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button style={{ margin: "10px", backgroundColor: "red", color: "white", borderRadius: "10px" }} onClick={handleFinalSaveClose}>No</Button>
          <Button style={{ margin: "10px", backgroundColor: "#4EA144", color: "white", borderRadius: "10px" }} onClick={createOrder}>Yes</Button>
        </div>
      </Modal>
      <Modal visible={openAlert}
        // onCancel={handleFinalSaveClose}
        closable = {false}
        footer={null}
        width={"25%"}
      >
        <div style={{display: "flex", justifyContent: "center"}}>
          <span><strong>Do you want to add products from the TBC list to this order?</strong></span>
        </div>
       
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px"}}>
          <Button style={{ margin: "10px", backgroundColor: "red", color: "white", borderRadius: "10px" }} onClick={()=> setOpenAlert(false)} >No</Button>
          <Button style={{ margin: "10px", backgroundColor: "#4EA144", color: "white", borderRadius: "10px" }} onClick={()=> {setTbcModal(true) 
            setOpenAlert(false)}} >Yes</Button>
        </div>
      </Modal>
      <Modal visible = {favModal}
        onCancel={()=> setFavModal(false)}
        footer = {null}
        closable = {false}
        width={"40%"}
      >
        <div className="continer fav_modal_content">
          <p>Do you want to add these products to the Favourite List?</p>
        </div>
        <ModalFooter>
          <div className="fav_Modal_footer">
            <Button type="primary" className="fav-btn_save"  onClick={handeSaveFavProduct}>
              Ok
            </Button>
            <Button type="default" className="fav-btn_cancel"  onClick={()=>{setFavModal(false)
              setOpenOrderSave(true)
            }}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal visible = {newFavItemModal}
        onCancel={()=> setNewFavItemModal(false)}
        footer = {null}
        title = {"Select the Product"}
        width={"30%"}
      >
        <div className="continer">
          <Checkbox.Group onChange={handelChangeFav} style={{display: "flex", flexDirection: "column", gap: "5px"}}>
            {addFavItem?.map((fav: any) => {
              return <Checkbox key={fav?.product_id} value={fav?.product_id}>{fav?.product_name}</Checkbox>
            })}
          </Checkbox.Group>
          
        </div>
        <ModalFooter>
          <div style={{marginTop: "10px", display: "flex", justifyContent: "center"}}>
            <Button type="primary"  onClick={addNewFav}>
              Add Product
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal visible = {favListModal}
        onCancel={()=> setFavListModal(false)}
        footer = {null}
        title = {"Select the Favorite List"}
        width={"30%"}
      >
        <div className="continer">
          <Radio.Group onChange={handelChangeFavList} style={{display: "flex", flexDirection: "column", gap: "5px"}}>
            {favlist?.map((fav: any) => {
              return <Radio key={fav?.id} value={fav?.id}>{fav?.name}</Radio>
            })}
          </Radio.Group>
          
        </div>
        <ModalFooter>
          <div style={{marginTop: "10px", display: "flex", justifyContent: "center"}}>
            <Button type="primary"  onClick={handelOpenFav}>
              Next
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal visible={tbcModal}
        onCancel={handleTbc}
        footer={null}
        width={"25%"}
      >
        <div className="continer">
          <Table columns={tbcColoums} dataSource={tbcItem} rowSelection={rowSelection} pagination ={false} rowKey = "id"/>
        </div>
        <ModalFooter>
          <div style={{marginTop: "10px"}}>
            <Button type="primary" onClick={addTbcProduct}>
              Add Product
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal 
        visible = {preview}
        onCancel={handleClosePreview}
        footer={null}
        width={"70%"}
        className="order_preview"
      >
        <div className="order_preview_box">
          <InvoicePreview allTaxes = {allTaxes} orderItems = {selectedProducts} customer = {customerId} order = {`${OrgName} - ${newOrderId}`}/>
        </div>
      </Modal>
    </>
  );
};