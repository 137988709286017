
import { IResourceComponentsProps,useExport, CrudFilters, HttpError, useGetIdentity } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    DatePicker,
    Button,
    Card,
    Input,
    Form,
    FormProps,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons
} from "@pankod/refine-antd";
import { IOrderStatus } from "interfaces";
import { CreateOrderStatus } from "./create";
import { EditOrderStatus } from "./edit";
const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined, CheckOutlined, CloseOutlined } = Icons;


const { RangePicker } = DatePicker;

export const OrderStatusList: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IOrderStatus>({
        resource: "order_statuses",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
        ],
        metaData: {
            fields: ["id", "name", "sort", "bg_color", "ob_org_id", "notify_customer"],
        },
    });

    const { formProps, modalProps, show      } = useModalForm({
        resource: "order_statuses",
        action: "create",
        redirect: false,
        
        successNotification:{
            key: "j",
            message: "string",
            type: "success",
            description: "j"
        },
        onMutationSuccess:()=>{

            return false

        }
         
     });

     console.log("formProps",formProps)
      
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,

    } = useModalForm({
        resource: "order_statuses",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "name", "sort", "bg_color", "ob_org_id", "notify_customer"],
        },
    });

    const { triggerExport, isLoading } = useExport<IOrderStatus>();
    let datacount = tableQueryResult?.data?.total;


    return (
        <List
            title="Order Status"
            pageHeaderProps={{

                extra: [
                    //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                    <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" resourceName="settings/orderstatuses" children="Add Order Status" icon={<PlusOutlined />} onClick={() => show()} />,
                ],

            }}
        >
            <span>Records: {datacount}</span>
            <Table {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="name" title="Name" />
                <Table.Column dataIndex="bg_color" title="Status Color" />
                <Table.Column dataIndex="notify_customer" title="Notify Customer"
                    render={(value) => (value == true ? <CheckOutlined style={{ color: '#6ead65' }} /> : <CloseOutlined style={{ color: '#ff7875' }} />)}
                />
                <Table.Column dataIndex="sort" title="Sort" />
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<IOrderStatus>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                resourceName="settings/orderstatuses"
                                onClick={() => editShow(record.id)}
                            />
                            <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                resourceName="settings/orderstatuses"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            <CreateOrderStatus modalProps={modalProps} formProps={formProps} />
            <EditOrderStatus modalProps={editModalProps} formProps={editFormProps} />

        </List>
    );
};
