import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useExport, useList, useNotification } from "@pankod/refine-core";
import { Editor } from '@tinymce/tinymce-react';
import { Col, Row, DatePicker } from 'antd';
import {
  List,
} from "@pankod/refine-antd";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct, IPrice, ICountry, ICustomerGroup, AccountStatus } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { nhost } from "utility/nhost";
import { GET_ALL_CUSTOMER_GRPS_WITH_ORGID, GET_ALL_PAYMENT_OPTIONS, UPDATE_CUSTOMER_GROUPS } from "query";
import { jsonConverter } from "components/helpers/jsonConverter";
import './style.css'

type CreateCustomerProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeCreateModal: () => void
  createModalVisible: boolean,
  handleFormSubmitted: () => void,
  tableQueryResult: any
};

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;

const onChange = (key: string) => {
};

export const CreateCustomer: React.FC<CreateCustomerProps> = ({
  modalProps,
  formProps,
  tableQueryResult,
  handleFormSubmitted,
  closeCreateModal,
  createModalVisible
}) => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();

  const [checkAddress, setCheckAddress] = useState(false);
  const [checkboxEmail, setCheckboxEmail] = useState(false);
  const [checkboxActive, setCheckboxActive] = useState(false);
  const [checkboxDisableOrder, setCheckboxDisableOrder] = useState(false);
  const [checkboxOrderXls, setCheckboxOrderXls] = useState(false);
  const [checkboxOrderPdf, setCheckboxOrderPdf] = useState(false);
  const [checkboxReceiveEmail, setCheckboxReceiveEmail] = useState(false);
  const [specifyActivity, setSpecifyActivity] = useState("");
  const [opened, setOpened] = useState(false);
  const [countryOption, setCountryOption] = useState("");
  const [countryOptionData, setCountryOptionData] = useState([] as any[]);
  const [countryOptionValue, setCountryOptionValue] = useState("");
  const [countryVariation, setCountryVariation] = useState([]);
  const [disableOrderList, setDisableOrderList] = useState<any>();
  
  const [customerGrps, setCustomerGrps] = useState<any>([]);
  const [selectedCusGrp, setSelectedCusGrp] = useState<any>([]);
  const [onlinePay, setOnlinePay] = useState<any>(false);
  const [paymentOptions, setPaymentOptions] = useState<any>([]);
  const [statusCode, setStatusCode] = useState<any>();

  useEffect(()=>{
    setStatusCode(formProps?.initialValues?.sage_acc_status_number)
  },[formProps])
  

  const userId = user?.metadata?.org_id;

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: priceListSelectProps } = useSelect<IPrice>({
    resource: "pricelists",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });


  const { data: tableData } = useList<AccountStatus>({
    resource: "customer_account_status",
    metaData: {
      fields: ["id", "account_status_name", "sage_status_code"],
    }
  });

  const { selectProps: priceListAdminSelectProps } = useSelect<IPrice>({
    resource: "pricelists",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: privacyGroupListSelectProps } = useSelect<ICustomerGroup>({
    resource: "customer_groups",
    fetchSize: 1000,
    optionLabel: "group_name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    metaData: {
      fields: ["id", "group_name"],
    },
    sort: [{ field: "group_name", order: "asc", },]
  });

  const { selectProps: privacyGroupAdminSelectProps } = useSelect<ICustomerGroup>({
    resource: "customer_groups",
    fetchSize: 1000,
    optionLabel: "group_name",
    optionValue: "id",
    metaData: {
      fields: ["id", "group_name"],
    },
    sort: [{ field: "group_name", order: "asc", },]
  });

  const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });



  // const { selectProps: countrySelectProps } = useSelect<ICountry>({
  //   resource: "countries",
  //   fetchSize: 1000,
  //   optionLabel: "name",
  //   optionValue: "id",
  //   metaData: {
  //     fields: ["id", "name"],
  //   },
  //   sort: [{ field: "name", order: "asc", },]
  // });


  const handleOk = () => {
    setOpened(false)
  }

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setCheckAddress(e.target.checked);
  };
  const onChangeCheckboxEmail = (e: CheckboxChangeEvent) => {
    setCheckboxEmail(e.target.checked);
  };
  const onChangeCheckboxActive = (e: CheckboxChangeEvent) => {
    setCheckboxActive(e.target.checked);
  };
  const onChangeCheckboxDisableOrder = (e: CheckboxChangeEvent) => {
    setCheckboxDisableOrder(e.target.checked);
  };
  const onChangeCheckboxOrderXls = (e: CheckboxChangeEvent) => {
    setCheckboxOrderXls(e.target.checked);
  };
  const onChangeCheckboxOrderPdf = (e: CheckboxChangeEvent) => {
    setCheckboxOrderPdf(e.target.checked);
  };
  const onChangeCheckboxReceiveEmail = (e: CheckboxChangeEvent) => {
    setCheckboxReceiveEmail(e.target.checked);
  };

  // const onChangeCheckboxDisableOrderList = (e: CheckboxChangeEvent) => {
  //   setDisableOrderList(e.target.checked);
  // };

  
  const getAllCustomerGrps = () => {
    GET_ALL_CUSTOMER_GRPS_WITH_ORGID(userId).then((res:any) => {      
      setCustomerGrps(() => []);
      setCustomerGrps(res?.customer_groups);
    })
  }
  
  const getAllPaymentOptions = () => {
    GET_ALL_PAYMENT_OPTIONS(userId).then((res:any) => {      
      setPaymentOptions(() => []);
      setPaymentOptions(res?.payment_options);
    })
  }
  useEffect(() => {
    if(userId){
      getAllCustomerGrps();
      getAllPaymentOptions();
    }
  },[userId])

  const apidata = (e: any) => {
    const getQuery = `query MyQuery {
      ob_orgs(where: {email: {_eq: "${e.email}"}})
      {
        id
      }
    }`;
    nhost.graphql.request(getQuery)
      .then((response: any) => {
 
        if (response?.data?.ob_orgs[0]?.id) {
          createCustomer(e, response?.data?.ob_orgs[0]?.id);
        } else {
          createOrg(e);
          // createCustomer(e, response?.data?.ob_orgs[0]?.id);
        }
      })
  }

  const createOrg = (e: any) => {
    let createdBy = user?.id;
    let address = e.address ?? "";
     const MUTATIONAPI = `mutation MyMutation {
        insert_ob_orgs(objects: { name: "${e.name}",email: "${e.email}",address: "${address}",created_by:"${createdBy}"}) {
          returning{
            id
            name
            address
          }
        }
      }
  `
    nhost.graphql.request(MUTATIONAPI)
      .then((response: any) => {
        let customer_org_id = response?.data?.insert_ob_orgs?.returning?.[0]?.id;
        createCustomer(e, customer_org_id);
      })
  }
  const createCustomer = (e: any, orgId: any) => {    
    
    let ob_org_id = user?.metadata?.org_id;
    let createdBy = user?.id;
    let specifyActivity = e?.specify_activity ?? " ";
    let website = e?.website ?? " ";
    let notifyCustomerEmail = e?.notify_customer_email ?? "false";
    let disableOrdering = e?.disable_ordering ?? "false";
    let discount_rate = e?.discount_rate ?? 0;
    let adminDescription = e?.admin_description ?? " ";
    let customerReferenceCode = e?.customer_reference_code ?? " ";
    let addressLine2 = e?.address_line_2 ?? " ";
    let privacyGroup = e?.privacy_group ?? " ";
    let minOrderValue = e?.min_order_value ?? 0;
    let postalCode = e?.postal_code ?? " ";
    let vatNumber = e?.vat_number ?? " ";
    let address = e.address ?? "";
    let pricelist = e.Price_list ?? "";
    let city = e.city ?? " ";
    let province = e.province ?? " ";
    let country = e.country ?? " ";
    let companyNumber = e.company_number ?? null;
    let phone = e.phone ?? null;

    const isActiveArg = e?.is_active !== undefined ? `is_active: ${e?.is_active},` : "";
    const CUSTOMERMUTATIONAPI = `mutation MyMutation {
    insert_customers(objects: { ob_org_id: "${ob_org_id}",customer_org_id: "${orgId}",created_by:"${createdBy}"
  name:"${e.name}",address:"${address}", activity:"${e.activity}",
  specify_activity:"${specifyActivity}",email:"${e.email}",price_list:"${pricelist}",address_line_2:"${addressLine2}",
  city:"${city}",province:"${province}",country:"${country}",website:"${website}",customer_reference_code:"${customerReferenceCode}",
  privacy_group:"${privacyGroup}",vat_number:"${vatNumber}",admin_description:"${adminDescription}",
  notify_customer_email:"${notifyCustomerEmail}",${isActiveArg},disable_ordering:"${disableOrdering}"
  company_number:"${companyNumber}",phone:"${phone}", discount_rate:"${discount_rate}",min_order_value:"${minOrderValue}",postal_code:"${postalCode}",
  payment_options: ${e.enable_pay_now == true ? jsonConverter(e.payment_options) : null},
  enable_pay_now: ${e.enable_pay_now != undefined || null ? `"${e.enable_pay_now}"` : false},
  is_show_price_list: ${e.is_show_price_list != undefined || null ? `"${e.is_show_price_list}"` : false}

}) {
  returning{
    id
    ob_org_id
    customer_org_id
    name
    fullname
    address
    email
    price_list
  }
}
}
`
    nhost.graphql.request(CUSTOMERMUTATIONAPI)
      .then((response: any) => {
         localStorage.setItem('customer_org_id', response?.data?.insert_customers?.returning[0]?.customer_org_id);
         const customerGrpUpdate = () => {
          const findCusGrp = customerGrps?.filter((grp:any) => {
            const addCusId = selectedCusGrp?.find((slect:any) => {
              if(slect == grp?.id){
                const cusGrpId = grp?.id
                const idToAdd = response?.data?.insert_customers?.returning[0]?.id
                const existingIdsSet:any = new Set(grp.customer_ids.split(","));
                existingIdsSet.add(idToAdd);
                const updatedCustomerIds = [...existingIdsSet].join(",");
                UPDATE_CUSTOMER_GROUPS(cusGrpId, updatedCustomerIds)
                .then(() => {
                })
                .catch((error:any) => {
                  console.error("Error updating customer_ids:", error.message);
                });
              }
            });
          })
        }
        customerGrpUpdate();
        setOpened(true)
        setTimeout(() => {
          setOpened(false)
        }, 4000);
        tableQueryResult?.refetch()
        // closeCreateModal()
      });
    // tableQueryResult?.refetch()
    closeCreateModal()
    formProps?.form?.resetFields();
  }



  const onChangeHandle = (e: any) => {
    setSpecifyActivity(e)
  }

  const { Option } = Select;

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 75 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const handleSelectChange = (value: any) => {
    let copyValue: any = [...value]?.filter((e) => e);
    setSelectedCusGrp(copyValue);
  };

  const handleProductSearch = (customerGrpsearch: string) => {

    if (customerGrpsearch?.length > 0) {
      const filterProduct = customerGrps?.filter((cusGrp: any) => {
        return cusGrp?.group_name?.toLowerCase().includes(customerGrpsearch?.toLocaleLowerCase())
      })
      if (filterProduct?.length != 0) {
        setCustomerGrps([...filterProduct])
      }
      else {
        getAllCustomerGrps();
      }
    } else {
      getAllCustomerGrps();
    }
  };

  const handlePayOnline = () => {    
    setOnlinePay(!onlinePay)
  }

  const validateCompanyName = (_:any, value:any) => {
    if (!/^[\w+.-]+@\w+(\.\w+)*(\s*,\s*[\w+.-]+@\w+(\.\w+)*)*$/.test(value)) {
      return Promise.reject('Please enter valid email address');
    }
    return Promise.resolve();
  };

  const handleSelect = (e:any)=>{
    const filteredStatus = tableData?.data?.filter((param:any) => param?.sage_status_code === e);
  
    if (filteredStatus) {
        setStatusCode(filteredStatus[0]?.sage_status_code);
    } else {
        setStatusCode(""); // handle the case when no matching status is found
    }
  }

  return (
    <>
      <Modal {...modalProps} title="Create Customer"
        onCancel={closeCreateModal}
        visible={createModalVisible}
      >
        <Form
          {...formProps} onFinish={(e) => {
            apidata(e)
          }}
          wrapperCol={{ span: 23 }}
          autoComplete="off"
          layout="vertical"
        >
          <Tabs defaultActiveKey="1" onChange={onChange}>
            <TabPane tab="Customer details" key="1">
              <Row>
                <Col span={12}>

                  <Form.Item
                    name="name"
                    label="Customer Name"
                    rules={[{ required: true, message: 'Please input your customer name!' }]}>

                    <Input placeholder="Enter customer name" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>
                  {/* <Form.Item
                    name="fullname"
                    label="Full Name"
                    rules={[{ required: true, message: 'Please input your full name!' }]}>

                    <Input placeholder="Enter full name" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item> */}

                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label="Activity"
                        name="activity"
                        rules={[{ required: true }]}
                        style={{ width: "100%" }}
                      >
                        <Select placeholder="Select Activity" onChange={onChangeHandle}>
                          <Select.Option value="Others" name="activity" >Others</Select.Option>
                          <Select.Option value="Retailer" name="activity" >Retailer</Select.Option>
                          <Select.Option value="Distributor" name="activity" >Distributor</Select.Option>
                          <Select.Option value="Vip" name="activity" >Vip</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="specify_activity"
                        label="Specify activity"
                        rules={specifyActivity === "Others" ? [{ required: true, message: 'Please input your specify activity!' }] : [{}]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true },{ validator: validateCompanyName },]}
                    // rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                    <Input placeholder="Enter email address" onChange={(e) => onChange} style={{ width: "100%" }} type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" />
                  </Form.Item>
                  <Form.Item name="notify_customer_email" valuePropName="checked" initialValue={true}>
                    <Checkbox checked={checkboxEmail} onChange={onChangeCheckboxEmail}>Notify customer by email</Checkbox>
                  </Form.Item>
                  <Form.Item name="is_active" valuePropName="checked" initialValue={true}>
                    <Checkbox checked={checkboxActive} onChange={onChangeCheckboxActive} >Is active</Checkbox>
                  </Form.Item>
                  <Form.Item name="disable_ordering" valuePropName="checked">
                    <Checkbox checked={checkboxDisableOrder} onChange={onChangeCheckboxDisableOrder} >Disable Ordering</Checkbox>
                  </Form.Item>
                  <Form.Item  name="is_show_price_list" valuePropName="checked">
                  <Checkbox  >Enable PriceList</Checkbox>
                </Form.Item>
                  {user?.defaultRole === "admin" ?
                    <>
                      <Form.Item
                        name="Price_list"
                        label="Price List"
                        // rules={[{ required: true, }]} 
                        >
                        <Select {...priceListAdminSelectProps} />
                      </Form.Item>
                      <Form.Item name="is_active" valuePropName="checked"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                      >
                        <Checkbox checked={checkboxActive} onChange={onChangeCheckboxActive}
                        >Is active</Checkbox>
                      </Form.Item>
                      <Form.Item name="disable_ordering" valuePropName="checked">
                        <Checkbox checked={checkboxDisableOrder} onChange={onChangeCheckboxDisableOrder} >Disable Ordering</Checkbox>
                      </Form.Item>
                    </> : ""
                  }
                  {/* <Form.Item
                  label="Price list"
                  name="price_list"
                  rules={[{ required: true }]}
                  style={{ width: "100%" }}
                >
                  <Select defaultValue="Base price list" onChange={onChange}>
                    <Select.Option value="Base price list" name="price_list" >Base price list</Select.Option>
                    <Select.Option value="Discounted price list(Based on Basic price list)" name="price_list" >Discounted price list(Based on Basic price list)</Select.Option>
                    <Select.Option value="VIP price list" name="price_list" >VIP price list</Select.Option>
                    <Select.Option value="Wholesale price list" name="price_list" >Wholesale price list</Select.Option>
                  </Select>
                </Form.Item> */}
                  {/* <Form.Item
                      name="discount"
                      label="Discount (%)"
                      rules={[{ required: true, message: 'Please input your discount!' }]}>

                      <InputNumber placeholder="Enter discount" onChange={(e) => onChange} style={{ width: "100%" }} />
                    </Form.Item> */}
                  <Form.Item
                    name="Price_list"
                    label="Price List"
                    // rules={[{ required: true, }]} 
                    >
                    <Select {...priceListSelectProps} />
                  </Form.Item>
                  <Form.Item
                    name="min_order_value"
                    label="Minimum order value"
                    rules={[]}>

                    <InputNumber placeholder="Enter minimum order value" min="0" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label="Phone"
                    // rules={[{ required: true, message: 'Please input your phone number!' }]}
                  >
                    <Input
                    className="custom-input" type="number"
                      addonBefore={prefixSelector}
                      maxLength={11}
                      min="0"     
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => onChange} 
                      style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item
                    name="address"
                    label="Address"
                    // rules={[{ required: true, message: 'Please input your address!' }]}
                    >

                    <Input placeholder="Enter address" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>
                </Col >
                <Col span={12}>
                  <Form.Item
                    name="address_line_2"
                    label="Address line 2"
                    // rules={[{ required: true, message: 'Please input your address line 2!' }]}
                    >

                    <Input placeholder="Enter address line 2" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item
                    name="city"
                    label="City"
                    // rules={[{ required: true, message: 'Please input your city!' }]}
                    >

                    <Input placeholder="Enter city" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="province"
                    label="Province"
                    // rules={[{ required: true, message: 'Please input your province!' }]}
                    >

                    <Input placeholder="Enter province" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    label="Country"
                    name="country"
                    // rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Select placeholder="Select Country" onChange={onChange}>
                      <Select.Option value="India" name="country" >India</Select.Option>
                      <Select.Option value="USA" name="country" >USA</Select.Option>
                      <Select.Option value="UK" name="country" >UK</Select.Option>
                      <Select.Option value="Dubai" name="country" >Dubai</Select.Option>
                      <Select.Option value="Canada" name="country" >Canada</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="postal_code"
                    label="Postal Code"
                    rules={[]}>

                    <Input placeholder="Enter postal code"
                    type="number"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}   
                    min="0"                 
                    onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="website"
                    label="Website"
                    rules={[
                      { 
                          required: true, 
                          message: 'Please input your website !' 
                      },
                        {
                          pattern: /^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                          message: 'Please enter a valid website',
                      },
                      
                  ]}
                  >
                    <Input placeholder="Enter website" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="company_number"
                    label="Company Number"
                    // rules={[{ required: true, message: 'Please input your postal company number!' }]}
                  >

                    <Input className="input-number-no-spinner" min="0" type="number" placeholder="Enter company number" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="vat_number"
                    label="VAT Number"
                    rules={[]}
                  >

                    <Input placeholder="Enter VAT Number" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="customer_reference_code"
                    label="Customer Reference Code"
                  // rules={[{ required: true, message: 'Please input your customer reference code!' }]}
                  >

                    <Input placeholder="Enter customer reference code" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item>

                  {/* <Form.Item
                  label="Parent Customer"
                  name="ob_org_id"
                  rules={[{ required: true, }]} >
                  <Select {...OBOrgSelectProps} />
                </Form.Item> */}

                  {/* <Form.Item
                    name="privacy_group"
                    label="Customer Groups"
                    rules={[]} >
                    <Select {...privacyGroupListSelectProps} />
                  </Form.Item> */}
                  <Form.Item
                    name="privacy_group"
                    label="Customer Groups"
                    rules={[]} >
                    {/* <Select {...privacyGroupListSelectProps} /> */}
                    <Select
                mode="multiple"
                showSearch
                style={{ width: "100%" }}
                placeholder=""
                onSearch={handleProductSearch}
                filterOption={false}
                defaultValue={selectedCusGrp}
                onChange={handleSelectChange}
              >
                {customerGrps?.map((e: any, i: any) => {
                  return <Option key={e?.id}>{e?.group_name}</Option>;
                })}
              </Select>
                  </Form.Item>

                  {/* <Form.Item
                    name="privacy_group"
                    label="Privacy Group"
                  // rules={[{ required: true, message: 'Please input your privacy group!' }]}
                  >

                    <Input placeholder="Enter privacy group" onChange={(e) => onChange} style={{ width: "100%" }} />
                  </Form.Item> */}
                </Col >
              </Row >
              <hr />
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="admin_description"
                    label="Admin Description"
                    rules={[]}
                  >
                    <TextArea rows={4} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
                  <Form.Item
                    name="custom_alert"
                    label="Custom Alert"
                    rules={[]}
                  >
                    <TextArea rows={4} style={{ width: "100%" }} />
                  </Form.Item>
                </Col> */}
              </Row>
            </TabPane >
            <TabPane tab="Payment Options" key="7">
            <Form.Item  name="enable_pay_now" valuePropName="checked">
                  <Checkbox onChange={() => handlePayOnline()}>Enable Online Pay Option</Checkbox>
                </Form.Item>
                {onlinePay ? 
                <Form.Item
                label="Payment type"
                name="payment_options"
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Select 
                placeholder="Select Payment type" 
                onChange={onChange}
                mode="multiple"
                >
                      {paymentOptions?.length > 0 ? 
                      paymentOptions?.map((pay:any) => {
                        return <Select.Option value={pay?.name} >{pay?.name}</Select.Option>
                      }) : <></>}
                    </Select>
                  </Form.Item>
                  : <></>}
            </TabPane>
            <TabPane tab="Customer Account Details" key="8">
              <Form.Item name="sage_acc_status_number" label="Sage Account Status" rules={[{required: true, message: "Please select the sage account status"}]}>
                <Select showSearch = {false} onChange={(e)=>handleSelect(e)}>
                  {
                    tableData?.data?.map((option: any, index: any)=>{
                      return(
                      <Option key={index} value={option?.sage_status_code}>
                        {option?.sage_status_code} - {option?.account_status_name}
                      </Option>)
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item name="credit_limit" label="Credit Limit">
                <Input type="text" readOnly />
              </Form.Item>
              <Form.Item name="credit_position" label="Credit Position">
                <Input type="text" readOnly />
              </Form.Item>
              <Form.Item name="total_invoice_amount" label="Total Invoice Amount">
                <Input type="text" readOnly />
              </Form.Item>
              <Form.Item name="total_credit_amount" label="Total Credit Amount">
                <Input type="text" readOnly />
              </Form.Item>
              <Form.Item name="total_payment_amount" label="Total Payment Amount">
                <Input type="text" readOnly />
              </Form.Item>
              <Form.Item name="total_balance_amount" label="Total Balance Amount">
                <Input type="text" readOnly />
              </Form.Item>
            </TabPane>
            <TabPane tab="Address" key="2">
              <div>
                <Form.List name="addresses">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <div>
                            <Form.Item
                              {...restField}
                              name={[name, 'address']}
                              label="Address Line 1"
                              rules={[{ required: true, }]} >
                              <Input placeholder="Address Line 1" />
                            </Form.Item>
                            {/* <Form.Item
                                {...restField}
                                name={[name, 'address_line_2']}
                                label="Address Line 2"
                                rules={[{ required: true, }]} >
                                <Input placeholder="Address Line 2" />
                              </Form.Item> */}
                          </div>
                          <div>
                            <Form.Item
                              {...restField}
                              name={[name, 'city']}
                              label="City Province"
                            >
                              <Input placeholder="city province" />
                            </Form.Item>
                            {/* <Form.Item
                                {...restField}
                                name={[name, 'city']}
                                label="City Province"
                              >
                                <Input placeholder="city province" />
                              </Form.Item> */}
                          </div>
                          <Form.Item
                            {...restField}
                            name={[name, 'country']}
                            label="Country"
                            rules={[{ required: true, }]} >
                            <Input placeholder="Country" />
                          </Form.Item>
                          <Form.Item
                            name="postal_code"
                            label="Postal Code"
                            rules={[{ required: true, message: 'Please input your postal code!' }]}>

                            <Input placeholder="Enter the phone postal code" type= "number" onChange={(e) => onChange} style={{ width: "100%" }} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'is_active']}
                            valuePropName="checked"
                          >
                            <Checkbox>Active</Checkbox>
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space >
                      ))}
                      <Form.Item>
                        <Button type="primary" onClick={() => add()} block icon={false} style={{ width: "16%" }}>
                        <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                          <PlusOutlined style={{marginRight: "5px"}}/> Add Address
                        </div>
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List >
              </div >
            </TabPane >
            {
              user?.defaultRole == "developer"?
                <>
                  <TabPane tab="Billing" key="3">
                    <Row>
                      <Col span={12}>
                        <Form.Item>
                          <Checkbox checked={checkAddress} onChange={onChangeCheckbox}>Same as contact details</Checkbox>
                        </Form.Item>
                        {(checkAddress == false) ?
                          <>
                            <Form.Item
                              name="address"
                              label="Address"
                              rules={[{ required: true, message: 'Please input your address!' }]}>

                              <Input placeholder="Enter address" onChange={(e) => onChange} style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item
                              name="address_line_2"
                              label="Address line 2"
                              rules={[{ required: true, message: 'Please input your address line 2!' }]}>

                              <Input placeholder="Enter address line 2" onChange={(e) => onChange} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                              name="city"
                              label="City"
                              rules={[{ required: true, message: 'Please input your city!' }]}>

                              <Input placeholder="Enter city" onChange={(e) => onChange} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                              name="province"
                              label="Province"
                              rules={[{ required: true, message: 'Please input your province!' }]}>

                              <Input placeholder="Enter province" onChange={(e) => onChange} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                              label="Country"
                              name="country"
                              rules={[{ required: true }]}
                              style={{ width: "100%" }}
                            >
                              <Select placeholder="Select Country" onChange={onChange}>
                                <Select.Option value="India" name="country" >India</Select.Option>
                                <Select.Option value="USA" name="country" >USA</Select.Option>
                                <Select.Option value="UK" name="country" >UK</Select.Option>
                                <Select.Option value="Dubai" name="country" >Dubai</Select.Option>
                                <Select.Option value="Canada" name="country" >Canada</Select.Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="postal_code"
                              label="Postal Code"
                              rules={[{ required: true, message: 'Please input your postal code!' }]}>

                              <Input placeholder="Enter postal code" onChange={(e) => onChange} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                              name="phone"
                              label="Phone"
                              rules={[{ required: true, message: 'Please input your phone number!' }]}>

                              <Input placeholder="Enter phone number" onChange={(e) => onChange} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                              name="name"
                              label="Company Name"
                              rules={[{ required: true, message: 'Please input your postal company name!' }]}>

                              <Input placeholder="Enter postal company name" onChange={(e) => onChange} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                              name="fullname"
                              label="Full Name"
                              rules={[{ required: true, message: 'Please input your postal full name!' }]}>

                              <Input placeholder="Enter postal full name" onChange={(e) => onChange} style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                              name="vat_number"
                              label="VAT Number"
                              rules={[{ required: true, message: 'Please input your VAT Number!' }]}>

                              <Input placeholder="Enter VAT Number" onChange={(e) => onChange} style={{ width: "100%" }} />
                            </Form.Item>
                          </> : ''
                        }
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Sales Rep" key="4">
                    <div>
                      <Form.List name="sales_rep">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item
                                  {...restField}
                                  name={[name, 'sales_rep_name']}
                                  label="Sales Rep"
                                  style={{ width: "200px" }}>
                                  <Select>
                                    <Select.Option value="Please select value" name="sales_rep" >Please select Sales Rep</Select.Option>
                                    `           </Select>
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'commission']}
                                  label="Commission (%)"
                                >
                                  <Input type="number" defaultValue="0.0" />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button type="primary" onClick={() => add()} block icon={false} style={{ width: "16%" }}>
                                <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                                 <PlusOutlined style={{marginRight: "5px"}}/> Add Sales Rep
                                </div>
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </div>
                  </TabPane>
                  <TabPane tab="Email Settings" key="5">
                    <Row>
                      <Col span={12}>
                        <Form.Item name="order_as_XLS" valuePropName="checked">
                          <Checkbox checked={checkboxOrderXls} onChange={onChangeCheckboxOrderXls}>Attach new order as XLS to email</Checkbox>
                        </Form.Item>
                        <Form.Item name="order_as_PDF" valuePropName="checked">
                          <Checkbox checked={checkboxOrderPdf} onChange={onChangeCheckboxOrderPdf}>Attach order as PDF to email</Checkbox>
                        </Form.Item>
                        <Form.Item
                          name="new_order_notification"
                          label="Email for new orders notification"
                          rules={[{ required: true, message: 'Please input your primary color!' }]}>

                          <Input onChange={(e) => onChange} style={{ width: "100%" }} />
                        </Form.Item>
                        <p>You can add more than one email separated by commas. For example email1@example.com,email2@example.com,email3@example.com</p>

                        <Form.Item name="receive_email_notify" valuePropName="checked">
                          <Checkbox checked={checkboxReceiveEmail} onChange={onChangeCheckboxReceiveEmail}>Receive email notifications</Checkbox>
                        </Form.Item>
                        <Form.Item
                          name="outgoing_email"
                          label="Bcc outgoing emails to customers"
                        // rules={[{ required: true, message: 'Please input your Bcc outgoing emails to customers!' }]}
                        >

                          <Input onChange={(e) => onChange} style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Customer homepage products" key="6">
                    <div>
                      <p>Products added in this section will appear in the customer homepage (when logged in) for quick ordering</p>
                      <Form.List name="customer_homepage_products">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item
                                  {...restField}
                                  name={[name, 'code']}
                                  label="Code"
                                  style={{ width: "200px" }}>
                                  <Input hidden />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'search_product']}
                                  label="Product"
                                >
                                  <Input placeholder="Search" />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                              </Space>
                            ))}
                            <Form.Item>
                              <Button type="primary" onClick={() => add()} block icon={false} style={{ width: "35%" }}>
                              <div style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                                 <PlusOutlined style={{marginRight: "5px"}}/> Add Customer Homepage Product
                                </div>
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </div>
                  </TabPane> </> : ""
            }
          </Tabs >
          <Form.Item name="created_by"
            initialValue={user?.id}
          >
            <Input type="hidden" />
          </Form.Item>
        </Form >
      </Modal >
      <Modal visible={opened}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleOk}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
          Customer has been created successfully
          </span>
        </div>
      </Modal>
    </>
  );
};
