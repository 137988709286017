import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity, useNotification } from "@pankod/refine-core";
// import {IOrderStatus} from "interfaces";
import { useState, useEffect } from "react";
import { nhost } from "utility";
import { GET_ALL_CUSTOMERS, GET_ALL_PRODUCTS, UPDATE_CUSTOMER_PRODUCTS_PRIVACY } from "query";
import { async } from "q";
const { TextArea } = Input;

type EditCustomergroupProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closePopup: () => void;
  editPopup: boolean;
};


export const EditCustomerGroup: React.FC<EditCustomergroupProps> = ({
  modalProps,
  formProps,
  closePopup,
  editPopup
}) => {
  const { open, close } = useNotification();

  console.log(formProps?.initialValues?.hide_product_ids, "formProps?.initialValues?.hide_product_ids");
  

  const customerId = formProps?.initialValues?.id
  const initialValues = {
    customer_ids: formProps?.initialValues?.customer_ids?.split(","),
    group_name : formProps?.initialValues?.group_name,
    created_by: formProps?.initialValues?.created_by,
    ob_org_id: formProps?.initialValues?.ob_org_id,
    pricelist_id: formProps?.initialValues?.pricelist_id,
    hide_product_ids: formProps?.initialValues?.hide_product_ids == "" || undefined || null ? null : formProps?.initialValues?.hide_product_ids?.split(","),
  }
  
  const { TabPane } = Tabs;
  const { Option } = Select;

  const { data: user } = useGetIdentity();
  const [notify, setNotify] = useState(false);
  const User:any = nhost.auth.getUser()
  const orgId = User?.metadata?.org_id;
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const { selectProps: priceListSelectProps } = useSelect({
    resource: "pricelists",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: orgId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const getCustomers = () => {
    GET_ALL_CUSTOMERS(orgId).then((data:any) => {
      if(data){        
        setCustomers(data?.customers)
      }
    })
  }

  const getProducts = () => {
    GET_ALL_PRODUCTS(orgId)
      .then((data: any) => {
        console.log(data, "products");
        setProducts(() => []);
        setProducts(data?.products);
        setIsLoading(false);
      }).catch((error: any) => {
        console.log(error, "error");
      })
  }

  useEffect(() => {
    getCustomers()
    getProducts()
  },[])

  const handleCustomerSearch = (search:any) => {
    const searchValue = customers?.filter((data:any,i:number) => {            
     return data?.name?.toLowerCase().includes(search?.toLowerCase())
    })
    if(searchValue?.length !== 0){
      setCustomers([...searchValue])
    }
    else{
      getCustomers()
    }
  }

  const handleProductSearch = (productsearch: string) => {

    if (productsearch?.length > 0) {
      setIsLoading(true);
      const filterProduct = products?.filter((prod: any) => {
        return prod?.name?.toLowerCase().includes(productsearch?.toLocaleLowerCase())
      })
      if (filterProduct?.length != 0) {
        setProducts([...filterProduct])
      }
      else {
        getProducts();
      }
    } else {
      getProducts();
    }
  };

  const onChange = (key: string) => {
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setNotify(e.target.checked);

  };

  const onFinish = async (data:any) => {
    console.log(data?.hide_product_ids,"editvalues");
    // const MUTATIONAPI = `mutation MyMutation {
    //   update_customer_groups(where: { id: { _eq: "${customerId}"} }
    //   ,_set: {
    //     created_by: "e613b876-ade3-44a9-b307-274712d190f5", 
    //     customer_ids: "${data?.customer_ids}", 
    //     group_name: "${data?.group_name}", 
    //     ob_org_id: "${User?.metadata?.org_id}",
    //     pricelist_id: ${data?.pricelist_id  == undefined || null ? `"${formProps?.initialValues?.pricelist_id}"` : `"${data?.pricelist_id}"`},
    //     hide_product_ids: ${data?.hide_product_ids  == undefined || null || ""  ? null : `"${data?.hide_product_ids}"` },
    //   }) {
    //     affected_rows
    //     returning {
    //       group_name
    //       id
    //     }
    //   }
    // }`;
    // if(data?.pricelist_id != null || data?.pricelist_id != undefined || data?.hide_product_ids){
    //   const customerIds = data?.customer_ids;
    //   const CUSTOMER_MUTATION:any = `mutation myMutation {
    //     update_customers(where: { id: { _in: [${customerIds.map((id:any) => `"${id}"`).join(",")}] } },
    //     _set: {
    //       price_list: "${data?.pricelist_id}",
    //       hide_product_ids: ${data?.hide_product_ids  == undefined || null || ""  ? null : `"${data?.hide_product_ids}"` },
    //     }){
    //       affected_rows
    //         returning {
    //           id
    //         }
    //       }
    //   }`

    // const queryValue  = await nhost.graphql.request(MUTATIONAPI);
    //   if (queryValue) {
    //     console.log(queryValue,"suces2");
    //   }
    //   else{
    //     console.log("error");   
    //   }

    //   const secondqueryValue  = await nhost.graphql.request(CUSTOMER_MUTATION);
    //   if (secondqueryValue?.error == null) {
    //     closePopup()
    //     console.log(secondqueryValue,"suces1");
    //     open?.({
    //       key: "success",
    //       type: "success",
    //       message: "",
          
    //       description: `${data?.group_name} has been updated`,
    //     });
    //     setTimeout(() => {
    //       close("success");
    //     }, 5000);
    //   }
    //   else{
    //     console.log("error");   
    //     open?.({
    //       key: "error",
    //       type: "error",
    //       message: "",
    //       description: `Something Went Wrong ${secondqueryValue?.error}`,
    //     });
    //     setTimeout(() => {
    //       close("error");
    //     }, 5000);
    //   }
    // }
      
    // if(data?.product_disable == true || data?.product_disable == false){
    //   const customerId = data?.customer_ids?.map((cusId:any) => {
    //     const hideProducts = data?.hide_product_ids?.map((id:any) => {
    //      return { customer_id: cusId, product_id: id,  created_by: "e613b876-ade3-44a9-b307-274712d190f5", ob_org_id: User?.metadata?.org_id,}
    //    })
    //    console.log(hideProducts,"hideProducts");
       
    //    const results:any = UPDATE_CUSTOMER_PRODUCTS_PRIVACY(cusId, { set: hideProducts, });
    //    console.log(results,"results");
    //    return hideProducts
    //  }) 
    //   console.log(customerId,"customerId");
      // }
  }


  console.log(products, "productsproducts");
  

  return (
    <Modal {...modalProps}
     title="Edit Customer Group"
     onCancel={closePopup}
     visible={editPopup}
     >
      <Form {...formProps}
        wrapperCol={{ span: 23 }}
        onFinish={onFinish}
        initialValues={initialValues}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Tabs>
          <TabPane tab="Group" key="1">
        <Form.Item
          name="group_name"
          label="Customer Group Name"
          rules={[{ required: true, message: 'Please input your name!' }]}>

          <Input placeholder="Enter the name" onChange={(e) => onChange} style={{ width: "100%" }} />
        </Form.Item>

        {user?.defaultRole === "admin" ?
          <Form.Item
            label="Orderbit Organisation"
            name="ob_org_id"
            style={{display: "none"}}
            rules={[{ required: true, }]} >
            {/* <Select {...OBOrgSelectProps} /> */}
          </Form.Item> :
          <Form.Item
            name="ob_org_id"
            style={{display: "none"}}
            initialValue={user?.metadata?.org_id}
          >
            <Input hidden />
          </Form.Item>
        }

        <Form.Item name="created_by"
          initialValue={user?.id}
          style={{display: "none"}}
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item 
        label="Select Customers"
        name="customer_ids"
        rules={[{ required: true, message: 'Please select Customers!' }]}
        >
          <Select
          mode="multiple"
          showSearch
          onSearch={handleCustomerSearch}
          // onChange={handleSelectedChange}
          filterOption={false}
          >
            {customers?.map((data:any) => {
              return <Option key={data?.id}>{data?.name}</Option>
            })}
          </Select>
        </Form.Item>
        </TabPane>
        <TabPane tab="Pricelist" key="2">
        <Form.Item
          label="Select Price List"
          name="pricelist_id"
          rules={[{ required: true, message: 'Please select Price List!' }]}
          >
           <Select {...priceListSelectProps} />
          </Form.Item>
        </TabPane>
        <TabPane tab="Product Activity" key="3">
        <Form.Item
              label="Hide Products"
              name="hide_product_ids"
            >
              <Select
                mode="multiple"
                showSearch
                style={{ width: "100%" }}
                placeholder="Please select"
                // value={selectedValues}
                // onChange={handleSelectChange}
                // onSelect={onSelects}
                onSearch={handleProductSearch}
                filterOption={false}
              >
                {[...products]?.map((e: any, i: any) => {
                  return <Option key={e?.id}>{e?.name}</Option>;
                })}
              </Select>
            </Form.Item>
        </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};
