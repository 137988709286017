import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber,
  Select,
  useSelect,
  Dropdown,
  Space,
  Tooltip,
  Menu,
  Checkbox,
  Popconfirm,
  Table,
} from "@pankod/refine-antd";
import { message } from 'antd';

import { Col, Row } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  useCreate,
  useGetIdentity,
  useNotification,
} from "@pankod/refine-core";
import { IOrderStatus, IOrg } from "interfaces";
import { useState } from "react";
const { TextArea } = Input;

type CreateOrderStatusProps = {
  modalProps: ModalProps;
  formProps: any;
  closePopup: () => void;
  createPopup: boolean;
};

const colors = [
  "#fff",
  "red",
  "blue",
  "#000",
  "green",
  "cadetblue",
  "orange",
  "pink",
  "yellow",
  "gray",
];

export const CreateOrderStatus: React.FC<CreateOrderStatusProps> = ({
  modalProps,
  formProps,
  closePopup,
  createPopup,
}) => {
  const { data: user } = useGetIdentity();
  const [notify, setNotify] = useState(false);

  console.log("formProps", formProps);
  const { mutate } = useCreate();

  const { open, close } = useNotification();

  const [selectedColor, setSelectedColor] = useState<string>("");

  const { selectProps: OBOrderStatusSelectProps } = useSelect<IOrderStatus>({
    resource: "order_statuses",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: [
        "id",
        "name",
        "sort",
        "bg_color",
        "ob_org_id",
        "notify_customer",
      ],
    },
    sort: [{ field: "name", order: "asc" }],
  });
  console.log("user data", user);
  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc" }],
  });

  const onChange = (key: string) => {};

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setNotify(e.target.checked);
  };

  const selectColor = (e: string) => {
    console.log(e, "color");
    setSelectedColor(e);
    formProps.form.setFieldsValue({ ["bg_color"]: e });

    console.log("props", formProps);
  };


  const onFinish = (e: any) => {
    console.log(
      "onfinaosdadsasd",
      e,
      user?.metadata?.org_id,
      user?.id,
      user?.id
    );

    mutate({
      resource: "order_statuses",
      values: {
        ...e,
        ob_org_id: user?.metadata?.org_id,
        created_by: user?.id,
        updated_by: user?.id,
     
      },
 
      successNotification: {
        key: "j",
        message: "",
        type: "success",
        description: "Order status has been created ",

      },
    });
    closePopup();
    formProps?.form?.resetFields();
  };
    
  return (
    <Modal {...modalProps} visible={createPopup} onCancel={closePopup} title="Create Order Status"
    >  
      <Form
        {...formProps}
        wrapperCol={{ span: 23 }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input
            placeholder="Enter the name"
            onChange={(e) => onChange}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "Please input Status Type!" }]}
        >
          <Select>
            <Select.Option value="order">Order</Select.Option>
            <Select.Option value="invoice">Invoice</Select.Option>
            <Select.Option value="quotation">Quotation</Select.Option>
          </Select>
        </Form.Item>

        {user && user.defaultRole === "admin" ? (
          <Form.Item
            label="Orderbit Organisation"
            name="ob_org_id"
            rules={[{ required: true }]}
          >
            <Select {...OBOrgSelectProps} />
          </Form.Item>
        ) : (
          <Form.Item name="ob_org_id" initialValue={user?.metadata?.org_id}>
            <Input hidden />
          </Form.Item>
        )}

        <Form.Item
          label="Preview"
          name="bg_color"
          rules={[{ required: true }]}
          style={{ width: "100%" }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {colors.map((color) => (
              <div
                key={color}
                style={{
                  backgroundColor: color,
                  borderRadius: "50%",
                  color: "#fff",
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                  marginBottom: "10px",
                  outlineColor: "black",
                  border: "1px solid black",
                  cursor: "pointer",
                  boxShadow:
                    selectedColor === color
                      ? "inset 0px 0px 0px 3px #acf2bf"
                      : "none",
                }}
                onClick={() => selectColor(color)}
              >
                {" "}
              </div>
            ))}
          </div>
        </Form.Item>

        <Form.Item
          name="notify_customer"
          style={{ marginTop: "20px" }}
          valuePropName="checked"
        >
          <Checkbox
            name="notify_customer"
            checked={notify}
            onChange={onChangeCheckbox}
          >
            Notify customer via email
          </Checkbox>
        </Form.Item>
        <Form.Item name="sort" label="Sort" style={{ marginTop: "20px" }} rules={[{ required: true }]}>
          <InputNumber
            placeholder="Enter the sort"
            defaultValue="0"
            onChange={(e) => onChange}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name="created_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
