import React from 'react';

export const FilterDate = (type: string) => {
  const now = new Date();
  const formatDateToISO = (date: Date) => {
    return date.toISOString();
  };

  switch (type) {
    case "LAST_7": {
      const endDate = new Date(now);
      const startDate = new Date(now);
      startDate.setDate(startDate.getDate() - 6); // Subtract 6 days to get the start date (including today makes it 7 days)
      return { startDate: formatDateToISO(startDate), endDate: formatDateToISO(endDate) };
    }

    case "PREVIEWS_WEEK": {
      const now = new Date();
      const startDate = new Date(now);
      const endDate = new Date(now);
  
      startDate.setDate(startDate.getDate() - startDate.getDay() - 7);
      endDate.setDate(startDate.getDate() + 6);
  
      return { startDate: formatDateToISO(startDate), endDate: formatDateToISO(endDate) };
    }  

    case "CURRENT_WEEK": {
      const startDate = new Date(now);
      const endDate = new Date(now);

      startDate.setDate(startDate.getDate() - startDate.getDay());
      endDate.setDate(startDate.getDate() + 6);

      return { startDate: formatDateToISO(startDate), endDate: formatDateToISO(endDate) };
    }

    case "CURRENT_MONTH": {
      const startDate = new Date(now.getFullYear(), now.getMonth(), 1); // First day of the current month
      const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the current month
      return { startDate: startDate, endDate: endDate };
    }
    
    // case "PREVIWES_CUSTOME_DATE": {
    //   const startDate = new Date(now.getFullYear(), now.getMonth(), 1); // First day of the current month
    //   const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the current month
    //   return { startDate: startDate, endDate: endDate };
    // }

    default:
      return null;
  }
};
