import { useEffect, useState } from "react";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Col,
  Row,
  Checkbox,
  useSelect,
  Select,
  DatePicker,
  TimePicker,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import dayjs from "dayjs";
import moment, { Moment } from "moment";

type CreateFaqProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  viewOrderMax: any;

};

export const CreateTrip: React.FC<CreateFaqProps> = ({
  modalProps,
  formProps,
  viewOrderMax,
}) => {
  const { TextArea } = Input;
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;

  const [routeId, setRouteId] = useState<any>(null);
  const [formattedValue, setFormattedValue] = useState('');
  //   const [startSelectedTime, setStartSelectedTime] = useState<any>(moment('12:00:00', 'HH:mm:ss'));

  const { selectProps } = useSelect<any>({
    resource: "route",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  const handleSearch = (e: any) => {
    setRouteId(e);
  };

  const handleOnClearSearch = () => {
    setRouteId(null);
  };

  const validateStartTime = async (_: any, start_time: any) => {
    const end_time = formProps?.form?.getFieldValue("end_time");
    const startTimeString = moment(start_time).format("HH:mm:ss");
    if(startTimeString !== "00:00:00"){
    }
    else{
        if (start_time.format("HH:mm:ss") === "00:00:00") {
          throw new Error("Start time cannot be 00:00:00");
        }
        if (end_time && start_time && start_time.isAfter(end_time)) {
          throw new Error("Start time must be before End time");
        }
    }
  };

  const validateEndTime = async (_: any, end_time: any) => {
    const start_time = formProps?.form?.getFieldValue("start_time");
    const endTimeString = moment(end_time).format("HH:mm:ss");
    if(endTimeString !== "00:00:00"){
    }
    else{
      if (end_time.format("HH:mm:ss") === "00:00:00") {
        throw new Error("End time cannot be 00:00:00");
      }
      if (start_time && end_time && end_time.isBefore(start_time)) {
        throw new Error("End time must be after start time");
      }
    }
  };
  
  const validateDueTime = async (_: any, due_time: any) => {
    // const start_time = formProps?.form?.getFieldValue("start_time");
    const dueTimeString = moment(due_time).format("HH:mm:ss");
    if(dueTimeString !== "00:00:00"){
    }
    else{
    if (due_time.format("HH:mm:ss") === "00:00:00") {
      throw new Error("Due time cannot be 00:00:00");
    }
    // if (start_time && end_time && end_time.isBefore(start_time)) {
    //   throw new Error("Due time must be after start time");
    // }
  }
  };

  const handleInputChange = (value: any) => {
    const getValue = value?.target?.value;
    if (getValue !== null && getValue !== undefined && getValue !== "") {
      const parsedValue = parseInt(getValue, 10);
      if (!isNaN(parsedValue)) {
        const formatted = parsedValue.toString().padStart(2, '0');
        setFormattedValue(formatted);
        formProps?.form?.setFieldsValue({ [`seq_number`]: formatted });
      }
    }
  };
  
  useEffect(() => {
    formProps?.form?.resetFields();
    const nextValue = String(viewOrderMax + 1).padStart(2, "0");
    formProps?.form?.setFieldsValue({ seq_number: nextValue });
  }, [viewOrderMax]);

  return (
    <Modal {...modalProps} title="Create Trip">
      <Form {...formProps} size="large" layout="vertical">
        <Form.Item label="Trip Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item
          name="route_id"
          label="Select Route"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select a Route"
            onSelect={handleSearch}
            clearIcon="*"
            allowClear
            onClear={handleOnClearSearch}
            {...selectProps}
          />
        </Form.Item>
        <Row justify="space-between">
          <Form.Item
            label="Date"
            name="date"
            rules={[{ required: true }]}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : "",
            })}
          >
            <DatePicker
              disabledDate={(current) => {
                return current && current < moment().startOf("day");
              }}
            />
          </Form.Item>
          <Form.Item
            label="Start Time"
            name="start_time"
            rules={[
              { required: true, message: "Please select a Start time!" },
              { validator: validateStartTime },
            ]}
          >
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item
            label="End Time"
            name="end_time"
            rules={[
              { required: true, message: "Please select an End time!" },
              { validator: validateEndTime },
            ]}
          >
            <TimePicker format="HH:mm:ss" />
          </Form.Item>

          <Form.Item
            label="Due Time"
            name="due_time"
            rules={[
              { required: true, message: "Please select a Due time!" },
              { validator: validateDueTime },
            ]}
          >
           <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </Row>
        <Form.Item
          label="Driver Name"
          name="driver_name"
          rules={[{ required: true, message: "Please enter a Driver Name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Vehicle Number"
          name="vehicle_number"
          rules={[
            { required: true, message: "Please enter a Vehicle Number!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
            label="Sequence Number"
            name="seq_number"
            rules={[{ required: true }]}

            >
                <Input type="number" min={1} 
          value={formattedValue}
          onChange={handleInputChange}/>
            </Form.Item>
        <Form.Item label="Notes" name="notes">
          <TextArea />
        </Form.Item>
        {user ? (
          <>
            <Form.Item
              name="created_by"
              initialValue={user?.id}
              style={{ display: "none" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="ob_org_id"
              initialValue={userId}
              style={{ display: "none" }}
            >
              <Input />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
};
