export const UserBaseRouter =  async(parama: any) =>{

    switch(parama?.metadata?.org_id){
        case "1e84e052-4427-4808-b100-870391d93c2d": 
        return ("/orders")                           //PFS
        break;
        default : 
        return("/dashboard")
        break;
    }
}