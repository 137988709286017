import {
  Form,
  Input,
 
  FormProps,
  Tabs,
 
  Typography,
  useTable,
  } from "@pankod/refine-antd";
 
 import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct, IPrice, ICountry, ICustomerGroup } from "interfaces";
import React, {   useState } from 'react';
import { nhost } from "utility/nhost";
import { Select, Button, Modal,Spin ,Space} from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { useNotification } from "@pankod/refine-core";
import  * as  XLSX from 'xlsx';
import { CHECK_FAVORITE_LIST, EXCEL_INSERT_FAVORITE_LISTS, GET_CUSTOMERS } from "query";

  
type CreateFavouriteProps = {
  formProps: FormProps;
  closeCreateModal: () => void
  createModalVisible: boolean,
  handleFormSubmitted: () => void,
  tableQueryResult: any,
  parentRefetch:any
};

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
 
const onChange = (key: string) => {
};

export const CreateFavourite: React.FC<any> = ({
  handleFormSubmitted,
  closeCreateModal,
  parentRefetch
}) => {
  const { data: user } = useGetIdentity();

  const { open, close } = useNotification();
 
  const [model, setModel] = useState(false);

  const [customerId, setCustomerId] = React.useState<any>([])

  const [customers, setCustomers] = React.useState<any>([])

  const [name, setName] = useState<string>()
  
  const [customerFav, setCustomerFav] = useState<any>(false)

  const [form] = Form.useForm()

  const handleOk = async() => {

    try{
        const results:any = await  EXCEL_INSERT_FAVORITE_LISTS( 
          {
          Object:{name:name, customer_id:customerId[0], is_active:true,created_by:customerId[0]}
          })
  
        if(results?.data?.insert_favorite_lists?.affected_rows==1){
         setModel(false)
  
          open?.({
            key:"success",
            type: "success",
            message: "",
            description: `Sucessfully added List`,
        });
        form.resetFields()
        if(results?.error?.[0]?.message){
           open?.({
            key:"error",
            type: "error",
            message: "",
            description: results?.error?.[0]?.message,
        });
        }
        parentRefetch()
        setTimeout(() => { close("success"); }, 5000);
        setTimeout(() => { close("error"); }, 5000);
  
        }
      } catch(error){
        open?.({
          key:"error",
          type: "error",
          message: "",
          description: `Something Went Wrong ${error}`,
      });
      setTimeout(() => { close("error"); }, 5000);

      }finally{
        setTimeout(() => { close(''); }, 5000);
      }
  }

 
  const { Option } = Select;
 

  // let getProducts =  `query GetCustomers( $limit: Int!, $offset: Int!, $where:customers_bool_exp!) {
  //   customers(limit: $limit, offset: $offset, where:$where, ) {
  //     id
  //     name
     
  //   }
   
  // }
  //       `

    const handleDownload = () => {
      const headers = ["Product", "Product_Code"];
      const data:any = [ ];
  
      const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "products.xlsx");
    };
    

const getInitialData = () =>{
    
  GET_CUSTOMERS( 
    {limit:10, 
     offset:0, 
      // where:  {
      //   "user":{
      //     "id": {
      //       "_eq": user?.id
      //     }
      //   }
      // }
      where:  {
        "ob_org_id": {
          "_eq": user?.metadata?.org_id
        }
      }
    
    }
      ).then((data:any)=>{
        
  
    const copysSdata:any = data?.data?.customers;
      
    setCustomers([...copysSdata])
  }).catch((error)=>{
   })

}




React.useEffect(()=>{
  if(user?.id){
    getInitialData()
  }
},[user])

 
const onSelects = async (e:any)=>{
   if(e){
    setCustomerId([e])

    try{
      let response: any = await CHECK_FAVORITE_LIST({id: e})
      console.log(response?.data?.favorite_lists?.length);
      
      if(response?.data?.favorite_lists?.length > 0){
        console.log("call");
        
        setCustomerFav(true);
      }
    }
    catch(error){

    }

  }else{
      // setLimit(limit+5)
  }


}

const handleSearch = (newValue: string) => {
  if (newValue) {
  GET_CUSTOMERS( 
     { 
          limit:10, 
          offset:0, 
          where:  {name:{_ilike:"%"+newValue?.trim()+"%"},
          //  user:{id: {
          //   _eq: user?.id
          // }}
          ob_org_id: {
            _eq: user?.metadata?.org_id
          }
        }
        }
      ).then((data:any)=>{
        setCustomers(()=>[...data?.data?.customers])
 
    }).catch((error)=>{
      console.log("error =>", error)
    })

  } else {
    GET_CUSTOMERS( 

        { 
          limit:10, 
          offset:0, 
          // where:  {
          //   "user":{
          //     "id": {
          //       "_eq": user?.id
          //     }
          //   }
          // }
          where:  {
            "ob_org_id": {
              "_eq": user?.metadata?.org_id
            }
          }
        }
      ).then((data:any)=>{
        setCustomers(()=>[...data?.data?.customers])
 
    }).catch((error)=>{
      console.log("Error =>", error)
    })
    setCustomers([]);
  }
};



  return (
    <>
      <Modal   
        title="Create Favorite"
        onCancel={()=>setModel(false)}
        visible={model}
        onOk={handleOk}
        okText="Create"
      >
        <Form
            onFinish={(e) => {
            }}
          wrapperCol={{ span: 23 }}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
              <Select 
                // mode="multiple"
                showSearch
                style={{ width: '100%' }}
                placeholder="Select Customer"
                value={customerId }
                onChange={(e)=>console.log("customerId",customerId)}
                onSelect={onSelects}
                onSearch={handleSearch}
                filterOption={false}
                // notFoundContent={isLoading ? <Spin size="small" /> : null}
                // virtual={true}
                id="select"
              >
                   { 
                      [...customers]?.map((e:any,i:any)=>{  
                         return  <Option    key={e?.id}>{e?.name}</Option>
                    }) 
                    } 
                </Select>

              <Input
              style={{marginTop:"5px"}}
              value={name}
              type="text"
              onChange={e=>setName(e?.target?.value)}
              placeholder="Name"
            />         
  
        </Form >
      </Modal >

 <Space  style={{ marginTop: "15px" }} >

 <Button  onClick={handleDownload}>Download Sample Excel</Button>
      
      <Button
              type="primary"
              children={<div style={{ display: "flex", alignItems: "center" }}>
              <PlusOutlined style={{ marginRight: "5px" }} />
              Create List
            </div>}
            icon={false}
              onClick={() =>
                 setModel(true)
              }
            />,

 </Space>


 
    </>
  );
};
