import { ArrowUpOutlined } from "@ant-design/icons";
import { Table } from "@pankod/refine-antd";
import { useDashboardContext } from "contaxt/dashboardContext";
import { useEffect, useState } from "react";
import CountUp from "react-countup";

export const CustomerOrderAnalysis = () => {
  
  const {filterDate, orderCustomers, orderCustomersCard, orderData} = useDashboardContext();
  const [repeatCount, setRepeatCount] = useState(0)
  
  let filterStates = filterDate == null ? true : false;

  useEffect(()=>{
    repeatOrder()
  }, [orderData])

  function repeatOrder (){
    let orderPatten = orderData?.nodes?.map((data: any) => {
      let mapItems = data?.items?.map((item: any) => {
        return [item.id]
      }).flat()
      return mapItems;
    }).flat()

    const itemCounts = orderPatten?.reduce((acc: Record<string, number>, id: any) => {
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});
    if(itemCounts){
      const compactedItems = Object.entries(itemCounts)?.map(([id, count]: any) => ({
        id,
        "count": count
      }))

      if(compactedItems?.length >0 ){
        let repaterCount = compactedItems?.filter((data: any)=> data.count >= 2)
        let calCount =  repaterCount?.reduce((acc: any, dec: any) => acc + dec?.count, 0) / repaterCount?.length
        let calcultePer = (calCount / orderData?.nodes?.length) *100
        setRepeatCount(Math.floor(calcultePer))
      }
    }  
  }

  const coloums: any = [
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Order",
      dataIndex: "orderCount",
      sorter: (a: any, b: any) => a.orderCount - b.orderCount,
    },
    {
      dataIndex:"revenue",
      title: "Revenue",
      render:(_: any)=>{
        return(
          <p>£ {_.toFixed(2)}</p>
        )
      },
      sorter: (a: any, b: any) => a.revenue - b.revenue,
    },
  ]
 
  return (
    <div className="continer">
      <div className="analysis_card">
        <div className="analysis_card-header">
          <div className="card_title">
            <h4>High Order and Low Order Customer</h4>
          </div>
        </div>
        <div className="analysis_card-body">
          <div className="analysis_report_box">
            <div className="report_card">
              <div className="report_title">
                <p>High Order Customers</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={orderCustomersCard?.highOrder}
                      duration={2.6}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    {/* <p className="report_rate">0.0%</p> */}
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Low Order Customers</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={isNaN(orderCustomersCard?.lowOrder) ? 0 : orderCustomersCard?.lowOrder}
                      duration={2.75}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    {/* <p className="report_rate">0.0%</p> */}
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Highe Order (LTV)</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={orderCustomersCard?.LTV?.ltv_HighOrder}
                      prefix="£"
                      duration={2}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    {/* <p className="report_rate">0.0%</p> */}
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Low Order(LTV)</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={orderCustomersCard?.LTV?.ltv_LowOrder}
                      prefix="£"
                      duration={2}
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    {/* <p className="report_rate">0.0%</p> */}
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
            <div className="report_card">
              <div className="report_title">
                <p>Repeat Purchase Rate</p>
              </div>
              <div className="report_body">
                <div className="report_box">
                  <div className="report_value_section">
                    <CountUp
                      start={0}
                      end={repeatCount}
                      duration={2.75}
                      suffix="%"
                      className="countup_count"
                    />
                    <ArrowUpOutlined className="arrow" />
                    {/* <p className="report_rate">0.0%</p> */}
                  </div>
                  {filterStates && <p className="report_rate">this week</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="analysis_table">
            <div className="analysis_tsp_table">
                <Table dataSource={orderCustomers} columns={coloums} pagination={{ pageSize: 5 }}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};