import { createContext, ReactNode, useContext, useState } from "react";

type MenuContextType = {
    menuPermission: boolean;
    setPermissions: (permissions: boolean) => void;
};

const MenuContext = createContext<MenuContextType | undefined>(undefined);

export const AuthPermissionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [menuPermission, setPermissions] = useState<boolean>(true);

    return (
        <MenuContext.Provider value={{ menuPermission, setPermissions }}>
            {children}
        </MenuContext.Provider>
    );
};

export const useMenuContext = () => {
    const context = useContext(MenuContext);
    if (!context) {
        throw new Error("useMenuContext must be used within an AuthPermissionProvider");
    }
    return context;
};
