import React from 'react';

import { useLogin, useTranslate, useNavigation, } from "@pankod/refine-core";

import {
    AntdLayout, Menu, MenuProps, Icons, useMenu,
    ErrorComponent
} from "@pankod/refine-antd";
import {
    Title,
    Header,
    Footer,
    OrderBitLayout,
    OffLayoutArea,
} from "components/layout";


import { Routes, Route, Link } from 'react-router-dom';
import { Dashboard } from './list';
import { OrderTrendChart } from 'components/dashboard/orderTrend';
const { DashboardOutlined} = Icons;
const { Sider, Content } = AntdLayout;
const { SubMenu } = Menu;



export const DashboardPage: React.FC = () => {

    const { push } = useNavigation();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();

    return (
        <AntdLayout style={{ minHeight: "90vh", flexDirection: "row" }}>
            <Sider width={200} className="site-layout-background">
                <h2 style={{ margin: "20px" }}>Dashboard</h2>
                {/* <Menu
                    theme="light"
                    selectedKeys={[selectedKey]}
                    defaultOpenKeys={defaultOpenKeys}
                    mode="inline"
                    onClick={({ key }) => {
                        push(key as string);
                    }}
                >
                    <Menu.Item
                        icon={<DashboardOutlined />}
                        key="/dashboard"
                        onClick={() => {
                        push("/dashboard");
                        }}
                        style={{
                        fontWeight: selectedKey === "/dashboard" ? "bold" : "normal",
                        }}
                    >
                        Dashboards
                    </Menu.Item>
                </Menu> */}
            </Sider>
            <Content
                className="site-layout-background"
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                }}
            >
                <Routes>
                    <Route index element={<Dashboard />} />
                    <Route path="*" element={<ErrorComponent />} />
                    <Route path="OrderTrend" element={<OrderTrendChart />} />
                </Routes>
            </Content>
        </AntdLayout>
    );
};
