import { Table } from "@pankod/refine-antd";
import { useGetIdentity, useList } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import moment from "moment";
import React, { useState } from "react";

type InvoicePreviewType = {
    orderItems: any,
    allTaxes: any,
    customer: any,
    order: any
};

const InvoicePreview: React.FC<InvoicePreviewType>  = ({ orderItems, allTaxes, customer, order}) => {
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    // PFS
    const custome_modify = userId === "1e84e052-4427-4808-b100-870391d93c2d" ? true : false
    const [expandedComments, setExpandedComments] = useState<any>({});

    const orgDetails = useList<IOrg>({
        resource: "ob_orgs",
        metaData: {
          fields: [
            "primary_color",
            "logo",
            "currency",
            "address",
            "name",
            "id"
          ],
        },
        config: {
          filters: [
            {
              field: "id",
              operator: "eq",
              value: userId,
            },
          ],
        },
    });

    const companyName = orgDetails?.data?.data[0]?.name ?? "Orderbit";
    const companyAddress = orgDetails?.data?.data[0]?.address ?? ["Orderbit"];
    const primaryColour = orgDetails?.data?.data[0]?.primary_color ?? "#4EA144";
    let initialValue = 0;
    const totalValue = orderItems?.reduce(function (
        accumulator: any,
        curValue: any
    ) {
      return accumulator + (curValue.price * curValue.neededquantity);
    },
    initialValue);

    let VAT = 0;
    const calculateVat = () => {
      VAT = 0;
      orderItems?.forEach((item: any) => {
        const taxId = item.tax_id;
        const itemTotal = item?.price * item?.neededquantity;
        const tax = allTaxes?.find((tax: any) => tax.id === taxId);
        
        if (tax) {
          const findSeparateTax = itemTotal * (tax?.vat_rate / 100);
          if (findSeparateTax) {
            VAT += findSeparateTax;
          }
        }
      });
      return VAT;
    };
    calculateVat();
  

    const primaryColourStyle: React.CSSProperties = {
        color: primaryColour,
    };
    
    const primaryColourStyleforPrintPage: React.CSSProperties = {
        // height: "100vh",
        borderBottom: `15px solid ${primaryColour}`,
        borderTop: `15px solid ${primaryColour}`,
    };

    const handleExpandClick = (index: number) => {
        setExpandedComments((prevState: any) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const Coloums = [
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                fontWeight: "500",
                color: "#00000078",
                fontSize: "15px",
                lineHeight: "15px",
              }}
            >
              S.No
            </div>
          ),
          key: "id",
          width: 40,
          render: (text: any, record: any, index: number) => {
            return (
              <div style={{ display: "flex", justifyContent: "left" }}>
                {index + 1}
              </div>
            );
          },
        },
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                fontWeight: "500",
                color: "#00000078",
                fontSize: "15px",
                lineHeight: "15px",
              }}
            >
              Product
            </div>
          ),
          dataIndex: "name",
          width: 200,
          key: "name",
          render: (value: any) => (
            <div style={{ display: "flex", justifyContent: "left" }}>{value}</div>
          ),
        },
        ...(custome_modify
          ? [
              {
                title: (
                  <div style={{ display: "flex", justifyContent: "left", fontWeight: "500", color: "#00000078", fontSize: "15px", lineHeight: "15px" }}>
                    Comment
                  </div>
                ),
                dataIndex: "comment",
                width: 200,
                key: "comment",
                render: (value: string, index: number) => {
                  const isExpanded = expandedComments[index];
                  const truncatedText = value?.length > 100 ? `${value.slice(0, 100)}` : value;
          
                  return (
                    <div style={{ display: "flex", justifyContent: "left" }}>
                     <p> {value}</p>
                    </div>
                  );
                },
              },
            ]
          : []),
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "500",
                color: "#00000078",
                fontSize: "15px",
                lineHeight: "15px",
              }}
            >
              Quantity
            </div>
          ),
          width: 80,
          key: "neededquantity",
          dataIndex: "neededquantity",
          render: (value: any) => (
            <div style={{ display: "flex", justifyContent: "center" }}>{value}</div>
          ),
        },
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "500",
                color: "#00000078",
                fontSize: "15px",
                lineHeight: "15px",
              }}
            >
              Price
            </div>
          ),
          width: 80,
          key: "price",
          dataIndex: "price",
          render: (value: any) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              £ {value}
            </div>
          ),
        },
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "500",
                color: "#00000078",
                fontSize: "15px",
                lineHeight: "15px",
              }}
            >
              Net
            </div>
          ),
          width: 80,
          key: "status",
          dataIndex: "",
          render: (value: any) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              £ {(value?.price * value?.neededquantity)?.toFixed(2)}
            </div>
          ),
        },
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                fontWeight: "500",
                color: "#00000078",
                fontSize: "15px",
                lineHeight: "15px",
              }}
            >
              VAT £
            </div>
          ),
          width: 80,
          key: "tax_id",
          dataIndex: "",
          render: (value: any) => {

            const tax = allTaxes?.find((tax: any) => tax.id === value.tax_id);
            if (tax) {
              const vatAmount = ((value?.price * value?.neededquantity) * (tax.vat_rate / 100)).toFixed(2);
              return (
                <div style={{ display: "flex", justifyContent: "left" }}>
                  {vatAmount}
                </div>
              );
            }
            return "0.00";
          },
        },
    
        ...(orderItems?.[0]?.additional_charges?.map((charge: any, index: any) => ({
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "500",
                color: "#00000078",
                fontSize: "15px",
                lineHeight: "15px",
                textTransform: "capitalize",
              }}
            >
              {charge?.charges_name}
            </div>
          ),
          width: 80,
          key: "status",
          dataIndex: "",
          render: (value: any) => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {charge.rate}
              {charge?.charges_type === "amount" ? "£" : "%"}
            </div>
          ),
        })) ||
          [] ||
          []),
      ];

  return (
    <div style={primaryColourStyleforPrintPage}>
      <div className="invoice_print_page">
        <div className="invoice_heading_container">
          <div className="invoice_heading_text" style={primaryColourStyle}>
            INVOICE
          </div>
          <div>
            <img src={orgDetails?.data?.data[0]?.logo} className="invoice_heading_image" />
          </div>
        </div>

        <div className="invoice_detail_container">
          <div className="ourcompany_details_container">
            <div className="ourcomany_details">
              <h6
                className="ourcomany_details_heading"
                style={primaryColourStyle}
              >
                {companyName}
              </h6>
              <div>{/* {companyAddress} */}</div>
            </div>
            <div className="customercompany_details">
              <h6 style={primaryColourStyle}>Invoice To:</h6>
              <p>{customer?.fullname}</p>
              <div>
                {/* {orderItems[0]?.address?.customers?.map((data: any) => {
                  return (
                    <> */}
                      {customer?.address}
                      {customer?.address_line_2} {customer?.city} {customer?.country}{" "}
                      {customer?.postal_code}
                    {/* </> */}
                  {/* ); */}
                {/* })} */}
              </div>
            </div>
            <div className="invoice_details_box">
              <h6 style={primaryColourStyle}>Invoice Number</h6>
              <p>
                {/* {formProps?.initialValues?.orderids} */}
                {order}
              </p>
              <h6 style={primaryColourStyle}>Invoice Date</h6>
              <p>
                {" "}
                {moment()
                  .utc()
                  .format("MMM Do YYYY h:mm A")}
              </p>
              {/* <h6 style={primaryColourStyle}>Invoice Status</h6>
              <p>{formProps?.initialValues?.status}</p> */}
            </div>
          </div>
        </div>

        <div className="invoice_table_container">
          <div>
            <Table
              columns={Coloums}
              dataSource={orderItems}
              pagination={false}
            />
          </div>
        </div>

        <div className="invoice_totalvalues_container">
          <div className="invoice_totalvalues_box">
            <div className="invoice_totalvalues_emptybox"></div>
            <div className="invoice_totalvalue_text">
              <h6>Total GBP :</h6>
              <h6>Total VAT :</h6>
              <h6>Garriage GBP :</h6>
              {orderItems?.[0]?.additional_charges?.map(
                (charge: any, index: any) => (
                  <h6 style={{ textTransform: "capitalize" }}>
                    {charge?.charges_name} :
                  </h6>
                )
              )}
              {/* <h6>Amount Due GBP :</h6> */}
            </div>
            <div className="invoice_totalvalue_value">
              <h6>£ {totalValue?.toFixed(2)}</h6>
              <h6>£ {calculateVat()?.toFixed(2)}</h6>
              <h6>£ {(totalValue + Number(VAT.toFixed(2)))?.toFixed(2)}</h6>
              {orderItems?.[0]?.additional_charges?.map(
                (charge: any, index: any) => (
                  <h6 style={{ textTransform: "capitalize" }}>
                    {charge?.charges_type === "amount"
                      ? `£${charge?.rate}`
                      : `${charge?.rate}%`}
                  </h6>
                )
              )}
              {/* <h6>
                £ {formProps?.initialValues?.outstanding?.toFixed(2) ?? "0.00"}
              </h6> */}
            </div>
          </div>
        </div>

        <div className="invoice_notes_container">
          <h6 style={primaryColourStyle}>Notes:</h6>
        </div>

        <div className="invoice_terms_container">
          <h6 style={primaryColourStyle}>Terms and Conditions:</h6>
        </div>
      </div>
    </div>
  );
};

export default InvoicePreview;
