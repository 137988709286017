import { Form, Input, ModalProps, FormProps, Modal, Select, InputNumber, useSelect, message, Checkbox } from '@pankod/refine-antd'
import { useGetIdentity, useCreate, useMutationMode, useNavigation } from '@pankod/refine-core'
import { IUser } from 'interfaces'
import { useEffect, useState } from 'react'
import { nhost } from 'utility'
import { getAllRoles, updateUserById } from 'users'
import { } from '@pankod/refine-react-router-v6'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

type CreateUserProps = {
  modalProps: ModalProps
  formProps: FormProps
  close: any
  record: any
  refreshData: any
}
export interface ISignup {
  email: string
  password: string
  displayName: string
  defaultRole: string
}
export const UserEdit: React.FC<CreateUserProps> = ({ modalProps, formProps, close, record, refreshData }) => {
  const { data: user } = useGetIdentity()
  const [form] = Form.useForm()
  const [checked, setChecked] = useState(false)
  const [valuePropName, setPropName] = useState('unchecked')
  const [reload, setReload] = useState(false)
  const { push, goBack } = useNavigation()
  const [roleList, setRoleList] = useState([] as any[])
  useEffect(() => {
    formProps.form?.resetFields()
  })
  // useEffect(() => {
  //   getAllRoles().then((response: any) => {
  //     setRoleList(response.authRoles)
  //   })

  //   // form.setFieldsValue({ displayName: "", email: "", defaultRole: "" });
  // }, [record])
  useEffect(() => {
    if (record?.disabled === true) {
      setPropName('checked')
    }
    // form.setFieldsValue({ displayName: "", email: "", defaultRole: "" });
  }, [record])
  const success = (msg: string, type: string) => {
    if (type === 'success') {
      message.success(msg)
      close()
    } else message.error(msg)
  }
  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked)
    if (e.target.checked === true) {
      setPropName('checked')
    }
  }
  //   const { mutate: createUser } = useCreate<IUser>();
  return (
    <Modal
      {...modalProps}
      title="Create User"
      destroyOnClose={true}
      onCancel={() => {
        formProps.form?.resetFields()
        refreshData()
        close()
      }}
    >
      <Form
        // form={form}
        {...formProps}
        size="large"
        layout="vertical"
        onFinish={values => {
          updateUserById(values).then(response => {
            refreshData()
            if (response === 'OK') {
              success('User Updated', 'success')
            } else {
              success('Error Occured', 'danger')
            }
          })
          //   refreshData();
        }}
      >
        <Form.Item
          label="Display Name"
          name="displayName"
          initialValue={record?.displayName}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item label="Role" name="defaultRole" rules={[{ required: true }]} initialValue={record?.defaultRole}>
          <Select>
            {roleList.length > 0 &&
              roleList?.map(item => (
                <option key={item?.role} value={item?.role}>
                  {item?.role}
                </option>
              ))}
          </Select>

        </Form.Item> */}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
          initialValue={record?.email}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Active"
          name="disabled"
          valuePropName={valuePropName}
          //   valuePropName="checked"
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={record?.disabled}
        >
          <Checkbox onChange={onChange}>Deactivate</Checkbox>
        </Form.Item>
        <Form.Item name="user_id" initialValue={record?.id}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="updated_by" initialValue={user?.id}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  )
}