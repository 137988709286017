import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

type EditEmailTemplateProps = {
    modalProps: ModalProps;
    formProps: FormProps;
};


export const EditEmailTemplate: React.FC<EditEmailTemplateProps> = ({
    modalProps,
    formProps,
}) => {
    const { data: user } = useGetIdentity();
    return (
        <Modal {...modalProps} title="Edit Category">
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Content"
                    name="content"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="updated_by"
                    initialValue={user?.id}>
                    <Input type="hidden" />
                </Form.Item>

            </Form>
        </Modal>
    );
};
