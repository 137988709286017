import { CheckOutlined, CloseOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { CreateButton, List, RefreshButton, useModalForm, useTable, Table, Space, EditButton, Popconfirm, getDefaultSortOrder, DateField } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useNotification } from "@pankod/refine-core";
import { CreateAdditionalChanrge } from "./create";
import { IAdditionChargeOptions } from "interfaces";
import { EditAdditionalChanrgeOption } from "./edit";
import { DELETE_ADDITIONAL_CHARGE } from "query";

export const AdditionChargesList: React.FC<IResourceComponentsProps> = () =>{
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    const { open, close } = useNotification();

    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IAdditionChargeOptions>({
        resource: "customer_checkout_addititonal_charges",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
        ],
        metaData: {
            fields: ["id","is_active", "ob_org_id", "charges_name", "charges_type", "rate"],
        },
    });

    const { formProps, modalProps, show } = useModalForm({
        resource: "customer_checkout_addititonal_charges",
        action: "create",
        redirect: false,
        successNotification: {
            key: "j",
            message: "",
            type: "success",
            description: "Additional charges created successfully",
    
        },
    });

    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,

    } = useModalForm({
        resource: "customer_checkout_addititonal_charges",
        action: "edit",
        redirect: false,
        successNotification: {
            key: "j",
            message: "",
            type: "success",
            description: "Additional charges updated successfully",
    
        },
        metaData: {
            fields:  ["id","is_active", "ob_org_id", "charges_name", "charges_type", "rate", "updated_by", "created_by", "created_at", "updated_at"],
        },
    });

    const handleDelete = (data: any) => {
        DELETE_ADDITIONAL_CHARGE(data).then((repsone: any) => {
          if (repsone) {
            open({
                key: "delete_success",
                type: "success",
                message: "",
                description: "Successfully Deleted"
            });
            setTimeout(() => {
              close("delete_success");
            }, 2000);
            tableQueryResult?.refetch();
          }
        });
      }
    
    return (
        <List
        title="Additional Charges "
        pageHeaderProps={{

            extra: [
                //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                <RefreshButton 
                    type="primary"
                    children={<div style={{ display: "flex", alignItems: "center" }}>
                    <ReloadOutlined style={{ marginRight: "5px" }} />
                    Refresh
                </div>}
                icon={false}
                onClick={() => tableQueryResult?.refetch()} />,
                <CreateButton type="primary" resourceName ="settings/additionalCharges"
                    children={<div style={{ display: "flex", alignItems: "center" }}>
                            <PlusOutlined style={{ marginRight: "5px" }} />
                            Add Additional Charges
                        </div>}
                    icon={false}
                    onClick={() => show()} 
                />,
            ],

        }}
    >
         <Table {...tableProps} rowKey="id" size="small">
            <Table.Column dataIndex= "charges_name" title = "Name"/>
            <Table.Column dataIndex= "charges_type" title = "Charges Type"/>
            <Table.Column dataIndex= "rate" title = "rate"/>
            <Table.Column dataIndex= "is_active" title = "active"
            render={(value) => (value == true ? <CheckOutlined style={{ color: '#6ead65' }} /> : <CloseOutlined style={{ color: '#ff7875' }} />)}/>
            <Table.Column 
                dataIndex="created_at"
                title="Created At"
                render={(value) => <DateField value={value} format="LLL" />}
                defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                sorter
            />
            <Table.Column<IAdditionChargeOptions>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                resourceName="settings/additionalCharges"
                                onClick={() => editShow(record.id)}
                            />
                            <Popconfirm
                              title="Delete?"
                              onConfirm={() => handleDelete(record.id)}
                            >
                              <a style={{ color: "red" }}>Delete</a>
                            </Popconfirm>
                        </Space>
                    )}
                />
         </Table>
        <CreateAdditionalChanrge modalProps={modalProps} formProps={formProps} />
        <EditAdditionalChanrgeOption modalProps={editModalProps} formProps={editFormProps}  />
    </List>
    )
}