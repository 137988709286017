import React from 'react';

import { useLogin, useTranslate, useNavigation, } from "@pankod/refine-core";

import {
    AntdLayout, Menu, MenuProps, Icons, useMenu
} from "@pankod/refine-antd";
import {
    Title,
    Header,
    Footer,
    OrderBitLayout,
    OffLayoutArea,
} from "components/layout";
import {ProductsList} from "./catalogue/products";
import {CategoriesList} from "./catalogue/categories";
import {BrandsList} from "./catalogue/brands";
import {UserManagementList} from "./users"

import { Routes, Route, Link } from 'react-router-dom';
const { ExclamationCircleOutlined ,SettingTwoTone,UnorderedListOutlined,
    SettingOutlined, FileDoneOutlined, ReadOutlined, CheckCircleOutlined,
    LogoutOutlined } = Icons;
const { Sider, Content } = AntdLayout;
const { SubMenu } = Menu;


const items2: MenuProps['items'] = [Icons.UserOutlined, Icons.LaptopOutlined, Icons.NotificationOutlined].map(
    (icon, index) => {
        const key = String(index + 1);

        return {
            key: `sub${key}`,
            icon: React.createElement(icon),
            label: `subnav ${key}`,

            children: new Array(4).fill(null).map((_, j) => {
                const subKey = index * 4 + j + 1;
                return {
                    key: subKey,
                    label: `option${subKey}`,
                };
            }),
        };
    },
);

export const MasterPage: React.FC = () => {

    const { push } = useNavigation();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();


    return (
        <AntdLayout style={{ minHeight: "90vh", flexDirection: "row" }}>
            <Sider width={200} className="site-layout-background">
                <h2 style={{margin:"20px"}}><ExclamationCircleOutlined  style={{margin:"5px"}}/>Master</h2>
                <Menu
                    theme="light"
                    selectedKeys={[selectedKey]}
                    defaultOpenKeys={defaultOpenKeys}
                    mode="inline"
                    onClick={({ key }) => {


                        push(key as string);
                    }}
                >
                        
                    <Menu.SubMenu title="Catalogue" icon={<ReadOutlined />}>
                        <Menu.Item
                            key="/master/products"
                            onClick={() => {
                                push("/master/products");
                            }}
                            style={{
                                fontWeight: (selectedKey === "/master/products") ? "bold" : "normal",
                            }}
                        >
                            Products
                        </Menu.Item>
                        <Menu.Item
                            key="/master/categories"
                            onClick={() => {
                                push("/master/categories");
                            }}
                            style={{
                                fontWeight: (selectedKey === "/master/categories") ? "bold" : "normal",
                            }}
                        >
                            Categories
                        </Menu.Item>
                        <Menu.Item
                            key="/master/users"
                            onClick={() => {
                                push("/master/users");
                            }}
                            className={ (selectedKey === "/master/users") ? "ant-menu-item-selected" : ""}

                        >
                            Users
                        </Menu.Item>
                        <Menu.Item
                            key="/master/brands"
                            onClick={() => {
                                push("/master/brands");
                            }}
                            style={{
                                fontWeight: (selectedKey === "/master/brands") ? "bold" : "normal",
                            }}
                        >
                            Brands
                        </Menu.Item>
                        

                        
                    </Menu.SubMenu>
                    <Menu.SubMenu title="Master" icon={<FileDoneOutlined />}>
                        <Menu.Item
                            key="/products"
                            onClick={() => {
                                push("/products");
                            }}
                            style={{
                                fontWeight: (selectedKey === "/products") ? "bold" : "normal",
                            }}
                        >
                            OB Products
                        </Menu.Item>

                    </Menu.SubMenu>
                    

                </Menu>
            </Sider>
            <Content
                className="site-layout-background"
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                }}
            >
                <Routes>
                    <Route index element={<div>Home</div>} />
                    <Route path="products" element={<ProductsList/>} />
                    <Route path="categories" element={<CategoriesList/>} />
                    <Route path="brands" element={<BrandsList/>} />

                    <Route path="customers" element={<div>Customers</div>} />
                    <Route path="users" element={<UserManagementList/>} />
                    <Route path="*" element={<div>Anything</div>} />
                </Routes>
            </Content>
        </AntdLayout>
    );
};
