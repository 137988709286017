import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IOrderStatus } from "interfaces";
import { useState } from "react";
const { TextArea } = Input;

type EditOrderStatusProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};


export const EditOrderStatus: React.FC<EditOrderStatusProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const [notify, setNotify] = useState(false);
  const onChange = (key: string) => {
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setNotify(e.target.checked);

  };
  return (
    <Modal {...modalProps} title="Edit Order Status">
      <Form {...formProps}
        wrapperCol={{ span: 23 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col span={12}>

            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please input your name!' }]}>

              <Input placeholder="Enter the name" onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Preview"
              name="bg_color"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select defaultValue="Select color" onChange={onChange}>
                <Select.Option value="#fff" style={{ backgroundColor: '#fff', color: '#000' }} name="bg_color" >White</Select.Option>
                <Select.Option value="red" style={{ backgroundColor: 'red', color: '#fff' }} name="bg_color" >Red</Select.Option>
                <Select.Option value="blue" style={{ backgroundColor: 'blue', color: '#fff' }} name="bg_color" >Blue</Select.Option>
                <Select.Option value="#000" style={{ backgroundColor: '#000', color: '#fff' }} name="bg_color" >Black</Select.Option>
                <Select.Option value="green" style={{ backgroundColor: 'green', color: '#fff' }} name="bg_color" >Green</Select.Option>
                <Select.Option value="light-blue" style={{ backgroundColor: 'cadetblue', color: '#fff' }} name="bg_color" >Light Blue</Select.Option>
                <Select.Option value="orange" style={{ backgroundColor: 'orange', color: '#fff' }} name="bg_color" >Orange</Select.Option>
                <Select.Option value="pink" style={{ backgroundColor: 'pink', color: '#fff' }} name="bg_color" >Pink</Select.Option>
                <Select.Option value="yellow" style={{ backgroundColor: 'yellow', color: '#fff' }} name="bg_color" >Yellow</Select.Option>
                <Select.Option value="gray" style={{ backgroundColor: 'gray', color: '#fff' }} name="bg_color" >Gray</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="notify_customer" valuePropName="checked">
              <Checkbox
              // name='notify_customer' checked={notify} onChange={onChangeCheckbox}
              >Notify customer via email</Checkbox>
            </Form.Item>
            <Form.Item
              name="sort"
              label="Sort">

              <InputNumber placeholder="Enter the sort" defaultValue="0" onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="updated_by"
              initialValue={user?.id}
            >
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
