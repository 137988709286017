import { newEnforcer } from "casbin";
import { nhost } from "utility";

import { model, adapter } from "./accessControl";
import { authProvider } from "./authProvider";

type CanParams = {
    resource: string;
    action: string;
    params?: any;
};

type CanReturnType = {
    can: boolean;
    reason?: string;
}


export const accessProvider = {
    can: async ({ resource, action, params }: CanParams) => {
        const user = await nhost.auth.getUser();
        const role  = user?.defaultRole;
        const enforcer = await newEnforcer(model, adapter);
        if (
            action === "delete" ||
            action === "edit" ||
            action === "show"
        ) {
            return Promise.resolve({
                can: await enforcer.enforce(
                    role,
                    `${resource}/${params.id}`,
                    action,
                ),
            });
        }
        if (action === "field") {
            return Promise.resolve({
                can: await enforcer.enforce(
                    role,
                    `${resource}/${params.field}`,
                    action,
                ),
            });
        }
        return Promise.resolve({
            can: await enforcer.enforce(role, resource, action),
        });
    },
};
