import React, { useEffect, useState } from 'react';

import { useLogin, useTranslate, useNavigation, } from "@pankod/refine-core";

import {
    AntdLayout, Menu, MenuProps, Icons, useMenu
} from "@pankod/refine-antd";
import {
    Title,
    Header,
    Footer,
    OrderBitLayout,
    OffLayoutArea,
} from "components/layout";
import { ProductShow, ProductsList } from "./products";
import { Routes, Route, Link } from 'react-router-dom';
import { CategoriesList } from './categories';
import { AddProductsList } from './addproducts/list';
import { PriceList } from './pricelist';
import { BrandsList } from './Brands';
import { Coupons } from './coupon';
import { ProductOptions } from './productoptions';
import { useGetIdentity } from "@pankod/refine-core";
import { AuthPermission } from 'utility/authPermission';
import { useMenuContext } from 'contaxt/menuContext';
// import { Coupons } from './coupons';
// import {Coupons} from './coupons';
const { BarcodeOutlined, AppstoreOutlined, DollarOutlined, TagOutlined, SkinOutlined, BlockOutlined, SettingTwoTone, UnorderedListOutlined,
    PercentageOutlined, ReadOutlined } = Icons;
const { Sider, Content } = AntdLayout;
const { SubMenu } = Menu;


const items2: MenuProps['items'] = [Icons.UserOutlined, Icons.LaptopOutlined, Icons.NotificationOutlined].map(
    (icon, index) => {
        const key = String(index + 1);

        return {
            key: `sub${key}`,
            icon: React.createElement(icon),
            label: `subnav ${key}`,

            children: new Array(4).fill(null).map((_, j) => {
                const subKey = index * 4 + j + 1;
                return {
                    key: subKey,
                    label: `option${subKey}`,
                };
            }),
        };
    },
);

export const CataloguePage: React.FC = () => {
    const { data: user } = useGetIdentity();
    const { push } = useNavigation();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
    const {menuPermission} = useMenuContext()


    return (
        <AntdLayout style={{ minHeight: "90vh", flexDirection: "row" }}>
            <Sider width={200} className="site-layout-background">
                <h2 style={{ margin: "20px" }}>Catalogue</h2>
                <Menu
                    theme="light"
                    selectedKeys={[selectedKey]}
                    defaultOpenKeys={defaultOpenKeys}
                    mode="inline"
                    onClick={({ key }) => {


                        push(key as string);
                    }}
                >
                    {/* <Menu.SubMenu title="Products" icon={<ReadOutlined />}>
                        <Menu.Item
                        icon={<BarcodeOutlined />}
                            key="/catalogue/products"
                            onClick={() => {
                                push("/catalogue/products");
                            }}
                            style={{
                                fontWeight: (selectedKey === "/catalogue/products") ? "bold" : "normal",
                            }}
                        >
                            Products
                        </Menu.Item>
                        <Menu.Item
                                icon={<SkinOutlined  />}

                            key="/catalogue/pricelist"
                            onClick={() => {
                                push("/catalogue/pricelist");
                            }}
                            style={{
                                fontWeight: (selectedKey === "/catalogue/pricelist") ? "bold" : "normal",
                            }}
                        >
                            Price Lists
                        </Menu.Item>
                        </Menu.SubMenu> */}
                        {!menuPermission &&
                    <Menu.Item
                        icon={<AppstoreOutlined />}
                        key="/catalogue/categories"
                        onClick={() => {
                            push("/catalogue/categories");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/catalogue/categories") ? "bold" : "normal",
                        }}
                    >
                        Categories
                    </Menu.Item>
                    }
                    {
                        user?.defaultRole == "developer" ?
                            <Menu.Item
                                icon={<TagOutlined />}
                                key="/catalogue/brands"
                                onClick={() => {
                                    push("/catalogue/brands");
                                }}
                                style={{
                                    fontWeight: (selectedKey === "/catalogue/brands") ? "bold" : "normal",
                                }}
                            >
                                Brands
                            </Menu.Item>
                            : ""}
                            {!menuPermission &&
                    <Menu.Item
                        icon={<SkinOutlined />}

                        key="/catalogue/productoptions"
                        onClick={() => {
                            push("/catalogue/productoptions");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/catalogue/productoptions") ? "bold" : "normal",
                        }}
                    >
                        Product Options
                    </Menu.Item>
                    }
                    {!menuPermission &&
                    <Menu.Item
                        icon={<DollarOutlined />}

                        key="/catalogue/pricelist"
                        onClick={() => {
                            push("/catalogue/pricelist");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/catalogue/pricelist") ? "bold" : "normal",
                        }}
                    >
                        Price Lists
                    </Menu.Item>
                    }
                    <Menu.Item
                        icon={<BarcodeOutlined />}
                        key="/catalogue/products"
                        onClick={() => {
                            push("/catalogue/products");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/catalogue/products") ? "bold" : "normal",
                        }}
                    >
                        Products
                    </Menu.Item>
                    {!menuPermission && 
                    <Menu.Item
                        icon={<PercentageOutlined />}
                        key="/catalogue/coupon"
                        onClick={() => {
                            push("/catalogue/coupon");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/catalogue/coupon") ? "bold" : "normal",
                        }}
                    >
                        Coupons
                    </Menu.Item>
                    }
                    {!menuPermission &&
                    <Menu.Item
                        icon={<UnorderedListOutlined />}
                        key="/catalogue/brands"
                        onClick={() => {
                            push("/catalogue/brands");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/catalogue/brands") ? "bold" : "normal",
                        }}
                    >
                        Brands
                    </Menu.Item>
                    }
                    {/* <Menu.Item
                        icon={<BlockOutlined />}
                        key="/catalogue/addproducts"
                        onClick={() => {
                            push("/catalogue/addproducts");
                        }}
                        style={{
                            fontWeight: (selectedKey === "/catalogue/addproducts") ? "bold" : "normal",
                        }}
                    >
                        Add Products
                    </Menu.Item> */}

                </Menu>
            </Sider>
            <Content
                className="site-layout-background"
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                }}
            >
                <Routes>
                    <Route index element={<ProductsList />} />
                    <Route path="products" element={<ProductsList />} />
                    <Route path="PriceList" element={<PriceList />} />
                    <Route path="categories" element={<CategoriesList />} />
                    <Route path="brands" element={<BrandsList />} />
                    <Route path="addproducts" element={<AddProductsList />} />
                    <Route path="coupon" element={<Coupons />} />
                    <Route path="productoptions" element={<ProductOptions />} />
                    {/* <Route path="products/show/:id" element={<ProductShow />} /> */}
                    <Route path="*" element={<div>Anything</div>} />
                </Routes>
            </Content>
        </AntdLayout>
    );
};
