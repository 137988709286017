import { useLogin, useTranslate } from "@pankod/refine-core";

import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
} from "@pankod/refine-antd";
import "./style.less";

const { Text, Title } = Typography;



export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

export const LoginPage: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();
    const t = useTranslate();

    const { mutate: login } = useLogin<ILoginForm>();

    const CardTitle = (
        <div style={{ textAlign: "center" }}>
            <img
                style={{ width: "200px" }}
                src="/images/logo-dark.png"
                width="100%"
            />
        </div>
    );

    return (
        <AntdLayout>
            <Row
                justify="center"
                align="middle"
                style={{
                    background: "#4EA144",
                    height: "100vh",

                }}
            >
                <Col xs={22}>
                    <div style={{ maxWidth: "408px", margin: "auto" }}>

                        <Card title={CardTitle} headStyle={{ borderBottom: 0 }}


                        >

                            <Form<ILoginForm>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    login(values);
                                }}
                                requiredMark={false}
                                initialValues={{
                                    remember: false,
                                    // username: "admin@orderbit.com",
                                    // password: "Orderbit@123",
                                }}
                            >
                                <Form.Item
                                    name="username"
                                    label={t("pages.login.email", "Username")}
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder=""
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={t(
                                        "pages.login.password",
                                        "Password",
                                    )}
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input
                                        type="password"
                                        placeholder=""
                                        size="large"
                                    />
                                </Form.Item>
                                <div style={{ marginBottom: "12px" }}>
                                    <Form.Item
                                        name="remember"
                                        valuePropName="checked"
                                        noStyle
                                    >
                                        <Checkbox
                                            style={{
                                                fontSize: "12px",
                                            }}
                                        >
                                            {t(
                                                "pages.login.remember",
                                                "Remember me",
                                            )}
                                        </Checkbox>
                                    </Form.Item>

                                    <a
                                        style={{
                                            float: "right",
                                            fontSize: "12px",
                                        }}
                                        href="#"
                                    >
                                        {t(
                                            "pages.login.forgotPassword",
                                            "Forgot password?",
                                        )}
                                    </a>
                                </div>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                >
                                    {t("pages.login.signin", "Sign in")}
                                </Button>
                            </Form>

                            <Row>
                                <Col >
                                    <div style={{ justifyContent: "center", marginTop: "10px" }}>
                                        Dont't have an account ?
                                        {' '}
                                        <a href="/signup">
                                            {t(
                                                "pages.login.signup",
                                                "Sign up",
                                            )}
                                        </a>
                                    </div>

                                </Col>
                            </Row>
                        </Card>

                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};
