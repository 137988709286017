import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import { useState, useEffect } from "react";
import { GET_ALL_CUSTOMERS, GET_ALL_PRODUCTS, INSERT_CUSTOMER_PRODUCT_PRIVACY, } from "query";
import { nhost } from "utility";
import { jsonConverter } from '../../../components/helpers/jsonConverter';

const { TextArea } = Input;

type CreateCustomerGroupUnitProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closePopup: () => void;
  createPopup: boolean;
  tableProps: any;
};

export const CreateCustomerGroup: React.FC<CreateCustomerGroupUnitProps> = ({
  modalProps,
  formProps,
  closePopup,
  createPopup,
  tableProps
}) => {
  
  const { TabPane } = Tabs;
  const { Option } = Select;
  const { open, close } = useNotification();

  const { data: user } = useGetIdentity();
  const User: any = nhost.auth.getUser()
  const orgId = User?.metadata?.org_id;
  const [notify, setNotify] = useState(false);
  const [customers, setCustomers] = useState([])
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: priceListSelectProps } = useSelect({
    resource: "pricelists",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: orgId,
      },
    ],
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const customer = tableProps?.dataSource?.map((id:any, index:any) => {
   let cusId = id?.customer_ids?.split(",")
   return cusId 
  })
  let customerIds:any = []
  // if(customer?.length > 0){
    // customerIds = [].concat(...customer)
    //  customerIds = ['a83b8d9f-5486-4f09-bac3-29001ab160a9',"7568003a-a8e2-45dc-8a5d-068f0eb02098"]
    // console.log(customerIds,"customerIds");
  // }

  const getCustomers = () => {
    // GET_CUSTOMERS_NOT_IN_CUSTOMERGRPS(customerIds).then((data: any) => {
    GET_ALL_CUSTOMERS(orgId).then((data: any) => {      
      if (data) {
        console.log(data?.customers?.length,"length");
        
        setCustomers(data?.customers)
      }
    })
  }

  const getProducts = () => {
    GET_ALL_PRODUCTS(orgId)
      .then((data: any) => {
        setProducts(() => []);
        console.log(data?.products?.length,"data? length");
        
        setProducts(data?.products);
        setIsLoading(false);
      }).catch((error: any) => {
        console.log(error, "error");
      })
  }

  useEffect(() => {
    getCustomers();
    getProducts();
  }, [])

  const onChange = (key: string) => {
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setNotify(e.target.checked);
  };

  const handleCustomerSearch = (search: any) => {
    const searchValue = customers?.filter((data: any, i: number) => {
      return data?.name?.toLowerCase().includes(search?.toLowerCase())
    })
    if (searchValue?.length !== 0) {
      setCustomers([...searchValue])
    }
    else {
      getCustomers()
    }
  }

  const handleProductSearch = (productsearch: string) => {

    if (productsearch?.length > 0) {
      setIsLoading(true);
      const filterProduct = products?.filter((prod: any) => {
        return prod?.name?.toLowerCase().includes(productsearch?.toLocaleLowerCase())
      })
      if (filterProduct?.length != 0) {
        setProducts([...filterProduct])
      }
      else {
        getProducts();
      }
    } else {
      getProducts();
    }
  };

  const handleSelectedChange = (data: any) => {
    let customerDetails: any = [];
    data?.map((da1: any) => {
      let find: any = customers?.find((a: any) => {
        return a?.id == da1
      })
      if (find?.id) {
        customerDetails?.push(find)
      }
    })
  }

  const onFinish = async (data: any) => {
    const MUTATIONAPI = `mutation MyMutation {
      insert_customer_groups(objects: {
        created_by: "e613b876-ade3-44a9-b307-274712d190f5", 
        customer_ids: "${data?.customer_ids}", 
        group_name: "${data?.group_name}", 
        ob_org_id: "${orgId}",
        pricelist_id: ${data?.pricelist_id == undefined ? null : `"${data?.pricelist_id}"`}
        hide_product_ids: "${data?.hide_product_ids}"
      }) {
        affected_rows
        returning {
          group_name
          id
        }
      }
    }`;

    if (data?.pricelist_id != null || data?.pricelist_id != undefined || data?.hide_product_ids) {
      
      const customerIds = data?.customer_ids;
      const CUSTOMER_MUTATION: any = `mutation myMutation {
        update_customers(where: { id: { _in: [${customerIds.map((id: any) => `"${id}"`).join(",")}] } },
        _set: {
          price_list: "${data?.pricelist_id}",
          hide_product_ids: ${data?.hide_product_ids  != "" || undefined ? `"${data?.hide_product_ids}"` : null  }
        }){
          affected_rows
            returning {
              id
            }
          }
      }`

    const queryValue = nhost.graphql.request(MUTATIONAPI);
    if (queryValue) {
      console.log(queryValue, "suces");
    }
    else {
      console.log("error");
    }
    const secondqueryValue =await nhost.graphql.request(CUSTOMER_MUTATION);
    if (secondqueryValue?.error == null) {
      console.log(secondqueryValue, "second");
      closePopup()
      open?.({
        key: "success",
        type: "success",
        message: "",

        description: `${data?.group_name} has been created 
        `,
      });
      setTimeout(() => {
        close("success");
        formProps?.form?.resetFields();
      }, 3000);
    }
    else {
      console.log("error");
      open?.({
        key: "error",
        type: "error",
        message: "",
        description: `Something Went Wrong ${secondqueryValue?.error}`,
      });
      setTimeout(() => {
        close("error");
      }, 5000);
    }
  }
    // if(data?.product_disable == true){
    //   const customerId = data?.customer_ids?.map((cusId:any) => {
    //    const hideProducts = data?.hide_product_ids?.map((id:any) => {
    //     return { customer_id: cusId, product_id: id,  created_by: "e613b876-ade3-44a9-b307-274712d190f5", ob_org_id: orgId,,}
    //   })
    //   const results:any = INSERT_CUSTOMER_PRODUCT_PRIVACY( { Object: hideProducts, });
    //   return hideProducts
    // })    
    // const customerId = data?.customer_ids?.map((id:any) => {
    // let hide = { customer_id: id, product_id: data?.hide_product_ids,  created_by: "e613b876-ade3-44a9-b307-274712d190f5", ob_org_id: orgId,}
    // const results:any = INSERT_CUSTOMER_PRODUCT_PRIVACY( { Object: hide, });
    // return hide
    // })
    // console.log(customerId,"customerId");
    
    // }
  }

  return (
    <Modal {...modalProps}
      onCancel={closePopup}
      visible={createPopup}
      title="Create Customer Group ">
      <Form {...formProps}
        wrapperCol={{ span: 23 }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Tabs>
          <TabPane tab="Group" key="1">
            <Form.Item
              name="group_name"
              label="Customer Group Name"
              rules={[{ required: true, message: 'Please input your name!' }]}>

              <Input placeholder="Enter the name" onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>

            {user?.defaultRole === "admin" ?
              <Form.Item
                label="Orderbit Organisation"
                name="ob_org_id"
                // style={{ display: "none" }}
                rules={[{ required: true, }]} >
                <Select {...OBOrgSelectProps} />
              </Form.Item> :
              <Form.Item
                name="ob_org_id"
                style={{ display: "none" }}
                initialValue={user?.metadata?.org_id}
              >
                <Input hidden />
              </Form.Item>
            }

            <Form.Item name="created_by"
              initialValue={user?.id}
              style={{ display: "none" }}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              label="Select Customers"
              name="customer_ids"
              rules={[{ required: true, message: 'Please select Customers!' }]}
            >
              <Select
                mode="multiple"
                showSearch
                onSearch={handleCustomerSearch}
                onChange={handleSelectedChange}
                filterOption={false}
              >
                {customers?.map((data: any) => {
                  return <Option key={data?.id}>{data?.name}</Option>
                })}
              </Select>
            </Form.Item>
          </TabPane>
          <TabPane tab="Pricelist" key="2">
            <Form.Item
              label="Select Price List"
              name="pricelist_id"
              rules={[{ required: true, message: 'Please select Price List!' }]}
            >
              <Select {...priceListSelectProps} />
            </Form.Item>
          </TabPane>
          <TabPane tab="Product Activity" key="3">
            <Form.Item
              label="Hide Products"
              name="hide_product_ids"
            >
              <Select
                mode="multiple"
                showSearch
                style={{ width: "100%" }}
                placeholder="Please select"
                // value={selectedValues}
                // onChange={handleSelectChange}
                // onSelect={onSelects}
                onSearch={handleProductSearch}
                filterOption={false}
              >
                {[...products]?.map((e: any, i: any) => {
                  return <Option key={e?.id}>{e?.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};
