import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
// import { Editor } from '@tinymce/tinymce-react';
import { Col, Row } from 'antd';
import {
  List,
} from "@pankod/refine-antd";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { nhost } from "utility";



type CreateProductProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const onChange = (key: string) => {
};

const onChangeCheckbox = (e: CheckboxChangeEvent) => {
};

interface SocialMediaProps {
  SocialMediaData?: {} | any
}

export const SocialMedia: React.FC<SocialMediaProps> = ({ SocialMediaData }) => {
  const [form] = Form.useForm();
  const { data: user } = useGetIdentity();
  const [open, setOpen] = useState(false)
  const userId = user?.metadata?.org_id;
  const { selectProps: OBProductSelectProps } = useSelect<IProduct>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const apidata = (e: any) => {
    const MUTATIONAPI = `mutation MyMutation {
        update_ob_orgs(_set: {facebook:"${e.facebook}",twitter:"${e.twitter}",linkedin:"${e.linkedin}",instagram:"${e.instagram}",pinterest:"${e.pinterest}"}, where: { id: { _eq: "${userId}" } }) {
          affected_rows
        }
      }
      `
    const onSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000);
      return data
    }
    onSubmit();
  }
  const handleCancel = () => {
    setOpen(true)
  }

  return (
    <>
      <Form
        // {...formProps}
        // name="basic"
        initialValues={SocialMediaData?.data?.data[0]}
        wrapperCol={{ span: 23 }}
        onFinish={(e) => {
          apidata(e)
        }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="facebook"
              label="Facebook">
              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="twitter"
              label="Twitter">

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="linkedin"
              label="Linkedin">

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="instagram"
              label="Instagram">

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="pinterest"
              label="Pinterest">

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item>
              <input type="submit" className="input-save" value="Save" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleCancel}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully Updated Social Media
          </span>
        </div>
      </Modal>
    </>

  );
};
