import {
  IResourceComponentsProps,
  useExport,
  CrudFilters,
  HttpError,
  useGetIdentity,
  useNotification,
} from "@pankod/refine-core";

import {
  List,
  RefreshButton,
  CreateButton,
  ExportButton,
  Table,
  DatePicker,
  Button,
  Card,
  Input,
  Form,
  FormProps,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  Popconfirm,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import { useEffect, useState } from "react";
import { IbrandProduct } from "interfaces";
import { IBrandFilterVariables } from "interfaces";
import { CreateBrand } from "./create";
import { EditBrand } from "./edit";
import {
  CheckOutlined,
  CloseOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { DELETE_BRANDS } from "query";
import { useNavigate } from "react-router-dom";
const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;

const { RangePicker } = DatePicker;

export const BrandsList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();
  const navigate = useNavigate();
  const userId = user?.metadata?.org_id;
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [brandId, setBrandId] = useState(null);
  const [maxViewOrder, setMaxViewOrder] = useState<any>(0);
  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    IbrandProduct,
    HttpError,
    IBrandFilterVariables
  >({
    resource: "brands",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "id",
        operator: "eq",
        value: brandId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "description",
        "image",
        "sort",
        "active",
        "private",
        "created_at",
        "access_customer_ids",
        "access_customergroup_ids",
        "featured_brand",
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name, created_at } = params;

      filters.push(
        {
          field: "name",
          operator: "eq",
          value: name,
        },
        {
          field: "created_at",
          operator: "gte",
          value: created_at ? created_at[0].toISOString() : undefined,
        },
        {
          field: "created_at",
          operator: "lte",
          value: created_at ? created_at[1].toISOString() : undefined,
        }
      );

      return filters;
    },
  });

  const { selectProps } = useSelect<any>({
    resource: "brands",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value: any) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  const { formProps, modalProps, show } = useModalForm({
    resource: "brands",
    action: "create",
    redirect: false,
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "brands",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "description",
        "image",
        "sort",
        "active",
        "private",
        "created_at",
        "access_customer_ids",
        "access_customergroup_ids",
        "featured_brand",
      ],
    },
  });

  const { triggerExport, isLoading } = useExport<IbrandProduct>();

  const handleFormSubmitted = () => setFormSubmitted(true);

  useEffect(() => {
    if (createModalVisible) {
      setFormSubmitted(false);
    }
  }, [createModalVisible]);

  useEffect(() => {
    if (!formSubmitted) return;
  }, [formSubmitted]);

  const handleCloseCreateModal = () => setCreateModalVisible(false);

  let datacount = tableQueryResult?.data?.total;

  const handleDelete = (data: any) => {
    DELETE_BRANDS(data).then((repsone: any) => {
      if (repsone) {
        open?.({
          key: "success",
          type: "success",
          message: "",
          description: `Successfully Deleted`,
        });
        setTimeout(() => {
          close("success");
        }, 4000);
        tableQueryResult?.refetch();
      }
    });
  };

  const handleSearch = (value: any) => {
    setBrandId(value);
  };

  const handleOnClearSearch = () => {
    setBrandId(null);
  };

  useEffect(() => {
    if (tableProps?.dataSource) {
      const maxViewOrder = tableProps?.dataSource?.reduce(
        (max: any, item: any) => {
          const viewOrder = item.sort;
          return viewOrder > max ? viewOrder : max;
        },
        0
      );
      if (maxViewOrder) {
        setMaxViewOrder(maxViewOrder);
      }
    }
  }, [tableProps]);

  const handleRefresh = () => {
    tableQueryResult?.refetch();
    navigate(0);
  };

  return (
    <List
      title="Brands"
      pageHeaderProps={{
        extra: [
          //  <ExportButton onClick={triggerExport} loading={isLoading} />,
          <>
            {/* <Filter formProps={searchFormProps} />, */}
            <Form
              layout="vertical"
              style={{ display: "flex", marginBottom: 17 }}
            >
              <Form.Item label="Brand">
                <Select
                  placeholder="Select a Brand"
                  style={{ width: 300, marginRight: 20 }}
                  onSelect={handleSearch}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearSearch}
                  {...selectProps}
                />
              </Form.Item>
            </Form>
            <RefreshButton
              type="primary"
              children={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ReloadOutlined style={{ marginRight: "5px" }} />
                  Refresh
                </div>
              }
              icon={false}
              onClick={handleRefresh}
            />
            ,
            <CreateButton
              type="primary"
              resourceName="catalogue/brands"
              children={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <PlusOutlined style={{ marginRight: "5px" }} />
                  Add Brand
                </div>
              }
              icon={false}
              onClick={() =>
                // show();
                setCreateModalVisible(true)
              }
            />
            ,
          </>,
        ],
      }}
    >
      <span>Records: {datacount}</span>
      <Table {...tableProps} rowKey="id" size="small">
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          title="active"
          dataIndex="active"
          render={(value) =>
            value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )
          }
        />
        <Table.Column
          title="Featured Brand"
          dataIndex="featured_brand"
          render={(value) =>
            value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )
          }
        />
        <Table.Column
          title="private"
          dataIndex="private"
          render={(value) =>
            value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )
          }
        />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        />
        <Table.Column<IbrandProduct>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                resourceName="catalogue/brands"
                onClick={() => editShow(record.id)}
              />
              {/* <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                resourceName="catalogue/brands"
                                recordItemId={record.id}
                            /> */}
              <Popconfirm
                title="Delete?"
                onConfirm={() => handleDelete(record.id)}
              >
                <a style={{ color: "red" }}>Delete</a>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      <CreateBrand
        tableQueryResult={tableQueryResult}
        handleFormSubmitted={handleFormSubmitted}
        closeCreateModal={handleCloseCreateModal}
        createModalVisible={createModalVisible}
        modalProps={modalProps}
        formProps={formProps}
        viewOrderMax={maxViewOrder}
      />
      <EditBrand modalProps={editModalProps} formProps={editFormProps} />
    </List>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" style={{ display: "flex" }} {...formProps}>
      <Form.Item style={{ marginLeft: 3 }} name="name">
        <Input
          placeholder="Enter the brand name"
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item style={{ marginLeft: 3 }} name="created_at">
        <RangePicker />
      </Form.Item>
      <Form.Item style={{ marginLeft: 3 }}>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
