import { useEffect, useState } from "react";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Checkbox,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

type CreateFaqProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const CreateRoute: React.FC<CreateFaqProps> = ({
  modalProps,
  formProps,
}) => {
  const { TextArea } = Input;
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;

  return (
    <Modal {...modalProps} title="Create Route">
      <Form {...formProps} size="large" layout="vertical">
        <Form.Item label="Route Name" 
          name="name"
          rules={[
            { required: true, message: "Route Name is required" },
            { max: 99, message: "Route Name cannot exceed 100 characters" },
          ]}
          >
          <Input  maxLength={100} max={99}/>
        </Form.Item>
        <Form.Item label="Description"
         name="description"
         rules={[
          { max: 500, message: "Description cannot exceed 500 characters" },
         ]}
         >
          <TextArea  maxLength={500} />
        </Form.Item>
        <Form.Item
          label=""
          name="is_active"
          initialValue={true}
          valuePropName="checked"
        >
          <Checkbox> Show </Checkbox>
        </Form.Item>
        {user ? (
          <>
            <Form.Item
              name="created_by"
              initialValue={user?.id}
              style={{ display: "none" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="ob_org_id"
              initialValue={userId}
              style={{ display: "none" }}
            >
              <Input />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
};
