import { useEffect, useState } from "react";
import { useLogin, useTranslate, useNotification, useNavigation } from "@pankod/refine-core";
import { Link, useNavigate } from 'react-router-dom'
import { useCustom, useApiUrl } from "@pankod/refine-core";
import { gql, GraphQLClient } from "graphql-request";

import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
    Alert
} from "@pankod/refine-antd";
import { useSignUpEmailPassword } from '@nhost/react'
import { nhost } from "utility";


import "./style.less";
import { jsonConverter } from "components/helpers/jsonConverter";
import { GET_USER } from "query";

const { Text, Title } = Typography;



export interface ISignupForm {
    username: string;
    password: string;
}

export interface ResponseErrorMessage {
    message: string;
}

export const SignupPage: React.FC = () => {
    const navigate = useNavigate();
  const route = useNavigation()
  const { open, close } = useNotification();
    const { mutate: login } = useLogin<ISignupForm>();

    const [form] = Form.useForm<ISignupForm>();
    const t = useTranslate();
    const [IsError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const signup = async (values: any) => {        
        setIsError(false);
        setErrorMessage('')

        /*
                let QUERY = gql`mutation userSignup {
                    (arg1: {password: "${values.password}", firstname: "${values.firstname}", lastname:"${values.lastname}",orgname:"${values.orgname}",email:"${values.email}"}){
                      returning{
                        error
                        session
                      }
                    }
                  }
                  `;
                  */
        let USERQUERY = `query MyQuery {
            users {
              email
            }
          }`;
        // let QUERY = gql`mutation userSignup {
        //     userSignup(arg1: {email: "${values.username}", firstname: "${values.firstname}", lastname: "${values.lastname}", orgname: "${values.orgname}", password: "${values.password}"}) {
        //      error
        //      session

        //    }
        //  }`
        let QUERY = gql`mutation MyMutation {
            insertUsers(objects: {email: "${values.username}", displayName:"${values.firstname}", avatarUrl: "${values.username}",locale:"en",emailVerified:${false},  disabled: ${false},phoneNumberVerified:${false},passwordHash: "${"hashedPassword"}"}) {
                affected_rows
                returning{
                    id
                    metadata
                }
                }
              }`;
        const MUTATIONAPI = `mutation MyMutation {
            insert_ob_orgs(objects: { email: "${values.username}",name: "${values.firstname + " " +  values?.lastname}", subdomain: "${values?.orgname}"}) {
              returning{
                id
                name
                address
              }
            }
          }`;
        // await nhost.graphql.request(USERQUERY).then((data:any)=>{
        // var exists;
        // data?.data?.users?.map((userData:any)=>{
        //   if(userData?.email == values.username){

        //   }else{
        //     exists = false;
        //   }
        // });
        // if(exists == false){
        const createUser = async () => {
            const result = await nhost.auth.signUp({
                email: values.username,
                password: values.password,
                options: {
                    defaultRole: "user",
                    displayName: `${values.firstname}`.trim(),
                    locale: "en",
                },
            }).then((res: any) => {
                GET_USER(values.username).then((user: any) => {
                    if(user?.users){
                        var user_id = user?.users[0]?.id
                        nhost.graphql.request(MUTATIONAPI).then((result: any) => {    
                            var org_id = { org_id: result?.data?.insert_ob_orgs?.returning[0]?.id }
                            if(result?.data == null || result?.error?.length > 0 || org_id == undefined){
                                open?.({
                                    key:"org_error",
                                    type: "error",
                                    message: "",
                                    description: `Something Went Wrong... Email already in use`,
                                });
                                  setTimeout(() => {
                                    close("org_error");
                                  }, 4000);
                        }
                            else {
                                const MUTATIONAPI = `mutation MyMutation {
                        updateUsers(where: {id: {_eq: "${user_id}"}}, _set: {metadata:${jsonConverter(org_id)},defaultRole:"owner", emailVerified:${true}}) {
                          affected_rows
                        }
                      }
                      `
                                nhost.graphql.request(MUTATIONAPI).then((user_res:any) => {
                                    if(user_res?.data == null || user_res?.error?.length > 0){
                                        open?.({
                                            key:"userUpdate_error",
                                            type: "error",
                                            message: "",
                                            description: `Something Went Wrong ${user_res?.error[0]?.message}`,
                                        });
                                          setTimeout(() => {
                                            close("userUpdate_error");
                                          }, 4000);
                                }
                                else{
                                    open?.({
                                        key:"signup_success",
                                        type: "success",
                                        message: "",
                                        description: `Successfully Registered`,
                                    });
                                    route?.push(`/login`)
                                      setTimeout(() => {
                                        close("success");
                                      }, 4000);
                                }
                                    let CREATEROLE = gql`mutation MyMutation {
                            insertAuthUserRoles(objects: {role: "owner", userId: "${user_id}"}) {
                              affected_rows
                              returning {
                                id
                              }
                            }
                          }`;
                                    //   nhost.graphql.request(CREATEROLE).then((res:any) => {
                                    //     console.log(res,"ress");

                                    //       login(values);

                                    //   })
                                })
                            }
                        })
                    }
                    else{
                        open?.({
                            key:"error",
                            type: "error",
                            message: "",
                            description: `Something Went Wrong ${res?.error?.message}`,
                        });
                          setTimeout(() => {
                            close("error");
                          }, 4000);
                    }
                })
            })
            console.log(result, "result");
        }
        createUser()
        // nhost.graphql.request(QUERY, {}, {
        //     headers: {
        //         "x-hasura-default-role": "owner",
        //         "x-hasura-role": "owner",
        //     },
        // }).then((data: any) => {
        //     console.log(data, 'dataaa');

        //     // var user_id = data?.data?.userSignup?.session?.user?.id
        //     var user_id = data?.data?.insertUsers?.returning[0]?.id
        //     console.log(user_id,"user_id");
        //     nhost.graphql.request(MUTATIONAPI).then((result: any) => {
        //         var org_id = { org_id: result?.data?.insert_ob_orgs?.returning[0]?.id }
        //         const MUTATIONAPI = `mutation MyMutation {
        //             updateUsers(where: {id: {_eq: "${user_id}"}}, _set: {metadata:${jsonConverter(org_id)},defaultRole:"owner", emailVerified:${true}}) {
        //               affected_rows
        //             }
        //           }
        //           `
        //         nhost.graphql.request(MUTATIONAPI).then(() => {
        //             let CREATEROLE = gql`mutation MyMutation {
        //                 insertAuthUserRoles(objects: {role: "owner", userId: "${user_id}"}) {
        //                   affected_rows
        //                   returning {
        //                     id
        //                   }
        //                 }
        //               }`;
        //                   nhost.graphql.request(CREATEROLE).then((res:any) => {
        //                     console.log(res,"ress");

        //                     //   login(values);

        //                   })
        //         })
        //     })
        // }).catch((err) => {
        //     setIsError(true);
        //     const responseError = err.error as ResponseErrorMessage
        //     setErrorMessage(responseError.message)
        // });
        // }else{
        //     setIsError(true);
        //     setErrorMessage('Email already exist.')
        // }
        // }).catch((err)=>{
        // })
    }

    const validateCompanyName = (_:any, value:any) => {
        if (!/^[A-Za-z]+$/.test(value)) {
          return Promise.reject('Company name can only contain letters.');
        }
        return Promise.resolve();
      };


    const CardTitle = (
        <div style={{ textAlign: "center" }}>
            <img
                style={{ width: "200px" }}
                src="/images/logo-dark.png"
                width="100%"
            />
        </div>

    );

    const validateEmail = (_:any, value:any) => {
        if (!/^[\w+.-]+@\w+(\.\w+)*(\s*,\s*[\w+.-]+@\w+(\.\w+)*)*$/.test(value)) {
          return Promise.reject('Please enter valid email address');
        }
        return Promise.resolve();
      };

    return (
        <AntdLayout>
            <Row
                justify="center"
                align="middle"
                style={{
                    background: "#4EA144",
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div style={{ maxWidth: "408px", margin: "auto" }}>

                        <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                            {IsError && <Alert style={{ marginBottom: '10px' }} message={errorMessage} type="error" showIcon />}

                            <Form<ISignupForm>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    signup(values);
                                }}
                                requiredMark={false}

                            >
                                <Form.Item
                                    name="firstname"
                                    label={t("pages.login.firstname", "First Name")}
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder=""
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="lastname"
                                    label={t("pages.login.lastname", "Last Name")}
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        size="large"
                                        placeholder=""
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="username"
                                    label={t("pages.login.email", "Work Email")}
                                    rules={[{ required: true },{ validator: validateEmail },]}
                                    // rules={[{ required: true, message: 'Please input your email!' }]}
                                    >
                                    <Input
                                        defaultValue="@mail.com"
                                        size="large"
                                        placeholder=""
                                        type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="orgname"
                                    label={t("pages.login.company", "Company Name")}
                                    rules={[{ required: true },{ validator: validateCompanyName },]}
                                >
                                    <Input
                                        size="large"
                                        placeholder=""
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={t(
                                        "pages.login.password",
                                        "Password",
                                    )}
                                    rules={[{ required: true }]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input
                                        type="password"
                                        placeholder=""
                                        size="large"
                                        defaultValue=""
                                    />
                                </Form.Item>


                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                >
                                    {t("pages.login.signup", "Sign Up")}
                                </Button>
                            </Form>
                            <Row>
                                <Col >
                                    <div style={{ justifyContent: "center", marginTop: "10px" }}>
                                        Have an account already?
                                        {' '}
                                        <a href="/login">
                                            {t(
                                                "pages.login.login",
                                                "Login",
                                            )}
                                        </a>
                                    </div>



                                </Col>
                            </Row>

                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};