import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber, Checkbox, useSelect, Select
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import { useEffect, useState } from 'react';
import { UploadProps, Upload, message } from 'antd';
import { CheckCircleOutlined, PictureOutlined } from '@ant-design/icons';
import { nhost } from "utility";

type EditPagesProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    tableQueryResult: any;
    closeEditModal: () => void;
    editModalVisible: boolean;
};

const { TextArea } = Input;
const { Dragger } = Upload;

const props: UploadProps = {
    beforeUpload: (file) => {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSizeInBytes) {
        message.error('File size exceeds the limit (10MB).');
        return false;
      }
      return true;
    },
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
    },
};

const onChange = (key: string) => {
};

export const EditPages: React.FC<EditPagesProps> = ({
    modalProps,
    formProps,
    tableQueryResult,
    closeEditModal,
    editModalVisible
}) => {
    const { data: user } = useGetIdentity();
    const [urlList, setUrlList] = useState([] as any[]);
    const userId = user?.metadata?.org_id;
    const [open, setOpen] = useState(false)
    const handleCancel = () => {
        setOpen(false)
    }

    

    const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
        resource: "ob_orgs",
        fetchSize: 1000,
        optionLabel: "name",
        optionValue: "id",
        metaData: {
            fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc", },]
    });
    const [textBoxModal, setTextBoxModal] = useState(false);
    const handleActiveCheck = () => {
        setTextBoxModal(!textBoxModal);
    };
    useEffect (() =>{
        if(formProps.initialValues?.customer || formProps.initialValues?.privacy_group){
            setTextBoxModal(true)
        }
        setUrlList([formProps.initialValues?.file])
    },[formProps])

    const { selectProps:customerSelectProps } = useSelect<any>({
        resource: "customers",
        filters: [
          {
            field: "ob_org_id",
            operator: "eq",
            value: userId,
          },
        ],
        metaData: {
          fields: ["id", "name"],
        },
        optionLabel: "name",
        optionValue: "id",
    
        onSearch: (value) => [
          {
            field: "name",
            operator: "contains",
            value: "%" + value + "%",
          },
        ],
      });
      const { selectProps:privacyGroupSelectProps } = useSelect<any>({
        resource: "customer_groups",
        filters: [
          {
            field: "ob_org_id",
            operator: "eq",
            value: userId,
          },
        ],
        metaData: {
          fields: ["id", "group_name"],
        },
        optionLabel: "group_name",
        optionValue: "id",
    
        onSearch: (value) => [
          {
            field: "group_name",
            operator: "contains",
            value: "%" + value + "%",
          },
        ],
      });

      const uploadImages = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options;
        try {
          {
            const data = new FormData()
            data.append("file", file);
            data.append("upload_preset", "bt5y9hp4")
            data.append("cloud_name", "dov89ugan")
            data.append("folder", "orderbit")
            fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
              method: "post",
              body: data
            })
              .then(resp => resp.json())
              .then(data => {
                setUrlList(urlList => [...urlList, data.secure_url]);
              })
              .catch(err => console.log(err));
            onSuccess("Ok");
          }
        } catch (err) {
          const error = new Error("Some error");
          onError({ err });
        }
      };

      const apidata = (e: any) => {
        e.footer_menu = e.footer_menu === undefined ? false :  e.footer_menu
        e.header_menu = e.header_menu === undefined ? false :  e.header_menu
        e.header_submenu = e.header_submenu === undefined ? false :  e.header_submenu
        e.Active = e.Active === undefined ? false :  e.Active

        let viewOrder = e?.view_order ?? "0";
        const MUTATIONAPI = `mutation MyMutation {
            update_pages(where: {id: {_eq: "${formProps.initialValues?.id}"}},_set: { name: "${e.name}",view_order:"${viewOrder}" file: "${e.file}", header_menu: ${e.header_menu}, header_submenu: ${e.header_submenu},footer_menu: ${e.footer_menu}, ob_org_id: "${user?.metadata?.org_id}",
            content: "${e.content}",meta_description: "${e.meta_description}",active: ${e.active},login: ${e.login},private: ${e.private},customer: "${e.customer}",privacy_group: "${e.privacy_group}",
        }){
            affected_rows
            }
        }
        `
        const imageSubmit = async () => {
          const { data } = await nhost.graphql.request(MUTATIONAPI)
    
          if (data) {
            // handleFormSubmitted()
            setOpen(true)
            setTimeout(() => {
              setOpen(false)
            }, 4000);
            tableQueryResult?.refetch()
            closeEditModal()
            return data;
          }
        }
        imageSubmit();
        // closeModal();
      }

    return (
        <>
        <Modal {...modalProps} title="Edit Pages" 
            onCancel={closeEditModal}
            visible={editModalVisible}
        >
            <Form {...formProps} layout="vertical"onFinish={(e) => {
                e.file = urlList.join(",")
                apidata(e)
            }} >
                <Form.Item
                    label="Name"
                    name="name"
                    style={{ paddingBottom: '5px' }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <div style={{ display: 'flex', float: 'left', paddingBottom: '5px' }}>
                    <Form.Item label="" name="header_menu" valuePropName="checked">
                        <Checkbox> Show at header menu </Checkbox>
                    </Form.Item>
                    <Form.Item label="" name="header_submenu" valuePropName="checked">
                        <Checkbox> Show at header sub menu </Checkbox>
                    </Form.Item>
                    <Form.Item label="" name="footer_menu" valuePropName="checked">
                        <Checkbox> Show at footer menu </Checkbox>
                    </Form.Item>
                </div>
                {user?.defaultRole === "admin" ?
                    <Form.Item
                        style={{ width: '100%', paddingBottom: '5px' }}
                        label="Orderbit Organisation"
                        name="ob_org_id"
                        rules={[{}]} >
                        <Select {...OBOrgSelectProps} />
                    </Form.Item> : ""}
                <Form.Item
                    name="content"
                    label="Content"
                    rules={[{}]}
                    style={{ width: '100%', paddingBottom: '5px' }}
                >
                    {/* <Editor
            apiKey='your-api-key'
            // onInit={(evt, editor) => editorRef.current = editor}
            initialValue="<p>This is the initial content of the editor.</p>"
            init={{
              height: 200,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          /> */}
                    <TextArea />
                </Form.Item>
                <Form.Item
                    name="meta_description"
                    label="Meta description"
                    rules={[{}]}
                    style={{ paddingBottom: '5px' }}
                >
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    name="view_order"
                    label="View Order"
                    rules={[{required:true}]}
                    style={{ paddingBottom: '5px' }}
                >
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <div style={{ display: 'flex', float: 'left', width: '100%', paddingBottom: '5px' }}>

                    <Form.Item label="" name="active" valuePropName="checked">
                        <Checkbox> Active </Checkbox>
                    </Form.Item>
                    <Form.Item label="" name="login" valuePropName="checked">
                        <Checkbox> Login Required  </Checkbox>
                    </Form.Item>
                    <Form.Item label="" name="private" valuePropName="checked" style={{ marginTop: '5px' }}>
                        <Checkbox checked = {textBoxModal} onClick={() => handleActiveCheck()}> Private ( By making a banner private, it will be visible only to selected customers. ) </Checkbox>
                    </Form.Item>
                </div>
                {textBoxModal === true ?
                     <>
                     <Form.Item
                       label="Customers"
                       name="customer"
                      //  rules={[{required: true}]}
                       style={{ width: '100%' }}
                     >
                       <Select {...customerSelectProps} mode="multiple"/>
                     </Form.Item>
                     <Form.Item
                       label="Privacy groups"
                       name="privacy_group"
                      //  rules={[{required: true}]}
                     >
                       <Select {...privacyGroupSelectProps} mode="multiple"/>
                     </Form.Item>
                   </>
                    : ""}
                 <div style={{ marginTop: '50px' }}>
            <Dragger {...props} name="file" customRequest={uploadImages}
              style={{ width: '100%', marginTop: '30px' }}>
              <p className="ant-upload-drag-icon">
                <PictureOutlined />
              </p>
              <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
            </Dragger>
          </div>
          <Form.Item
            style={{ width: '100%' }}
          >
            <h1 style={{ marginTop: '10px', fontSize: "14px" }}>Uploaded image will be displayed here</h1>
            {urlList.map((url) => {
              return <img style={{ width: '10%', padding: '5px' }} src={url} />
            })}
          </Form.Item>
          <Form.Item name="created_by"
            initialValue={user?.id}
          >
            <Input type="hidden" />
          </Form.Item>
                <Form.Item name="updated_by"
                    initialValue={user?.id}>
                    <Input type="hidden" />
                </Form.Item>
            </Form>
        </Modal>
        <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleCancel}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully Update pages</span>
        </div>
      </Modal>
      </>
    );
};
