import { useState, useEffect } from 'react';
import {useGetIdentity,HttpError, useNotification,} from "@pankod/refine-core";
import {Form,Input,ModalProps,FormProps,Tabs,Modal,Button,Checkbox,Popconfirm,Table,} from "@pankod/refine-antd";
import { gql } from "graphql-request";
import { jsonConverter } from 'components/helpers/jsonConverter';
import { nhost } from 'utility';


type EditUserProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    closeEditModal: () => void;
    createEditModal: boolean;
    // tableQueryResult: any;
}
export const UserEdit: React.FC<EditUserProps> = ({modalProps,formProps,closeEditModal,createEditModal,}) => {
 
    const [form] = Form.useForm();  
    const { data: user } = useGetIdentity();
    const { open, close } = useNotification();

    const [getFormData, setGetFormData] = useState(false);

    useEffect(() => {
        console.log(formProps?.initialValues,"formProps?.initialValues");
        if(formProps?.initialValues){
            setGetFormData(true);
        }
        else if(!createEditModal){
            setGetFormData(false);
        }        
    },[formProps?.initialValues]);

    const apidata = (e:any) => {

        let UPDATEQUERY = gql`mutation MyMutation {
            updateUsers(where: {id: {_eq: "${formProps?.initialValues?.id}"}},
             _set: {metadata:${jsonConverter(e.metadata)},displayName: "${e.displayName}",email:"${e.email}",  
            }) {
                returning{ metadata }
               }
            }
           `;
          nhost.graphql.request(UPDATEQUERY).then((updateUser: any) => {
            if (updateUser?.error == null) {
                open?.({ key: "success", type: "success", message: "", description: `Successfully User Updated`,});
                setTimeout(() => { close("success"); }, 3000);
                closeEditModal();
            }
            else {
                open?.({ key: "error", type: "error", message: "", description: `${updateUser?.error}`,});
                setTimeout(() => { close("error"); }, 3000);
            }
        });
    }
     
    const validateCompanyName = (_:any, value:any) => {
        if (!/^[\w+.-]+@\w+(\.\w+)*(\s*,\s*[\w+.-]+@\w+(\.\w+)*)*$/.test(value)) {
          return Promise.reject('Please enter valid email address');
        }
        return Promise.resolve();
      };

    return (
        <Modal
            {...modalProps}
            title="Edit User"
            visible={createEditModal}
            onCancel={closeEditModal}
            footer={false}
        >
            {getFormData ? 
            <Form
                {...formProps}
                // form={form}
                onFinish={(e) => {
                    apidata(e);
                }}
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item
                    name="displayName"
                    label="First Name"
                    rules={[{ required: true, message: 'Please input your First Name!' }]}
                >
                    <Input size="large" placeholder="" />
                </Form.Item>
                <Form.Item
                    name={["metadata","last_name"]}
                    label="Last Name"
                    rules={[{ required: true, message: 'Please input your Last Name!' }]}

                >
                    <Input size="large" placeholder="" />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Work Email"
                    rules={[{ required: true },{ validator: validateCompanyName },]}
                    // rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                    <Input size="large" placeholder="" type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" />
                </Form.Item>

                <Form.Item
                    name={["metadata","company_name"]}
                    label="Company Name"
                    rules={[{ required: true, message: 'Please input your Company Name!' }]}
                >
                    <Input size="large" placeholder="" />
                </Form.Item>
                <Form.Item
                    name={["metadata","org_id"]}
                    label="Org Name"
                    style={{display: "none"}}
                >
                    <Input size="large" placeholder="" />
                </Form.Item>
                {/* <Form.Item
                    name="password"
                    label="Password"
                    style={{ marginBottom: "12px" }}
                >
                    <Input type="password" placeholder="" size="large" />
                </Form.Item> */}
                {user ?
                    <Form.Item name="updated_by" initialValue={user?.id}>
                        <Input type="hidden" />
                    </Form.Item>
                    : <></>
                }
                <Form.Item
                >
                    <div>
              <input type="submit" className="input-save" 
                style={{backgroundColor: "#4EA144", color: "#fff",}}
                value="Save" />
                <Button style={{float: "right", marginRight: "10px"}} onClick={closeEditModal}>Cancel</Button>
                </div>
            </Form.Item>
            </Form>
            : <></> }
        </Modal>
    )
}