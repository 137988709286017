import { Checkbox, Col, Form, FormProps, Input, Modal, ModalProps, Row, Select } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

type CreateAdditionalChanrgeProps = {
    modalProps: ModalProps;
    formProps: FormProps;
  };

export const CreateAdditionalChanrge: React.FC<CreateAdditionalChanrgeProps> = ({
    modalProps,
    formProps,
  }) =>{
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;

    return (
      <Modal {...modalProps} title="Create Additional Charges">
        <Form {...formProps}
          wrapperCol={{ span: 23 }}
          autoComplete="off"
          layout="vertical"
        >
          <Row>
            <Col span={12}>

              <Form.Item
                name= "charges_name"
                label ="Charges Name"
                rules={[{ required: true, }]}
              >
                <Input style={{ width: "100%" }}/>
              </Form.Item>
              <Form.Item
                name= "charges_type"
                label ="Charges Type"
                rules={[{ required: true, }]}
              >
                <Select>
                  <Select.Option value="amount" name="Amount">Amount</Select.Option>
                  <Select.Option value="percentage" name="Percentage">Percentage</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name= "rate"
                label ="Rate"
                rules={[{ required: true, }]}
              >
                <Input style={{ width: "100%" }}/>
              </Form.Item>
              <Form.Item name="is_active" valuePropName="checked">
                <Checkbox>
                  Active
                </Checkbox>
              </Form.Item>
            {user ? 
            <>
            <Form.Item
                    name="created_by"
                    initialValue={user?.id}
                >
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item
                    name="ob_org_id"
                    initialValue={userId}
                >
                    <Input type="hidden" />
                </Form.Item>
                </>
                : <></> }
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }