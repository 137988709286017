
import { Refine, useGetIdentity } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-nhost";
import { authProvider } from "./utility/authProvider";
import { accessProvider } from "utility/accessProvider";
import { NhostAuthProvider } from "@nhost/react-auth";
import { LoginPage } from './pages/auth';
import { SignupPage } from './pages/auth/signup';
import { nhost } from "utility";
import {
  Title,
  Header,
  Sider,
  Footer,
  OrderBitLayout,
  OffLayoutArea,
} from "components/layout";
import { CataloguePage } from "pages/catalogue";
import { CustomerPage } from "pages/customers/router"
import { MasterPage } from "pages/master";
import { StorePage } from "pages/store";
import { SettingsPage } from "pages/settings";
import "styles/antd.less";
import { OrdersPage } from "pages/orders/router";
import { AuthPermissionProvider } from "contaxt/menuContext";
import { DashboardPage } from "pages/dashboard/router";

function App() {

  return (
    <NhostAuthProvider nhost={nhost}>
      <AuthPermissionProvider>
        <Refine
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          dataProvider={dataProvider(nhost)}
          authProvider={authProvider}
          LoginPage={LoginPage}

          Title={Title}
          Header={Header}
          Sider={Sider}
          Footer={Footer}
          Layout={OrderBitLayout}
          OffLayoutArea={OffLayoutArea}
          resources={[
            { name: "dashboard", list: DashboardPage },
            { name: "orders", list: OrdersPage},
            // { name: "products", list: CataloguePage,},
            // { name: "orgs", list: OrgsList }, 
            // { name: "dashboard", list: DashboardPage},
          ]}
          routerProvider={
            {
            ...routerProvider,
            routes: [
              {
                element: <MasterPage />,
                path: "/master/*",
              },
              {
                element: <OrderBitLayout><CataloguePage /></OrderBitLayout>,
                path: "/catalogue/*",
              },
              {
                element:  <OrderBitLayout> <DashboardPage  /> </OrderBitLayout>,
                path: "/dashboard/*",
              },
              {
                element: <OrderBitLayout><OrdersPage /></OrderBitLayout>,
                path: "/order/*",
              },
              {
                element: <OrderBitLayout><CustomerPage/></OrderBitLayout>,
                path: "/customers/*",
              },
              {
                element: <OrderBitLayout><StorePage/></OrderBitLayout>,
                path: "/store/*",
              },
              {
                element: <OrderBitLayout><SettingsPage/></OrderBitLayout>,
                path: "/settings/*",
              },
              {
                element: <SignupPage />,
                path: "/signup",
              },
              {
                element: <ErrorComponent />,
                path: "*",
              },

            ] as typeof routerProvider.routes,
          }}
          accessControlProvider={accessProvider}
        />
      </AuthPermissionProvider>
    </NhostAuthProvider>
  );
}

export default App;
