import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
// import {IOrderStatus} from "interfaces";
import { useState } from "react";
const { TextArea } = Input;

type EditMeasurementUnitProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};


export const EditMeasurementUnit: React.FC<EditMeasurementUnitProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const [notify, setNotify] = useState(false);
  const onChange = (key: string) => {
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setNotify(e.target.checked);

  };
  return (
    <Modal {...modalProps} title="Edit Measurement Unit">
      <Form {...formProps}
        wrapperCol={{ span: 23 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col span={12}>

            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please input your name!' }]}>

              <Input placeholder="Enter the name" onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item name="updated_by"
              initialValue={user?.id}
            >
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
