import {
  IResourceComponentsProps,
  useExport,
  CrudFilters,
  HttpError,
  useGetIdentity,
} from "@pankod/refine-core";
import {
  List,
  RefreshButton,
  CreateButton,
  ExportButton,
  Table,
  useTable,
  ShowButton,
  Space,
  EditButton,
  DeleteButton,
  DateField,
  getDefaultSortOrder,
  useModalForm,
  Icons,
  Col,
  DatePicker,
  Card,
  Form,
  Select,
  Input,
  Button,
  FormProps,
  useSelect,
  Row,
  TableProps,
  Popconfirm,
  Modal,
} from "@pankod/refine-antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { CatalogueMenu } from "components/setup/catalogue/menu";
import { ICategory, IPrice, IProduct, IProductFilterVariables } from "interfaces";
import { CreateProduct } from "./create";
import { EditProduct } from "./edit";
import { useEffect, useState } from "react";
import { GET_ALL_PRODUCTS, ORG_NAME } from "../../../components/../query";
import { Delete_Product } from "../../../query";
import { Console, log } from "console";
import { ProductShow } from "./show";
import { useNavigation, } from "@pankod/refine-core";
import { AuthPermission } from "utility/authPermission";

const {
  PlusOutlined,
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  CheckOutlined,
  CloseOutlined,
} = Icons;
const { RangePicker } = DatePicker;
interface FILTERS {
  field:String,
  operator:String,
  value:String
}

export const ProductsList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const route = useNavigation();
  const userId = user?.metadata?.org_id;
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [createEditModalVisible, setCreateEditModalVisible] = useState(false);
    const [showModalVisible, setshowModalVisible] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formEditSubmitted, setFormEditSubmitted] = useState(false);
  const [allProducts, setProducts]: any = useState({});

  const [open, setOpen] = useState(false);
  const [myCategory, setMyCategory] = useState<any>(null);
  const [products, setNewProducts] = useState<any>(null);

  const [menuPermission, setPermissions] = useState<any>(true)

  useEffect(()=>{
    if(user){
      AuthPermission(user?.metadata?.org_id).then((res: any) =>{
        setPermissions(res)
      })
    }
  },[user])
  const [showProductFilter, setShowProductFilter] = useState<boolean>(false);

  const [categoriesFilters, setCategoriesFilters] = useState<FILTERS[]>([
    {
      field: "category_id",
      operator: "eq",
      value: myCategory,
    },
  ]);
  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    IProduct,
    HttpError,
    IProductFilterVariables
  >({
    resource: "products",
    initialPageSize: 10,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },{
        field:"category_id",
        operator:"eq",
        value:myCategory
      },{
        field:"id",
        operator:"eq",
        value:products
      }
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "category_id",
        { category: ["name"] },
        "ob_org_id",
        { org: ["name"] },
        "description",
        "image",
        "length",
        "width",
        "height",
        "msrp",
        "minimum_quantity",
        "maximum_quantity",
        "quantity",
        "allow_backorder",
        "track_inventory",
        "box_quantity",
        "status",
        "vat_class",
        "is_active",
        "promote_category",
        "featured_product",
        "deals",
        "product_id",
        "buy_with",
        "created_at",
        "product_unique_id",
        "customer_price",
        "access_customer_ids",
        "access_customergroup_ids",
        "specifications",
        "tags",
        "tax_id",
      ],
    },

    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name, created_at } = params;

      filters.push(
        {
          field: "name",
          operator: "contains",
          value: name !== "" ? name : null,
        },
        {
          field: "created_at",
          operator: "gte",
          value: created_at ? created_at[0].toISOString() : undefined,
        },
        {
          field: "created_at",
          operator: "lte",
          value: created_at ? created_at[1].toISOString() : undefined,
        }
      );
      return filters;
    },
  });

  const { tableProps: newtableProps, sorter: newSorter, tableQueryResult: newTableQueryResult, searchFormProps: newSearchFormProps } = useTable<
    IProduct,
    HttpError,
    IProductFilterVariables
  >({
    resource: "products",
    initialPageSize: 10,
    initialCurrent: 1,
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },{
        field:"category_id",
        operator:"eq",
        value:myCategory
      },{
        field:"id",
        operator:"eq",
        value:products
      }
    ],
    metaData: {
      fields: [
        "id",
        "name",
        "category_id",
        { category: ["name"] },
        "ob_org_id",
        { org: ["name"] },
        "description",
        "image",
        "length",
        "width",
        "height",
        "msrp",
        "minimum_quantity",
        "maximum_quantity",
        "quantity",
        "allow_backorder",
        "track_inventory",
        "box_quantity",
        "status",
        "vat_class",
        "is_active",
        "promote_category",
        "featured_product",
        "deals",
        "product_id",
        "buy_with",
        "created_at",
        "product_unique_id",
        "customer_price",
        "access_customer_ids",
        "access_customergroup_ids",
        "specifications",
        "tags",
        "tax_id",
      ],
    },

    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name, created_at } = params;

      filters.push(
        {
          field: "name",
          operator: "contains",
          value: name !== "" ? name : null,
        },
        {
          field: "created_at",
          operator: "gte",
          value: created_at ? created_at[0].toISOString() : undefined,
        },
        {
          field: "created_at",
          operator: "lte",
          value: created_at ? created_at[1].toISOString() : undefined,
        }
      );
      return filters;
    },
  });

  const getProducts = async () => {
    GET_ALL_PRODUCTS().then((response: any) => {
      setProducts(response);
    });
  };
  useEffect(() => {
    getProducts();
  }, []);

  const allprod = allProducts;

  const [dataSource, setDataSource] = useState<readonly IProduct[] | undefined>(
    undefined
  );
  const [newTableProps, setNewTableProps]: any = useState(tableProps);
  const [searchTableProps, setSearchTableProps]: any = useState([]);

  const [currentModalId, setCurrentModalId] = useState<null | string>(null);
  let searchedValue = searchFormProps.form?.getFieldValue("name");

  useEffect(() => {    
        getProducts();
    if (!dataSource?.length) {
      return;
    }

    if (searchedValue) {
      const newDataSource = allprod?.products?.filter((item: any) =>
       item?.name?.toLowerCase().includes(searchedValue.toLowerCase()) || item?.categories?.toLowerCase().includes(searchedValue.toLowerCase())
      );
      setNewTableProps((prev: any) => ({ ...prev, dataSource: newDataSource }));
      setSearchTableProps(newDataSource);
      return;
    }

    if (searchedValue === "") {
      setSearchTableProps([]);
      setNewTableProps(tableProps);
    }
  }, [searchFormProps?.form?.getFieldValue("name"), open]);
    
    // useEffect(() => {
    //     getProducts();
    // },[tableProps]);

  useEffect(() => {
    if (!newTableProps.dataSource) {
      setNewTableProps(tableProps);
    }
    if (tableProps.dataSource?.length) {
      setDataSource(tableProps.dataSource);
    }
  }, [newTableProps, tableProps.dataSource?.length, open == true]);

  let datacount = tableQueryResult?.data?.total;

  const { formProps, modalProps, show } = useModalForm({
    resource: "products",
    action: "create",
    redirect: false,
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "products",
    action: "edit",
    redirect: false,
    metaData: {
      fields: [
        "id",
        "name",
        "category_id",
        "code",
        "description",
        "image",
        "temp_img", "length",
        "width",
        "height",
        "msrp",
        "minimum_quantity",
        "maximum_quantity",
        "quantity",
        "allow_backorder",
        "track_inventory",
        "box_quantity",
        "status",
        "vat_class",
        "brand_id",
        "is_active",
        "customer_price",
        "related_products",
        "upccode",
        "barcode",
        "referencecode",
        "packagequantity",
        "metadescription",
        "searchkeywords",
        "product_status_rules",
        "promote_category",
        "featured_product",
        "deals",
        "status_product",
        "product_unique_id",
        "buy_with",
        "access_private",
        "access_customer_id",
        "access_private_group",
        "price_details",
        "created_at",
        "access_customer_ids",
        "access_customergroup_ids",
        "specifications",
        "tags",
        "tax_id",
      ],
    },
  });
    const {
        formProps: showFormProps,
        modalProps: showModalProps,
        show: ShowPopup,

    } = useModalForm({
        resource: "products",
        action: 'clone',
        redirect: false,
        metaData: {
            fields: ["id", "name", "category_id", "code", "description", "image", "temp_img", "length", "width", "height", "msrp", "minimum_quantity", "maximum_quantity", "quantity", "allow_backorder", "track_inventory", "box_quantity", "status", "vat_class", "brand_id", "is_active", "customer_price", "related_products", "upccode", "barcode", "referencecode", "packagequantity", "metadescription", "searchkeywords", "product_status_rules", "promote_category", "featured_product", "deals",
                "status_product", "product_unique_id", "buy_with", "access_private", "access_customer_id", "access_private_group", "price_details", "created_at","access_customer_ids", "access_customergroup_ids","specifications","tags",],
        },
    });

    const { selectProps: vatClassSelectProps } = useSelect<IPrice>({
      resource: "vat_class",
      fetchSize: 1000,
      optionLabel: "name",
      optionValue: "id",
      filters: [
        {
          field: "ob_org_id",
          operator: "eq",
          value: userId
        },
      ],
      metaData: {
        fields: ["id", "name"],
      },
      sort: [{ field: "name", order: "asc", },]
    });
  const { triggerExport, isLoading } = useExport<IProduct>();

  const handleFormSubmitted = () => setFormSubmitted(true);
  const handleEditFormSubmitted = () => setFormEditSubmitted(true);

useEffect(()=>{
  if(myCategory){
    setShowProductFilter(true); 
  }else{
     setShowProductFilter(false);
     setNewProducts(null);
  }
},[myCategory])


  useEffect(() => {
    setCategoriesFilters([
      {
        field: "category_id",
        operator: "eq",
        value: myCategory,
      },
    ]);

  }, [myCategory]);
  useEffect(()=>{
    if(myCategory){
      setCategoriesFilters([{
        field:"category_id",
        operator:"eq",
        value:myCategory
      },{
        field:"id",
        operator:"eq",
        value:products
      }])
    }
  },[products])

  const handleSearch = (e: any) => {
    setMyCategory(e)
  };
  const handleOnClearSearch = () => {
    setMyCategory(null);
  };
  const handleProductSelect = (value: any) => {
     setNewProducts(value);
  };
  const handleOnClearProducts = ()=>{
    setNewProducts(null)
  }

  const { selectProps:productSelectProps } = useSelect<any>({
    resource: "products",
  filters: myCategory
    ? [
        {
          field: "category_id",
          operator: "eq",
          value: myCategory,
        },
      ]
    : [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
  ],
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",

    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
    // filters: [
    //   {
    //     field: "category_id",
    //     operator: "eq",
    //     value: myCategory,
    //   },
    // ],
  });

  const { selectProps:categorySelectProps } = useSelect<any>({
    resource: "categories",
    filters: [
      {
        field: "is_active",
        operator: "eq",
        value: true,
      },
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      // {
      //   field:'category_id',
      //   operator:'contains',
      //   value:myCategory
      // }
    ],
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",

    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });
  // console.log("data is  categroes", categorySelectProps);

  useEffect(() => {
    if (createModalVisible) {
      setFormSubmitted(false);
    }
  }, [createModalVisible]);
  useEffect(() => {
    if (createEditModalVisible) {
      setFormEditSubmitted(false);
    }
  }, [createEditModalVisible]);

  // useEffect(() => {
  //   if (!formSubmitted) return;
  // }, [formSubmitted]);
  // useEffect(() => {
  //   if (!formEditSubmitted) return;
  // }, [formEditSubmitted]);

  //   }, [formEditSubmitted])

    const handleCloseCreateModal = () => setCreateModalVisible(false)
    const handleCloseEditCreateModal = () => setCreateEditModalVisible(false)
    const handleCloseShowModal = () => setshowModalVisible(false)

    const handleDelete = (data: any) => {
        Delete_Product(data).then((repsone) => {
            if (repsone) {
                setOpen(true);

        setTimeout(() => {
          setOpen(false);
        }, 4000);
        
        tableQueryResult?.refetch();
      }
    });
  };

  return (
    <List
      title="Products"
      pageHeaderProps={{
        extra: [
          <>
            <Form layout="vertical" style={{ display: "flex" }}>
              <Form.Item label="Category">
                <Select
                  placeholder="Select a Category"
                  style={{ width: 250, marginRight: 20 }}
                  onSelect={handleSearch}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearSearch}
                  {...categorySelectProps}
                />
              </Form.Item>
              <Form.Item label="Product">
                <Select
                  placeholder="Select a Product"
                  style={{ width: 300, marginRight: 20 }}
                  onSelect={handleProductSelect}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearProducts}
                  {...productSelectProps}
                />
              </Form.Item>
            </Form>
            {/* <Filter formProps={searchFormProps} /> */}
            {/* <RefreshButton
                style={{ marginTop: "15px" }}
                onClick={() => tableQueryResult?.refetch()}
              /> */}
            {/* , */}
            {!menuPermission &&
            <CreateButton
              style={{ marginTop: "15px" }}
              type="primary"
              children={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <PlusOutlined style={{ marginRight: "5px" }} />
                  Add Product
                </div>
              }
              icon={false}
              resourceName="catalogue/products"
              onClick={() => setCreateModalVisible(true)}
            />
             }
            {/* , */}
          </>,
        ],
      }}
    >
      <span>Records: {datacount}</span>
      {myCategory || products ? (
        <Table
          style={{ marginTop: "5px" }}
          {...newtableProps}
          rowKey="id"
          size="small"
        >
          {/* <Table.Column dataIndex="product_unique_id" title="Product Id" />
        <Table.Column dataIndex="name" title="Name" sorter />
        <Table.Column<IProduct>
          dataIndex="category_id"
          title="Category"
          render={(_, record) => record?.category?.name}
          defaultSortOrder={getDefaultSortOrder("category_id", sorter)}
          sorter
        />
        <Table.Column dataIndex="description" title="Description" sorter />
        <Table.Column dataIndex="box_quantity" title="Box Quantity" sorter />
        <Table.Column dataIndex="status" title="Status" sorter />
        <Table.Column dataIndex="vat_class" title="Vat Class" sorter />
        <Table.Column
          title="Is active"
          dataIndex="is_active"
          key="is_active"
          render={(value) =>
            value == true ? (
              <CheckOutlined style={{ color: "#6ead65" }} />
            ) : (
              <CloseOutlined style={{ color: "#ff7875" }} />
            )
          }
        />
        <Table.Column
          dataIndex="created_at"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
          sorter
        /> */}
          <Table.Column dataIndex="name" title="Name" sorter />
          <Table.Column<IProduct>
            dataIndex="category_id"
            title="Category"
            render={(_, record) => record?.category?.name}
            defaultSortOrder={getDefaultSortOrder("category_id", sorter)}
            sorter
          />
          {/* <Table.Column dataIndex="description" title="Description" sorter />
                  <Table.Column dataIndex="box_quantity" title="Box Quantity" sorter /> */}
          <Table.Column dataIndex="status" title="Status" sorter />
          <Table.Column
            dataIndex="vat_class"
            title="Vat Class"
            render={(value) => {
              const findRoute = vatClassSelectProps?.options?.find(
                (res: any) => value == res?.value
              );
              return findRoute?.label;
            }}
          />
          <Table.Column
            title="Is active"
            dataIndex="is_active"
            key="is_active"
            render={(value) =>
              value == true ? (
                <CheckOutlined style={{ color: "#6ead65" }} />
              ) : (
                <CloseOutlined style={{ color: "#ff7875" }} />
              )
            }
          />
          <Table.Column
            dataIndex="created_at"
            title="Created At"
            render={(value) => <DateField value={value} format="LLL" />}
            defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
            sorter
          />
          <Table.Column dataIndex="product_unique_id" title="Product Id" />
          <Table.Column<IProduct>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => {
              return (
                <Space>
                  {!menuPermission &&
                  <EditButton
                    size="small"
                    type="link"
                    hideText={true}
                    resourceName="catalogue/products"
                    recordItemId={record.id}
                    onClick={() => {
                      editShow(record.id);
                      setCreateEditModalVisible(true);
                      setCurrentModalId(record.id);
                    }}
                  />}
                  {menuPermission &&
                  <ShowButton
                    size="small"
                    type="link"
                    hideText={true}
                    resourceName="catalogue/products"
                    recordItemId={record.id}
                    onClick={() => {
                      editShow(record.id);
                      setCreateEditModalVisible(true);
                      setCurrentModalId(record.id);
                    }}
                  />}
                  {!menuPermission &&
                  <>
                  <Popconfirm
                    title="Delete?"
                    onConfirm={() => handleDelete(record.id)}
                  >
                    <a style={{ color: "red" }}>Delete</a>
                  </Popconfirm>
                  <ShowButton
                    size="small"
                    type="link"
                    hideText={true}
                    resourceName="products"
                    // onClick={() => route?.push(`./show/${record.id}`)}
                    recordItemId={record.id}
                    onClick={() => {
                      ShowPopup(record.id);
                      setshowModalVisible(true);
                    }}
                  />
                  </>
                  }
                </Space>
              );
            }}
          />
        </Table>
      ) : (
        <Table
          style={{ marginTop: "5px" }}
          {...tableProps}
          rowKey="id"
          size="small"
        >
          {/* <Table.Column dataIndex="product_unique_id" title="Product Id" />
          <Table.Column dataIndex="name" title="Name" sorter />
          <Table.Column<IProduct>
            dataIndex="category_id"
            title="Category"
            render={(_, record) => record?.category?.name}
            defaultSortOrder={getDefaultSortOrder("category_id", sorter)}
            sorter
          />
          <Table.Column dataIndex="description" title="Description" sorter />
          <Table.Column dataIndex="box_quantity" title="Box Quantity" sorter />
          <Table.Column dataIndex="status" title="Status" sorter />
          <Table.Column dataIndex="vat_class" title="Vat Class" sorter />
          <Table.Column
            title="Is active"
            dataIndex="is_active"
            key="is_active"
            render={(value) =>
              value == true ? (
                <CheckOutlined style={{ color: "#6ead65" }} />
              ) : (
                <CloseOutlined style={{ color: "#ff7875" }} />
              )
            }
          />
          <Table.Column
            dataIndex="created_at"
            title="Created At"
            render={(value) => <DateField value={value} format="LLL" />}
            defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
            sorter
          /> */}
          <Table.Column dataIndex="name" title="Name" sorter />
          <Table.Column<IProduct>
            dataIndex="category_id"
            title="Category"
            render={(_, record) => record?.category?.name}
            defaultSortOrder={getDefaultSortOrder("category_id", sorter)}
            sorter
          />
          {/* <Table.Column dataIndex="description" title="Description" sorter />
                    <Table.Column dataIndex="box_quantity" title="Box Quantity" sorter /> */}
          <Table.Column dataIndex="status" title="Status" sorter />
          <Table.Column
            dataIndex="vat_class"
            title="Vat Class"
            render={(value) => {
              const findRoute = vatClassSelectProps?.options?.find(
                (res: any) => value == res?.value
              );
              return findRoute?.label;
            }}
          />
          <Table.Column
            title="Is active"
            dataIndex="is_active"
            key="is_active"
            render={(value) =>
              value == true ? (
                <CheckOutlined style={{ color: "#6ead65" }} />
              ) : (
                <CloseOutlined style={{ color: "#ff7875" }} />
              )
            }
          />
          {!menuPermission &&
          <Table.Column
            dataIndex="created_at"
            title="Created At"
            render={(value) => <DateField value={value} format="LLL" />}
            defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
            sorter
          />
          }
          <Table.Column dataIndex="product_unique_id" title="Product Id" />
          <Table.Column<IProduct>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => {
              return (
                <Space>
                  {menuPermission &&
                  <ShowButton
                    size="small"
                    type="link"
                    hideText={true}
                    resourceName="catalogue/products"
                    recordItemId={record.id}
                    onClick={() => {
                      editShow(record.id);
                      setCreateEditModalVisible(true);
                      setCurrentModalId(record.id);
                    }}
                  />}
                  {!menuPermission &&
                  <EditButton
                    size="small"
                    type="link"
                    hideText={true}
                    resourceName="catalogue/products"
                    recordItemId={record.id}
                    onClick={() => {
                      editShow(record.id);
                      setCreateEditModalVisible(true);
                      setCurrentModalId(record.id);
                    }}
                  />}
                  {!menuPermission &&
                  <Popconfirm
                    title="Delete?"
                    onConfirm={() => handleDelete(record.id)}
                  >
                    <a style={{ color: "red" }}>Delete</a>
                  </Popconfirm>
                  }
                  {!menuPermission &&
                  <ShowButton
                    size="small"
                    type="link"
                    hideText={true}
                    resourceName="products"
                    // onClick={() => route?.push(`./show/${record.id}`)}
                    recordItemId={record.id}
                    onClick={() => {
                      ShowPopup(record.id);
                      setshowModalVisible(true);
                    }}
                  />
                  }
                </Space>
              );
            }}
          />
        </Table>
      )}
      <CreateProduct
        tableQueryResult={tableQueryResult}
        handleFormSubmitted={handleFormSubmitted}
        closeCreateModal={handleCloseCreateModal}
        createModalVisible={createModalVisible}
        modalProps={modalProps}
        formProps={formProps}
      />
      <EditProduct
        currentModalId={currentModalId}
        tableQueryResult={tableQueryResult}
        handleEditFormSubmitted={handleCloseEditCreateModal}
        closeEditCreateModal={handleCloseEditCreateModal}
        createEditModalVisible={createEditModalVisible}
        modalProps={editModalProps}
        formProps={editFormProps}
      />
      <ProductShow
        closeShowModal={handleCloseShowModal}
        showModalVisible={showModalVisible}
        modalProps={showModalProps}
        formProps={showFormProps}
      />
      <Modal
        visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        footer={null}
        width="22%"
        style={{
          position: "absolute",
          marginTop: "-5%",
          marginLeft: "75%",
          gridRow: "auto",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>
          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%" }}>Product has been removed</span>
        </div>
      </Modal>
    </List>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const [searchText, setSearchText] = useState("")
    return (
        <Form layout="vertical" {...formProps} style={{ display: 'flex' }}>

            <Form.Item label="Search" name="name">
                <Input
                    allowClear
                    placeholder="Name, Category"
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item style={{ marginLeft: '5px', marginTop: '20px' }}>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>

        </Form>
    );
};
