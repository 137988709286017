
import { IResourceComponentsProps,useExport, CrudFilters, HttpError, useGetIdentity, useNotification } from "@pankod/refine-core";
import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    DatePicker,
    Button,
    Card,
    Input,
    Form,
    FormProps,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm,Icons, Popconfirm
} from "@pankod/refine-antd";
import { IPaymentOptions } from "interfaces";
import { CreatePaymentOption } from "./create";
import { EditPaymentOption } from "./edit";
import { ReloadOutlined } from "@ant-design/icons";
import { DELETE_PAYMENT_OPTIONS } from "query";
const { PlusOutlined,MailOutlined, AppstoreOutlined, SettingOutlined,CheckOutlined, CloseOutlined  } = Icons;


const { RangePicker } = DatePicker;

export const PaymentOptionsList: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
    const { open, close } = useNotification();
    const userId = user?.metadata?.org_id;
    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IPaymentOptions>({
        resource: "payment_options",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
        ],
        metaData: {
            fields: ["id", "name", "description","is_active","private","view_order","payment_fee_percentage","payment_fee_amount","secrete_keys"],
        },
    });
    
    const { formProps, modalProps, show } = useModalForm({
        resource: "payment_options",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,
        
    } = useModalForm({
        resource: "payment_options",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "name", "description","is_active","private","view_order","payment_fee_percentage","payment_fee_amount","secrete_keys"],
        },
    });

    const { triggerExport, isLoading } = useExport<IPaymentOptions>();
    let datacount = tableQueryResult?.data?.total;

    const handleDelete = (data: any) => {
        DELETE_PAYMENT_OPTIONS(data).then((repsone) => {
          if (repsone) {
            open({
                key: "delete_success",
                type: "success",
                message: "",
                description: "Successfully Deleted"
            });
            setTimeout(() => {
              close("delete_success");
            }, 2000);
            tableQueryResult?.refetch();
          }
        });
      };

    return (
        <List 
        title="Payment Options"
            pageHeaderProps={{

                extra: [
                  //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                    <RefreshButton
                        type="primary"
                        children={<div style={{ display: "flex", alignItems: "center" }}>
                            <ReloadOutlined style={{ marginRight: "5px" }} />
                            Refresh
                        </div>}
                        icon={false}
                        onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" resourceName="settings/paymentoptions"
                     children={<div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Add Payment Option
                  </div>}
                  icon={false}
                     onClick={() => show()} />,
                ],

            }}
        >
            <span>Records: {datacount}</span>
            <Table {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="name" title="Name" />
                <Table.Column dataIndex="description" title="Description" />
                <Table.Column dataIndex="view_order" title="View Order" />
                <Table.Column dataIndex="payment_fee_percentage" title="Payment Fee Percentage" />
                <Table.Column dataIndex="payment_fee_amount" title="Payment Fee Amount" />
                <Table.Column dataIndex="is_active" title="Is active"
                render={(value) => (value == true ? <CheckOutlined style={{ color: '#6ead65' }} /> : <CloseOutlined style={{ color: '#ff7875' }} />)}
                />
                <Table.Column dataIndex="private" title="Private"
                render={(value) => (value == true ? <CheckOutlined style={{ color: '#6ead65' }} /> : <CloseOutlined style={{ color: '#ff7875' }} />)}
                />
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<IPaymentOptions>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                resourceName="settings/paymentoptions"
                                onClick={() => editShow(record.id)}
                            />
                            <Popconfirm
                              title="Delete?"
                              onConfirm={() => handleDelete(record.id)}
                            >
                              <a style={{ color: "red" }}>Delete</a>
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
            <CreatePaymentOption modalProps={modalProps} formProps={formProps} />
            <EditPaymentOption modalProps={editModalProps} formProps={editFormProps} />

        </List>
    );
};
