import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import { useState } from "react";
const { TextArea } = Input;

type EditProductStatusProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};


export const EditProductStatus: React.FC<EditProductStatusProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const [order, setOrder] = useState(false);
  const [view, setView] = useState(false);

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const onChange = (key: string) => {
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setOrder(e.target.checked);
  };
  const onChangeViewCheckbox = (e: CheckboxChangeEvent) => {
    setView(e.target.checked);
  };

  return (
    <Modal {...modalProps} title="Edit Product Status">
      <Form {...formProps}
        wrapperCol={{ span: 23 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Row>
          <Col span={12}>

            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please input your name!' }]}>

              <Input placeholder="Enter the name" onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="view_order"
              label="View Order">

              <InputNumber placeholder="Enter the order count" defaultValue="0" onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item >
            <Form.Item name='can_order' valuePropName="checked">
              <Checkbox
              //  checked={order} onChange={onChangeCheckbox}
              >Can Order?</Checkbox>
            </Form.Item >
            <Form.Item name='product_grid_view' valuePropName="checked">
              <Checkbox
              // checked={view} onChange={onChangeViewCheckbox}
              >Show at product grid view</Checkbox>
            </Form.Item>
            <Form.Item
              name="bg_color"
              label="Background Color"
              rules={[{ required: true, }]}>

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="text_color"
              label="Text Color"
              rules={[{ required: true, }]}>

              <Input onChange={(e) => onChange} style={{ width: "100%" }} />
            </Form.Item>
            {/* <Form.Item
                label="Orderbit Organisation"
                name="ob_org_id"
                rules={[{ required: true, }]} >
                <Select {...OBOrgSelectProps} />
                 </Form.Item> */}
            <Form.Item name="updated_by"
              initialValue={user?.id}
            >
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
