import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
// import { Editor } from '@tinymce/tinymce-react';
import { Col, Row } from 'antd';
import {
  List,
} from "@pankod/refine-antd";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import "./pages.css"
import { nhost } from "utility";





type CreateProductProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface CustomerRegistrationProps {
  CustomerRegistrationFieldData?: {} | any
}




export const CustomerRegisterField: React.FC<CustomerRegistrationProps> = ({ CustomerRegistrationFieldData }) => {
  const [form] = Form.useForm();
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [customerPhone, setCustomerPhone] = useState(false)
  const [customerActivity, setCustomerActivity] = useState(false)
  const [customerAddress, setCustomerAddress] = useState(false)
  const [customeAddress2, setCustomerAddress2] = useState(false)
  const [customerCity, setCustomerCity] = useState(false)
  const [customerProvince, setCustomerProvince] = useState(false)
  const [customerCountry, setCustomerCountry] = useState(false)
  const [customerPostal, setCustomerpostal] = useState(false)
  const [customerCompanyNum, setCustomerCompanyNum] = useState(false)
  const [customerVat, setCustomerVat] = useState(false)
  const [customerWebsite, setCustomerWebsite] = useState(false)
  const [open, setOpen] = useState(false)
  const [intialFormData, setFormData] = useState<any>([])




  const PhoneCheckbox = (e: CheckboxChangeEvent) => {
    setCustomerPhone(e.target.checked)
  };
  const ActivityCheckbox = (e: CheckboxChangeEvent) => {
    setCustomerActivity(e.target.checked)
  };
  const addressCheckbox = (e: CheckboxChangeEvent) => {
    setCustomerAddress(e.target.checked)
  };
  const address2Checkbox = (e: CheckboxChangeEvent) => {
    setCustomerAddress2(e.target.checked)
  };
  const cityCheckbox = (e: CheckboxChangeEvent) => {
    setCustomerCity(e.target.checked)
  };
  const provinceCheckbox = (e: CheckboxChangeEvent) => {
    setCustomerProvince(e.target.checked)
  };
  const countryCheckbox = (e: CheckboxChangeEvent) => {
    setCustomerCountry(e.target.checked)
  };
  const postalCheckbox = (e: CheckboxChangeEvent) => {
    setCustomerpostal(e.target.checked)
  };
  const companyNumCheckbox = (e: CheckboxChangeEvent) => {
    setCustomerCompanyNum(e.target.checked)
  };
  const vatCheckbox = (e: CheckboxChangeEvent) => {
    setCustomerVat(e.target.checked)
  };
  const websiteCheckbox = (e: CheckboxChangeEvent) => {
    setCustomerWebsite(e.target.checked)
  };



  const { selectProps: OBProductSelectProps } = useSelect<IProduct>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  useEffect(() => {
    setFormData(CustomerRegistrationFieldData?.data?.data[0])
    form.setFieldsValue(CustomerRegistrationFieldData?.data?.data[0])
  }, [CustomerRegistrationFieldData?.data])


  const apidata = (e: any) => {
    const {
      customer_phone,
      company_activity,
      customer_address,
      customer_address_2,
      customer_city,
      customer_province,
      customer_country,
      customer_postal_code,
      customer_comapny_number,
      customer_vat_number,
      customer_website
    } = form.getFieldsValue();

    const MUTATIONAPI =

      `mutation MyMutation {
        update_ob_orgs(_set: {customer_phone:${customer_phone},company_activity:${company_activity},customer_address:${customer_address}, customer_address_2:${customer_address_2}, customer_city:${customer_city},customer_province:${customer_province},customer_country:${customer_country} ,customer_postal_code:${customer_postal_code},customer_comapny_number:${customer_comapny_number},customer_vat_number:${customer_vat_number},customer_website:${customer_website} }, where: { id: { _eq: "${userId}" } }) {
          affected_rows
        }
      }`

    const onSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000);
      return data
    }
    onSubmit();

  }

  const handleCancel = () => {
    setOpen(false)
  }



  return (
    <>
      <Form
        // {...formProps}
        // name="basic"
        form={form}

        wrapperCol={{ span: 23 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        onFinish={(e) => {
          apidata(e)
        }}
      >
        <Row>
          <Col span={12}>
            <p>Check the fields that should be mandatory upon registration</p>
            <Form.Item name="customer_phone" valuePropName="checked">
              <Checkbox name="customer_phone"
                checked={customerPhone} onChange={PhoneCheckbox}
              >Phone</Checkbox>
            </Form.Item>
            <Form.Item name="company_activity" valuePropName="checked">
              <Checkbox name="company_activity"
                checked={customerActivity} onChange={ActivityCheckbox}
              >Company Activity</Checkbox>
            </Form.Item>
            <Form.Item name="customer_address" valuePropName="checked">
              <Checkbox name="customer_address"
                checked={customerAddress} onChange={addressCheckbox}
              >Address</Checkbox>
            </Form.Item>
            <Form.Item name="customer_address_2" valuePropName="checked">
              <Checkbox name="customer_address_2"
                checked={customeAddress2} onChange={address2Checkbox}
              >Address Line 2</Checkbox>
            </Form.Item>
            <Form.Item name="customer_city" valuePropName="checked">
              <Checkbox name="customer_city"
                checked={customerCity} onChange={cityCheckbox}
              >City</Checkbox>
            </Form.Item>
            <Form.Item name="customer_province" valuePropName="checked">
              <Checkbox name="customer_province"
                checked={customerProvince} onChange={provinceCheckbox}
              >Province</Checkbox>
            </Form.Item>
            <Form.Item name="customer_country" valuePropName="checked">
              <Checkbox name="customer_country"
                checked={customerCountry} onChange={countryCheckbox}
              >Country</Checkbox>
            </Form.Item>
            <Form.Item name="customer_postal_code" valuePropName="checked">
              <Checkbox name="customer_postal_code"
                checked={customerPostal} onChange={postalCheckbox}
              >Postal Code</Checkbox>
            </Form.Item>
            <Form.Item name="customer_comapny_number" valuePropName="checked">
              <Checkbox name="customer_comapny_number"
                checked={customerCompanyNum} onChange={companyNumCheckbox}
              >Company Number</Checkbox>
            </Form.Item>
            <Form.Item name="customer_vat_number" valuePropName="checked">
              <Checkbox name="customer_vat_number"
                checked={customerVat} onChange={vatCheckbox}
              >VAT number</Checkbox>
            </Form.Item>
            <Form.Item name="customer_website" valuePropName="checked">
              <Checkbox name="customer_website"
                checked={customerWebsite} onChange={websiteCheckbox}
              >Website</Checkbox>
            </Form.Item>
            <Form.Item>
              {/* <Button style={{float:'right'}}>Save</Button> */}
              <input type="submit" className="input-save"
                value="Save" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleCancel}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully Created Customer Registration</span>
        </div>
      </Modal>
    </>

  );
};