import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal, Col, Row,
  Checkbox, useSelect, Select, DatePicker
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import dayjs from 'dayjs'
import { Editor } from '@tinymce/tinymce-react';
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import { useState } from "react";
import moment, { Moment } from 'moment';

type CreateNewsProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};
const { TextArea } = Input;
const { RangePicker } = DatePicker;
export const CreateNews: React.FC<CreateNewsProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write",);

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });
  
  return (
    <Modal {...modalProps} title="Create News">
      <Form {...formProps} size="large" layout="vertical">
        {/* <Row>
          <Col xs={12}> */}
            <Form.Item
              label="Title"
              name="title"
              // style={{ width: '80%' }}
              rules={[
                { required: true, message: "Title Name is required" },
                { max: 50, message: "Title cannot exceed 50 characters" },
              ]}
            >
              <Input max="50" maxLength={51}/>
            </Form.Item>
            <Form.Item
              label="Summary"
              name="summary"
              // style={{ width: '80%' }}
              rules={[
                { required: true, message: "Summary is required" },
                { max: 500, message: "Summary cannot exceed 500 characters" },
              ]}
            >
              <Input max="500" maxLength={501}/>
            </Form.Item>
            <Form.Item
              label="Date"
              name="date"
              // style={{ width: '100%' }}
              rules={[{ required: true }]}
              getValueProps={value => ({ 
                value: value ? dayjs(value) : '', 
              })}
            >
              <DatePicker
                // style={{ width: '80%' }}
                disabledDate={(current) => {
                  return current && current < moment().startOf('day');
                  }}
              />
            </Form.Item>
            <div style={{ display: 'flex', float: 'left' }}>
              <Form.Item label="" name="important" valuePropName="checked">
                <Checkbox> Important </Checkbox>
              </Form.Item>
              <Form.Item label="" name="show" valuePropName="checked" style={{ marginLeft: '25px' }}>
                <Checkbox> Show </Checkbox>
              </Form.Item>
            </div>
          {/* </Col>
          <Col xs={12}> */}
            {user ? <>
              {user?.defaultRole === "admin" ?
                <Form.Item
                  label="Orderbit Organisation"
                  name="ob_org_id"
                  rules={[{}]} >
                  <Select {...OBOrgSelectProps} />
                </Form.Item>
                :
                <Form.Item
                  name="ob_org_id"
                  initialValue={userId}
                >
                  <Input type="hidden" />
                </Form.Item>
              }
              <Form.Item name="created_by"
                initialValue={user?.id}
                style={{display: "none"}}
              >
                <Input type="hidden" />
              </Form.Item>
            </> : <></>}
            <Form.Item
              label="Content"
              name="content"
              rules={[{}]}
              style={{ width: '100%', height: '100%' }}
            >
              {/* <Editor
                // apiKey='your-api-key'
                // onInit={(evt, editor) => editorRef.current = editor}
                initialValue="<p>This is the initial content of the editor.</p>"
                init={{
                  height: 250,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              /> */}
          {/* <TextArea /> */}
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown: any) =>
              Promise.resolve(
                <ReactMarkdown>{markdown}</ReactMarkdown>,
              )
            }
          />
        </Form.Item>
          {/* </Col>
        </Row> */}
      </Form>
    </Modal>
  );
};
