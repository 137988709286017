
import { IResourceComponentsProps, CrudFilters, useMany, HttpError, useExport } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons, Form, DatePicker, Input, Button, FormProps
} from "@pankod/refine-antd";
import { CatalogueMenu } from "components/master/catalogue/menu";
import { IProduct, IProductFilterVariables } from "interfaces";
import { CreateProduct } from "./create";
import { EditProduct } from "./edit";
const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;
const { RangePicker } = DatePicker;

export const ProductsList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IProduct, HttpError, IProductFilterVariables>({
        resource: "ob_products",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: ["id", "name", "created_at"],
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { name, created_at } = params;

            filters.push(
                {
                    field: "name",
                    operator: "eq",
                    value: name !== '' ? name : null,
                },
                {
                    field: "created_at",
                    operator: "gte",
                    value: created_at ? created_at[0].toISOString() : undefined,
                },
                {
                    field: "created_at",
                    operator: "lte",
                    value: created_at ? created_at[1].toISOString() : undefined,
                },
            );

            return filters;
        },
    });

    let datacount = tableQueryResult?.data?.total;

    const { formProps, modalProps, show } = useModalForm({
        resource: "ob_products",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,

    } = useModalForm({
        resource: "ob_products",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "name", "created_at"],
        },
    });

    const { triggerExport, isLoading } = useExport<IProduct>();


    return (
        <List
            title="Orderbit Products Master"
            pageHeaderProps={{

                extra: [
                    <Filter formProps={searchFormProps} />,
                    //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                    <RefreshButton style={{ marginTop: '15px' }} onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton style={{ marginTop: '15px' }} type="primary" children="Add OB Product" resourceName="master/products" icon={<PlusOutlined />} onClick={() => show()} />,
                ],

            }}
        >

            <span>Records: {datacount}</span>

            <Table style={{ marginTop: '5px' }} {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="name" title="Name" />
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<IProduct>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                resourceName="master/products"
                                icon={null}
                                recordItemId={record.id}
                                onClick={() => editShow(record.id)}
                            />
                            <DeleteButton
                                size="small"
                                resourceName="master/products"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>

            <CreateProduct modalProps={modalProps} formProps={formProps} />
            <EditProduct modalProps={editModalProps} formProps={editFormProps} />
        </List>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    return (
        <Form layout="vertical" {...formProps} style={{ display: 'flex' }}>
            <Form.Item label="Search" name="name">
                <Input
                    allowClear
                    placeholder="ID, Title, Content, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="Created At" name="created_at" style={{ marginLeft: '5px' }}>
                <RangePicker />
            </Form.Item>
            <Form.Item style={{ marginLeft: '5px', marginTop: '20px' }}>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};