import { useNavigation, useShow, IResourceComponentsProps, useExport, CrudFilters, useMany, HttpError, useGetIdentity } from "@pankod/refine-core";
import {
  List,
  RefreshButton,
  Table,
  Select,
  Space,
  DeleteButton,
  Icons,
  Typography,
  DateField,
  useModalForm,
  useModal,
  CreateButton,
  EditButton, FormProps, Form, Input, Button, DatePicker, useTable, useSelect
} from "@pankod/refine-antd";
import { TableColumnsType, Tag } from "antd";
import { getAllUsers } from "users";
import { useState, useEffect } from "react";
import { IUser, IPostUserFilterVariables } from "interfaces";
import { UserCreate } from "pages/master/users/create";
import { UserEdit } from "pages/master/users/edit";
import { ReloadOutlined } from "@ant-design/icons";
import { GET_ALL_CUSTOMERS_WITH_ORGID } from "query";
const { PlusOutlined, AppstoreOutlined, CopyOutlined } = Icons;
const { Text, Link } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;




export const UserManagementTable = () => {
  const { show } = useNavigation();
  const [refresh, setRefresh] = useState(false);
  const [userList, setUserList] = useState([] as any[]);
  const [userRecord, setUserRecord] = useState([]);
  const [customers, setCustomers] = useState<any>([]);
  const [getUser, setGetUser] = useState<any>([])
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [getAllUser, setGetAllUser] = useState<any>([])
  const [customerId, setCustomerId] = useState<any>();
  const [searchedCustomer, setSearchedCustomer] = useState<any>();

  // const { selectProps } = useSelect<any>({
  //   resource: "users",
  //   metaData: {
  //     fields: ["id", "name"],
  //   },
  //   optionLabel: "name",
  //   optionValue: "id",
  //   filters:[
  //     {
  //       field:"ob_org_id",
  //       operator:"eq",
  //       value:userId
  //     }
  //    ],
  //   onSearch: (value) => [
  //     {
  //       field: "name",
  //       operator: "contains",
  //       value: "%" + value + "%",
  //     },
  //   ],
  // });
  // const { searchFormProps } = useTable<IUser, HttpError, IPostUserFilterVariables>({
  //   resource: "users",
  //   permanentFilter: [
  //     {
  //         field: "ob_org_id",
  //         operator: "eq",
  //         value: userId,
  //     },
  // ],
  //   metaData: {
  //     fields: [
  //       "id",
  //       "disabled",
  //       "displayName",
  //       "defaultRole",
  //       "email",
  //       // "password",
  //     ],
  //   },
  //   onSearch: (params: any) => {
  //     const filters: CrudFilters = [];
  //     const { displayName, created_at } = params;

  //     filters.push(
  //       {
  //         field: "displayName",
  //         operator: "eq",
  //         value: displayName !== '' ? displayName : null,
  //       },
  //       {
  //         field: "created_at",
  //         operator: "gte",
  //         value: created_at ? created_at[0].toISOString() : undefined,
  //       },
  //       {
  //         field: "created_at",
  //         operator: "lte",
  //         value: created_at ? created_at[1].toISOString() : undefined,
  //       },
  //     );

  //     return filters;
  //   },
  // });
  // const metaData = {
  //   fields: [
  //     "id",
  //     "disabled",
  //     "displayName",
  //     "defaultRole",
  //     "email",
  //     "password",
  //   ],
  // };
  const getUsers = async () => {
    getAllUsers().then((response: any) => {
      setUserList(response.users);
    });
  };

  const getCustomers = () => {
    GET_ALL_CUSTOMERS_WITH_ORGID(userId).then((data:any) => {
      if(data){        
        setCustomers(data?.customers)
      }
    })
  }

  useEffect(() => {
    getUsers();
  }, [refresh]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getCustomers();
  }, [user]);

  useEffect(() => {
    if (customers && userList) {
      const getUser = userList?.filter((user: any) =>
        customers?.some((cust: any) => cust?.customer_org_id === user?.metadata?.org_id)
      )
      if(getUser){
        setGetUser(getUser);
        setGetAllUser(getUser);
      }
    }
  }, [customers, userList]);



  const getUserDetails = (record: any) => {
    setUserRecord(record);
    editShow();
  };
  const {
    modalProps,
    formProps,
    close: close,
    show: createShow,
  } = useModalForm({
    resource: "users",
    action: "create",
    autoSubmitClose: true,
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
    close: editClose,
  } = useModalForm({
    // resource: "users",
    action: "edit",
  });
  const columns: TableColumnsType<IUser> = [
    // {
    //   dataIndex: "id",
    //   title: "Id",

    // },
    {
      dataIndex: "displayName",
      title: "User Name",
    },
    {
      dataIndex: "email",
      title: "Email id",
    },
    {
      dataIndex: "defaultRole",
      title: "Role",
    },
    {
      dataIndex: "disabled",
      title: "Active",
      render: (value) => (
        <Tag color={value ? "red" : "green"} key={value}>
          {value ? "INACTIVE" : "ACTIVE"}
        </Tag>
      ),

      //  return (
      //       <Tag color={color} key={tag}>
      //         {tag.toUpperCase()}
      //       </Tag>
      //     );
    },
    {
      dataIndex: "createdAt",
      title: "Created_at",
      render: (value) => <DateField format="DD-MMM-YY" value={value} />,
    },
    {
      title: "Updated_at",
      dataIndex: "updatedAt",
      render: (value) => <DateField format="DD-MMM-YY" value={value} />,
    },
    {
      title: "Last seen",
      dataIndex: "lastSeen",
      render: (value) =>
        value !== null ? <DateField format="DD-MMM-YY" value={value} /> : "",
    },
    // {
    //   title: "Actions",
    //   dataIndex: "actions",
    //   render: (_, record) => (
    //     <Space>

    //       <EditButton
    //         size="small"
    //         type="link"
    //         hideText
    //         title="Edit"
    //         resourceName="settings/users"
    //         recordItemId={record.id}
    //         onClick={() => {
    //           getUserDetails(record);
    //           // editShow(record.id);
    //         }}
    //       />
    //     </Space>
    //   ),
    // },
  ];

  const onChange = (page: number, pageSize?: number): void => {
    // setCurrent(page)
    ;
  };
  const refreshData = () => {
    setRefresh(!refresh);
  };

  const handleCustomerSearch = (e:any) => {
    const searchCustomer = getUser?.filter((data:any) => data?.displayName.toLowerCase().includes(e.toLowerCase()))
    if(searchCustomer?.length !== 0){
      setGetAllUser([...searchCustomer])      
    }
  }

  const handleSearch = (e: any) => {
    setCustomerId(e);
    const findCustomer = getUser?.find((data:any) => data?.id == e)
    if(findCustomer){
      setSearchedCustomer([findCustomer])
    }
  };

  const handleOnClearSearch = () => {
    setCustomerId(null);
    setGetAllUser([...getUser])
  };
  
  return (
    <List
    title = {""}
      pageHeaderProps={{
        extra: [
          <>
           {/* <Filter formProps={searchFormProps} />, */}
          <Form layout="vertical">
              <Form.Item label="Name">
                <Select
                  showSearch
                  placeholder="Select a User"
                  style={{ width: 300, marginRight: 20 }}
                  onSelect={handleSearch}
                  onSearch={handleCustomerSearch}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearSearch}
                  filterOption={false}
                  >
                    {[...getAllUser]?.map((data:any) => {
                      return <Option key={data?.id}>{data?.displayName}</Option>
                    })}
                  </Select>
              </Form.Item>
            </Form>
          <RefreshButton style={{ marginTop: '15px' }}
          type="primary"
          children={<div style={{ display: "flex", alignItems: "center" }}>
                    <ReloadOutlined style={{ marginRight: "5px" }} />
                    Refresh
                  </div>}
                  icon={false}
                  onClick={() => getUsers()} />
           {/* <CreateButton
            style={{ marginTop: '15px' }}
            type="primary"
            resourceName="settings/users"
            children={<div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Create
                  </div>}
                  icon={false}
            onClick={() => createShow()} 
          /> */}
          </>
        ]
      }}
    >
      <Table
        size="small"
        columns={columns}
        dataSource={customerId ? searchedCustomer : getUser}
        pagination={{
          total: customerId ? false : getUser?.length,
          onChange,
        }}
      />

      <UserCreate
        modalProps={modalProps}
        formProps={formProps}
        close={close}
        refreshData={refreshData}
      />
      <UserEdit
        modalProps={editModalProps}
        formProps={editFormProps}
        close={editClose}
        record={userRecord}
        refreshData={refreshData}
      />


    </List>
  );
};



const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps} style={{ display: 'flex' }}>
      <Form.Item label="Search" name="displayName">
        <Input
          allowClear
          placeholder="ID, Title, Content, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Created At" name="created_at" style={{ marginLeft: '5px' }}>
        <RangePicker />
      </Form.Item>
      <Form.Item style={{ marginLeft: '5px', marginTop: '20px' }}>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};