import { Col, DatePicker, Form, Input, List, Row, Select, Table, useTable, useSelect, Button, Modal, getDefaultSortOrder, useModalForm, Space, ShowButton } from '@pankod/refine-antd';
import { IResourceComponentsProps, useCreate, useGetIdentity, useList, useNotification } from '@pankod/refine-core';
import { ICustomer, ICustomerReceipt, IInvoiceStatus, IOrder, IPaymentType } from 'interfaces';
import { GET_INVOICE_LIST, GET_ORDERS, GET_ORDERS_LIST, UPDATE_INVOICE_ID, UPDATE_INVOICE_BALANCE, GET_CUSTOMER_RECEIPT, ORG_NAME, INSERT_CUSTOMER_RECEIPT, UPDATE_CUSTOMER_BALANCE, GET_CUSTOMER_SEARCH, GET_CUSTOMER, GET_ALL_CUSTOMERS_WITH_ORGID } from 'query';
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import dayjs from "dayjs";
import moment, { Moment } from "moment";

const { Option } = Select;

export const CustomerReceipt: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
    const { open, close } = useNotification();
    const [form] = Form.useForm();
    const userId = user?.metadata?.org_id;
    const [customer, setCustomer] = useState<any>([])
    const [customerID, setCustomerId] = useState<any>([])
    const [invoiceList, setInvoice] = useState<any>([])
    const [tableInvoicel, setTableInvoice] = useState<any>([])
    const [customerReceipt, setCustomerReceipt] = useState<any>([])
    const [newCustomerReceipt, setNewCustomerReceipt] = useState<any>([])
    const [disableSave, setDisableSave] = useState<any>(false)
    const [OrgName, setOrgName] = useState([]);
    const [receiptBalance, setReceiptBalance] = useState<any>(0);
    const [showModal, setShowModal] = useState(false)
    const [customerIdReseipt, setCustomerIdReseipt] = useState<any>()
    const [show, setShow] = useState<boolean>(false) 
    const [showCustomerReceipt, setShowCustomerReceipt] = useState<any>([])


    useEffect(()=>{
        getCustomerReceipt()
    }, [userId])

    useEffect(()=>{

    })

    useEffect(()=>{
        if(userId){
            ORG_NAME(userId).then((response: any) => {
                setOrgName(response?.ob_orgs[0]?.orgname_prefix);
            });
        }
    },[userId])

    const getCustomerReceipt = () =>{
        GET_CUSTOMER_RECEIPT(userId).then((res: any)=>{
            if(res?.customer_receipt?.length > 0){
                setCustomerReceipt(res?.customer_receipt[0]?.receipt_no)
                CountFunc(res?.customer_receipt[0]?.receipt_no)
            }else{
                CountFunc(`${OrgName} - 0000`)
            }
        })
    }

    useEffect(() => {
        const handleDevToolsChange = (event:any) => {
          if (event.detail.isOpen) {
            // Developer tools are open
            // You can take action here, such as displaying a warning message
            // alert('Developer tools are open!');
          }
        };
    
        window.addEventListener('devtoolschange', handleDevToolsChange);
    
        return () => {
          window.removeEventListener('devtoolschange', handleDevToolsChange);
        };
    }, []);

    const {data: invoice} = useList<IInvoiceStatus>({
        resource: "invoices",
        config:{
            filters:[
                {
                    field: "ob_org_id",
                    operator: "eq",
                    value: userId,
                }
            ]
        },
        metaData:{
            fields: ["id", "paymentreference", "invoice_date", "orderids", "price", "total", "paymentreference"]
        }
    })

    const {tableProps: tableData, sorter, tableQueryResult} = useTable<ICustomerReceipt>({
        resource: "customer_receipt",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter:[
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
            {
                field: "customer_id",
                operator: "eq",
                value: customerIdReseipt,
            },
        ],
        metaData:{
            fields: [
                "id", "receipt_date", "receipt_no", "customer_id", "invoice_reference_no", "payment_type", 
                "payment_reference_no", "amount_paid", "balance_amount", "ob_org_id", "created_by", "is_active", "remark"
            ]
        }  
    })

    useEffect(() =>{
        getCustomers()
    },[userId])

    const getCustomers = () => {
        GET_ALL_CUSTOMERS_WITH_ORGID(userId).then((res: any) => {
          setCustomer(res?.customers)
        })
      }
    
    function CountFunc(prodCount: any) {
        let mock = prodCount?.split('-');
        let count = Number(mock[1]) + 1;
        let incrementvalue: any = (+count);
        setNewCustomerReceipt(incrementvalue = ("0000" + incrementvalue).slice(-4))
        return incrementvalue = ("0000" + incrementvalue).slice(-4);
      };
    const handleCustomerSelect = (e: any) => {

        GET_CUSTOMER_SEARCH(e, userId).then((res: any) => {
            const filterCustomer: any = res?.customers[0]
            setCustomerId(filterCustomer)
            GET_ORDERS_LIST(userId, e).then((res: any) =>{
                
                GET_INVOICE_LIST(userId, res?.orders).then((res:any)=>{
    
                    let filterInvoices = res?.invoices?.filter((item: any) => item?.is_paid !== true && item?.outstanding > 0)
                    let ex_reference: string = ''
                    let reference: string = ''
                    filterInvoices?.map((data: any, index: number) => {
                        reference += data?.orderids;
                        if (index !== filterInvoices.length - 1) {
                            reference += "&";
                        }
                        if (data?.sage_invoice_no !== null) {
                            ex_reference += data?.sage_invoice_no;
                            if (index !== filterInvoices.length - 1) {
                                ex_reference += "&";
                            }
                        }
                    });
                    
                    let total_balance_amount = filterInvoices.reduce((accumulator: any, currentValue: any) => {
                        return accumulator + currentValue?.outstanding;
                      }, 0)
                      form.setFieldsValue({
                        "customer_name":filterCustomer?.name,    
                        "balance":total_balance_amount?.toFixed(2) || 0,
                        "reference":reference,
                        "ex_reference":ex_reference,
                    })
                    setInvoice([...filterInvoices])
                    setTableInvoice([...filterInvoices])
                    if(filterInvoices?.length === 0){
                        setDisableSave(true)
                        open?.({
                            key:"success",
                            type: "success",
                            message: "",
                            description: `No invoices are pending`,
                        })
                    }else{
                        setDisableSave(false)
                    }
                    setTimeout(() => { close("success"); }, 5000);
                })
            })  
        })
    }

    const handlePaymentAmount = (e:any) =>{
        let paymentAmount =e.target.value ;
        let newPayment:any = []
        let updatedInvoices = invoiceList.map((data: any) => {
            if (paymentAmount !== 0 ) {
                let newOutstanding = data.outstanding - paymentAmount
                if(newOutstanding <=0) newOutstanding = 0
                let amountPaid = data.outstanding - newOutstanding;

                if(paymentAmount - data.outstanding <=0){
                    paymentAmount = 0
                }else{
                    paymentAmount = paymentAmount - data.outstanding
                }
                // newPayment.push(data)
                return { ...data, outstanding: newOutstanding, amount_paid: amountPaid };
            }
            return { ...data, amount_paid: 0 };
        });
        setReceiptBalance(paymentAmount)
        setTableInvoice(updatedInvoices);
    }
    
    const onFinish = () => {

        let values = form.getFieldsValue() 
        let completeInvoice: any = []
        let remarks:string = ''
        let amount_paid = tableInvoicel?.reduce((accumulator: any, currentValue: any) => {return accumulator + currentValue?.amount_paid}, 0)        
        let total_payment_amount = customerID?.total_payment_amount !== null || customerID?.total_payment_amount !== 0 ? customerID?.total_payment_amount + amount_paid : amount_paid
        let total_balance_amount = 0
        
        if(customerID?.total_payment_amount > 0){
            let countAmount = Number(customerID?.total_balance_amount) - Number(values?.amount_paid)
            if(countAmount < 0){
                total_balance_amount +=0
                remarks =  `${customerID?.total_balance_amount}`
            }else{
                total_balance_amount +=countAmount
                remarks =  `${countAmount}`
            }
        }
        
        if(receiptBalance !== 0 ){
            if(total_balance_amount <= 0){
                total_balance_amount = receiptBalance
            }
        }

        INSERT_CUSTOMER_RECEIPT({
            Objects:{
                receipt_no: `CR${OrgName}-${newCustomerReceipt}`,
                receipt_date: moment(values.receipt_date).toISOString(),
                customer_id: customerID?.id,
                invoice_reference_no: values?.reference,
                payment_type: values?.payment_method,
                payment_reference_no: values?.ex_reference,
                amount_paid: values?.amount_paid,
                balance_amount: receiptBalance,
                ob_org_id: userId,
                created_by: user?.id,
                is_active: true,
                remark: remarks
            }
        }).then((res: any) =>{
            tableInvoicel?.map((data: any) =>{
                if(data?.outstanding === 0){
                    completeInvoice.push(data?.orderids)
                }
                UPDATE_INVOICE_BALANCE(data?.id,data?.outstanding)
            })

            UPDATE_CUSTOMER_BALANCE(customerID?.id, total_balance_amount, total_payment_amount)
    
            if(completeInvoice?.length > 0){
                completeInvoice?.forEach((InvoiceId: any) =>{
                    UPDATE_INVOICE_ID(userId,InvoiceId)
                })   
            }
            
            open?.({
                key: "success",
                type: "success",
                message: "",
                description: `Customer receipt successfully saved`,
            });
            setTimeout(() => {
                close("success");
            }, 3000)

            handleCustomerSelect(customerID?.id)
            // GET_CUSTOMER(userId)
            // form.resetFields();
            // setInvoice([])
            getCustomerReceipt()
        })
    }

    const handelModalClose = () =>{
        setShowModal(false)
        setShow(false)
    }

    const handelOpenModal = () =>{
        tableQueryResult.refetch()
        setShowModal(true)
    }

    const handleCustomerIDSelect = (e: any) =>{
        setCustomerIdReseipt(e)
    }

    const handleOnClearSearch = () => {
        setCustomerIdReseipt(null);
    };

    const handleCustomerSearch = (search: any) => {
        const searchValue = customer?.filter((data: any, i: number) => {
          return data?.name?.toLowerCase().includes(search?.toLowerCase()) || data?.customer_reference_code?.toLowerCase().includes(search?.toLowerCase())
        })
    
        if (searchValue?.length !== 0) {
          setCustomer([...searchValue])
        }
        else {
          setCustomer([])
        }
    
        if(search?.length === 0){
          getCustomers()
        }
      }

    return (
        <Container>
            <div style={{display:'flex', justifyContent: 'center'}}>
                <h3 style={{textAlign:'end', marginBottom:'20px', width: '54%'}}>Customer Receipt</h3>
                <div style={{display:'flex', width:'46%', justifyContent: 'flex-end'}}>
                    <Button type="primary" style={{ width: '100px' }} onClick={handelOpenModal}>View All</Button>
                </div>
            </div>
            <Form
                form={form}
                onFinish={onFinish} 
                initialValues = {
                    {
                        receipt_date: dayjs(moment().toDate())
                    }
                }
            >
                <Row gutter={{xs: 24, sm: 24, md: 24, lg: 24}}>
                    <Col  span={24}>
                        <div className="" style={{display:'flex', justifyContent:'space-between'}}>
                            <div>
                                <h5>Customer Details</h5>
                                <div style={{display:'flex',gap:'20px', marginLeft: '10px', marginTop: '20px'}}>
                                    <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                                        <div style={{display: 'flex',gap:'20px'}}>
                                            <p className='fontStyle5'>Sage Account No</p>
                                            <Form.Item
                                            name= 'saga_account_no'
                                            rules={[{ required: true, message: 'Please select saga account' }]}
                                            style={{marginLeft:'20px', width: '100%'}}
                                            >
                                                <Select
                                                    showSearch
                                                    filterOption={false}
                                                    onSearch={handleCustomerSearch}
                                                    onChange={handleCustomerSelect}
                                                    style={{width: '100%'}}
                                                >
                                                    {customer?.map((cus: any) => {
                                                    return (
                                                        <Option key={cus?.id}>
                                                        {`Code: ${cus?.customer_reference_code}:  ${cus?.name}`}
                                                        </Option>
                                                    );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>

                                        <div style={{display: 'flex',gap:'20px'}}>
                                            <p className='fontStyle5'>Customer Name</p>
                                            <Form.Item
                                            name= 'customer_name'
                                            style={{marginLeft:'20px'}}
                                            >
                                                <Input className="custom-input" readOnly/>
                                            </Form.Item>
                                        </div>

                                        <div style={{display: 'flex',gap:'20px'}}>
                                            <p className='fontStyle5'>Balance</p>
                                            <Form.Item
                                            name = 'balance'
                                            style={{marginLeft:'20px'}}
                                            >
                                                <Input className="custom-input" readOnly/>
                                            </Form.Item>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>     
                            <div className="customer_receipt_box">
                                <h5>Receipt Details</h5>
                                <div style={{display:'flex',gap:'20px', marginLeft: '10px', marginTop: '20px'}}>
                                    <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                                        <div style={{display: 'flex',gap:'20px'}}>
                                            <p className='fontStyle5'>Date</p>
                                            <Form.Item
                                            name='receipt_date'
                                            style={{width: '100%',marginLeft:'25px'}}
                                            >
                                                <DatePicker defaultValue={dayjs(moment().toDate())}  style={{width: '100%'}}/>
                                            </Form.Item>
                                        </div>

                                        <div style={{display: 'flex',gap:'20px'}}>
                                            <p className='fontStyle5'>Reference</p>
                                            <Form.Item
                                                name= 'reference'
                                                style={{width: '100%',marginLeft:'25px'}}
                                            >
                                                <Input readOnly/>
                                            </Form.Item>
                                        </div>

                                        <div style={{display: 'flex',gap:'20px'}}>
                                            <p className='fontStyle5'>Ex Reference</p>
                                            <Form.Item
                                                name= 'ex_reference'
                                                style={{width: '100%',marginLeft:'25px'}}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </div>
                                        
                                        <div style={{display: 'flex',gap:'20px'}}>
                                            <p className='fontStyle5'>Payment Type</p>
                                            <Form.Item
                                                name= 'payment_method'
                                                rules={[{ required: true, message: 'Please select payment type' }]}
                                                style={{width: '100%',marginLeft:'25px'}}
                                            >
                                                <Select 
                                                    options={[
                                                        { value: 'Cash', label: 'Cash' },
                                                        { value: 'Paypal', label: 'Paypal' },
                                                        { value: 'Stripe', label: 'Stripe' },
                                                        { value: 'Bank Transfer', label: 'Bank Transfer'},
                                                        { value: 'World Pay', label: 'World Pay'}
                                                    ]}
                                                />
                                            </Form.Item>
                                        </div>
                                        
                                        <div style={{display: 'flex',gap:'20px'}}>
                                            <p className='fontStyle5' style={{marginBottom:'5px'}}>Amount Paid (in GBP)</p>
                                            <Form.Item
                                                name= 'amount_paid'
                                                rules={[{ required: true, message: 'Enter the payment amount' }]} 
                                            >
                                                <Input onChange={(e)=>handlePaymentAmount(e)}/>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                        </div>
                    </Col>
                </Row>
            
                <Row gutter={{xs: 24, sm: 24, md: 24, lg: 24}}>
                    <Col span={24}>
                        <Table dataSource={tableInvoicel}>
                            <Table.Column title = "Invoice No" dataIndex= "orderids"/>
                            <Table.Column title = "Invoice date" dataIndex= "invoice_date"/>
                            <Table.Column title = "Invoice amount" dataIndex= "total"
                                render={(data: any) => data?.toFixed(2) }
                            />
                            <Table.Column title = "Paid amount" dataIndex= "paid_amount"
                                render={(data: any) => data?.toFixed(2) || 0 }
                            />
                            <Table.Column title = "Balance amount" dataIndex= "outstanding"
                                render={(data: any) => data?.toFixed(2) }
                            />
                            <Table.Column title = "Receipt amount" dataIndex= "amount_paid"
                                render={(data: any) => data?.toFixed(2) }
                            />
                        </Table>
                    </Col>
                </Row>
                <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }} justify='end'>
                    <Col>
                    </Col>

                    <Col>
                        <Form.Item >
                            <div style={{display:'flex', width:'100%', justifyContent: 'flex-end'}}>
                                <Button type="primary" style={{marginTop: '20px', width: '100px' }} htmlType='submit' disabled = {disableSave}>Save</Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Modal 
                title="Customer Receipt"
                visible = {showModal} 
                onCancel={handelModalClose}
                footer = {false}
                width={'70%'}
            >
                <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
                    <Col span={24}>
                        <Form
                            layout= 'vertical'
                        >
                            <div style={{display:'flex', justifyContent: 'flex-end'}}>
                                <Form.Item
                                    label = "Customer"
                                    style={{width:'30%'}}
                                >
                                    <Select
                                        allowClear
                                        clearIcon="*"
                                        showSearch
                                        filterOption={false}
                                        onSearch={handleCustomerSearch}
                                        onClear={handleOnClearSearch}
                                        onSelect={handleCustomerIDSelect}
                                        style={{width: '100%'}}
                                    >
                                        {customer?.map((cus: any) => {
                                        return (
                                            <Option key={cus?.id}>
                                            { cus?.name}
                                            </Option>
                                        );
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Form>
                    </Col>
                </Row>

                <Row>    
                    <Table
                        {...tableData}
                        style={{width: '100%'}}
                    >
                        <Table.Column 
                            dataIndex="receipt_no"
                            title="Receipt No"
                        />
                        <Table.Column 
                            dataIndex="receipt_date"
                            title="Receipt date"
                            render={(value) =>moment(value).format('DD/MM/YYYY')} 
                            defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                        />
                        <Table.Column 
                            dataIndex="invoice_reference_no"
                            title="Invoice Reference no"
                        />
                        <Table.Column 
                            dataIndex="payment_type"
                            title="Payment Type"
                        />
                        <Table.Column 
                            dataIndex="amount_paid"
                            title="Amount paid"
                        />
                        <Table.Column 
                            dataIndex="balance_amount"
                            title="Balance amount"
                        />
                        <Table.Column 
                            dataIndex="remark"
                            title="Adjusted Balance"
                        />
                    </Table>
                </Row>
        
            </Modal>
        </Container>
    );
};