import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
// import { Editor } from '@tinymce/tinymce-react';
import { Col, Row } from 'antd';
import {
  List,
} from "@pankod/refine-antd";
import { PictureOutlined, DownOutlined, UserOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps, FormInstance, InputRef } from 'antd';
import { message, Upload } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IProduct, IOrg, ICategory, IbrandProduct } from "interfaces";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { nhost } from "utility";
import "./pages.css"

interface DefaultValueProps {
  DefaultValueData?: {} | any
}

type CreateProductProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

const props: UploadProps = {
  beforeUpload: (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSizeInBytes) {
      message.error('File size exceeds the limit (10MB).');
      return false;
    }
    return true;
  },
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
  },
};

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const onChange = (key: string) => {
};

const onChangeCheckbox = (e: CheckboxChangeEvent) => {
};

export const DefaultValues: React.FC<DefaultValueProps> = ({ DefaultValueData }) => {
  const [form] = Form.useForm();
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [open, setOpen] = useState(false)
  const [urlList, setUrlList] = useState([] as any[]);


  const { selectProps: OBProductSelectProps } = useSelect<IProduct>({
    resource: "ob_products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBProductSelectBuyProps } = useSelect<IProduct>({
    resource: "products",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBBrandSelectProps } = useSelect<IbrandProduct>({
    resource: "brands",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBCustomerSelectProps } = useSelect<IbrandProduct>({
    resource: "customers",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const { selectProps: categorySelectProps } = useSelect<ICategory>({
    resource: "categories",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      {
        const data = new FormData()
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4")
        data.append("cloud_name", "dov89ugan")
        data.append("folder", "orderbit")
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data
        })
          .then(resp => resp.json())
          .then(data => {
            setUrlList(urlList => [...urlList, data.secure_url]);
          })
          .catch(err => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const apidata = (e: any) => {
    const MUTATIONAPI =
      `mutation MyMutation {
            update_ob_orgs(_set: {track_inventory:"${e.track_inventory}",backOrder:"${e.backOrder}",default_product_image:"${e.default_product_image}" }, where: { id: { _eq: "${userId}" } }) {
              affected_rows
            }
          }`


    const onSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000);
      return data
    }
    onSubmit();

  }

  useEffect(() => {
    form.setFieldsValue(DefaultValueData?.data?.data[0])
  }, [DefaultValueData?.data])

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <>
      <Form
        // {...formProps}
        // name="basic"
        form={form}
        wrapperCol={{ span: 23 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        onFinish={(e) => {
          e.default_product_image = urlList.join(",")
          apidata(e)
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Track inventory"
              name="track_inventory"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select>
                <Select.Option value="-" name="track_inventory" >-</Select.Option>
                <Select.Option value="yes" name="track_inventory" >Yes</Select.Option>
                <Select.Option value="no" name="track_inventory" >No</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Can backorder "
              name="backOrder"
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select>
                <Select.Option value="-" name="backOrder" >-</Select.Option>
                <Select.Option value="yes" name="backOrder" >Yes</Select.Option>
                <Select.Option value="no" name="backOrder" >No</Select.Option>
              </Select>
            </Form.Item>

            {/* <Form.Item
            name="default_product_image"
            label="Default Product Image"
          >
            <Input type='file' />
          </Form.Item> */}

            <Form.Item name="default_product_image">
              <div style={{ marginTop: '-1.5%' }}>
                <Dragger {...props} name="default_product_image" customRequest={uploadImages} accept="image/*"
                  style={{ width: '100%', marginTop: '30px' }}>
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Default Product Image(or Click)</p>
                </Dragger>
              </div>
            </Form.Item>
            <Form.Item
              style={{ width: '100%' }}
            >
              <strong style={{ marginTop: '10px' }}>Uploaded image will be displayed here</strong>
              {urlList.map((url) => {
                return <img style={{ width: '10%', padding: '5px' }} src={url} />
              })}
            </Form.Item>

            <Form.Item>
              <input type="submit" className="input-save"
                value="Save" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal visible={open}
        maskStyle={{ width: "0px" }}
        bodyStyle={{ height: "80px" }}
        onCancel={handleCancel}
        footer={null}
        width="27%"
        style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

          <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
          <span style={{ paddingLeft: "3%", }}>
            Successfully Updated Default Value
          </span>
        </div>
      </Modal>
    </>


  );
};