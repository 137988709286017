import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber,
  } from "@pankod/refine-antd";
  import { useGetIdentity } from "@pankod/refine-core";

  
  type CreateCategoryProps = {
    modalProps: ModalProps;
    formProps: FormProps;
  };
  
  export const CreateCategory: React.FC<CreateCategoryProps> = ({
    modalProps,
    formProps,
  }) => {
    const { data: user } = useGetIdentity();

    return (
      <Modal {...modalProps} title="Create Category">
        <Form {...formProps} size="large" layout="vertical">
          <Form.Item
            label="Category Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="created_by" 
          initialValue={user?.id}
          >
            <Input type="hidden"/>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  