import { Checkbox, Col, Form, Input, Modal, Row, Select } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { useState } from "react";
import { ModalProps } from "react-bootstrap";
import { FormProps } from "reactstrap";



type EditAdditionalChanrgeProps = {
    modalProps: ModalProps ;
    formProps: FormProps;
  };

export const EditAdditionalChanrgeOption: React.FC<EditAdditionalChanrgeProps> = ({
    modalProps,
    formProps,
  }) =>{

    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;

    return(
        <Modal {...modalProps} title="Edit Additional Charges">
            <Form {...formProps}
                wrapperCol={{ span: 23 }}
                autoComplete="off"
                layout="vertical"
            >
                <Row>
                    <Col span={12}>

                        <Form.Item
                            name= "charges_name"
                            label ="Charges Name"
                            rules={[{ required: true, }]}
                        >
                            <Input style={{ width: "100%" }}/>
                        </Form.Item>
                        <Form.Item
                            name= "charges_type"
                            label ="Charges Type"
                            rules={[{ required: true, }]}
                        >
                            <Select>
                            <Select.Option value="amount" name="Amount">Amount</Select.Option>
                            <Select.Option value="percentage" name="Percentage">Percentage</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name= "rate"
                            label ="Rate"
                            rules={[{ required: true, }]}
                        >
                            <Input style={{ width: "100%" }}/>
                        </Form.Item>
                        <Form.Item name="is_active" valuePropName="checked">
                            <Checkbox>
                                Active
                            </Checkbox>
                        </Form.Item>
                        <Form.Item name="updated_by"
                        initialValue={user?.id}
                        >
                            <Input type="hidden" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
  }