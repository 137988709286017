import React, { useEffect, useState } from 'react';

import { useLogin, useTranslate, useNavigation, useGetIdentity, } from "@pankod/refine-core";

import {
    AntdLayout, Menu, MenuProps, Icons, useMenu
} from "@pankod/refine-antd";
import {
    Title,
    Header,
    Footer,
    OrderBitLayout,
    OffLayoutArea,
} from "components/layout";
import { Routes, Route, Link } from 'react-router-dom';
import { ContainerOutlined, MessageOutlined, StarOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { OrdersList } from './list';
import { OrderDetailShow } from './Show';
import { OrderStatusList } from './orderStatus';
import { InvoiceList } from './invoices/list';
import { RouteList } from './dispatch/route';
import { TripList } from './dispatch/trip';
import { OrderViewList } from './dispatch/orderselect';
import { TripSheetList } from './dispatch/tripsheet';
import { CustomerReceipt } from './customerReceipt';
import {ListTBC} from "./TBC/list"
import { CustomerAlert } from './customerAlert';
import { GET_ORG } from 'query';
import { AuthPermission } from 'utility/authPermission';
import { useMenuContext } from 'contaxt/menuContext';
const { BarcodeOutlined ,AppstoreOutlined ,DollarOutlined ,TagOutlined,SkinOutlined ,BlockOutlined,SettingTwoTone,UnorderedListOutlined,
    PercentageOutlined, GatewayOutlined, HeatMapOutlined } = Icons;
const { Sider, Content } = AntdLayout;
const { SubMenu } = Menu;


const items2: MenuProps['items'] = [Icons.UserOutlined, Icons.LaptopOutlined, Icons.NotificationOutlined].map(
    (icon, index) => {
        const key = String(index + 1);

        return {
            key: `sub${key}`,
            icon: React.createElement(icon),
            label: `subnav ${key}`,

            children: new Array(1).fill(null).map((_, j) => {
                const subKey = index * 1 + j + 1;
                return {
                    key: subKey,
                    label: `option${subKey}`,
                };
            }),
        };
    },
);

export const OrdersPage: React.FC = () => {

    const { push } = useNavigation();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
    const { data: user } = useGetIdentity();
    const {menuPermission, setPermissions} = useMenuContext()

    useEffect(()=>{
      if(user){
        AuthPermission(user?.metadata?.org_id).then((res: any) =>{
          setPermissions(res)
        })
      }
    },[user])
    
    return (
      // <OrderBitLayout dashboard={true}>
        <AntdLayout style={{ minHeight: "90vh", flexDirection: "row" }}>
          <Sider width={200} className="site-layout-background">
            <h2 style={{ margin: "20px" }}>Orders</h2>
            <Menu
              theme="light"
              selectedKeys={[selectedKey]}
              defaultOpenKeys={defaultOpenKeys}
              mode="inline"
              onClick={({ key }) => {
                push(key as string);
              }}
            >
              <Menu.Item
                icon={<BarcodeOutlined />}
                key="/order"
                onClick={() => {
                  push("/order");
                }}
                style={{
                  fontWeight: selectedKey === "/order" ? "bold" : "normal",
                }}
              >
                Orders
              </Menu.Item>
              {user?.metadata?.org_id === "1e84e052-4427-4808-b100-870391d93c2d" && 
              <Menu.Item
                icon={<TagOutlined />}
                key="/order/tbc"
                onClick={() => {
                  push("/order/tbc");
                }}
                style={{
                  fontWeight: selectedKey === "/order/tbc" ? "bold" : "normal",
                }}
              >
                TBC
              </Menu.Item>
              }

              {user?.metadata?.org_id === "1e84e052-4427-4808-b100-870391d93c2d" &&
              <Menu.Item
                icon={<MessageOutlined />}
                key="/order/customerAlert"
                onClick={() => {
                  push("/order/customerAlert");
                }}
                style={{
                  fontWeight: selectedKey === "/order/customerAlert" ? "bold" : "normal",
                }}
              >
                Custom Alert
              </Menu.Item>
              }
              {!menuPermission &&
              <Menu.Item
                icon={<ContainerOutlined />}
                key="/order/orderstatus"
                onClick={() => {
                  push("/order/orderstatus");
                }}
                style={{
                  fontWeight:
                    selectedKey === "/order/orderstatus" ? "bold" : "normal",
                }}
              >
                Status
              </Menu.Item>
              }
              <Menu.Item
                icon={<BarcodeOutlined />}
                key="/order/invoice"
                onClick={() => {
                  push("/order/invoice");
                }}
                style={{
                  fontWeight:
                    selectedKey === "/order/invoice" ? "bold" : "normal",
                }}
              >
                Invoices
              </Menu.Item>
              
              
              {!menuPermission //&&
                // <Menu.Item
                //   icon={<ContainerOutlined />}
                //   key="/order/customerReceipt"
                //   onClick={() => {
                //     push("/order/customerReceipt");
                //   }}
                //   style={{
                //     fontWeight:
                //       selectedKey === "/order/customerReceipt" ? "bold" : "normal",
                //   }}
                // >
                //   Customer Receipt
                // </Menu.Item>
              }
              {!menuPermission //&&
                // <SubMenu
                //   key="order/route"
                //   icon={<TagOutlined />}
                //   title="Despatch"
                // >
                  
                //   <Menu.Item
                //     key="/order/orderview"
                //     icon={<HeatMapOutlined />}
                //     onClick={() => {
                //       push("/order/orderview");
                //     }}
                //     style={{
                //       fontWeight:
                //         selectedKey === "/order/orderview" ? "bold" : "normal",
                //     }}
                //   >
                //     Order View
                //   </Menu.Item>
                //   <Menu.Item
                //     key="/order/route"
                //     icon={<GatewayOutlined />}
                //     onClick={() => {
                //       push("/order/route");
                //     }}
                //     style={{
                //       fontWeight:
                //         selectedKey === "/order/route" ? "bold" : "normal",
                //     }}
                //   >
                //     Route
                //   </Menu.Item>
                //   <Menu.Item
                //     key="/order/trip"
                //     icon={<BlockOutlined />}
                //     onClick={() => {
                //       push("/order/trip");
                //     }}
                //     style={{
                //       fontWeight:
                //         selectedKey === "/order/trip" ? "bold" : "normal",
                //     }}
                //   >
                //     Trip
                //   </Menu.Item>
                //   <Menu.Item
                //     key="/order/tripsheet"
                //     icon={<ContainerOutlined />}
                //     onClick={() => {
                //       push("/orders/tripsheet");
                //     }}
                //     style={{
                //       fontWeight:
                //         selectedKey === "/order/tripsheet" ? "bold" : "normal",
                //     }}
                //   >
                //     Trip Sheet
                //   </Menu.Item>
                // </SubMenu>
              }

            </Menu>
          </Sider>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Routes>
              <Route index element={<OrdersList />} />
              <Route path="orders" element={<OrdersList />} />
              <Route path="customers" element={<OrdersList />} />
              <Route path="orderstatus" element={<OrderStatusList />} />
              <Route path="orderview" element={<OrderViewList />} />
              <Route path="route" element={<RouteList />} />
              <Route path="trip" element={<TripList />} />
              <Route path="tripsheet" element={<TripSheetList />} />
              {/* <Route path="/show/:id" element={<OrderDetailShow/>} /> */}
              <Route path="invoice" element={<InvoiceList />} />
              <Route path="customerReceipt" element={<CustomerReceipt />} />
              <Route path="customerAlert" element={<CustomerAlert />} />
              <Route path="tbc" element={<ListTBC />} />
              <Route path="*" element={<div>Order Page 404 </div>} />
            </Routes>
          </Content>
        </AntdLayout>
      // </OrderBitLayout>
    );
};
