
import { IResourceComponentsProps,useExport, CrudFilters, HttpError, useGetIdentity, useNotification } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    DatePicker,
    Button,
    Card,
    Input,
    Form,
    FormProps,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm,Icons,
    useSelect,
    Popconfirm,
} from "@pankod/refine-antd";
import { ITax } from "interfaces";
import { CreateTax } from "./create";
import { EditTax } from "./edit";
import { ReloadOutlined } from "@ant-design/icons";
import { IOrg } from "interfaces";
import { Delete_Taxes } from "query";

const { PlusOutlined,MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;


const { RangePicker } = DatePicker;

export const TaxList: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
  const { open, close } = useNotification();

  const userId = user?.metadata?.org_id;
    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<ITax>({
        resource: "taxes",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
        ],
        metaData: {
            fields: ["id", "vat_class","vat_rate","vat_customer_group","ob_org_id","name","created_at"],
        },
    });
    
    const { formProps, modalProps, show } = useModalForm({
        resource: "taxes",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,
        
    } = useModalForm({
        resource: "taxes",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "vat_class","vat_rate","vat_customer_group","ob_org_id","name","created_at"],
        },
    });

    const { selectProps: vatClassSelectProps } = useSelect<IOrg>({
        resource: "vat_class",
        optionLabel: "name",
        optionValue: "id",
        metaData: {
          fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc", },]
      });
      const { selectProps: vatGroupSelectProps } = useSelect<IOrg>({
        resource: "vat_group",
        optionLabel: "name",
        optionValue: "id",
        metaData: {
          fields: ["id", "name"],
        },
        sort: [{ field: "name", order: "asc", },]
      });

    const { triggerExport, isLoading } = useExport<ITax>();
    let datacount = tableQueryResult?.data?.total;

    const handleDelete = (data: any) => {
        Delete_Taxes(data).then((repsone:any) => {
          if (repsone) {
            open({type: "success",key: "delete_success", message: "", description: "Successfully Deleted"});
            setTimeout(() => {
                close("delete_success")
            }, 4000);
            tableQueryResult?.refetch();
          }
        });
      };

    return (
        <List 
        title="VAT Rules"
            pageHeaderProps={{

                extra: [
                  //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                    <RefreshButton
                        type="primary"
                        children={<div style={{ display: "flex", alignItems: "center" }}>
                            <ReloadOutlined style={{ marginRight: "5px" }} />
                            Refresh
                        </div>}
                        icon={false}
                    onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" resourceName="settings/orderstatuses"
                     children={<div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Add VAT Rule
                  </div>}
                  icon={false}
                      onClick={() => show()} />,
                ],

            }}
        >
            <span>Records: {datacount}</span>
            <Table {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="name" title="Name" />
                <Table.Column dataIndex="vat_class" title="VAT Class" 
                render={(value:any, record:any) => {
                    const findVat:any = vatClassSelectProps?.options?.find((res:any) => {
                        return res?.value == record?.vat_class
                    });
                    return findVat?.label
                }}
                />
                <Table.Column dataIndex="vat_rate" title="VAT Rate" />
                <Table.Column dataIndex="vat_customer_group" title="VAT Customer Group" 
                render={(value:any, record:any) => {
                    const findVat:any = vatGroupSelectProps?.options?.find((res:any) => {                        
                        return res?.value == record?.vat_customer_group
                    });
                    return findVat?.label
                }}
                />
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<ITax>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                resourceName="settings/tax"
                                onClick={() => editShow(record.id)}
                            />
                            {/* <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                resourceName="settings/tax"
                                recordItemId={record.id}
                            /> */}
                            <Popconfirm
                                title="Delete?"
                                onConfirm={() => handleDelete(record.id)}
                            >
                                <a style={{ color: "red" }}>Delete</a>
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
            <CreateTax modalProps={modalProps} formProps={formProps} />
            <EditTax modalProps={editModalProps} formProps={editFormProps} />

        </List>
    );
};
