import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber, Select, useSelect, Checkbox, Button, Upload, Space, UploadProps, message
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { GET_ALL_CUSTOMERS_WITH_ORGID, GET_ALL_CUSTOMER_GRPS_WITH_ORGID } from "query";
import { DeleteOutlined, MinusCircleOutlined, PictureOutlined, PlusOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Option } = Select;
type EditBrandProps = {
    modalProps: ModalProps;
    formProps: FormProps;
};

const { Dragger } = Upload;
const props: UploadProps = {
  beforeUpload: (file) => {
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSizeInBytes) {
      message.error('File size exceeds the limit (10MB).');
      return false;
    }
    return true;
  },
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info) {        
    const { status } = info.file;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
      return info?.file?.response
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
  },
};

export const EditBrand: React.FC<EditBrandProps> = ({
    modalProps,
    formProps,
}) => {
    const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [active,setActive] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [customers, setCustomers] = useState<any>([]);
  const [customerGrps, setCustomerGrps] = useState<any>([]);
    const [Private,setPrivate] = useState(false);
    const [urlList, setUrlList] = useState<any>([] as any[]);
    const [imageChange, setImageChange] = useState<any>(false);

    const getCustomers = () => {
      GET_ALL_CUSTOMERS_WITH_ORGID(userId).then((data:any) => {
        if(data){        
          setCustomers(data?.customers)
        }
      })
    }
  
    const getAllCustomerGrps = () => {
      GET_ALL_CUSTOMER_GRPS_WITH_ORGID(userId).then((res:any) => {      
        setCustomerGrps(() => []);
        setCustomerGrps(res?.customer_groups);
      })
    }
  
    useEffect(() => {
      getCustomers();
      getAllCustomerGrps();
      setPrivacy(formProps?.initialValues?.private)
      setPrivacy(formProps?.initialValues?.private)
      setUrlList([formProps.initialValues?.image]) 
    },[userId, formProps?.initialValues])

    const onChangeCheckbox = (e: CheckboxChangeEvent) => {
        setActive(e.target.checked);
      };
      const onChangeViewCheckbox = (e: CheckboxChangeEvent) => {
        setPrivate(e.target.checked);
      };

      const handleCustomerSearch = (search:any) => {
        if(search?.length > 0){
        const searchValue = customers?.filter((data:any,i:number) => {            
         return data?.name?.toLowerCase().includes(search?.toLowerCase())
        })
        if(searchValue?.length !== 0){
          setCustomers([...searchValue])
        }
        else{
          getCustomers();
        }
      }
      else{
        getCustomers();
      }
      }
    
      const handleCustomerGrpSearch = (customerGrpsearch: string) => {
    
        if (customerGrpsearch?.length > 0) {
          const filterProduct = customerGrps?.filter((cusGrp: any) => {
            return cusGrp?.group_name?.toLowerCase().includes(customerGrpsearch?.toLocaleLowerCase())
          })
          if (filterProduct?.length != 0) {
            setCustomerGrps([...filterProduct])
          }
          else {
            getAllCustomerGrps();
          }
        } else {
          getAllCustomerGrps();
        }
      };
     
      const onChangeCheckboxPrivacy = (e: any) => {
        let checkbox = e.target.checked
        setPrivacy(checkbox);
      };

      const uploadImages = () => async (options:any) => {    
        const { onSuccess, onError, file } = options;
        setImageChange(false);
        try {
          const data = new FormData();
    
          data.append("file", file);
          data.append("upload_preset", "bt5y9hp4");
          data.append("cloud_name", "dov89ugan");
          data.append("folder", "orderbit");
      
          fetch("https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
            method: "post",
            body: data,
          })
            .then((resp) => resp.json())
            .then((data) => {
                setUrlList( [data.secure_url]); 
              setImageChange(true);
              formProps?.form?.setFieldsValue({
                [`image`]: data.secure_url
              });          
              return onSuccess(data.secure_url);
            })
            .catch((err) => {
              console.log(err);
              onError({ err });
            });
        } catch (err) {
          console.log(err);
          onError({ err });
        };
      };

      const removeImage = (index:any) => {
      setUrlList([]);
      
      formProps?.form?.setFieldsValue({
        [`image`]: null
      }); 
      } 


      const imageStyles = { width: '10%', paddingTop: '10px', paddingBottom: '15px', paddingRight: '10px' }
    return (
        <Modal {...modalProps} title="Edit Brand">
            <Form {...formProps}  layout="vertical">
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  // rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <TextArea rows={4} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
            label="Sort"
            name="sort"
          >
            <Input />
          </Form.Item>
          <Form.Item
                    // name="images"
                    label="Main Image"
                  >
                    <Dragger
                      showUploadList={false}
                      {...props}
                      customRequest={uploadImages()}
                    >
                      <p className="ant-upload-drag-icon">
                        <PictureOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Drag image file here to upload(or Click)
                      </p>
                    </Dragger>
                  </Form.Item>
                  <Form.Item name="images" style={{ width: "100%" }}>
                    <h1 style={{ marginTop: "10px", fontSize: "14px" }}>
                      Uploaded image will be displayed here
                    </h1>
                    {(() => {
                      if (urlList.length) {
                        return urlList.map((url:any) => {
                          return (
                            <div>
                              <img style={imageStyles} src={url} />
                              {url ? (
                                <span>
                                  <DeleteOutlined onClick={removeImage} />
                                </span>
                                
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        });
                      }
                      return <></>;
                    })()}
                  </Form.Item>

                      {urlList || imageChange ? (
                        <Form.Item
                          label="df"
                          name="image"
                          initialValue={urlList[0]}
                          style={{ display: "none" }}
                        >
                          <Input value={urlList[0]}/>
                        </Form.Item>
                      ) : (
                        <></>
                      )}
          <Form.Item name="active" valuePropName="checked">
          <Checkbox name='active' checked={active} onChange={onChangeCheckbox}>Active</Checkbox>
          </Form.Item>
              <Form.Item
                label=""
                name="featured_brand"
                valuePropName="checked"
              >
                <Checkbox name="featured_brand">
                  Show in Featured Brand (homepage)
                </Checkbox>
              </Form.Item>
          {/* <Form.Item name="private" valuePropName="checked">
            <Checkbox name='private' checked={Private} onChange={onChangeViewCheckbox}>Private</Checkbox>
          </Form.Item> */}
                <p>By making a brand private, it will be visible only to selected customers.</p>
                <div>
                <Form.Item
                  label=""
                  name="private"
                  valuePropName="checked"
                >
                  <Checkbox
                    name="private"
                    onChange={onChangeCheckboxPrivacy}
                  >
                    Private
                  </Checkbox>
                </Form.Item>
                {privacy === true ? (
                  <>
                    {user?.defaultRole === "admin" ? (
                      <Form.Item
                        name="access_customer_id"
                        label="Customer"
                        style={{ width: "210px" }}
                      >
                        {/* <Select {...OBCustomerSelectProps} /> */}
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Select Customers"
                        name="access_customer_ids"
                      >
                        <Select
                          mode="multiple"
                          showSearch
                          onSearch={handleCustomerSearch}
                          // onChange={handleSelectedChange}
                          filterOption={false}
                        >
                          {customers?.map((data: any) => {
                            return <Option key={data?.id}>{data?.name}</Option>;
                          })}
                        </Select>
                      </Form.Item>
                    )}
                    <Form.Item
                      name="access_customergroup_ids"
                      label="Customer Groups"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder=""
                        onSearch={handleCustomerGrpSearch}
                        filterOption={false}
                        defaultValue={
                          formProps?.initialValues?.access_customergroup_ids
                        }
                      >
                        {customerGrps?.map((e: any, i: any) => {
                          return <Option key={e?.id}>{e?.group_name}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </>
                ) : (
                  ""
                )}
              </div>
                <Form.Item name="updated_by"
                    initialValue={user?.id}>
                    <Input type="hidden" />
                </Form.Item>

            </Form>
        </Modal>
    );
};
