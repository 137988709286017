import { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
} from "@pankod/refine-core";
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import {
  Button,
  CreateButton,
  DateField,
  DatePicker,
  EditButton,
  Form,
  FormProps,
  Icons,
  Input,
  List,
  Popconfirm,
  RefreshButton,
  Select,
  Space,
  Table,
  getDefaultSortOrder,
  useModalForm,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import { IFaq, IPostFilterVariables } from "interfaces";
import { CreateTrip } from "./create";
import { EditTrip } from "./edit";
import { DELETE_TRIP } from "query";
import { ReloadOutlined } from "@ant-design/icons";
const { PlusOutlined } = Icons;
const { RangePicker } = DatePicker;

export const TripList: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const { open, close } = useNotification();
  const userId = user?.metadata?.org_id;
  const [tripId, setTripId] = useState<any>(null)
  const [maxViewOrder, setMaxViewOrder] = useState<any>(0);

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<
    IFaq,
    HttpError,
    IPostFilterVariables
  >({
    resource: "trip",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "id",
        operator: "eq",
        value: tripId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "route_id",
        "date",
        "start_time",
        "end_time",
        "driver_name",
        "vehicle_number",
        "notes",
        "ob_org_id",
        "name",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
        "due_time",
        "seq_number",
      ],
    },
    onSearch: (params) => {
        const filters: CrudFilters = [];
        const { name, created_at } = params;

        filters.push(
            {
                field: "name",
                operator: "contains",
                value: name !== '' ? name : null,
            },
        );

        return filters;
    },
  });
  const { tableProps:newTableProps, sorter: newTableSorter, tableQueryResult: newTableQuery, searchFormProps : newTableSearch } = useTable<
    IFaq,
    HttpError,
    IPostFilterVariables
  >({
    resource: "trip",
    initialSorter: [
      {
        field: "created_at",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
      {
        field: "id",
        operator: "eq",
        value: tripId,
      },
    ],
    metaData: {
      fields: [
        "id",
        "route_id",
        "date",
        "start_time",
        "end_time",
        "driver_name",
        "vehicle_number",
        "notes",
        "ob_org_id",
        "name",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
        "due_time",
        "seq_number",
      ],
    },
    onSearch: (params) => {
        const filters: CrudFilters = [];
        const { name, created_at } = params;

        filters.push(
            {
                field: "name",
                operator: "contains",
                value: name !== '' ? name : null,
            },
        );

        return filters;
    },
  });
  let datacount = tableQueryResult?.data?.total;

  const { formProps, modalProps, show } = useModalForm({
    resource: "trip",
    action: "create",
    redirect: false,
    autoSubmitClose: true,
    autoResetForm: true,
  });
  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: editShow,
  } = useModalForm({
    resource: "trip",
    action: "edit",
    redirect: false,
    autoSubmitClose: true,
    autoResetForm: true,
    metaData: {
      fields: [
        "id",
        "route_id",
        "date",
        "start_time",
        "end_time",
        "driver_name",
        "vehicle_number",
        "notes",
        "ob_org_id",
        "name",
        "created_at",
        "created_by",
        "updated_at",
        "updated_by",
        "due_time",
        "seq_number",
      ],
    },
  });

  const { selectProps } = useSelect<any>({
    resource: "trip",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  const { selectProps: routeSelectProps } = useSelect<any>({
    resource: "route",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  const handleDelete = (data: any) => {
    DELETE_TRIP(data).then((repsone:any) => {      
      if (repsone?.delete_trip) {
        open({
          key: "delete_success",
          type: "success",
          message: "",
          description: "Successfully Deleted",
        });
        setTimeout(() => {
          close("delete_success");
        }, 2000);
        tableQueryResult?.refetch();
      }
      else {
        open({
          key: "delete_error",
          type: "error",
          message: "",
          description: "You have to remove this Trip from Order",
        });
        setTimeout(() => {
          close("delete_error");
        }, 3000);
      }
    });
  };

  const handleSearch = (e: any) => {
    setTripId(e);
  };

  const handleOnClearSearch = () => {
    setTripId(null);
  };

  
  useEffect(() => {
    if(tableProps?.dataSource){
    const maxViewOrder = tableProps?.dataSource?.reduce((max:any, item:any) => {
      const viewOrder = item.seq_number;
      return viewOrder > max ? viewOrder : max;
    }, 0);
    if(maxViewOrder){
      setMaxViewOrder(maxViewOrder)
    }
  }
},[tableProps]);  
  
  return (
    <List
      title="Orderbit Trip"
      pageHeaderProps={{
        extra: [
          <>
          <Form layout="vertical">
              <Form.Item label="Name">
                <Select
                  placeholder="Select a Trip"
                  style={{ width: 300, marginRight: 20 }}
                  onSelect={handleSearch}
                  clearIcon="*"
                  allowClear
                  onClear={handleOnClearSearch}
                  {...selectProps}
                />
              </Form.Item>
            </Form>
          <CreateButton
            style={{ marginTop: "15px" }}
            type="primary"
            children={
              <div style={{ display: "flex", alignItems: "center" }}>
                <PlusOutlined style={{ marginRight: "5px" }} />
                Add Trip
              </div>
            }
            icon={false}
            resourceName="store/news"
            onClick={() => show()}
          />,
          </>
        ],
      }}
    >
      <span>Records: {datacount}</span>
      { tripId ? 
      <Table
        style={{ marginTop: "5px" }}
        {...newTableProps}
        rowKey="id"
        size="small"
      >
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="route_id"
          title="Route"
          render={(value) => {
            const findRoute = routeSelectProps?.options?.find((res:any) => value == res?.value)            
            return findRoute?.label
          }}
        />
        <Table.Column
          dataIndex="date"
          title="Date"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="start_time"
          title="Start Time"
          render={(value) => <DateField value={value} format="HH:mm:ss" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="end_time"
          title="End Time"
          render={(value) => <DateField value={value} format="HH:mm:ss" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="due_time"
          title="Due Time"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        {/* <Table.Column dataIndex="driver_name" title="Driver Name" />
        <Table.Column dataIndex="vehicle_number" title="Vehicle Number" /> */}
        <Table.Column<IFaq>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                resourceName="store/news"
                onClick={() => editShow(record.id)}
              />
              <Popconfirm
                title="Delete?"
                onConfirm={() => handleDelete(record.id)}
              >
                <a style={{ color: "red" }}>Delete</a>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      : <Table
        style={{ marginTop: "5px" }}
        {...tableProps}
        rowKey="id"
        size="small"
      >
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="route_id"
          title="Route"
          render={(value) => {
            const findRoute = routeSelectProps?.options?.find((res:any) => value == res?.value)            
            return findRoute?.label
          }}
        />
        <Table.Column
          dataIndex="date"
          title="Date"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="start_time"
          title="Start Time"
          render={(value) => <DateField value={value} format="HH:mm:ss" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="end_time"
          title="End Time"
          render={(value) => <DateField value={value} format="HH:mm:ss" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="due_time"
          title="Due Time"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("date", sorter)}
          sorter
        />
        {/* <Table.Column dataIndex="driver_name" title="Driver Name" />
        <Table.Column dataIndex="vehicle_number" title="Vehicle Number" /> */}
        <Table.Column<IFaq>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                size="small"
                type="link"
                icon={null}
                recordItemId={record.id}
                resourceName="store/news"
                onClick={() => editShow(record.id)}
              />
              <Popconfirm
                title="Delete?"
                onConfirm={() => handleDelete(record.id)}
              >
                <a style={{ color: "red" }}>Delete</a>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
      }

      <CreateTrip modalProps={modalProps} formProps={formProps}  viewOrderMax ={maxViewOrder}/>
      <EditTrip modalProps={editModalProps} formProps={editFormProps} />
    </List>
  );
};
const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps} style={{ display: "flex" }}>
      <Form.Item label="Search" name="name">
        <Input
          allowClear
          placeholder="Title"
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item style={{ marginLeft: "5px", marginTop: "21px" }}>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
