import { List, Tabs } from "@pankod/refine-antd";
import {
    IResourceComponentsProps,
    useNavigation,
    useShow,
  } from "@pankod/refine-core";
  import { UserManagementTable } from "pages/settings/usermanagement";
import { AdminManagementTable } from "../adminmanagement";

  const {TabPane} = Tabs
  export const UserManagementList: React.FC<IResourceComponentsProps> = () => {
    const { show } = useNavigation();
    return (
      <List title ={"User"}>
        <UserManagementTable />
        {/* <Tabs defaultActiveKey="1" type="card">
          <TabPane tab = "User" key={1}>
            <UserManagementTable />
          </TabPane>
          <TabPane tab = "Admin" key={2}>
            <AdminManagementTable />
          </TabPane>
        </Tabs> */}
      </List>
    )
  };