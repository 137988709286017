import {
    Form,
    Input,
    ModalProps,
    FormProps,
    Modal,
    InputNumber,
    Checkbox,
    Select
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DeleteOutlined, PictureOutlined, } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { UploadProps } from 'antd';
import { nhost } from "utility";
import { useNotification } from "@pankod/refine-core";
import { GET_CATEGORIES_BY_ORGID } from "query";

type EditCategoryProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    closeEditModal: () => void;
    openEditModal: boolean;
};


export const EditCategory: React.FC<EditCategoryProps> = ({
    modalProps,
    formProps,
    closeEditModal,
    openEditModal
}) => {
    const { data: user } = useGetIdentity();
  const [trackInventory, settrackInventory] = useState(false);
  const { open, close } = useNotification();

  const { Option } = Select;
  const { Dragger } = Upload;
  const userId = user?.metadata?.org_id;

  const [ urlList, setUrlList ] = useState<any>();
  const categoryId = formProps?.initialValues?.id;

  const [allCategories, setAllCategories] = useState<any>([]);


  const getCategories = async () => {
    GET_CATEGORIES_BY_ORGID(userId).then((response: any) => {
        setAllCategories(response?.categories);
    });
};

useEffect(() => {
  getCategories();
},[userId, formProps?.initialValues]);
console.log(formProps?.initialValues,"formProps?.initialValues?");

    const onChangeCheckbox = (e: CheckboxChangeEvent) => {
        settrackInventory(e.target.checked)
      };

      useEffect(() => {
        setUrlList([formProps?.initialValues?.image_url])
      },[formProps?.initialValues]);

      const props: UploadProps = {
        beforeUpload: (file) => {
          const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
          if (file.size > maxSizeInBytes) {
            message.error('File size exceeds the limit (10MB).');
            return false;
          }
          return true;
        },
        name: 'file',
        multiple: true,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
          }
          if (status === 'done') {        
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
        },
      };
    
      const uploadImages = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options;
        try {
          {
            const data = new FormData()
            data.append("file", file);
            data.append("upload_preset", "bt5y9hp4")
            data.append("cloud_name", "dov89ugan")
            data.append("folder", "orderbit")
            fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
              method: "post",
              body: data
            })
              .then(resp => resp.json())
              .then(data => {

                setUrlList((urlList:any) => [ data.secure_url]);
              })
              .catch(err => console.log(err)
              );
            onSuccess("Ok");
          }
        } catch (err) {
          const error = new Error("Some error");
          onError({ err });
        }
      };
      
      const removeImage = () => {
        setUrlList([])
      }

      const apiData = (e:any) => {
    
        e.updated_by = user?.id ? user?.id : null;
        e.is_active = e.is_active != undefined ? e.is_active : false;
        // e.image_url = e.image_url ? e.image_url : null;
    
        let createCategory = `mutation MyMutation {
            update_categories(where: {id: {_eq: "${categoryId}"}}, _set: {image_url: ${e.image_url ? `"${e.image_url}"` : null},
            parent_id: ${e.parent_id ? `"${e.parent_id}"` : null},
             is_active: ${e.is_active}, name: "${e.name}",featured_category: ${e.featured_category}, updated_by: "${e.updated_by}"}) {
              affected_rows
              returning {
                id
                name
              }
            }
          }`
    
        const querySubmit = async () => {
          const { data }:any = await nhost.graphql.request(createCategory);
          
          if (data) {
            open?.({
              key:"success",
              type: "success",
              message: "",
              description: `${data?.update_categories?.returning[0]?.name} Category Sucessfully Updated`,
          });
            setTimeout(() => {
              close("success");
            }, 4000);
            closeEditModal();
            return data
          }
        }
        querySubmit();
        // setUrlList([]);
      }
      
    return (
        <Modal className="Edit_category" {...modalProps} title="Edit Category"
         onCancel={closeEditModal}
         visible={openEditModal}
        >
        <Form {...formProps} layout="vertical"
          onFinish={(e) => {
            e.image_url = urlList?.join(" ")
            apiData(e)
          }}
        >
          <Form.Item
            label="Category Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Parent Category"
            name="parent_id"
          >
            <Select
            allowClear
            clearIcon="*"
            >
              {allCategories?.map((category: any) => {
                return <Option key={category?.id} value={category?.id}>{category?.name}</Option>
              })}
            </Select>
          </Form.Item>
          <Form.Item label="" name="is_active" valuePropName="checked">
            <Checkbox name="is_active" onChange={onChangeCheckbox}>Is active?</Checkbox>
          </Form.Item>
          <Form.Item label="" name="featured_category" valuePropName="checked">
            <Checkbox>Show in Featured Category (homepage)</Checkbox>
          </Form.Item>
          <Form.Item label="Image" name="image_url">
            <div className="Image_div">
              <div>
                <Dragger showUploadList={false}
                  {...props}
                  name="image_url"
                  customRequest={uploadImages}
                >
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">Drag image file here to upload(or Click)</p>
                </Dragger>
              </div>
              {/* <Form.Item
                    style={{ width: '100%' }}
                  > */}
              <div className="category_img_div">
                <h1 style={{ fontSize: "14px" }}>Uploaded image will be displayed here</h1>
                {urlList?.map((url: any) => {
                  return <div>
                    <img className="category_img" src={url} />
                    {url ? <span ><DeleteOutlined onClick={removeImage} /></span> : <></>}
                  </div>
                })}
              </div>
            </div>
          </Form.Item>
          {/* </Form.Item> */}
          <Form.Item name="updated_by"
            style={{ display: "none" }}
            initialValue={user?.id}>
            <Input type="hidden" />
          </Form.Item>

        </Form>
        </Modal>
    );
};
