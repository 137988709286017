import {
  Checkbox,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  useSelect,
  Select,
  DatePicker,
  Row,
  InputNumber,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import dayjs from "dayjs";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";

type EditFaqProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};
const { TextArea } = Input;
const { TimePicker } = DatePicker;

export const EditTrip: React.FC<EditFaqProps> = ({ modalProps, formProps }) => {
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [routeId, setRouteId] = useState<any>(null);
  const [formattedValue, setFormattedValue] = useState('');

  const { selectProps } = useSelect<any>({
    resource: "route",
    metaData: {
      fields: ["id", "name"],
    },
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "ob_org_id",
        operator: "eq",
        value: userId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value: "%" + value + "%",
      },
    ],
  });

  const handleSearch = (e: any) => {
    setRouteId(e);
  };

  const handleOnClearSearch = () => {
    setRouteId(null);
  };

  const validateStartTime = async (_:any, start_time:any) => {
      
      const end_time = formProps?.form?.getFieldValue("end_time");
      const startTimeString = moment(start_time).format("HH:mm:ss");

      if(startTimeString !== "00:00:00"){
        if (startTimeString === "00:00:00") {
            throw new Error("Start time cannot be 00:00:00");
          }
        
          if (end_time && moment(startTimeString, "HH:mm:ss").isAfter(moment(end_time, "HH:mm:ss"))) {
            throw new Error("Start time must be before End time");
          }
      }
      else{
          if (start_time.format("HH:mm:ss") === "00:00:00") {
            throw new Error("Start time cannot be 00:00:00");
          }
          if (end_time && start_time && start_time.isAfter(end_time)) {
              throw new Error("Start time must be before End time");
            }
      }
  };

  const validateEndTime = async (_:any, end_time:any) => {
    
    const start_time = formProps?.form?.getFieldValue("start_time");
    const endTimeString = moment(end_time).format("HH:mm:ss");
    
    if(endTimeString !== "00:00:00"){
        if (endTimeString === "00:00:00") {
            throw new Error("End time cannot be 00:00:00");
          }
        
          if (start_time && moment(endTimeString, "HH:mm:ss").isAfter(moment(start_time, "HH:mm:ss"))) {
            throw new Error("End time must be after Start time");
          }
      }
      else{
          if (end_time.format("HH:mm:ss") === "00:00:00") {
            throw new Error("End time cannot be 00:00:00");
          }
          if (start_time && end_time && end_time.isBefore(start_time)) {
            throw new Error("End time must be after Start time");
          }
      }
  };

  const validateDueTime = async (_:any, due_time:any) => {    
    const start_time = formProps?.form?.getFieldValue("start_time");
    const dueTimeString = moment(due_time).format("HH:mm:ss");
    if(dueTimeString !== "00:00:00"){
        if (dueTimeString === "00:00:00") {
            throw new Error("Due time cannot be 00:00:00");
          }
    }
    else{
        if (due_time.format("HH:mm:ss") === "00:00:00") {
          throw new Error("Due time cannot be 00:00:00");
        }
    }
    // if (start_time && end_time && end_time.isBefore(start_time)) {
    //   throw new Error("Due time must be after start time");
    // }
  };

  const handleInputChange = (value: any, from:any) => {
    let getValue = from == "useEffect" ? value : value?.target?.value;
      if (getValue !== null && getValue !== undefined && getValue !== "") {
        const parsedValue = parseInt(getValue, 10);
        if (!isNaN(parsedValue)) {
          const formatted = parsedValue.toString().padStart(2, '0');
          setFormattedValue(formatted);
          formProps?.form?.setFieldsValue({ [`seq_number`]: formatted });
        }
      }
  };

  useEffect(() => { 
    const nextValue = formProps?.initialValues?.seq_number.toString().padStart(2, "0");
    formProps?.form?.setFieldsValue({ [`seq_number`]: nextValue });
    setFormattedValue(nextValue)
    setTimeout(() => {
      handleInputChange(nextValue, "useEffect") 
    }, 300);
  }, [formProps?.initialValues?.seq_number, formProps]);

  return (
    <Modal {...modalProps} title="Edit Trip">
      <Form {...formProps} size="large" layout="vertical">
        <Form.Item label="Trip Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item
          name="route_id"
          label="Select Route"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select a Route"
            onSelect={handleSearch}
            clearIcon="*"
            allowClear
            onClear={handleOnClearSearch}
            {...selectProps}
          />
        </Form.Item>
        <Row justify="space-between">
          <Form.Item
            label="Date"
            name="date"
            rules={[{ required: true }]}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : "",
            })}
          >
            <DatePicker
              disabledDate={(current) => {
                return current && current < moment().startOf("day");
              }}
            />
          </Form.Item>
          <Form.Item
            label="Start Time"
            name="start_time"
            rules={[
                { required: true, message: "Please select a Start time!" },
                { validator: validateStartTime },
              ]}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : null,
            })}
          >
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item
            label="End Time"
            name="end_time"
            rules={[
              { required: true, message: "Please select an End time!" },
              { validator: validateEndTime },
            ]}
            getValueProps={(value) => ({
              value: value ? dayjs(value) : null,
            })}
          >
            <TimePicker format="HH:mm:ss" />
          </Form.Item>
          <Form.Item
            label="Due Time"
            name="due_time"
            getValueProps={(value) => ({
                value: value ? dayjs(value) : null,
            })}
            rules={[
                { required: true, message: "Please select a Due time!" },
                { validator: validateDueTime },
              ]}
            >
             <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </Row>

        <Form.Item label="Driver Name" name="driver_name">
          <Input />
        </Form.Item>
        <Form.Item label="Vehicle Number" name="vehicle_number">
          <Input />
        </Form.Item>
        <Form.Item
        label="Sequence Number"
        name="seq_number"
        rules={[{ required: true }]}

      >
        <Input
        type="number"
        min={1} 
        value={formattedValue}
        onChange={(value:any) => handleInputChange(value,"onchange")}
        />
      </Form.Item>
        <Form.Item label="Notes" name="notes">
          <TextArea />
        </Form.Item>
        <Form.Item
          label=""
          name="updated_by"
          initialValue={user?.id}
          style={{ display: "none" }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
