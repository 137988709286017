
import { IResourceComponentsProps, useExport, CrudFilters, HttpError } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    DatePicker,
    Button,
    Card,
    Input,
    Form,
    FormProps,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons
} from "@pankod/refine-antd";
import { ICustomerGroup } from "interfaces";
import { CreateCustomerGroup } from "./create";
import { EditCustomerGroup } from "./edit";
const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;


const { RangePicker } = DatePicker;

export const CustomerGroupList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<ICustomerGroup>({
        resource: "customer_groups",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: ["id","group_name", "ob_org_id", "created_at",],
        },
    });

    const { formProps, modalProps, show } = useModalForm({
        resource: "customer_groups",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,

    } = useModalForm({
        resource: "customer_groups",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "group_name", "ob_org_id"],
        },
    });

    const { triggerExport, isLoading } = useExport<ICustomerGroup>();


    return (
        <List
            title="Customer Group"
            pageHeaderProps={{

                extra: [
                    //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                    <RefreshButton onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton type="primary" resourceName="settings/customergroups" children="Add Customer Group" icon={<PlusOutlined />} onClick={() => show()} />,
                ],

            }}
        >
            <Table {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="group_name" title="Name" />
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<ICustomerGroup>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                resourceName="settings/customergroups"
                                onClick={() => editShow(record.id)}
                            />
                            <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                resourceName="settings/customergroups"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            <CreateCustomerGroup modalProps={modalProps} formProps={formProps} />
            <EditCustomerGroup modalProps={editModalProps} formProps={editFormProps} />

        </List>
    );
};
