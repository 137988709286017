
import { IResourceComponentsProps, useExport, CrudFilters, HttpError } from "@pankod/refine-core";
import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons, DatePicker, Form,
    Input, Button, FormProps, Typography, Popconfirm, Modal
} from "@pankod/refine-antd";
import { IPages, IPostFilterVariables } from "interfaces";
import { CreatePages } from "./create";
import { EditPages } from "./edit";
import { useGetIdentity } from "@pankod/refine-core";
import { useEffect, useState } from 'react';
import { Delete_News, Delete_Pages } from "../../../query";
import { CheckCircleOutlined, ReloadOutlined } from '@ant-design/icons';


const { PlusOutlined, CheckOutlined, CloseOutlined } = Icons;
const { RangePicker } = DatePicker;


export const PagesList: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
    const userId = user?.metadata?.org_id;
    const [open, setOpen] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false)

    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IPages, HttpError, IPostFilterVariables>({
        resource: "pages",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
        ],
        metaData: {
            fields: ["id", "name", "ob_org_id", { org: ["name"] }, "meta_description", "header_menu", "header_submenu", "footer_menu", "content", "view_order", "active", "login", "private", "customer", "privacy_group", "file", "date", "created_at"],
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { name, created_at } = params;

            filters.push(
                {
                    field: "name",
                    operator: "eq",
                    value: name !== '' ? name : null,
                },
                {
                    field: "created_at",
                    operator: "gte",
                    value: created_at ? created_at[0].toISOString() : undefined,
                },
                {
                    field: "created_at",
                    operator: "lte",
                    value: created_at ? created_at[1].toISOString() : undefined,
                },
            );

            return filters;
        },
    });

    let datacount = tableQueryResult?.data?.total;

    const handleCloseCreateModal = () => setCreateModalVisible(false)
    const handleCloseEditModal = () => setEditModalVisible(false)


    const { formProps, modalProps, show } = useModalForm({
        resource: "pages",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,
    } = useModalForm({
        resource: "pages",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "name", "ob_org_id", "meta_description", "header_menu", "header_submenu", "footer_menu", "content", "view_order", "active", "login", "private", "customer", "privacy_group", "file", "date", "created_at"],
        },
    });


    const handleFormSubmitted = () => setFormSubmitted(true);

    useEffect(() => {
        if (createModalVisible) {
            setFormSubmitted(false)
        }
    }, [createModalVisible])

    useEffect(() => {

        if (!formSubmitted) return;

    }, [formSubmitted])



    const { triggerExport, isLoading } = useExport<IPages>();
    const { Text } = Typography;
    const handleDelete = (data: any) => {
        Delete_Pages(data).then((repsone) => {
            if (repsone) {
                setOpen(true);
                setTimeout(() => {
                    setOpen(false);
                }, 4000);
                tableQueryResult?.refetch();
            }
        })
    }

    const handelEditModal = (data:any) =>{
        editShow(data)
        setEditModalVisible(true)
    }
    return (

        <List
            title="Orderbit Pages"
            pageHeaderProps={{

                extra: [
                    <Filter formProps={searchFormProps} />,
                    //  <ExportButton onClick={triggerExport} loading={isLoading} />,
                    <RefreshButton style={{ marginTop: '15px' }}
                    type="primary"
                  children={<div style={{ display: "flex", alignItems: "center" }}>
                    <ReloadOutlined style={{ marginRight: "5px" }} />
                    Refresh
                  </div>}
                  icon={false}
                    onClick={() => tableQueryResult?.refetch()} />,
                    <CreateButton style={{ marginTop: '15px' }} type="primary"
                     children={<div style={{ display: "flex", alignItems: "center" }}>
                    <PlusOutlined style={{ marginRight: "5px" }} />
                    Add Pages
                  </div>}
                  icon={false}
                      resourceName="store/pages" onClick={() =>
                        // show()

                        setCreateModalVisible(true)

                    } />,
                ],
            }}
        >
            <span>Records: {datacount}</span>

            <Table style={{ marginTop: '5px' }} {...tableProps} rowKey="id" size="small">
                <Table.Column dataIndex="name" title="Name" />
                {user?.defaultRole === "admin" ?
                    <Table.Column<IPages> dataIndex="ob_org_id" title="Org"
                        render={(_, record) => record?.org?.name}
                        defaultSortOrder={getDefaultSortOrder("ob_org_id", sorter)}
                        sorter /> : ""}
                <Table.Column dataIndex="meta_description" title="Description"
                    render={(value) => <span style={{ overflowWrap: 'anywhere' }}>{value}</span>}
                />
                {/* <Table.Column dataIndex="customer" title="Customers" />
                <Table.Column dataIndex="privacy_group" title="Privacy Groups" /> */}
                <Table.Column
                    title="Header Menu"
                    dataIndex="header_menu"
                    render={(value) => (value == true ? <CheckOutlined style={{ color: '#6ead65' }} /> : <CloseOutlined style={{ color: '#ff7875' }} />)}
                />
                <Table.Column
                    title="Header Sub Menu"
                    dataIndex="header_submenu"
                    render={(value) => (value == true ? <CheckOutlined style={{ color: '#6ead65' }} /> : <CloseOutlined style={{ color: '#ff7875' }} />)}
                />
                <Table.Column
                    title="Footer Menu"
                    dataIndex="footer_menu"
                    render={(value) => (value == true ? <CheckOutlined style={{ color: '#6ead65' }} /> : <CloseOutlined style={{ color: '#ff7875' }} />)}
                />
                {/* <Table.Column dataIndex="date" title="Date" /> */}
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<IPages>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                resourceName="store/pages"
                                onClick={() => handelEditModal(record.id)}
                            />
                            {/* <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                resourceName="store/pages"
                                recordItemId={record?.id}
                            /> */}
                            <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)} >
                                    <a style={{ color: "red" }}>Delete</a>
                                </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
            <CreatePages tableQueryResult={tableQueryResult} handleFormSubmitted={handleFormSubmitted} closeCreateModal={handleCloseCreateModal} createModalVisible={createModalVisible} modalProps={modalProps} formProps={formProps} />
            <EditPages modalProps={editModalProps}  tableQueryResult={tableQueryResult} formProps={editFormProps}  closeEditModal={handleCloseEditModal} editModalVisible={editModalVisible} />
            <Modal visible={open}
                maskStyle={{ width: "0px" }}
                bodyStyle={{ height: "80px" }}
                footer={null}
                width="27%"
                style={{ position: "absolute", marginTop: "-5%", marginLeft: "70%", gridRow: "auto", }}
            >
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "2%" }}>

                    <CheckCircleOutlined style={{ color: "green", fontSize: "22px" }} />
                    <span style={{ paddingLeft: "3%", }}>
                        Successfully Page deleted
                    </span>
                </div>
            </Modal>
        </List >
    );
};


const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    return (
        <Form layout="vertical" {...formProps} style={{ display: 'flex' }}>
            <Form.Item label="Search" name="name">
                <Input
                    allowClear
                    placeholder="ID, Title, Content, etc."
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            {/* <Form.Item label="Created At" name="created_at" style={{ marginLeft: '5px' }}>
                <RangePicker />
            </Form.Item> */}
            <Form.Item style={{ marginLeft: '5px', marginTop: '21px' }}>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};