import {
  Checkbox,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";

type EditFaqProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};
const { TextArea } = Input;

export const EditRoute: React.FC<EditFaqProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  return (
    <Modal {...modalProps} title="Edit Route">
      <Form {...formProps} size="large" layout="vertical">
        <Form.Item label="Route Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea />
        </Form.Item>
        <Form.Item label="" name="is_active" valuePropName="checked">
          <Checkbox> Show </Checkbox>
        </Form.Item>
        <Form.Item
          label=""
          name="updated_by"
          initialValue={user?.id}
          style={{ display: "none" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label=""
          name="ob_org_id"
          initialValue={userId}
          style={{ display: "none" }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
