import {
  Form,
  Input,
  Divider,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  Button,
  Typography,
  InputNumber, Select, useSelect,
  Dropdown, Space, Tooltip, Menu, Checkbox, Popconfirm, Table
} from "@pankod/refine-antd";
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGetIdentity } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import { useState } from "react";
const { TextArea } = Input;

type CreateMeasurementUnitProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const CreateMeasurementUnit: React.FC<CreateMeasurementUnitProps> = ({
  modalProps,
  formProps,
}) => {
  const { data: user } = useGetIdentity();
  const [notify, setNotify] = useState(false);

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc", },]
  });

  const onChange = (key: string) => {
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setNotify(e.target.checked);

  };
  return (
    <Modal {...modalProps} title="Create Measurement Unit">
      <Form {...formProps}
        wrapperCol={{ span: 23 }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >

        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input your name!' }]}>

          <Input placeholder="Enter the name" onChange={(e) => onChange} style={{ width: "100%" }} />
        </Form.Item>

        {user?.defaultRole === "admin" ?
          <Form.Item
            label="Orderbit Organisation"
            name="ob_org_id"
            rules={[{ required: true, }]} >
            <Select {...OBOrgSelectProps} />
          </Form.Item> :
          <Form.Item
            name="ob_org_id"
            initialValue={user?.metadata?.org_id}
          >
            <Input hidden />
          </Form.Item>
        }

        <Form.Item name="created_by"
          initialValue={user?.id}
        >
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
