
import { IResourceComponentsProps,useExport, CrudFilters, HttpError, useGetIdentity, useNotification } from "@pankod/refine-core";
import {useState} from "react"
import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    DatePicker,
    Button,
    Card,
    Input,
    Form,
    FormProps,
    useTable,
    Space,
    EditButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons, Popconfirm, Select, useSelect
} from "@pankod/refine-antd";
import { IOrderStatus } from "interfaces";
import { CreateOrderStatus } from "./create";
import { EditOrderStatus } from "./edit";
import { DELETE_ORDER_STATUS } from "query";
import { ReloadOutlined } from "@ant-design/icons";
import { Navigate, useNavigate } from "react-router-dom";
const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined, CheckOutlined, CloseOutlined } = Icons;


const { RangePicker } = DatePicker;

export const OrderStatusList: React.FC<IResourceComponentsProps> = () => {
    const { data: user } = useGetIdentity();
    const { open, close } = useNotification();
    const userId = user?.metadata?.org_id;
    const [createPopup, setCreatePopup] = useState(false)
    const [statusId, setStatusId] = useState(null)
     const navigate =useNavigate()
    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IOrderStatus>({
        resource: "order_statuses",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "ob_org_id",
                operator: "eq",
                value: userId,
            },
            {
                field: "id",
                operator: "eq",
                value: statusId,
            },
        ],
        metaData: {
            fields: ["id", "name", "sort", "bg_color", "ob_org_id", "notify_customer", "type",],
        },
    });

    const { formProps, modalProps, show      } = useModalForm({
        resource: "order_statuses",
        action: "create",
        redirect: false,
        
        successNotification:{
            key: "j",
            message: "string",
            type: "success",
            description: "j"
        },
        onMutationSuccess:()=>{

            return false

        }
         
     });
           
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,

    } = useModalForm({
        resource: "order_statuses",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "name", "sort", "bg_color", "ob_org_id", "notify_customer", "type",],
        },
    });

    const { selectProps } = useSelect<any>({
        resource: "order_statuses",
        metaData: {
          fields: ["id", "name"],
        },
        optionLabel: "name",
        optionValue: "id",
        filters:[
          {
            field:"ob_org_id",
            operator:"eq",
            value:userId
          }
         ],
        onSearch: (value:any) => [
          {
            field: "name",
            operator: "contains",
            value: "%" + value + "%",
          },
        ],
      });

    const { triggerExport, isLoading } = useExport<IOrderStatus>();
    let datacount = tableQueryResult?.data?.total;

    const closeCreatePopup = () =>  setCreatePopup(false);

    const deleteOrderStatuses = (record:any) => {
        DELETE_ORDER_STATUS(record?.id).then((res:any) => {
            tableQueryResult?.refetch();
            open?.({
                key: "success",
                type: "success",
                message: "",
        
                description: `${record?.name} has been deleted`,
              });
              setTimeout(() => {
                close("success");
              }, 5000);
        })
        .catch((err:any) => console.log(err,"error"))
    }

    const handleSearch = (value:any) => {
        setStatusId(value)
       }
    
       const handleOnClearSearch = () => {
        setStatusId(null)
       }

       const handleRefresh = () => {
        tableQueryResult?.refetch();
        navigate(0);
      };

    return (
        <List
            title="Order Status"
            pageHeaderProps={{

                extra: [
                    <>
                    <Form
                layout="vertical"
                style={{ display: "flex", marginBottom: 17 }}
              >
                <Form.Item label="Status Name">
                  <Select
                    placeholder="Select a Status Name"
                    style={{ width: 300, marginRight: 20 }}
                    onSelect={handleSearch}
                    clearIcon="*"
                    allowClear
                    onClear={handleOnClearSearch}
                    {...selectProps}
                  />
                </Form.Item>
              </Form>
                      {/* <ExportButton onClick={triggerExport} loading={isLoading} />, */}
                    <RefreshButton
                        type="primary"
                        children={<div style={{ display: "flex", alignItems: "center" }}>
                            <ReloadOutlined style={{ marginRight: "5px" }} />
                            Refresh
                        </div>}
                        icon={false}
                        onClick={() => handleRefresh() } />,
                    <CreateButton type="primary" resourceName="settings/orderstatuses"
                        children={<div style={{ display: "flex", alignItems: "center" }}>
                            <PlusOutlined style={{ marginRight: "5px" }} />
                            Add Order Status
                        </div>}
                        icon={false}
                        onClick={() => {
                            show();
                            setCreatePopup(true);
                        }} />,
                        </>
                ],

            }}
        >
            <span>Records: {datacount}</span>
            <Table {...tableProps} rowKey="id" size="small">

                <Table.Column dataIndex="name" title="Name" />
                <Table.Column dataIndex="bg_color" title="Status Color" />
                <Table.Column dataIndex="notify_customer" title="Notify Customer"
                    render={(value) => (value == true ? <CheckOutlined style={{ color: '#6ead65' }} /> : <CloseOutlined style={{ color: '#ff7875' }} />)}
                />
                {/* <Table.Column dataIndex="sort" title="Sort" /> */}
                <Table.Column dataIndex="type" title="Type" />
                <Table.Column
                    dataIndex="created_at"
                    title="Created At"
                    render={(value) => <DateField value={value} format="LLL" />}
                    defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
                    sorter
                />
                <Table.Column<IOrderStatus>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                size="small"
                                type="link"
                                icon={null}
                                recordItemId={record.id}
                                resourceName="settings/orderstatuses"
                                onClick={() => editShow(record.id)}
                            />
                            {/* <DeleteButton
                                size="small"
                                type="link"
                                icon={null}
                                resourceName="settings/orderstatuses"
                                recordItemId={record.id}
                            /> */}
                            <Popconfirm title="Delete?" onConfirm={() => deleteOrderStatuses(record)} >
                                <a style={{ color: "red" }}>Delete</a>
                            </Popconfirm>
                        </Space>
                    )}
                />
            </Table>
            <CreateOrderStatus modalProps={modalProps} formProps={formProps} closePopup={closeCreatePopup} createPopup={createPopup}/>
            <EditOrderStatus modalProps={editModalProps} formProps={editFormProps} />

        </List>
    );
};
