
import { IResourceComponentsProps, useExport } from "@pankod/refine-core";

import {
    List,
    RefreshButton,
    CreateButton,
    ExportButton,
    Table,
    Button,
    useTable,
    Input,
    Form,
    Space,
    EditButton,
    // ViewButton,
    DeleteButton,
    DateField,
    getDefaultSortOrder,
    useModalForm, Icons
} from "@pankod/refine-antd";
import { ICustomer, ICustomerFilterVariables } from "interfaces";
import { CreateCustomer } from "./create";
import { EditCustomer } from "./edit";
import { EditUser } from "./editUser";
import { useTranslate } from "@pankod/refine-core";
// import TimezoneSelect from 'react-timezone-select';

const { PlusOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } = Icons;


export const ViewOrderList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { tableProps, sorter, tableQueryResult } = useTable<ICustomer>({
        resource: "customers",
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: ["id", "name", "address", "status", "created_at"],
        },
    });
    const { formProps, modalProps, show } = useModalForm({
        resource: "customers",
        action: "create",
        redirect: false,
    });
    const {
        formProps: editFormProps,
        modalProps: editModalProps,
        show: editShow,

    } = useModalForm({
        resource: "customers",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "name", "address", "status", "fullname", "activity", "specify_activity", "email", "price_list", "discount_rate", "created_at", "min_order_value", "phone", "address_line_2", "city", "province", "country", "postal_code", "website", "company_number", "customer_reference_code", "privacy_group", "vat_number", "admin_description", "notify_customer_email", "is_active", "disable_ordering", "outgoing_email", "sales_rep", "addresses", "order_as_XLS", "order_as_PDF", "receive_email_notify", "new_order_notification", "bcc_outging_email", "customer_homepage_products"],
        },
    });

    const {
        formProps: editUserFormProps,
        modalProps: editUserModalProps,
        show: editUserShow,

    } = useModalForm({
        resource: "customers",
        action: "edit",
        redirect: false,
        metaData: {
            fields: ["id", "name", "address", "status", "fullname", "activity", "specify_activity", "email", "price_list", "discount_rate", "created_at", "min_order_value", "phone", "address_line_2", "city", "province", "country", "postal_code", "website", "company_number", "customer_reference_code", "privacy_group", "vat_number", "admin_description", "notify_customer_email", "is_active", "disable_ordering", "outgoing_email", "sales_rep", "addresses", "order_as_XLS", "order_as_PDF", "receive_email_notify", "new_order_notification", "bcc_outging_email", "customer_homepage_products"],
        },
    });
    const { triggerExport, isLoading } = useExport<ICustomer>();


    return (
        <div className="row">
            <div className="col-md-7">
                <div className="card" style={{ margin: "30px 0 0 0px", boxShadow: "0px 3px 5px 1px #ccc", border: "none" }}>
                    <div className="d-flex justify-content-between mt-3 ms-5">
                        <div>
                            <h4>#1</h4>
                            <p>20 sep 2021 04:31</p>
                        </div>
                        <div style={{ margin: "0 35px 0 0px" }}>
                            <span>. processing</span>
                        </div>
                    </div>
                    <div className="ms-5 d-flex" style={{ margin: "0px 0 30px 0px" }}>
                        <div>
                            <h6>Email</h6>
                            <p>lebron@james.com</p>
                        </div>
                        <div style={{ borderLeft: "1px solid #ccc", margin: "0 15px 0 15px" }}></div>
                        <div>
                            <h6>Phone</h6>
                            <p>123456789</p>
                        </div>
                        <div style={{ borderLeft: "1px solid #ccc", margin: "0 15px 0 15px" }}></div>
                        <div>
                            <h6>Payment</h6>
                            <p>Manual</p>
                        </div>
                    </div>
                </div>
                <div className="card" style={{ margin: "30px 0 0 0px", boxShadow: "0px 3px 5px 1px #ccc", border: "none" }}>
                    <div>
                        <h4 className="mt-3 ms-5" style={{ margin: "0px 0 50px 0px" }}>
                            Summary
                        </h4>
                        <div >
                            <div className="d-flex justify-content-between mt-3 ms-5">
                                <div>
                                    <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="55px" style={{ borderRadius: "12px" }} />
                                    <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
                                </div>
                                <div style={{ margin: "0 45px 0 0px" }}>
                                    <span style={{ margin: "0 10px 0 0" }}>$30.00</span>
                                    <span style={{ margin: "0 10px 0 0" }}>x 3</span>
                                    <span style={{ margin: "0 10px 0 0" }}>$90.00</span>
                                    <span>usd</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3 ms-5">
                                <div>
                                    <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="55px" style={{ borderRadius: "12px" }} />
                                    <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
                                </div>
                                <div style={{ margin: "0 45px 0 0px" }}>
                                    <span style={{ margin: "0 10px 0 0" }}>$30.00</span>
                                    <span style={{ margin: "0 10px 0 0" }}>x 3</span>
                                    <span style={{ margin: "0 10px 0 0" }}>$90.00</span>
                                    <span>usd</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3 ms-5">
                                <div>
                                    <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="55px" style={{ borderRadius: "12px" }} />
                                    <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
                                </div>
                                <div style={{ margin: "0 45px 0 0px" }}>
                                    <span style={{ margin: "0 10px 0 0" }}>$30.00</span>
                                    <span style={{ margin: "0 10px 0 0" }}>x 3</span>
                                    <span style={{ margin: "0 10px 0 0" }}>$90.00</span>
                                    <span>usd</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3 ms-5">
                                <div>
                                    <span>Subtotal</span>
                                </div>
                                <div style={{ margin: "0 45px 0 0px" }}>
                                    <span style={{ margin: "0 10px 0 0" }}>$150.00</span>
                                    <span>USD</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3 ms-5">
                                <div>
                                    <span>Shipping</span>
                                </div>
                                <div style={{ margin: "0 45px 0 0px" }}>
                                    <span style={{ margin: "0 10px 0 0" }}>$10.00</span>
                                    <span>USD</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3 ms-5">
                                <div>
                                    <span>Tax</span>
                                </div>
                                <div style={{ margin: "0 45px 0 0px" }}>
                                    <span style={{ margin: "0 10px 0 0" }}>$0.00</span>
                                    <span>USD</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3 ms-5">
                                <div>
                                    <span>Original Total</span>
                                </div>
                                <div style={{ margin: "0 45px 0 0px" }}>
                                    <h3>$160.00</h3>
                                    {/* <span>USD</span> */}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3 ms-5">
                                <div>
                                    <span>Refunded for Returns</span>
                                </div>
                                <div style={{ margin: "0 45px 0 0px" }}>
                                    <span style={{ margin: "0 10px 0 0" }}>$30.00</span>
                                    <span>USD</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3 ms-5" style={{ margin: "0px 0px 40px 0px" }}>
                                <div>
                                    <span>Net Total</span>
                                </div>
                                <div style={{ margin: "0 45px 0 0px" }}>
                                    <h5>$130.00</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-5">
                <div className="card" style={{ margin: "30px 0 0 0px", boxShadow: "0px 3px 5px 1px #ccc", border: "none" }}>
                    <h4 className="mt-3 ms-5">Timeline</h4>
                    <Input type="text" placeholder="Enter customer name" style={{ width: "80%", margin: "0px 0 30px 55px" }} />
                    <hr></hr>
                    <p className="mt-3 ms-5" style={{ fontWeight: "700" }}>oli@medusajs.com</p>
                    <span className="ms-5">Sat, 05 Feb 2022 22:16:03 GMT</span>
                    <div>
                        <div className="d-flex justify-content-between mt-3 ms-5">
                            <div>
                                <p>Refund</p>
                                <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
                            </div>
                            <div>
                                <p style={{ margin: "0 35px 0 0px" }}>$30.00</p>
                                <p style={{ margin: "0 35px 0 0px" }}>Return</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 ms-5">
                            <div>
                                <p>Refund</p>
                                <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 ms-5">
                            <div className="d-flex justify-content-between">
                                <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="44px" style={{ borderRadius: "12px" }} />
                                <div>
                                    <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
                                    <p style={{ margin: "0 0 0 10px" }}>s</p>
                                </div>
                            </div>
                            <div style={{ margin: "0 35px 0 0px" }}>
                                <p>x 1</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 ms-5">
                            <div>
                                <p>Refund</p>
                                <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 ms-5">
                            <div className="d-flex justify-content-between">
                                <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="44px" style={{ borderRadius: "12px" }} />
                                <div>
                                    <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
                                    <p style={{ margin: "0 0 0 10px" }}>s</p>
                                </div>
                            </div>
                            <div style={{ margin: "0 35px 0 0px" }}>
                                <p>x 1</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 ms-5">
                            <div>
                                <p>Refund</p>
                                <p style={{ margin: "-14px 0 0px 0" }}>Mon, 20 Sep 2021 11:21:43 GMT</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 ms-5" style={{ margin: "0px 0 137px 0px" }}>
                            <div className="d-flex justify-content-between">
                                <img src="https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGVuc3xlbnwwfHwwfHw%3D&w=1000&q=80" alt="img" width="35px" height="44px" style={{ borderRadius: "12px" }} />
                                <div>
                                    <span style={{ margin: "0 0 0 10px" }}>Medusa Water Bottle</span>
                                    <p style={{ margin: "0 0 0 10px" }}>s</p>
                                </div>
                            </div>
                            <div style={{ margin: "0 35px 0 0px" }}>
                                <p>x 1</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card" style={{ margin: "30px 0 0 0px", boxShadow: "0px 3px 5px 1px #ccc", border: "none" }}>

            </div>
        </div>
    );
};
